const initialState = {
    item: {},
    loading: true,
    error: null,
    errorDel: null,
};

const customerReducer = (state = initialState, action) => {
    switch (action.type){
        case 'SET_CUSTOMER_LOADING':
            return {
                ...state,
                loading: true
            };
        case 'SET_CUSTOMER_SUCCESS':
            return {
                ...state,
                loading: false,
                item: action.payload.customer,
                error: null
            };
        case 'SET_CUSTOMER_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case 'SET_CUSTOMER_DELETE_SUCCESS':
            return {
                ...state,
                item: {},
                loading: true,
                errorDel: null
            };
        case 'SET_CUSTOMER_DELETE_FAILURE':
            return {
                ...state,
                loading: false,
                errorDel: action.payload.errorDel
            };
        default:
            return state;
    }
};

export default customerReducer;
