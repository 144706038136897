import React from "react";
// Material UI components
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
// styles
import withStyles from "@material-ui/core/styles/withStyles";
import notificationErrorMsgStyle from '../../styles/jss/components/general/notificationErrorMsgStyle';

const NotificationErrorMsg = (props) => {
    const { classes, errorMsg } = props;

    return <Card className={classes.notificationErrorMsg}>
        <List className={classes.list}>
            <ListItem>
                <ErrorIcon className={classes.errorIcon} />
                {errorMsg}
            </ListItem>
        </List>
    </Card>
};

export default withStyles(notificationErrorMsgStyle)(NotificationErrorMsg);

