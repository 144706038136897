import React from 'react';
// Material UI components
import Button from '@material-ui/core/Button';
// style
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/general/actionButtonCompStyle';

const ActionButtonComp = ({
    classes,
    active,
    action,
    title,
    tooltip,
    size,
    color,
    icon,
    className,
    fullWidth
}) => {

    return <Button
        fullWidth={fullWidth}
        variant="contained"
        color={color ? color : (active ? "secondary": "inherit")}
        className={`${classes.actionBtn} ${className}`}
        onClick={action}
        title={tooltip}
        size={size ? size : "medium"}
    >{icon}{title}</Button>
};

export default withStyles(style)(ActionButtonComp);