import React, {} from 'react';
import TruncateMarkup from 'react-truncate-markup';
// styles
// import { withStyles } from '@material-ui/core/styles';
// import style from '../../styles/jss/components/general/simpleModalStyle';

class TruncateComp extends React.Component {
    state = {
        showMoreToggle: false,
    };

    render() {
       const readMoreEllipsis  = () => {
           return <span>
                    ...{' '}
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        this.setState({showMoreToggle: true});
                    }}>
                      read more
                    </a>
               </span>;
        };

        if (this.state.showMoreToggle) {
            return this.props.children;
        }

        return <TruncateMarkup lines={this.props.lines || 3} ellipsis={readMoreEllipsis}>
            {this.props.children}
        </TruncateMarkup>;
    }
}
//
// const TruncateComp = ({ children, lines }) => {
//     const [showMoreToggle, setShowMoreToggle] = useState(false);
//     const readMoreEllipsis  =function () {
//         return <span>
//                     ...{' '}
//                     <span onClick={() => setShowMoreToggle(!showMoreToggle)}>
//                       read more
//                     </span>
//                </span>;
//     }
//
//     if (showMoreToggle) {
//         return children;
//     }
//
//     return <TruncateMarkup lines={lines || 3} ellipsis={readMoreEllipsis}>
//         {children}
//     </TruncateMarkup>;
// };

export default TruncateComp;
// export default withStyles(style)(TruncateComp);
