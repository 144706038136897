import React from 'react';
import _ from 'lodash';
import { Route, Redirect } from 'react-router-dom';
import { getAuthTokenGlobal } from '../actions/libs/cookie';
import { DEFAULT_SIGNED_IN_PATHNAME } from '../actions/libs/app-globals';

class PublicRoute extends React.Component {

    shouldComponentUpdate = (nextProps, nextState) => {
        return !_.isEqual(this.props, nextProps);
    };

    render() {
        const { component: Component, ...rest } = this.props;
        const isAuthenticated = !!getAuthTokenGlobal();

        return <Route {...rest} component={(props) => (
            isAuthenticated ?
                <Redirect to={DEFAULT_SIGNED_IN_PATHNAME} />
                :
                <Component {...props} />
        )} />
    };
}

export default PublicRoute;