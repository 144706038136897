import React from "react";
// own components
import ActionButtonComp from '../general/ActionButtonComp';
// Material UI components
import IconSearch from '@material-ui/icons/Search';
import BackspaceIcon from '@material-ui/icons/Clear';
// styles
import withStyles from "@material-ui/core/styles/withStyles";
import style from '../../styles/jss/components/general/pageStyle';

const AdvSrcBtnContainer = ({
    classes,
    onHandleAdvancedSearch,
    onHandleResetSearch,
    resetAdvancedSearch,
}) => {

    return <div className={classes.footerBtnWrapper}>
        <ActionButtonComp
            active={true}
            action={onHandleAdvancedSearch}
            title="Search"
            icon={<IconSearch/>}
        />
        <ActionButtonComp
            active={resetAdvancedSearch}
            action={onHandleResetSearch}
            title="Reset"
            icon={<BackspaceIcon/>}
        />
    </div>;
};

export default withStyles(style)(AdvSrcBtnContainer);

