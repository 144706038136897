import React from 'react';
// Material UI components
import StorageIcon from '@material-ui/icons/Storage';
import CustomersIcon from '@material-ui/icons/ContactMail';
import LogsIcon from '@material-ui/icons/LibraryBooks';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import GrafanaIcon from '@material-ui/icons/Equalizer';
import Typography from '@material-ui/core/Typography';
// own components
import SignInErrorPage from '../components/auth/SignInErrorPage';
import CustomersPage from '../components/customers/CustomersPage';
import CustomerDetailsPage from '../components/customers/CustomerDetailsPage';
import EditServerPage from '../components/servers/EditServerPage';
import ServersPage from '../components/servers/ServersPage';
import ServerDetailsPage from '../components/servers/ServerDetailsPage';
import LogsPage from '../components/logs/LogsPage';
import NotFoundPage from '../components/general/NotFoundPage';

const headerTitle = (title, navBack) => {
    return (props) => {
        const handleGoBack = () => {
            if(navBack){
                props.history.push(navBack);
            }
        };

        return <div
            className={`itemsContainer ${navBack ? 'linkItem' : ''}`}
            onClick={handleGoBack}
        >
            {navBack && <IconButton
                color="inherit"
                className="iconBack"
            ><BackIcon /></IconButton>}
            <Typography
                variant="title"
                color="inherit"
                noWrap
                className="headerTitle"
            >{title}</Typography>
        </div>;
    }
};

const routes = [
    {
        path: "/sign_in_error",
        component: SignInErrorPage,
        isPublic: true,
        exact: true,
        title: headerTitle('Login'),
        isNav: false
    },
    {
        path: "/customers",
        icon: <CustomersIcon />,
        component: CustomersPage,
        isPublic: false,
        exact: true,
        title: headerTitle('Customers'),
        sidebarTitle: 'Customers',
        isNav: true
    },
    {
        path: "/customers/:id",
        icon: <CustomersIcon />,
        component: CustomerDetailsPage,
        isPublic: false,
        exact: false,
        title: headerTitle('Customer Overview', "/customers"),
        sidebarTitle: 'Customers',
        isNav: false
    },
    {
        path: "/servers",
        icon: <StorageIcon />,
        component: ServersPage,
        isPublic: false,
        exact: true,
        title: headerTitle('Servers'),
        sidebarTitle: 'Servers',
        isNav: true
    },
    {
        path: "/servers/:id",
        icon: <StorageIcon />,
        component: ServerDetailsPage,
        isPublic: false,
        exact: true,
        title: headerTitle('Server Overview', "/servers"),
        sidebarTitle: 'Servers',
        isNav: false
    },
    {
        path: "/servers/edit/:id",
        icon: <StorageIcon />,
        component: EditServerPage,
        isPublic: false,
        exact: true,
        title: headerTitle('Edit Server', "/servers"),
        sidebarTitle: 'Servers',
        isNav: false
    },
    /*
    {
        path: "/logs",
        icon: <LogsIcon />,
        component: LogsPage,
        isPublic: false,
        exact: false,
        title: headerTitle('Logs'),
        sidebarTitle: 'Logs',
        isNav: true
    },
    */
    {
        path: "/grafana",
        icon: <GrafanaIcon />,
        component: () => {
            window.location.href = "https://grafana.miloncare.com/d/NAAP4ZKMz/maintenance?orgId=1";
            return null;
        },
        isPublic: false,
        exact: true,
        title: headerTitle('Grafana'),
        sidebarTitle: 'Grafana',
        isNav: true,
        target: "_blank"
    },
    {
        component: NotFoundPage,
        title: headerTitle('Maintenance Control', "/customers"),
        isNav: false
    }
];

export default routes;
