import {
    infoBoxShadow
} from "../../material-base";

const style = theme => ({
    searchHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        '@media(max-width: 530px)': {
            flexDirection: 'column',
            alignItems: 'center',
            '&> *': {
                width: '100%',
                '&:last-child': {
                    marginTop: theme.spacing.unit*2
                }
            }
        }
    },
    searchTitle: {
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        }
    },
    infoBtn: {
        ...infoBoxShadow,
        '& .advSrcIcon': {
            transform: 'rotate(0deg)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        '& .advSrcIcon.active': {
            transform: 'rotate(180deg)',
            position: 'relative',
            top: '-1px'
        }
    },
});

export default style;
