import React from "react";
// Material UI
import IconButton from '@material-ui/core/IconButton';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
// own components
import SelectComp from '../general/SelectComp';
import InputTextComp from '../general/InputTextComp';
// styles
import withStyles from "@material-ui/core/styles/withStyles";
import style from '../../styles/jss/components/general/paginationCompStyle';

const rowsOptions = [
    { key: 50,  value: '50 rows' },
    { key: 100, value: '100 rows' },
    { key: 150, value: '150 rows' },
    { key: 200, value: '200 rows' },
    { key: 250, value: '250 rows' },
    { key: 300, value: '300 rows' }
];

const PaginationComp = (props) => {
    const {
        classes,
        handleNavigate,
        page,
        pages,
        items,
        handlePageChange,
        handlePageNumberChange,
        rows,
        handleRowsChange
    } = props;

    const onRowsChange = (name, value) => {
        handleRowsChange(name, value);
    };

    const onPageChange = (name, value) => {
        value = parseInt(value, 10);
        value = !value || value <= 0 || value >= pages ? pages : value;
        handlePageChange(name, value);
    };

    const isNavigateBefore = page <= pages && page !== 1 && page > 1;
    const isNavigateNext = page <= pages && page !== pages && page >= 1;

    const iconBtn = (isNavigate, direction) => <IconButton
        color={isNavigate ? "secondary" : "inherit"}
        className={classes.pageBtn}
        onClick={handleNavigate(direction)}
    >
        {direction === 'prev' ? <NavigateBefore/> : <NavigateNext />}
    </IconButton>;

    return <div className={classes.paginationWrapper}>
        {iconBtn(isNavigateBefore, 'prev')}
        <span>Page</span>
        <InputTextComp
            name="page"
            type="number"
            value={page}
            action={handlePageNumberChange}
            onBlur={onPageChange}
            active={true}
            min={1}
            max={pages}
            size="small"
        />
        <span>of {pages}</span>
        <SelectComp
            name="pages"
            value={rows}
            options={rowsOptions}
            inactive={true}
            action={onRowsChange}
        />
        {items == 1 ? <span>({items} item found)</span> : <span>({items} items found)</span>}
        {iconBtn(isNavigateNext, 'next')}
    </div>;
};

export default withStyles(style)(PaginationComp);


