import React from 'react';
// actions
import { getAuthTokenGlobal } from '../../actions/libs/cookie';
// Material UI components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
// own components
import InfoMsg from './InfoMsg';
// styles
import { withStyles } from '@material-ui/core/styles';
import pageStyle from '../../styles/jss/components/general/pageStyle';

class NotFoundPage extends React.Component {

    componentDidMount(){
        const authToken = getAuthTokenGlobal();
        if(authToken){
            this.props.history.push('/customers');
        }
    };

    backToCustomersList = () => {
        this.props.history.push('/customers');
    };

    render(){
        const { classes, history } = this.props;

        const authToken = getAuthTokenGlobal();
        let infoMsg;
        if(authToken){
            infoMsg = <InfoMsg
                size="small"
                msg="Page not found..."
                action={this.backToCustomersList}
                actionTitle="Back to start page"
                actionIcon={<BackIcon/>}
            />;
        }else{
            infoMsg = <InfoMsg
                size="small"
                msg="You are logged out. Sign in process will start automatically..."
            />;
        }

        return <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                {infoMsg}
            </CardContent>
        </Card>;
    }
}

export default withStyles(pageStyle)(NotFoundPage);