import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// Material UI
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
// own components
import Loader from '../general/Loader';
import ErrorMsg from '../general/ErrorMsg';
import InfoMsg from '../general/InfoMsg';
import ReplayIcon from '@material-ui/icons/Replay';
// style
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/general/cardCompStyle';

const ServerCard = ({
    classes,
    expanded,
    mainContent,
    title,
    compKey,
    dataType,
    _server,
    _logs,
    _devices,
    _customer,
    _screenshots,
    _devicesSoftware,
    handleExpandClick,
    handleReload
}) => {

    let data, dataVolume, noDataMsg;
    if(dataType === 'server'){
        data =  _server;
        dataVolume = Object.keys(data.item).length;
        noDataMsg = "Server data not found.";
    }else if(dataType === 'customer'){
        data = _customer;
        dataVolume = Object.keys(data.item).length;
        noDataMsg = "No customer data available."
    }else if(dataType === 'logs'){
        data =  _logs;
        dataVolume = data.data.length;
        noDataMsg = "No log records data found."
    }else if(dataType === 'devices'){
        data =  _devices;
        dataVolume = 1; // always to 1, can request data in comp
    }else if(dataType === 'screenshots'){
        data =  _screenshots;
        dataVolume = 1; // always to 1, can request data in comp
    }else if(dataType === 'devicesSoftware'){
        data = _devicesSoftware;
        dataVolume = data.data.length;
        noDataMsg = "No software versions for devices found."
    }

    let { loading, error } =  data;
    if(dataType === 'customer') loading = loading || (!error && dataVolume === 0);
    const isLoadingError = !loading && error;
    const isSuccessWithoutData = !loading && !error && dataVolume === 0;
    const isSuccessWithData = !loading && !error && dataVolume > 0;

    return <Card className={classes.card}>
        <CardHeader
            title={title}
            classes={{'title': classes.cardHeaderTitle}}
            onClick={handleExpandClick(compKey)}
            action={
                <IconButton
                    className={`${classes.expand} ${expanded ? 'open' : ''}`}
                    aria-expanded={expanded}
                >
                    <ExpandMoreIcon />
                </IconButton>
            }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent className={classes.withoutSpacingCardContent}>
                {loading && <Loader size="small" />}
                {isLoadingError && <ErrorMsg
                    size="small"
                    msg={`Loading data error has occurred: ${error.message}`}
                    action={handleReload}
                    actionTitle="Retry loading"
                    actionIcon={<ReplayIcon/>}
                />}
                {isSuccessWithoutData && <InfoMsg
                    size="small"
                    msg={noDataMsg}
                />}
                {isSuccessWithData && mainContent}
            </CardContent>
        </Collapse>
    </Card>
};

const mapStateToProps = (state) => {
    return {
        _server: state.server,
        _logs: state.logs,
        _customer: state.customer,
        _devices: state.devices,
        _screenshots: state.screenshots,
        _devicesSoftware:  state.devicesSoftware
    };
};

export default compose(
    withStyles(style),
    connect(mapStateToProps, null),
)(ServerCard);
