import {
    infoBoxShadow
} from "../../material-base";

const style = theme => {
    return {
        container: {
            margin: `0 ${theme.spacing.unit*3}px`
        },
        imgWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            marginBottom: theme.spacing.unit*2,
        },
        gridList: {
            width: '100%',
            height: 'auto',
        },
        imgTitle: {
            //color: 'white'//theme.palette.secondary.light,
        },
        imgTitleBar: {
            background:
                'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        },
        imgItem: {
            cursor: 'pointer',
        }
    }
};

export default style;

