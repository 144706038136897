/**
 * @fileoverview gRPC-Web generated client stub for user
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: user.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.user = require('./user_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.user.UserClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.user.UserPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user.LoginByOauth2Req,
 *   !proto.user.LoginByOauth2Resp>}
 */
const methodDescriptor_User_LoginByOauth2 = new grpc.web.MethodDescriptor(
  '/user.User/LoginByOauth2',
  grpc.web.MethodType.UNARY,
  proto.user.LoginByOauth2Req,
  proto.user.LoginByOauth2Resp,
  /**
   * @param {!proto.user.LoginByOauth2Req} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user.LoginByOauth2Resp.deserializeBinary
);


/**
 * @param {!proto.user.LoginByOauth2Req} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user.LoginByOauth2Resp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user.LoginByOauth2Resp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user.UserClient.prototype.loginByOauth2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user.User/LoginByOauth2',
      request,
      metadata || {},
      methodDescriptor_User_LoginByOauth2,
      callback);
};


/**
 * @param {!proto.user.LoginByOauth2Req} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user.LoginByOauth2Resp>}
 *     Promise that resolves to the response
 */
proto.user.UserPromiseClient.prototype.loginByOauth2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user.User/LoginByOauth2',
      request,
      metadata || {},
      methodDescriptor_User_LoginByOauth2);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user.LoginByOauth2CallbackReq,
 *   !proto.user.LoginByOauth2CallbackResp>}
 */
const methodDescriptor_User_LoginByOauth2Callback = new grpc.web.MethodDescriptor(
  '/user.User/LoginByOauth2Callback',
  grpc.web.MethodType.UNARY,
  proto.user.LoginByOauth2CallbackReq,
  proto.user.LoginByOauth2CallbackResp,
  /**
   * @param {!proto.user.LoginByOauth2CallbackReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user.LoginByOauth2CallbackResp.deserializeBinary
);


/**
 * @param {!proto.user.LoginByOauth2CallbackReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user.LoginByOauth2CallbackResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user.LoginByOauth2CallbackResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user.UserClient.prototype.loginByOauth2Callback =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user.User/LoginByOauth2Callback',
      request,
      metadata || {},
      methodDescriptor_User_LoginByOauth2Callback,
      callback);
};


/**
 * @param {!proto.user.LoginByOauth2CallbackReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user.LoginByOauth2CallbackResp>}
 *     Promise that resolves to the response
 */
proto.user.UserPromiseClient.prototype.loginByOauth2Callback =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user.User/LoginByOauth2Callback',
      request,
      metadata || {},
      methodDescriptor_User_LoginByOauth2Callback);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user.LogoutReq,
 *   !proto.user.LogoutResp>}
 */
const methodDescriptor_User_Logout = new grpc.web.MethodDescriptor(
  '/user.User/Logout',
  grpc.web.MethodType.UNARY,
  proto.user.LogoutReq,
  proto.user.LogoutResp,
  /**
   * @param {!proto.user.LogoutReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user.LogoutResp.deserializeBinary
);


/**
 * @param {!proto.user.LogoutReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user.LogoutResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user.LogoutResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user.UserClient.prototype.logout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user.User/Logout',
      request,
      metadata || {},
      methodDescriptor_User_Logout,
      callback);
};


/**
 * @param {!proto.user.LogoutReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user.LogoutResp>}
 *     Promise that resolves to the response
 */
proto.user.UserPromiseClient.prototype.logout =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user.User/Logout',
      request,
      metadata || {},
      methodDescriptor_User_Logout);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user.GetMeReq,
 *   !proto.user.GetMeResp>}
 */
const methodDescriptor_User_GetMe = new grpc.web.MethodDescriptor(
  '/user.User/GetMe',
  grpc.web.MethodType.UNARY,
  proto.user.GetMeReq,
  proto.user.GetMeResp,
  /**
   * @param {!proto.user.GetMeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user.GetMeResp.deserializeBinary
);


/**
 * @param {!proto.user.GetMeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user.GetMeResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user.GetMeResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user.UserClient.prototype.getMe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user.User/GetMe',
      request,
      metadata || {},
      methodDescriptor_User_GetMe,
      callback);
};


/**
 * @param {!proto.user.GetMeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user.GetMeResp>}
 *     Promise that resolves to the response
 */
proto.user.UserPromiseClient.prototype.getMe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user.User/GetMe',
      request,
      metadata || {},
      methodDescriptor_User_GetMe);
};


module.exports = proto.user;

