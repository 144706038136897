import {
    successBoxShadow,
    dangerBoxShadow
} from "../../material-base";

const style = theme => {
    return {
        selectWrapper: {
            '&.fixedWidth': {
                width: 200,
                '@media(max-width: 492px)': {
                    width: '100%'
                }
            },
        },
        selectLabel: {
            transform: 'scale(0.8)',
            top: '2px',
            color: '#999999',
            '&.active, &.active.error': {
                color: '#000!important'
            },
            '&.error': {
                color: '#999999',
            },
        },
        selectRows: {
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.shape.borderRadius,
            border: '1px solid #ced4da',
            '&:before, &:after': {
                borderBottom: 'none'
            },
            '& div div':{
                padding: `${theme.spacing.unit}px 22px ${theme.spacing.unit}px 10px`,
                backgroundColor: 'transparent!important',
            },
            '&:hover, &.active': {
                borderColor: theme.palette.secondary.main,
                ...successBoxShadow,
                '&:before': {
                    borderBottom: 'none!important'
                }
            },
            '&.error': {
                borderColor: theme.palette.error.main,
                ...dangerBoxShadow
            },
        },
        selectItem: {
            fontWeight: 300
        },
        errorSelect: {
            color: theme.palette.error.main
        }
    }
};

export default style;