import _ from 'lodash';
import {
    ListServersReq,
    ListServersCustomerFiltersReq,
    DeleteServerReq,
    UndeleteServerReq,
    VpnStartServerReq,
    VpnStopServerReq,
    BrowserRestartServerReq,
    ServerRestartServerReq,
    SoftwareUpdateServerReq,
    SoftwareUpdateVersionReq
} from '../../pb/server_pb';
import { serverPromiseService } from '../libs/request-clients';
import { getMetadata, handleResponseError, getVpnStatus, getDateTime } from '../libs/request-helpers';
import extractQueryParams from '../libs/get-query-params';

export const setServersLoading = () => ({
    type: 'SET_SERVERS_LOADING'
});

export const setServersSuccess = (servers, pages, items) => ({
    type: 'SET_SERVERS_SUCCESS',
    payload: { servers, pages, items }
});

export const setServersError = (error) => ({
    type: 'SET_SERVERS_FAILURE',
    payload: { error }
});

const setQueryParams = (listServersReq, queryParams) => {
    const { page, rows, sort, search, status } = queryParams;

    listServersReq.setPageNo(page);
    listServersReq.setItemsPerPage(rows);
    listServersReq.setSortQuery(sort);
    listServersReq.setStatus(status);
    if(search[0]){
        listServersReq.setQuery(search[0]);
    }
    if(search[1].length > 0) {
        let customerReq = new ListServersCustomerFiltersReq();
        listServersReq.setCustomer(customerReq);
        const setFieldSearch = (field, value) => {
            switch(field){
                case 'id':
                    customerReq.setId(value); break;
                case 'milon_care_id':
                    customerReq.setMilonCareId(value); break;
                case 'name':
                    customerReq.setName(value); break;
                case 'contact_person':
                    customerReq.setContactPerson(value); break;
                case 'street':
                    customerReq.setStreet(value); break;
                case 'zip':
                    customerReq.setZip(value); break;
                case 'city':
                    customerReq.setCity(value); break;
                case 'country':
                    customerReq.setCountry(value); break;
                case 'node_group':
                    // TODO
                    //listServersReq.setNodeGroupsList(value); break;
                case 'device_type':
                    // TODO
                    listServersReq.setDeviceType(value); break;
                case 'device_version':
                    // TODO
                    //listServersReq.setDevicesList(value); break;
                case 'tag':
                    listServersReq.setTag(value); break;
                case 'hostname':
                    listServersReq.setHostname(value); break;
                case 'is_online':
                    listServersReq.setIsOnline(parseInt(value, 10)); break;
                case 'vpn_status':
                    listServersReq.setVpnStatus(parseInt(value, 10)); break;
                case 'created':
                    // TODO
                    break;
                case 'os':
                    listServersReq.setOs(value); break;
                case 'hw_serial':
                    listServersReq.setHwSerial(value); break;
                case 'lan_mac':
                    listServersReq.setLanMac(value); break;
                case 'notes':
                    listServersReq.setNotes(value); break;

            }
        };
        search[1].forEach((fieldObj) => {
            const [ key ] = Object.keys(fieldObj);
            setFieldSearch(key, fieldObj[key]);
        });
    }
};

const getCustomerData = (customer) => {
    if(!customer) return {};

    return {
        id:             customer.getId(),
        number:         customer.getNumber(),
        milon_care_id:  customer.getMilonCareId(),
        name:           customer.getName(),
        contact_person: customer.getContactPerson(),
        country:        customer.getCountry(),
        city:           customer.getCity(),
        zip:            customer.getZip(),
        street:         customer.getStreet(),
        telephone:      customer.getTelephone(),
        mobile:         customer.getMobile(),
        email:          customer.getEmail(),
    };
};

export const startSetServers = (params) => {
    return (dispatch, getState) => {

        dispatch(setServersLoading());
        console.log('startSetServers');
        const queryParams = extractQueryParams(params, getState().serversFilters, 'servers');
        let listServersReq = new ListServersReq();
        setQueryParams(listServersReq, queryParams);

        return serverPromiseService.list(listServersReq, getMetadata())
            .then((response) => {
                const data = {
                    servers:    response.getResultsList(),
                    pages:      response.getPageCount(),
                    page:       response.getPageNo(),
                    items:      response.getItemCount()
                };

                let servers = [];
                data.servers.forEach((server) => {
                    const serverData = {
                        id:             server.getId(),
                        hostname:       server.getHostname(),
                        type:           server.getType(),
                        tag:            server.getTag(),
                        is_online:      server.getIsOnline(), //serverStatus
                        //browser_status:  [ 0, 1, 1 ], // TODO:
                        created_at:     getDateTime(server.getCreatedAt()),
                        vpn_status:     getVpnStatus(server.getVpnStatus()),
                        os:             server.getOs(),
                        vpn_ipv4:       server.getVpnIpv4(),
                        vpn_ipv6:       server.getVpnIpv6(),
                        lan_mac:        server.getLanMac(), // macAddress
                        hw_vendor:      server.getHwVendor(),
                        hw_product:     server.getHwProduct(),
                        hw_serial:      server.getHwSerial(), //serialNumber
                        customer:       getCustomerData(server.getCustomer())
                    };
                    servers.push(serverData);
                });
                const pages = data.pages > 0 ? data.pages : 1;
                dispatch(setServersSuccess(servers, pages, data.items));
            })
            .catch((err) => {
                dispatch(setServersError(err));
                handleResponseError(dispatch, err);
            });
    };
};

export const startDeleteServers = (server_ids) => {
    return (dispatch, getState) => {
        dispatch(setServersLoading());

        let deleteServerReq = new DeleteServerReq();
        deleteServerReq.setIdList(server_ids);
        return serverPromiseService.delete(deleteServerReq, getMetadata())
            .then((response) => {
                let servers = getState().servers;
                servers.data = _.filter(servers.data, (server) => { return _.indexOf(server_ids, server.id) === -1 });
                dispatch(setServersSuccess(servers.data, servers.pages));
            })
            .catch((err) => {
                err.message = `Deleting servers failed: ${err.message}`;
                dispatch(setServersError(err));
                handleResponseError(dispatch, err);
            });
    };
};

export const startUndeleteServers = (server_ids) => {
    return (dispatch, getState) => {
        dispatch(setServersLoading());

        let undeleteServerReq = new UndeleteServerReq();
        undeleteServerReq.setIdList(server_ids);
        return serverPromiseService.undelete(undeleteServerReq, getMetadata())
            .then((response) => {
                let servers = getState().servers;
                servers.data = _.filter(servers.data, (server) => { return _.indexOf(server_ids, server.id) === -1 });
                dispatch(setServersSuccess(servers.data, servers.pages));
            })
            .catch((err) => {
                err.message = `Restoring servers failed: ${err.message}`;
                dispatch(setServersError(err));
                handleResponseError(dispatch, err);
            });
    };
};

const handlePromiseMuitipleStream = (stream) => {
    return new Promise((resolve, reject) => {
        stream.on('error', (error) => {
            reject(error);
        });

        stream.on('data', (status) => {});
        stream.on('status', (status) => {
            if(status.code !== 0) {
                const err = { code: status.code, message: status.details };
                reject(err);
                return;
            }

            // Success
            resolve({});
        });
    });
};

export const startMultipleVpnConnection = (server_ids) => {
    return (dispatch, getState) => {

        let vpnStartServerReq = new VpnStartServerReq();
        vpnStartServerReq.setIdList(server_ids);
        vpnStartServerReq.setWaitForResult(false); // false for multiple servers
        let stream = serverPromiseService.vpnStart(vpnStartServerReq, getMetadata());

        return handlePromiseMuitipleStream(stream);
    };
};

export const stopMultipleVpnConnection = (server_ids) => {
    return (dispatch, getState) => {

        let vpnStopServerReq = new VpnStopServerReq();
        vpnStopServerReq.setIdList(server_ids);
        vpnStopServerReq.setWaitForResult(false); // false for multiple servers
        let stream = serverPromiseService.vpnStop(vpnStopServerReq, getMetadata());

        return handlePromiseMuitipleStream(stream);
    };
};

export const startMultipleRestartBrowsers = (server_ids) => {
    return (dispatch, getState) => {

        let browserRestartServerReq = new BrowserRestartServerReq();
        browserRestartServerReq.setIdList(server_ids);
        browserRestartServerReq.setWaitForResult(false); // false for multiple servers
        let stream = serverPromiseService.browserRestart(browserRestartServerReq, getMetadata());

        return handlePromiseMuitipleStream(stream);
    };
};

export const startRestartMultipleServers = (server_ids) => {
    return (dispatch, getState) => {

        let serverRestartServerReq = new ServerRestartServerReq();
        serverRestartServerReq.setIdList(server_ids);
        serverRestartServerReq.setWaitForResult(false); // false for multiple servers
        let stream = serverPromiseService.serverRestart(serverRestartServerReq, getMetadata());

        return handlePromiseMuitipleStream(stream);
    };
};

export const startUpdateDeviceVersions = (server_ids, device_versions) => {
    return (dispatch, getState) => {

        let softwareUpdateServerReq = new SoftwareUpdateServerReq();
        softwareUpdateServerReq.setIdList(server_ids);
        softwareUpdateServerReq.setWaitForResult(false); // false for multiple servers

        let versionsList = [];
        device_versions.forEach((item) => {
            const { software_id, version_id } = item;
            if(software_id && version_id){
                let reqVersion = new SoftwareUpdateVersionReq();
                reqVersion.setSoftwareId(software_id);
                reqVersion.setVersionId(version_id);
                versionsList.push(reqVersion);
            }
        });
        softwareUpdateServerReq.setVersionList(versionsList);
        let stream = serverPromiseService.softwareUpdate(softwareUpdateServerReq, getMetadata());

        return handlePromiseMuitipleStream(stream);
    };
};


