import React from 'react';
import SwitchComponent from '../routers/SwitchComponent';

import { withStyles } from '@material-ui/core/styles';
import signedOutLayoutStyle from '../styles/jss/layouts/signedOutLayoutStyle';

class SignedOutLayout extends React.Component {

    constructor(props) {
        super(props);
    }

    render(){
        const { classes } = this.props;

        return(
            <div className={classes.root}>
                <SwitchComponent />
            </div>
        );
    }
}

export default withStyles(signedOutLayoutStyle)(SignedOutLayout);


