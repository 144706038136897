import {
    ScreenshotsServerReq,
    ScreenshotsResultServerReq
} from '../../pb/server_pb';
import { serverPromiseService } from '../libs/request-clients';
import { getMetadata, handleResponseError, getDateTime } from '../libs/request-helpers';

export const setScreenshotsLoading = () => ({
    type: 'SET_SCREENSHOTS_LOADING'
});

export const setScreenshotsLoadingProgress = (stateText) => ({
    type: 'SET_SCREENSHOTS_LOADING_PROGRESS',
    payload: { stateText }
});

export const setScreenshotsSuccess = (screenshots) => ({
    type: 'SET_SCREENSHOTS_SUCCESS',
    payload: { screenshots }
});

export const setScreenshotsError = (error) => ({
    type: 'SET_SCREENSHOTS_FAILURE',
    payload: { error }
});

const getScreenId = (screenId) => {
    return (screenId ? screenId+1 : 1);
};

export const startSetScreenshots = (server_id) => {
    return (dispatch, getState) => {

        dispatch(setScreenshotsLoading());

        let screenshotsResultServerReq = new ScreenshotsResultServerReq();
        screenshotsResultServerReq.setId(server_id);

        return serverPromiseService.screenshotsResult(screenshotsResultServerReq, getMetadata())
            .then((response) => {
                let screenshots = [];
                let _screenshots = response.getScreenshotsList();
                _screenshots.forEach((_screenshot) => {
                    const screenshot = {
                        id:                 _screenshot.getId(),
                        screen_id:          getScreenId(_screenshot.getScreenId()),
                        url:                _screenshot.getUrl(),
                        created_at:         getDateTime(_screenshot.getCreatedAt())
                    };
                    screenshots.push(screenshot);
                });
                dispatch(setScreenshotsSuccess(screenshots));
            })
            .catch((err) => {
                dispatch(setScreenshotsError(err));
                handleResponseError(dispatch, err);
            });
    };
};

export const startGetScreenshots = (server_id) => {
    return (dispatch, getState) => {

        let screenshotsServerReq = new ScreenshotsServerReq();
        screenshotsServerReq.setIdList([server_id]);
        screenshotsServerReq.setWaitForResult(true); // true for one server
        let stream = serverPromiseService.screenshots(screenshotsServerReq, getMetadata());

        return new Promise((resolve, reject) => {
            stream.on('error', (error) => {
                error.message = `Start generating screenshots error occurred: ${error.message}`;
                reject(error);
            });

            stream.on('data', (response) => {

            });

            stream.on('status', (status) => {
                if(status.code !== 0) {
                    const message = `Error while generating screenshots occurred: ${status.details}`;
                    const err = { code: status.code, message: message };
                    reject(err);
                    return;
                }

                // Success
                resolve({ completed: true });
            });
        });
    };
};



