import { userPromiseService, getMeReq } from '../libs/request-clients';
import { getMetadata, handleResponseError } from '../libs/request-helpers';

export const setUserLoading = () => ({
    type: 'SET_USER_LOADING'
});

export const setUserSuccess = (user) => ({
    type: 'SET_USER_SUCCESS',
    payload: { user }
});

export const setUserError = (error) => ({
    type: 'SET_USER_FAILURE',
    payload: { error }
});

export const startSetUser = () => {
    return (dispatch, state) => {

        // data not yet here
        dispatch(setUserLoading());

        return userPromiseService.getMe(getMeReq, getMetadata())
            .then((response) => {
                const data = {
                    id:         response.getId(),
                    username:   response.getUsername(),
                    name:       response.getName(),
                    email:      response.getEmail()
                };
                dispatch(setUserSuccess(data));
            })
            .catch((err) => {
                dispatch(setUserError(err));
                handleResponseError(dispatch, err);
            });
    };
};

