import {
    drawerWidth
} from "../../material-base";

const headerStyle = theme => ({
    appBar: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.lightDark,
        boxShadow: 'none',
        position: 'fixed',
        marginLeft: drawerWidth,
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
    },
    menuIcon: {
        width: '38px',
        height: '38px',
        marginRight: '15px',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    headerToolbar: {
        justifyContent: 'space-between',
        '& .itemsContainer': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

        },
        '& .linkItem.itemsContainer': {
            cursor: 'pointer'
        },
        '& .iconBack': {
            width: '38px',
            height: '38px',
            marginRight: '15px'
        },
        '& .headerTitle': {
            fontWeight: '300',
            fontSize: '18px'
        }
    },
    userWrapper: {
        cursor: 'pointer'
    },
    item: {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    noActionItem: {
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        }
    },
    actionItem: {
        color: theme.palette.primary.dark,
        '& *':{
            color: theme.palette.primary.dark
        }
    }
});

export default headerStyle;
