import {

} from '../../material-base';

const style = theme => ({
    statusIcon: {
        fontSize: '12px',
        color: theme.palette.error.main,
        '&.active': {
            color: theme.palette.secondary.dark
        }
    }
});

export default style;
