import axios from 'axios';
import _ from 'lodash';
import extractQueryParams from '../libs/get-query-params';

export const setLogsLoading = () => ({
    type: 'SET_LOGS_LOADING'
});

export const setLogsSuccess = (logs, pages) => ({
    type: 'SET_LOGS_SUCCESS',
    payload: { logs, pages }
});

export const setLogsError = (error) => ({
    type: 'SET_LOGS_FAILURE',
    payload: { error }
});

const _customer = {
    id: 1234,
    name: 'Prime Time (München)',
    contactPerson: 'Alex Schröder',
    street: 'Leopoldstraße 12',
    zip: '123413',
    city: 'München',
    country: 'Deutschland',
    telephone: '12431235-978',
    website: 'www.test.com',
    email: 'test@email.com'
};

const _data = [
    [1536668660621, 'Katja Ptashnyk', _customer, 'Celsius normal', '192.168.11.170', 'created'],
    [1536668660621, 'Flo Arzt', _customer, 'Celsius normal', '192.168.11.78', 'created'],
    [1536668660621, 'Max Muster', _customer, 'Celsius normal','192.168.11.78', 'created'],
    [1536668660621, 'Max Muster', _customer, 'Celsius normal', '192.168.11.78', 'deleted'],
    [1536668660621, 'Max Muster', _customer, 'Celsius short','192.168.11.78', 'deleted'],
    [1536668660621, 'Flo Arzt', _customer, 'Celsius short','192.168.11.78', 'created'],
    [1536668660621, 'Flo Arzt', _customer, 'Celsius short', '', 'updated'],
    [1536668660621, 'Flo Arzt', _customer, 'MSI normal','', 'updated'],
    [1536668660621, 'Test User',_customer, 'MSI normal','', 'created'],
    [1536668660621, 'Katja Ptashnyk', _customer, 'MSI normal', '', 'updated'],
    [1536668660621, 'Katja Ptashnyk', _customer, 'Celsius normal','192.168.11.78', 'created'],
    [1536668660621, 'Katja Ptashnyk', _customer, 'Celsius normal','192.168.11.78', 'updated'],
    [1536668660621, 'Katja Ptashnyk', _customer, 'Celsius normal','', 'updated'],
];

const getData = () => {
    let data = [];
    _data.forEach((el, index) => {
        const [ t, user, customer, server, ipAddress, action ] = el;
        data.push({ id: index+1, t, user, customer, server, ipAddress, action });
    });
    return data;
};

export const startSetLogs = (params) => {
    return (dispatch, getState) => {

        // TODO: send request to server with params
        const queryParams = extractQueryParams(params, getState().logsFilters);

        // data not yet here
        dispatch(setLogsLoading());

        // data has come
        const logs = getData();
        const pages = 4;

        const promise = Promise.resolve();
        return promise.then((value) => {
            setTimeout(() => {
                // success
                dispatch(setLogsSuccess(logs, pages));

                // error case
                //dispatch(setLogsError('log-error'));
            }, 700);
        });
    };
};


