import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _ from 'lodash';
import createHistory from 'history/createBrowserHistory';
// actions
import { setCustomersFilters } from '../../actions/customers/filters';
import { startSetCustomers } from '../../actions/customers/customers';
import { getFilters } from '../../actions/libs/get-query-params';
// Material UI components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ReplayIcon from '@material-ui/icons/Replay';
// own components
import CustomersTableToolbar from './CustomersToolbar';
import Loader from '../general/Loader';
import ErrorMsg from '../general/ErrorMsg';
import InfoMsg from '../general/InfoMsg';
import CustomersTable from './CustomersTable';
// styles
import { withStyles } from '@material-ui/core/styles';
import pageStyle from '../../styles/jss/components/general/pageStyle';

const localHistory = createHistory();

class CustomersPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selected: [],
        }
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        return !_.isEqual(this.state.selected, nextState.selected)
            || !_.isEqual(this.props.customersObj, nextProps.customersObj)
            || !_.isEqual(this.props.customersFilters, nextProps.customersFilters)

    };

    componentDidMount(){
        // check query and update
        const filters = getFilters('customers');
        if(filters){
            this.clearSelected();
            this.props.setCustomersFilters(filters);
        }
        this.props.startSetCustomers(filters);
    }

    getCustomers = () => {
        this.clearSelected();

        // get data
        this.props.startSetCustomers()
            .then(() => {
                // generate / change url query
                let params = '/customers?';
                const filterKeys = Object.keys(this.props.customersFilters);
                filterKeys.forEach((key) => {
                    if(key !== 'search'){
                        params = `${params}${key}=${this.props.customersFilters[key]}&`;
                    }else{
                        const [ all, advanced ] = this.props.customersFilters.search;
                        if(all !== '') params = `${params}all=${all}&`;
                        advanced.forEach((el) => {
                            const [ searchKey ] = Object.keys(el);
                            params = `${params}${searchKey}=${el[searchKey]}&`;
                        });
                    }
                });
                params = params.substring(0, params.length - 1);
                localHistory.push(params);
            });
    };

    handleRequestSort = _sortBy => event => {
        if(_sortBy){
            const { sortBy, order, ...rest } = this.props.customersFilters;
            let _order = 'asc';
            if(sortBy === _sortBy && order === 'asc') _order = 'desc';
            const filters = {
                ...rest,
                sortBy: _sortBy,
                order: _order
            };
            this.props.setCustomersFilters(filters);
            this.getCustomers();
        }
    };

    clearSelected = () => {
        this.setState({ selected: [] });
    };

    handleSelectedCustomer = id => evt => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };

    handleSelectAllClick = (event, checked) => {
        const customers = this.props.customersObj.data;
        if (checked) {
            this.setState(state => ({ selected: customers.map(n => n.id) }));
            return;
        }
        this.setState({ selected: [] });
    };

    render() {
        const { selected } = this.state;
        const { classes, customersObj, customersFilters, history } = this.props;
        const { sortBy, order, deleted } = customersFilters;
        const { data, loading, error } = customersObj;

        const isLoadingError = !loading && error;
        const isSuccessWithoutData = !loading && !error && data.length === 0;
        const isSuccessWithData = !error && data.length > 0;

        return <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                <CustomersTableToolbar
                    clearSelected={this.clearSelected}
                    selected={selected}
                    getCustomers={this.getCustomers}
                />
                {loading && <Loader forTable={data.length > 0} />}
                {isLoadingError && <ErrorMsg
                    size="small"
                    msg={`Loading data error has occurred: ${error.message}.`}
                    action={this.getCustomers}
                    actionTitle="Retry loading"
                    actionIcon={<ReplayIcon/>}
                />}
                {isSuccessWithoutData && <InfoMsg
                    size="small"
                    msg="No customers found."
                />}
                {isSuccessWithData && <CustomersTable
                    data={data}
                    actionSort={this.handleRequestSort}
                    actionSelectAll={this.handleSelectAllClick}
                    actionSelectCustomer={this.handleSelectedCustomer}
                    sortBy={sortBy}
                    order={order}
                    selected={selected}
                    history={history}
                    deleted={deleted}
                />}
            </CardContent>
        </Card>
    }
}

const mapStateToProps = (state) => {
    return {
        customersFilters: state.customersFilters,
        customersObj: state.customers
    };
};

const mapDispatchToProps = (dispatch) => ({
    startSetCustomers: (params) => dispatch(startSetCustomers(params)),
    setCustomersFilters: (filters) => dispatch(setCustomersFilters(filters)),
});

const options = {
    areStatesEqual: (next, prev) => {
        return _.isEqual(prev.customersFilters, next.customersFilters)
            && _.isEqual(prev.customers, next.customers);
    }
};

export default compose(
    withStyles(pageStyle),
    connect(mapStateToProps, mapDispatchToProps, null, options),
)(CustomersPage);

