export const setCookie = (cname, cvalue, expires) => {
    // const d = new Date();
    // if(!isMinute){
    //     // days
    //     d.setTime(d.getTime() + (exCounter*24*60*60*1000));
    // }else{
    //     // minutes
    //     d.setTime(d.getTime() + (exCounter*60*1000));
    // }

    let cookieValue = `${cname}=${cvalue}; path=/`;

    cookieValue += `; samesite=lax`;

    if (expires) {
        cookieValue += `; expires=${expires.toUTCString()};`;
    }

    if (location.protocol.toLowerCase() === 'https:') {
        cookieValue += `; secure`;
    }

    document.cookie = cookieValue;
};

export const getCookie = (cname) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return undefined;
};

export const deleteCookie = (cname) => {
    const expires = '01 Jan 1970 00:00:00 UTC';
    document.cookie = `${cname}=; expires=${expires}; path=/`;
};

export const setAuthTokenGlobal = (token, expires) => {
    setCookie('authToken', token, expires);
};

export const removeAuthTokenGlobal = () => {
    deleteCookie('authToken');
};

export const getAuthTokenGlobal = () => {
    return getCookie('authToken');
};
