import {
} from '../../material-base';

const style = theme => ({
    loaderWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `0 ${theme.spacing.unit*3}px`
    },
    small: {
        minWidth: '250px',
        minHeight: '200px'
    },
    main: {
        minHeight: '40vh',
    },
});

export default style;
