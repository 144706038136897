/*
    https://material-ui.com
 */

const drawerWidth = 260;

const transition = {
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
};

const defaultFont = {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 300,
    lineHeight: "1.5em"
};

const grayColor = "#999999";
const backgroundColor = "#eeeeee";
const orangeColor = "#ff9800";
const blueColor = "#3f51b5";

const primaryBoxShadow = {
    boxShadow:
        "0 12px 20px -10px rgba(0, 172, 193, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 172, 193, 0.2)"
};
const infoBoxShadow = {
    boxShadow:
        "0 12px 20px -10px rgba(94, 94, 94, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(94, 94, 94, 0.2)"
};
const successBoxShadow = {
    boxShadow:
        "0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)"
};
const dangerBoxShadow = {
    boxShadow:
        "0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)"
};

const card = {
    overflow: 'inherit',
    ...infoBoxShadow
};

const cardHeader = {
    margin: "-20px 15px 0",
    borderRadius: "3px",
    padding: "15px"
};

const cardContent = {
    "&:last-child": {
        padding: '16px'
    }
};

const root = {
    flexGrow: "1",
    zIndex: "1",
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
    minHeight: "100vh",
    backgroundColor: backgroundColor,
};

const toolbar = {
    minHeight: 56,
    'media (min-width:0px) and (orientation: landscape)': {
        minHeight: 48
    },
    'media (min-width: 600px)': {
        minHeight: 64
    }
};

const spacingUnit = 8;

const content = {
    backgroundColor: backgroundColor,
    padding: spacingUnit * 3,
    width: '100%',
};

const tableFontSize = '0.9rem';

export {
    drawerWidth,
    transition,
    card,
    defaultFont,
    grayColor,
    orangeColor,
    blueColor,
    primaryBoxShadow,
    infoBoxShadow,
    successBoxShadow,
    dangerBoxShadow,
    cardHeader,
    cardContent,
    root,
    toolbar,
    content,
    tableFontSize
};
