import {

} from "../../material-base";

const style = theme => {
    return {
        paginationWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&> * ': {
                margin: `0 ${theme.spacing.unit / 2}px`
            },
        },
        pageBtn: {
            width: 38,
            height: 38,
            margin: 0
        }
    }
};

export default style;