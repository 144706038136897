import moment from 'moment';
import _ from 'lodash';
import {
    LogFileRequestServerReq
} from '../../pb/server_pb';
import { serverPromiseService } from '../libs/request-clients';
import { setDevicesSuccess } from '../devices/devices';
import { getMetadata } from '../libs/request-helpers';

export const setLogLoading = () => ({
    type: 'SET_LOG_LOADING'
});

export const setLogLoadingProgress = (stateText) => ({
    type: 'SET_LOG_LOADING_PROGRESS',
    payload: { stateText }
});

export const setLogSuccess = (log) => ({
    type: 'SET_LOG_SUCCESS',
    payload: { log }
});

export const setLogError = (error) => ({
    type: 'SET_LOG_FAILURE',
    payload: { error }
});

const updateDevices = (devicesData, newData) => {
    let element, position;
    devicesData.forEach((el, index) => {
        if(el.id === newData.id){
            position = index;
            element = { ...el, ...newData };
        }
    });
    if(element && position){
        devicesData[position] = element;
    }

    return devicesData;
};

export const startGetLog = (server_id, net_scan_id) => {
    return (dispatch, getState) => {

        dispatch(setLogLoading());

        let logFileRequestServerReq = new LogFileRequestServerReq();
        logFileRequestServerReq.setIdList([server_id]);
        logFileRequestServerReq.setNetScanIdList([net_scan_id]);
        logFileRequestServerReq.setWaitForResult(true); // true for one server
        let stream = serverPromiseService.logFileRequest(logFileRequestServerReq, getMetadata());

        return new Promise((resolve, reject) => {
            let data = {
                completed: false,
                msg: '',
                logfile_url: '',
                logfile_created_at: ''
            };

            stream.on('error', (error) => {
                error.message = `Error occurred while getting log: ${error.message}`;
                dispatch(setLogError(error));
                reject(error);
            });

            stream.on('data', (response) => {
                data.completed  = response.getCompleted();
                data.msg        = response.getStateText();
                data.logfile_url= response.getUrl();
                if(data.completed){
                    data.logfile_created_at = moment().format('YYYY-MM-DD HH:mm:ss');
                }
                dispatch(setLogLoadingProgress(data.msg));
            });

            stream.on('status', (status) => {
                if (status.code !== 0) {
                    const message = `Error occurred while getting log: ${status.details}`;
                    const err = {code: status.code, message: message};
                    dispatch(setLogError(err));
                    reject(err);
                    return;
                }

                // Success
                const newData = {
                    logfile_url: data.logfile_url,
                    logfile_created_at: data.logfile_created_at,
                    id: net_scan_id,
                };
                dispatch(setLogSuccess(newData));

                // Update devices
                const devicesData = updateDevices(getState().devices.data, newData);
                dispatch(setDevicesSuccess(devicesData));
                resolve(data);
            });
        });
    }
};