import {
    infoBoxShadow
} from "../../material-base";

const toolbarStyle = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
        '&.singleItem': {
            justifyContent: 'space-around'
        },
        '@media(max-width: 1160px)': {
            flexDirection: 'column',
            '&> *': {
                width: '100%',
                marginBottom: theme.spacing.unit + 2,
                '&:last-child': {
                    marginBottom: 0
                }
            }
        },
    },
    toolbarBtnContainer: {
        display: 'flex',
        ...infoBoxShadow,
        '& button': {
            boxShadow: 'none',
            borderRight: '1px solid white',
            '&:first-child': {
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0,
            },
            '&:nth-child(2)': {
                borderRadius: 0
            },
            '&:last-child': {
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
                borderRight: 'none',
                borderBottomRightRadius: '4px',
                borderTopRightRadius: '4px',
            },
        },
        '@media(max-width: 1160px)': {
            '& button': {
                width: '50%',
                padding: `${theme.spacing.unit}px ${theme.spacing.unit*2}px`,
                fontSize: '0.875rem'
            }
        },
    },
    searchContainer: {
        backgroundColor: 'rgba(0,0,0,0.04)',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing.unit * 2,
        }
    },
    collapse: {
        paddingTop: theme.spacing.unit * 2,
        overflow: 'inherit',
    },
    inputsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        '&> *': {
            margin: `0 ${theme.spacing.unit*2}px ${theme.spacing.unit}px 0`,
        },
        '@media(max-width: 492px)': {
            flexDirection: 'column',
        },
    },
    footerBtnWrapper: {
        margin: `${theme.spacing.unit}px 0`,
        '&> *': {
            marginRight: theme.spacing.unit*2,
            '&:last-child': {
                marginRight: 0
            }
        }
    },
});

export default toolbarStyle;