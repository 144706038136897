import { ListCustomersReq } from '../../pb/customer_pb';
import { customerPromiseService } from '../libs/request-clients';
import { getMetadata, handleResponseError } from '../libs/request-helpers';
import extractQueryParams from '../libs/get-query-params';

export const setCustomersLoading = () => ({
    type: 'SET_CUSTOMERS_LOADING'
});

export const setCustomersSuccess = (customers, pages, items) => ({
    type: 'SET_CUSTOMERS_SUCCESS',
    payload: { customers, pages, items }
});

export const setCustomersError = (error) => ({
    type: 'SET_CUSTOMERS_FAILURE',
    payload: { error }
});

const setQueryParams = (listCustomersReq, queryParams) => {
    const { page, rows, sort, deleted, search } = queryParams;
    listCustomersReq.setPageNo(page);
    listCustomersReq.setItemsPerPage(rows);
    listCustomersReq.setSortQuery(sort);
    listCustomersReq.setDeleted(deleted);
    if(search[0]){
        listCustomersReq.setQuery(search[0]);
    }
    if(search[1].length > 0) {
        const setFieldSearch = (field, value) => {
            switch(field){
                case 'number':
                    listCustomersReq.setNumber(value); break;
                case 'milon_care_id':
                    listCustomersReq.setMilonCareId(value); break;
                case 'name':
                    listCustomersReq.setName(value); break;
                case 'contact_person':
                    listCustomersReq.setContactPerson(value); break;
                case 'street':
                    listCustomersReq.setStreet(value); break;
                case 'zip':
                    listCustomersReq.setZip(value); break;
                case 'city':
                    listCustomersReq.setCity(value); break;
                case 'country':
                    listCustomersReq.setCountry(value); break;
                case 'node_group':
                    //listCustomersReq.setNodeGroup(value); break;
                case 'device_name':
                    //listCustomersReq.setDeviceName(value); break;
                case 'device_version':
                    //listCustomersReq.setDeviceVersion(value); break;
            }
        };
        search[1].forEach((fieldObj) => {
            const [ key ] = Object.keys(fieldObj);
            setFieldSearch(key, fieldObj[key]);
        });
    }
};

export const startSetCustomers = (params) => {
    return (dispatch, getState) => {

        // data not yet here
        dispatch(setCustomersLoading());

        const queryParams = extractQueryParams(params, getState().customersFilters, 'customers');
        let listCustomersReq = new ListCustomersReq();
        setQueryParams(listCustomersReq, queryParams);

        return customerPromiseService.list(listCustomersReq, getMetadata())
            .then((response) => {
                const data = {
                    customers:  response.getResultsList(),
                    pages:      response.getPageCount(),
                    page:       response.getPageNo(),
                    items:      response.getItemCount()
                };

                let customers = [];
                data.customers.forEach((customer) => {
                    const customerData = {
                        id:             customer.getId(),           // customer id in db
                        number:         customer.getNumber(),       // customer number
                        milon_care_id:  customer.getMilonCareId(),
                        name:           customer.getName(),
                        contact_person: customer.getContactPerson(),
                        country:        customer.getCountry(),
                        city:           customer.getCity(),
                        zip:            customer.getZip(),
                        street:         customer.getStreet(),
                        telephone:      customer.getTelephone(),
                        mobile:         customer.getMobile(),
                        email:          customer.getEmail(),
                        created_at:     customer.getCreatedAt(),
                        updated_at:     customer.getUpdatedAt(),
                        deleted_at:     customer.getDeletedAt(),
                    //    servers:        [ 0, 1, 1], // TODO
                    //    device_version: '123324' // TODO
                    };
                    customers.push(customerData);
                });
                const pages = data.pages > 0 ? data.pages : 1;
                dispatch(setCustomersSuccess(customers, pages, data.items));
            })
            .catch((err) => {
                dispatch(setCustomersError(err));
                handleResponseError(dispatch, err);
            });
    };
};

