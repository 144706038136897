const initialState = {
    loading: false,
    stateText: '',
    error: null,
    serverId: ''
};

const browsersReducer = (state = initialState, action) => {
    switch (action.type){
        case 'SET_BROWSERS_LOADING_PROGRESS':
            return {
                ...state,
                loading: true,
                serverId: action.payload.serverId,
                stateText: action.payload.stateText
            };
        case 'SET_BROWSERS_SUCCESS':
            return {
                ...state,
                stateText: '',
                loading: false,
                serverId: '',
                error: null
            };
        case 'SET_BROWSERS_FAILURE':
            return {
                ...state,
                loading: false,
                serverId: '',
                stateText: '',
                error: action.payload.error
            };
        default:
            return state;
    }
};

export default browsersReducer;
