import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// actions
import { startSetScreenshots, startGetScreenshots } from '../../actions/screenshots/screenshots';
// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
// own components
import MassActionNotification from '../general/MassActionNotification';
import ScreenshotsList from './ScreenshotsList';
import ActionButtonComp from '../general/ActionButtonComp';
// style
import { withStyles } from '@material-ui/core/styles';
import pageStyle from '../../styles/jss/components/general/pageStyle';

class Screenshots extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            getScreenshotsLoading: false,
            getScreenshotsError: ''
        };
    }

    handleGetScreenshots = () => {
        if(!this.state.getScreenshotsLoading){

            this.setState({ getScreenshotsLoading: true });

            const [ empty, path, server_id ] = this.props.history.location.pathname.split('/');

            this.props.startGetScreenshots(server_id)
                .then((response) => {
                    if(response.completed){
                        this.setState({ getScreenshotsLoading: false });
                        this.props.startSetScreenshots(server_id);
                    }
                })
                .catch((err) => {
                    this.setState({
                        getScreenshotsLoading: false,
                        getScreenshotsError: err.message
                    })
                });
        }
    };

    cancelAction = (evt) => {
        this.setState({
            getScreenshotsError: ''
        });
    };

    render() {
        const { getScreenshotsLoading, getScreenshotsError } = this.state;
        const { classes, _screenshots } = this.props;
        const dataVolume = _screenshots.data.length > 0;

        return <div>
            {!dataVolume && <p className={classes.textCardContainer}>
                No screenshots available for this server.
            </p>}
            {dataVolume && <ScreenshotsList />}
            {getScreenshotsError && <MassActionNotification
                cancelAction={this.cancelAction}
                noIcon={false}
                topOriented={true}
                message={getScreenshotsError}
                error={true}
            />}
            <div className={classes.btnContainer}>
                <ActionButtonComp
                    active={true}
                    action={this.handleGetScreenshots}
                    title={!getScreenshotsLoading ? 'Generate screenshots' :
                        <span>Generating screenshots is in process... <CircularProgress size={20} color="inherit"/></span>}
                />
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        _screenshots: state.screenshots
    };
};

const mapDispatchToProps = (dispatch) => ({
    startSetScreenshots: (server_id) => dispatch(startSetScreenshots(server_id)),
    startGetScreenshots: (server_id) => dispatch(startGetScreenshots(server_id)),
});

export default compose(
    withStyles(pageStyle),
    connect(mapStateToProps, mapDispatchToProps),
)(Screenshots);