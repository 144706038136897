import { ServerRestartServerReq } from '../../pb/server_pb';
import { serverPromiseService } from '../libs/request-clients';
import { getMetadata } from '../libs/request-helpers';
import { setServerSuccess } from '../servers/server';

export const setServerRestartLoadingProgress = (serverId, stateText = '') => ({
    type: 'SET_SERVER_RESTART_LOADING_PROGRESS',
    payload: { serverId, stateText }
});

export const setServerRestartSuccess = () => ({
    type: 'SET_SERVER_RESTART_SUCCESS'
});

export const setServerRestartError = (error) => ({
    type: 'SET_SERVER_RESTART_FAILURE',
    payload: { error }
});

export const startRestartServer = (server_id) => {
    return (dispatch, getState) => {
        server_id = parseInt(server_id, 10);

        dispatch(setServerRestartLoadingProgress(server_id));

        let serverRestartServerReq = new ServerRestartServerReq();
        serverRestartServerReq.setIdList([server_id]);
        serverRestartServerReq.setWaitForResult(true); // true for one server
        let stream = serverPromiseService.serverRestart(serverRestartServerReq, getMetadata());

        return new Promise((resolve, reject) => {
            let data = {
                completed: false,
                msg: '',
            };

            stream.on('error', (error) => {
                error.message = `Error occurred while restarting server: ${error.message}`;
                dispatch(setServerRestartError(error));
                reject(error);
            });

            stream.on('data', (response) => {
                data.completed  = response.getCompleted();
                data.msg        = response.getStateText();
                dispatch(setServerRestartLoadingProgress(server_id, data.msg));
            });

            stream.on('status', (status) => {
                if (status.code !== 0) {
                    const message = `Error occurred while restarting server: ${status.details}`;
                    const err = {code: status.code, message: message};
                    dispatch(setServerRestartError(err));
                    reject(err);
                    return;
                }

                // Success
                dispatch(setServerRestartSuccess());
                const serverData = getState().server.item;
                serverData.is_online = true;
                dispatch(setServerSuccess(serverData));
                resolve(data);
            });
        });
    };
};
