import React from 'react';
// Material UI components
import TextField from '@material-ui/core/TextField';
// style
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/general/inputTextCompStyle';

const InputTextComp = ({
    classes,
    name,
    label,
    notEmpty,
    value,
    placeholder,
    action,
    type,
    onBlur,
    active,
    min,
    max,
    size,
    fullWidth,
    error,
    helperText,
    multiline
}) => {

    const handleOnChange = (evt) => {
        const name = evt.currentTarget.name;
        const value = evt.currentTarget.value;
        let allowUpdate = true;

        if(type === "number"){
            let isMin = min || min === 0;
            let isMax = max || max === 0;
            if(isMin || isMax){
                allowUpdate = false;
                if(isMin && isMax && value === "" || value >= min && value <= max) allowUpdate = true;
                else if(isMin && !isMax && (value === "" || value >= min)) allowUpdate = true;
                else if(isMax && !isMin && (value === "" || value <= max)) allowUpdate = true;
            }
        }
        action(name, value, allowUpdate);
    };

    const handleOnBlur = (evt) => {
        const name = evt.currentTarget.name;
        const value = evt.currentTarget.value;
        onBlur(name, value);
    };

    const errorInput = error ? 'error' : '';
    active = active ? active : (value.length > 0 ? 'active' : '');
    label = label ? (notEmpty ? `${label} *` : label) : '';

    return <TextField
        type={type ? type : 'text'}
        name={name}
        label={label}
        value={value}
        error={error}
        helperText={helperText}
        placeholder={placeholder}
        className={`${classes.textFieldRoot} ${size} ${fullWidth}`}
        InputProps={{
            disableUnderline: true,
            classes: {
                input: `${classes.searchDetailedInput} ${active} ${errorInput}`,
            },
        }}
        InputLabelProps={{
            shrink: true,
            className: `${classes.searchDetailedLabel} ${active} ${errorInput}`,
            focused: false
        }}
        onChange={handleOnChange}
        onBlur={onBlur ? handleOnBlur : null}
        autoComplete={name}
        multiline={!!multiline}
    />
};

export default withStyles(style)(InputTextComp);
