import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// actions
import { startNetScan, startSetDevices } from '../../actions/devices/devices';
// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
// own components
import DevicesInNetworkTable from './DevicesInNetworkTable';
import ActionButtonComp from '../general/ActionButtonComp';
import MassActionNotification from '../general/MassActionNotification';
// style
import { withStyles } from '@material-ui/core/styles';
import pageStyle from '../../styles/jss/components/general/pageStyle';

class DevicesInNetwork extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            netScanLoading: false,
            netScanError: ''
        };
    }

    handleStartNetScan = () => {
        if(!this.state.netScanLoading){

            this.setState({ netScanLoading: true });

            const [ empty, path, server_id ] = this.props.history.location.pathname.split('/');

            this.props.startNetScan(server_id)
                .then((response) => {
                    if(response.completed){
                        this.setState({ netScanLoading: false });
                        this.props.startSetDevices(server_id);
                    }
                })
                .catch((err) => {
                    this.setState({
                        netScanLoading: false,
                        netScanError: err.message
                    })
                });
        }
    };

    cancelAction = (evt) => {
        this.setState({
            netScanError: ''
        });
    };

    render() {
        const { netScanLoading, netScanError } = this.state;
        const { classes, _devices, history } = this.props;
        const dataVolume = _devices.data.length > 0;

        return <div>
            {!dataVolume && <p className={classes.textCardContainer}>
                No information about devices available for this server.
            </p>}
            {dataVolume && <DevicesInNetworkTable history={history} />}
            {netScanError && <MassActionNotification
                cancelAction={this.cancelAction}
                noIcon={false}
                topOriented={true}
                message={netScanError}
                error={true}
            />}
            <div className={classes.btnContainer}>
                <ActionButtonComp
                    active={true}
                    action={this.handleStartNetScan}
                    title={!netScanLoading ? 'Start net scan' :
                        <span>Net scan is in process... <CircularProgress size={20} color="inherit"/></span>}
                />
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        _devices: state.devices
    };
};

const mapDispatchToProps = (dispatch) => ({
    startNetScan: (server_id) => dispatch(startNetScan(server_id)),
    startSetDevices: (server_id) => dispatch(startSetDevices(server_id)),
});

export default compose(
    withStyles(pageStyle),
    connect(mapStateToProps, mapDispatchToProps),
)(DevicesInNetwork);
