import {
} from '../../material-base';

const searchCustomerStyle = (theme) => ({
    notificationSearchWrapper: {
        margin: `${theme.spacing.unit/2}px 0 ${theme.spacing.unit*2}px ${theme.spacing.unit}px`,
        width: '100%',
        '& h3': {
            margin: `0 0 ${theme.spacing.unit*2}px 0`,
            fontSize: 16
        },
    },
    foundCustomersCard: {
        width: 'calc(100% + 55px)',
        color: theme.palette.common.black,
        margin: `${theme.spacing.unit*2}px 0`,
    },
    contentWrapper: {
        color: theme.palette.common.black,
    },
    list: {
        maxHeight: 260,
        overflowY:'auto',
    },
    listItem: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'inherit'
        },
        '&:nth-child(even)' : {
            backgroundColor: 'rgba(0,0,0,0.04)'
        },
        '&.active': {
            backgroundColor: 'rgba(0, 212, 25, 0.08)'
        }
    },
    itemTextWrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    itemText: {
        fontWeight: '500'
    },

});

export default searchCustomerStyle;