import { createMuiTheme } from '@material-ui/core/styles';
import cyan from '@material-ui/core/colors/cyan';
// https://www.sessions.edu/color-calculator/
// https://material-ui.com/style/color/
const theme = createMuiTheme({
    palette: {
        primary: {
            light: cyan[200],
            main: cyan[500],
            lightDark: cyan[600],
            dark: cyan[700],
            contrastText: '#fff',
        },
        secondary: {
            light: '#33dc47',
            main: '#00d419',
            dark: '#009411',
            contrastText: '#fff',
        },
        error: {
            light: '#dc3833',
            main: '#d40700',
            dark: '#940400',
            contrastText: '#fff',
        },
    }
});

export default theme;