import qs from 'query-string';
import {
    setCookie,
    getCookie,
    deleteCookie,
    setAuthTokenGlobal,
    removeAuthTokenGlobal,
    getAuthTokenGlobal
} from '../libs/cookie';
import {
    userPromiseService,
    loginByOauth2Req,
    loginByOauth2CallbackReq,
    logoutReq
} from '../libs/request-clients';
import {
    getMetadata,
    handleResponseError
} from '../libs/request-helpers';
import { AUTH_PATHNAME } from '../libs/app-globals';

export const setLoginSuccess = (authToken) => ({
    type: 'SET_LOGIN_SUCCESS',
    payload: { authToken }
});

export const setLoginError = (error) => ({
    type: 'SET_LOGIN_FAILURE',
    payload: { error }
});

export const startLogin = () => {
    return (dispatch) => {
        const authToken = getAuthTokenGlobal();

        return new Promise((resolve, reject) => {

            // Step 1: authToken found -> render app
            if(authToken){
                dispatch(setLoginSuccess(authToken));
                resolve({ authToken: authToken, redirect: false });
                return;
            }

            // Step 2: authToken not found -> check stateToken
            const stateToken = getCookie('stateToken');
            const qsObj = qs.parse(document.location.search);
            const isStateToken = stateToken === qsObj.state;
            const isPathname = window.location.pathname === AUTH_PATHNAME;
            const isParamCode = !!qsObj.code;

            // Step 3: stateToken not found -> send request `loginByOauth2` to backend
            if(!stateToken || !isPathname || !isParamCode) {
                userPromiseService.loginByOauth2(loginByOauth2Req)
                    .then((response) => {
                        const url = response.getOauth2Url();
                        const state = response.getOauth2State();

                        // save stateToken
                        let expireDate = new Date();
                        expireDate.setMinutes(expireDate.getMinutes() + 15);
                        setCookie('stateToken', state, expireDate);
                        // redirect client to oauth2 authorize endpoint (azure ad)
                        window.location.href = url;
                        resolve({});
                    })
                    .catch((err) => {
                        deleteCookie('stateToken');
                        dispatch(setLoginError(err));
                        reject({ error: 'userPromiseService.loginByOauth2' });
                    });
                return;
            }

            if(!(isStateToken && isPathname && isParamCode)) {
                const code = !isStateToken ? 'stateToken' : (!isPathname ? 'pathName' : 'params');
                const err = `${ !isStateToken ? 'state token is' : (!isPathname ? 'path name is' : 'parameters are') } incorrect`;
                dispatch(setLoginError({ code, message: err }));
                reject({ error: 'isStateToken && isPathname && isParamCode' });
                return;
            }

            // Step 5: get authToken from backend using code & stateToken
            loginByOauth2CallbackReq.setOauth2Token(qsObj.code);
            loginByOauth2CallbackReq.setOauth2State(stateToken);
            userPromiseService.loginByOauth2Callback(loginByOauth2CallbackReq)
                .then((response) => {
                    // save authToken in cookie and in app
                    const authToken = response.getSessionToken();
                    setAuthTokenGlobal(authToken, response.getExpiresAt().toDate());
                    dispatch(setLoginSuccess(authToken));

                    // delete stateToken
                    deleteCookie('stateToken');

                    resolve({ authToken: authToken, redirect: true });
                })
                .catch((err) => {
                    dispatch(setLoginError(err));
                    reject({ error: 'get authToken from backend using code & stateToken' });
                });
        });
    };
};

export const setLogoutSuccess = () => ({
    type: 'SET_LOGOUT_SUCCESS'
});

export const setLogoutError = (error) => ({
    type: 'SET_LOGOUT_FAILURE',
    payload: { error }
});

export const startLogout = () => {
    return (dispatch, state) => {
        return userPromiseService.logout(logoutReq, getMetadata())
            .then((response) => {
                // remove token globally and in app store
                removeAuthTokenGlobal();
                dispatch(setLogoutSuccess());

                // redirect to logout
                document.location = response.getRedirectTo();
            })
            .catch((err) => {
                dispatch(setLogoutError(err));
                handleResponseError(dispatch, err);
            });
    };
};
