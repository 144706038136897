import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import moment from 'moment';
// Material UI
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
// style
import { withStyles } from '@material-ui/core/styles';
import tableStyle from '../../styles/jss/components/general/tableStyle';

const logsColumns = [
    { key: 't',         label: 'Date & time' },
    { key: 'user',      label: 'User' },
    { key: 'action',    label: 'Action' },
    { key: 'ipAddress', label: 'IP address' }
];

const LogsRecords = ({ classes, _logs }) => {

    const { data } = _logs;

    return <Table className={`${classes.mainTable} cardTable LogsRecords`}>
        <TableHead>
            <TableRow>
                {logsColumns.map((el) => {
                    return <TableCell key={el.key}>{el.label}</TableCell>
                })}
            </TableRow>
        </TableHead>
        <TableBody>
            {data.map((logItem) => {
                return <TableRow key={logItem.id}>
                    {logsColumns.map((cell) => {
                        let value = logItem[cell.key];
                        if(cell.key === 't'){
                            value = value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '---';
                        }else{
                            value = value ? value : '---';
                        }
                        return <TableCell key={cell.key}>{value}</TableCell>
                    })}
                </TableRow>
            })}
        </TableBody>
    </Table>;
};

const mapStateToProps = (state) => {
    return {
        _logs: state.logs
    };
};

export default compose(
    withStyles(tableStyle),
    connect(mapStateToProps, null),
)(LogsRecords);
