const initialState = {
    data: [],
    pages: 1,
    loading: true,
    error: null
};

const customersReducer = (state = initialState, action) => {
    switch (action.type){
        case 'SET_CUSTOMERS_LOADING':
            return {
                ...state,
                loading: true
            };
        case 'SET_CUSTOMERS_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload.customers,
                pages: action.payload.pages,
                items: action.payload.items,
                error: null
            };
        case 'SET_CUSTOMERS_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
};

export default customersReducer;
