import {
    card
} from "../../material-base";

const pageStyle = theme => ({
    card: {
        ...card
    },
    cardContent: {
        padding: '0',
        '&:last-child':{
            padding: '0'
        }
    },
    gridContainer: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down("md")]: {
            flexDirection: 'column',
        },
    },
    gridItem: {
        margin: `0 0 ${theme.spacing.unit*2}px 0`,
        '&.withParent': {
            width: '50%',
            [theme.breakpoints.down("md")]: {
                width: '100%',
            },
            '&:first-child': {
                marginRight: `${theme.spacing.unit*2}px`,
                [theme.breakpoints.down("md")]: {
                    marginRight: 0,
                },
            }
        }
    },
    btnContainer: {
        display: 'flex',
        alignContent: 'space-between',
        margin: `0 0 0 ${theme.spacing.unit*3}px`,
        [theme.breakpoints.down("xs")]: {
            margin: `0 0 0 ${theme.spacing.unit*2}px`,
        },
        '&> *': {
            marginRight: theme.spacing.unit,
            '&:last-child': {
                marginRight: 'none'
            }
        }
    },
    footerBtnWrapper: {
        margin: `${theme.spacing.unit}px 0`,
        '&> *': {
            marginRight: theme.spacing.unit*2,
            '&:last-child': {
                marginRight: 0
            }
        }
    },
    textCardContainer: {
        margin: `0 ${theme.spacing.unit*3}px ${theme.spacing.unit*2}px ${theme.spacing.unit*3}px`,
    },
    alert: {
        textAlign: "center",
        background: "rgb(211, 47, 47)",
        borderRadius: "4px",
        border: "1px solid rgb(211, 47, 47)",
        margin: "10px",
        color: "rgb(255, 255, 255)",
        fontWeight: "bold",
    }
});

export default pageStyle;
