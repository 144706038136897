export const setLogsFilters = ({
    order, sortBy, search, page, rows
}) => {
    return {
        type: 'SET_LOGS_FILTERS',
        order,
        sortBy,
        search,
        page,
        rows
    };
};

