import qs from 'query-string';
import _ from 'lodash';
// default reducer state
import { customersFiltersDefault } from '../../reducers/customers/filters';
import { logsFiltersDefault } from '../../reducers/logs/filters';
import { serversFiltersDefault } from '../../reducers/servers/filters';

export default (params, filters, dataType) => {
    // first update filters
    if(params){
        const paramsKeys = Object.keys(params);
        paramsKeys.forEach((key) => {
            filters[key] = params[key];
        })
    }

    // extract search
    let searchStr = '';
    const keys = Object.keys(filters);
    keys.forEach((key) => {
        if(key === 'search'){
            let [ all, searchItems ] = filters[key];
            if(all) searchStr = `${searchStr}all=${all}&`;
            if(searchItems){
                searchItems.forEach((item) => {
                    const [ searchKey ] = Object.keys(item);
                    searchStr = `${searchStr}${searchKey}=${item[searchKey]}&`;
                });
            }
        }else{
            searchStr = `${searchStr}${key}=${filters[key]}&`;
        }
    });
    searchStr = searchStr.substring(0, searchStr.length-1);

    // change object
    const firstLvlQuery = Object.keys(filters);
    let search = ['', []];
    const qsObj = qs.parse(searchStr);
    const qsObjKeys = Object.keys(qsObj);
    const pageOrItems = ['page', 'rows'];
    let queryObject = { sort: `${qsObj.order === 'asc' ? `+${dataType}.` : `-${dataType}.`}${qsObj.sortBy === 'address' ? 'country' : qsObj.sortBy}` };
    qsObjKeys.forEach((key) => {
        if(_.indexOf(pageOrItems, key) !== -1){
            queryObject[key] = parseInt(qsObj[key], 10);
        }else if(_.indexOf(firstLvlQuery, key) === -1){
            search = key === 'all' ? [qsObj[key], []] : ['', [...search[1],{ [key]: qsObj[key] }]];
        }
    });
    queryObject.search = search;
    if(dataType === 'customers'){
        queryObject.deleted = qsObj.deleted === 'true';
    }
    if(dataType === 'servers'){
        queryObject.status = getServerStatus(qsObj.serverType);
        if(qsObj.sortBy === 'address'){
            queryObject.sort = `${qsObj.order === 'asc' ? `+` : `-`}customers.country`;
        }else if(qsObj.sortBy === 'name'){
            queryObject.sort = `${qsObj.order === 'asc' ? `+` : `-`}customers.name`;
        }
    }
    return queryObject;
};

const getServerStatus = (serverType) => {
    let status = 0;
    switch(serverType){
        case 'active':
            status = 1; break;
        case 'new':
            status = 2; break;
        case 'deleted':
            status = 3; break;
    }
    return status;
};

const getDefaultFilters = (dataType) => {
    switch(dataType){
        case 'customers':
            return customersFiltersDefault;
        case 'servers':
            return serversFiltersDefault;
        case 'logs':
            return logsFiltersDefault;
        default:
            return {};
    }
};

export const getFilters = (dataType) => {
    const defaultFilters = getDefaultFilters(dataType);
    let queryStr = document.location.search;
    let filters = {};
    if(queryStr){
        const firstLvlQuery = Object.keys(defaultFilters);
        const pageOrItems = ["page", "rows"];
        const booleans = ["deleted"];
        let search = ['', []];
        const qsObj = qs.parse(queryStr);
        const qsKeys = Object.keys(qsObj);
        qsKeys.forEach((key) => {
            let value = qsObj[key];
            if(_.indexOf(booleans, key) !== -1){
                value = value === "true";
            }
            if(_.indexOf(pageOrItems, key) !== -1){
                value = parseInt(value, 10);
            }
            if(_.indexOf(firstLvlQuery, key) !== -1){
                filters[key] = value;
            }else if(key === 'all'){
                search[0] = value;
            }else{
                search[1].push({ [key]: value });
            }
        });
        filters.search = search;
        return filters;
    }
    return null;
};