export const customersFiltersDefault = {
    sortBy: 'name',
    order: 'asc',
    deleted: false,
    search: ['', []],
    page: 1,
    rows: 150,
};

const customersFiltersReducer = (state = customersFiltersDefault, action) => {
    switch (action.type){
        case 'SET_CUSTOMERS_FILTERS':
            return {
                ...state,
                sortBy: action.sortBy,
                order: action.order,
                deleted: action.deleted,
                search: action.search,
                page: action.page,
                rows: action.rows,
            };
        default:
            return state;
    }
};

export default customersFiltersReducer;