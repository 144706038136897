import React from "react";
// own components
import ActionButtonComp from '../general/ActionButtonComp';
// styles
import withStyles from "@material-ui/core/styles/withStyles";
import style from '../../styles/jss/components/general/infoMsgStyle';

const ErrorMsg = (props) => {

    const { classes, size, msg, action, actionTitle, actionIcon } = props;
    const message = msg ? msg : 'Error has occurred.';

    return <div className={`${classes.loaderWrapper} ${size === 'small' ? classes.small : classes.main}`}>
        <p>{message}</p>
        {action && actionTitle && <p>
            <ActionButtonComp
                active={true}
                action={action}
                title={actionTitle}
                icon={actionIcon}
            />
        </p>}
    </div>;
};

export default withStyles(style)(ErrorMsg);

