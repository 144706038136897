import { VpnStartServerReq } from '../../pb/server_pb';
import { serverPromiseService } from '../libs/request-clients';
import { setServerSuccess } from '../servers/server';
import { getMetadata, getVpnStatus } from '../libs/request-helpers';

export const setStartVpnLoadingProgress = (serverId, stateText = '') => ({
    type: 'SET_START_VPN_LOADING_PROGRESS',
    payload: { serverId, stateText }
});

export const setStartVpnSuccess = () => ({
    type: 'SET_START_VPN_SUCCESS'
});

export const setStartVpnError = (error) => ({
    type: 'SET_START_VPN_FAILURE',
    payload: { error }
});

export const startServerVpn = (server_id) => {
    return (dispatch, getState) => {
        server_id = parseInt(server_id, 10);

        dispatch(setStartVpnLoadingProgress(server_id));

        let vpnStartServerReq = new VpnStartServerReq();
        vpnStartServerReq.setIdList([server_id]);
        vpnStartServerReq.setWaitForResult(true); // true for one server
        let stream = serverPromiseService.vpnStart(vpnStartServerReq, getMetadata());

        return new Promise((resolve, reject) => {
            let serverData = getState().server.item;
            let data = {
                completed: false,
                msg: '',
                vpn_status: 0,
                vpn_ipv4: '',
                vpn_ipv6: ''
            };

            stream.on('error', (error) => {
                error.message = `Error occurred while starting VPN: ${error.message}`;
                dispatch(setStartVpnError(error));
                reject(error);
            });

            stream.on('data', (response) => {
                data.completed  = response.getCompleted();
                data.msg        = response.getStateText();
                data.vpn_status = getVpnStatus(response.getVpnStatus());

                serverData.vpn_status = data.vpn_status;

                if(data.completed){
                    data.vpn_ipv4 = response.getVpnIpv4();
                    data.vpn_ipv6 = response.getVpnIpv6();

                    serverData.vpn_ipv4 = data.vpn_ipv4;
                    serverData.vpn_ipv6 = data.vpn_ipv6;
                }
                dispatch(setStartVpnLoadingProgress(server_id, data.msg));
                dispatch(setServerSuccess(serverData));
            });

            stream.on('status', (status) => {
                if(status.code !== 0) {
                    const message = `Error occurred while starting VPN: ${status.details}`;
                    const err = {code: status.code, message: message};
                    dispatch(setStartVpnError(err));
                    reject(err);
                    return;
                }

                // Success
                dispatch(setStartVpnSuccess());
                resolve(data);
            });
        });
    }
};
