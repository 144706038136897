import React from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import enGB from 'date-fns/locale/en-GB';
import de from 'date-fns/locale/de';
import ru from 'date-fns/locale/ru';
// https://www.npmjs.com/package/react-datepicker
//import "react-datepicker/dist/react-datepicker.css";
// https://reactdatepicker.com

// register locale
registerLocale('en-GB', enGB);
registerLocale('de', de);
registerLocale('ru', ru);

// set default locale
const DEFAULT_LOCALE = 'en-GB';
setDefaultLocale(DEFAULT_LOCALE);

// set others defaults
const DEFAULT_DATE_FORMAT = 'dd-MM-yyyy'; // yyyy -> low case, MM -> uppercase
const DEFAULT_TIME_FORMAT = 'HH:mm';
const DEFAULT_TIME_INTERVAL = 15;

class DatePickerComp extends React.Component {

    constructor(props){
        super(props);
    }

    handleChangeDate = (date) => {
        this.props.handleChangeDate(this.props.name, date);
    };

    handleFocus = (event) => {
        if(!event.currentTarget.value.length > 0){
            const datepicker = document.querySelector(`[for=${this.props.name}]`).parentElement;
            datepicker.classList.add("focus");
        }
    };

    handleFocusLabel = (evt) => {
        const datepicker = document.querySelector(`[for=${this.props.name}]`).parentElement;
        datepicker.classList.remove("focus");
    };

    componentWillUnmount = () => {
        // remove event focus
        const datepickerInput = document.querySelector(`[name=${this.props.name}]`);
        datepickerInput.removeEventListener('focus', this.handleFocus);
    };

    componentDidMount = () => {
        // set focus event
        const datepickerInput = document.querySelector(`[name=${this.props.name}]`);
        datepickerInput.addEventListener('focus', this.handleFocus);
    };

    render(){
        const {
            locale,
            label,
            notEmpty,
            placeholderText,
            helperText,
            error,
            date,
            startDate,
            endDate,
            dateFormat,
            showTimeSelect,
            isClearable,
            timeFormat,
            timeIntervals,
            minDate,
            maxDate,
            minTime,
            maxTime,
            excludeDates,
            name,
            selectsStart,
            selectsEnd,
        } = this.props;

        const _label = notEmpty ? `${label} *`: label;
        const active = date ? 'active' : '';
        const errorInput = error ? 'error' : '';
        let _date = date;
        let _startDate = startDate;
        let _endDate = endDate;
        const _minDate = minDate ? minDate : (selectsEnd ? startDate : undefined);
        const _maxDate = maxDate ? maxDate : (selectsStart ? endDate : undefined);

        if(startDate && endDate && (selectsStart || selectsEnd)){
            let diff = endDate.valueOf() - startDate.valueOf();
            if(diff < 0){
                _date = selectsStart ? startDate : endDate;
                if(selectsStart) _endDate = startDate;
                else if(selectsEnd) _startDate = endDate;
            }
        }

        return <div className={`react-datepicker-container ${active} ${errorInput}`}>
            <label
                className="react-datepicker-label"
                htmlFor={name}
            >{_label}</label>
            <DatePicker
                name={name}
                fixedHeight={showTimeSelect}
                placeholderText={placeholderText}
                isClearable={isClearable}
                selected={_date}
                startDate={_startDate}
                endDate={_endDate}
                selectsStart={selectsStart}
                selectsEnd={selectsEnd}
                onChange={this.handleChangeDate}
                onBlur={this.handleFocusLabel}
                locale={locale ? locale : DEFAULT_LOCALE}
                dateFormat={dateFormat ? dateFormat : DEFAULT_DATE_FORMAT}
                showTimeSelect={showTimeSelect}
                timeFormat={timeFormat ? timeFormat : DEFAULT_TIME_FORMAT}
                timeIntervals={timeIntervals ? timeIntervals : DEFAULT_TIME_INTERVAL}
                minDate={_minDate}
                maxDate={_maxDate}
                minTime={minTime}
                maxTime={maxTime}
                excludeDates={excludeDates ? excludeDates : []}
            />
            {helperText && <div
                className="helperText"
            >{helperText}</div>}
        </div>;
    }

}

export default DatePickerComp;

