import React from "react";
// Material UI components
import IconCircle from '@material-ui/icons/Brightness1';
// styles
import withStyles from "@material-ui/core/styles/withStyles";
import style from '../../styles/jss/components/general/multipleStatusStyle';

const MultipleStatus = (props) => {
    const { classes, data } = props;

    let serverStatus = [];
    data.forEach((el, index) => {
        serverStatus.push(
            <IconCircle
                key={index}
                className={`${classes.statusIcon} ${el === 1 ? 'active' : ''}`}
            />
        )
    });

    return(serverStatus);
};

export default withStyles(style)(MultipleStatus);
