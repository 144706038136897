import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// Material UI
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MultipleStatus from '../general/MultipleStatus';
// style
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/general/tableStyle';

const serversColumns = [
    { key: 'id',            label: 'ID' },
    { key: 'hostname',      label: 'Hostname' },
//    { key: 'browser_status',label: 'Browser status' },
    { key: 'vpn_status',    label: 'VPN status' },
    { key: 'tag',           label: 'Tag' },
    { key: 'type',          label: 'Type' },
    { key: 'os',            label: 'OS' },
];

const StudioServers = ({ classes, _customer, history }) => {

    const servers = _customer.item.servers;

    const handleToServerDetails = (id) => (evt) => {
        if(id){
            history.push(`/servers/${id}`);
        }
    };

    return <Table className={`${classes.mainTable} cardTable StudioServers`}>
        <TableHead>
            <TableRow>
                {serversColumns.map((clmn) => {
                    return <TableCell
                        key={clmn.key}
                    >{clmn.label}</TableCell>
                })}
            </TableRow>
        </TableHead>
        <TableBody>
            {servers.map((el, index) => {
                return <TableRow
                    key={index}
                    className={`${!el.isOnline ? 'inactive' : ''} ${el.deletedAt ? 'deleted' : ''}`}
                    onClick={handleToServerDetails(el.id)}
                >
                    {serversColumns.map((item) => {
                        const key = item.key;
                        const content = key === 'browser_status' ?
                            <MultipleStatus data={el[key]} /> :
                            (el[key] ? el[key] : '---');

                        return <TableCell
                            key={key}
                            className="withAction"
                        >{content}</TableCell>
                    })}
                </TableRow>
            })}
        </TableBody>
    </Table>;
};

const mapStateToProps = (state) => {
    return {
        _customer: state.customer,
    };
};

export default compose(
    withStyles(style),
    connect(mapStateToProps, null),
)(StudioServers);
