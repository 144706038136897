const initialState = {
    item: {},
    loading: false,
    stateText: '',
    error: null
};

const logReducer = (state = initialState, action) => {
    switch (action.type){
        case 'SET_LOG_LOADING':
            return {
                ...state,
                loading: true
            };
        case 'SET_LOG_LOADING_PROGRESS':
            return {
                ...state,
                stateText: action.payload.stateText
            };
        case 'SET_LOG_SUCCESS':
            return {
                ...state,
                stateText: '',
                loading: false,
                item: action.payload.log,
                error: null
            };
        case 'SET_LOG_FAILURE':
            return {
                ...state,
                loading: false,
                stateText: '',
                error: action.payload.error
            };
        default:
            return state;
    }
};

export default logReducer;
