import {
    tableFontSize,
} from "../../material-base";

const tableStyle = theme => ({
    mainTable: {
        '&.cardTable th, &.cardTable td': {
            borderBottom: 'none',
            color: 'rgba(0, 0, 0, 0.87)'
        },
        '& th': {
            fontWeight: 300,
            '& .sortColumn': {
                color: 'rgba(0, 0, 0, 0.87)'
            },
            '& .headerCellContent': {
                display: 'flex',
                alignItems: 'center'
            },
            '&.noSort': {
                cursor: 'default'
            },
            '& .sortIcon': {
                color: 'rgba(0, 0, 0, 0.37)',
                fontSize: '18px',
                marginLeft: '3px',
                transition: 'all 0.6s ease',
                mozTransition: 'all 0.6s ease',
                webkitTransition: 'all 0.6s ease',
                oTransition: 'all 0.6s ease',
                '&.desc': {
                    transform: 'rotate(180deg)',
                    mozTransform: 'rotate(180deg)',
                    msTransform: 'rotate(180deg)',
                    oTransform: 'rotate(180deg)',
                    webkitTransform: 'rotate(180deg)',
                },
                '&.active': {
                    color: theme.palette.secondary.main,
                }
            }
        },
        '& th, td': {
            fontSize: tableFontSize,
            padding: `${theme.spacing.unit}px 0 ${theme.spacing.unit}px ${theme.spacing.unit * 3}px`,
        },
        '&.withHeader th': {
            backgroundColor: 'rgba(0,0,0,0.04)',
            fontWeight: 400
        },
        '& td': {
            '& span, & div': {
                fontWeight: 400,
                verticalAlign: "top"
            },
            '& .miniSpanContent': {
                padding: `0 ${theme.spacing.unit * 3}px 0 0`,
                fontSize: '13px'
            }
        },
        '& tr': {
            height: 'auto',
            '&.inactive': {
                backgroundColor: 'rgba(212, 7, 0, 0.1)',
            },
            '&.deleted': {
                textDecoration: 'line-through'
            },
            '&.selected': {
                backgroundColor: 'rgba(0, 212, 25, 0.08)'
            },
            '&.nodeItem': {
                '& td:first-child': {
                    width: 250
                }
            }
        },
        '& .withAction:hover': {
            cursor: 'pointer'
        },
        '& .checkBox': {
            width: 'auto',
            height: 'auto',
            '& svg': {
                fontSize: 20
            }
        },
        [theme.breakpoints.down("md")]: {
            '& thead': {
                border: 'none',
                clip: 'rect(0 0 0 0)',
                height: 1,
                margin: '-1px',
                overflow: 'hidden',
                padding: 0,
                position: 'absolute',
                width: '1px',
            },
            '&.withHeader thead': {
                position: 'relative',
                '& th': {
                    width: '100%',
                    backgroundColor: '#e0e0e0c9',
                }
            },
            '& tbody': {
                display: 'block'
            },
            '& tr': {
                display: 'block',
                '&:nth-child(even)' : {
                    backgroundColor: 'rgba(0,0,0,0.04)'
                },
                '&.selected:nth-child(even)': {
                    backgroundColor: 'rgba(0, 212, 25, 0.08)'
                },
            },
            '& td': {
                display: 'block',
                position: 'relative',
                paddingLeft: '50%',
                textAlign: 'left'
            }
        },
    },
    mobileTable: {
        margin: `0 0 ${theme.spacing.unit*2}px 0`,
        '& tr': {
            height: 'auto',
            '&.inactive': {
                backgroundColor: 'rgba(212, 7, 0, 0.1)',
            },
        },
        '&.modal tr': {
            height: 'auto',
            '&:nth-child(odd)': {
                backgroundColor: 'rgba(0,0,0,0.04)'
            }
        },
        '& td': {
            borderBottom: 'none',
            fontSize: tableFontSize,
            padding: `${theme.spacing.unit}px 0 ${theme.spacing.unit}px ${theme.spacing.unit * 3}px`,
            '&:first-child:not(colspan)' : {
                fontWeight: 300,
                verticalAlign: "top"
            },
            '&:last-child:not([colspan])' : {
                paddingLeft: 0,
                width: '50%'
            },
            [theme.breakpoints.down("xs")]: {
                paddingLeft: 18,
            },
            '& span.online': {
                color: theme.palette.secondary.main
            },
            '& span.offline': {
                color: theme.palette.error.main
            },
            '& .copyTextBtn': {
                width: 'auto',
                height: 'auto',
                marginLeft: theme.spacing.unit
            },
            '& div, span': {
                fontWeight: 400
            },
            '&.notes': {
                backgroundColor: "#dbdbdb",
                whiteSpace: "pre-wrap"
            }

        },
        '&.modal td': {
            padding: `${theme.spacing.unit}px ${theme.spacing.unit*3}px`,
        },
    },
    sensorTable: {
        margin: '0',
        width: '100%',
        borderCollapse: 'collapse',
        // '& thead tr td': {
        //     fontWeight: "bold !important",
        // },
        '& td': {
            margin: '0',
            padding: '0',
            width: 'auto !important'
        },
        '& tbody tr:hover td': {
            background: '#eee'
        },
        '& .value': {
            textAlign: 'right'
        }
    }
});

export default tableStyle;
