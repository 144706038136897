import {
    drawerWidth,
    defaultFont,
} from "../../material-base";

const sidebarStyle = theme => {
    return {
        drawerPaper: {
            borderRight: 'none',
            backgroundImage: 'url("/images/sidebar.jpg")',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            width: drawerWidth,
            [theme.breakpoints.up('md')]: {
                position: 'fixed',
            },
        },
        toolbar: theme.mixins.toolbar,
        toolbarContentWrapper: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: "35px",
            textDecoration: 'none'
        },
        toolbarContent: {
            fontSize: '18px',
            color: 'white',
        },
        divider: {
            backgroundColor: theme.palette.common.white,
            opacity: '0.2',
            marginBottom: '15px'
        },
        list: {
            paddingLeft: "0",
            paddingTop: "0",
            paddingBottom: "0",
            margin: '10px 25px 0 25px',
            listStyle: "none",
            position: "unset",
        },
        item: {
            transition: "all 300ms linear",
            position: "relative",
            display: "block",
            textDecoration: "none",
            borderRadius: '4px',
            "&:hover,&:focus,&:visited,&": {
                color: "#FFFFFF"
            },
            "&.active": {
                backgroundColor: theme.palette.primary.main
            }
        },
        itemLink: {
            width: "auto",
            margin: "0",
            borderRadius: "3px",
            position: "relative",
            display: "block",
            padding: "10px 25px",
            backgroundColor: "transparent",
            ...defaultFont
        },
        itemIcon: {
            width: "24px",
            height: "30px",
            float: "left",
            marginRight: "15px",
            textAlign: "center",
            verticalAlign: "middle",
            color: theme.palette.common.white,
        },
        itemText: {
            ...defaultFont,
            margin: "0",
            lineHeight: "30px",
            fontSize: "16px",
            color: "#FFFFFF",
            padding: '0'
        },
        logo:{
            position: 'relative',
            left: '-10px',
            width: '35px'
        },
        logoutBtn: {
            padding: "10px 25px",
            position: 'absolute',
            bottom: '0',
            margin: '25px',
            width: 'calc(100% - 50px)',
        },
    }
};

export default sidebarStyle;