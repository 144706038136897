import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// Material UI components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
// style
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/general/tableStyle';

const customerColumns = [
    { key: 'number',        label: 'Customer number' },
    { key: 'milon_care_id', label: 'Milon CARE ID' },
    { key: 'name',          label: 'Name' },
    { key: 'contact_person',label: 'Contact person' },
    { key: 'street',        label: 'Street' },
    { key: 'zip',           label: 'Zip' },
    { key: 'city',          label: 'City' },
    { key: 'country',       label: 'Country' },
    { key: 'telephone',     label: 'Telephone' },
    { key: 'homepage',      label: 'Homepage' },
    { key: 'email',         label: 'E-mail' },
];
const CustomerTable = ({ classes, _customer }) => {

    const dataItem = _customer.item;

    return <Table className={classes.mobileTable}>
        <TableBody>
            {customerColumns.map((el) => {
                const key = el.key;
                const value = dataItem[el.key] ? dataItem[el.key] : '---';

                // @todo move logic to a separate component, see also customer table @ customer
                let link ='';
                let linkTarget = '_self';

                if (value !== '---') {
                    switch (key) {
                        case 'homepage':
                            if (value === '') {
                                break;
                            }

                            linkTarget = '_blank';

                            if (value.toLowerCase().substr(0, 7) !== 'http://'
                                && value.toLowerCase().substr(0, 8) !== 'https://') {
                                link = 'http://' + value;
                            } else {
                                link = value;
                            }

                            break;

                        case 'email':
                            if (value === '') {
                                break;
                            }

                            link = 'mailto:' + value;
                            break;

                        case 'telephone':
                            if (value === '') {
                                break;
                            }

                            link = 'tel:' + value;
                            break;
                    }
                }

                return <TableRow key={key}>
                    <TableCell>{el.label}</TableCell>
                    <TableCell>{link ? <a href={link} target={linkTarget}>{value}</a> : <span>{value}</span>}</TableCell>

                        {/*{link ? <TableCell style={{cursor: "pointer"}} onClick={() => { window.open(link)}}>{value}</TableCell> : <TableCell>{value}</TableCell> }*/}
                </TableRow>;
            })}
        </TableBody>
    </Table>
};

const mapStateToProps = (state) => {
    return {
        _customer: state.customer
    };
};

export default compose(
    withStyles(style),
    connect(mapStateToProps, null),
)(CustomerTable);
