import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import _ from 'lodash';
// actions
import { startSetUser } from '../../actions/auth/user';
import { startLogout } from '../../actions/auth/auth';
// Material UI components
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// styles
import { withStyles } from '@material-ui/core/styles';
import headerStyle from '../../styles/jss/components/general/headerStyle';

class Header extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            anchorEl: null,
        }
    }

    componentDidMount(){
        this.props.startSetUser();
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleMenu = (evt) => {
        this.setState({ anchorEl: evt.currentTarget });
    };

    handleLogout = (evt) => {
        this.handleClose();
        this.props.startLogout();
    };

    render(){
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        const { classes, user, handleDrawerToggle, switcher } = this.props;
        const { item, loading, errorSet } = user;

        let initials;
        if(!loading && !errorSet && item.name){
            try{
                const [ firstname, lastname ] = item.name.split(' ');
                initials = `${firstname.substring(0, 1)}${lastname.substring(0, 1)}`.toUpperCase();
            }catch(err){}
        }

        const menuItems = [ 'name', 'email' ];
        const userMenuId = 'user-menu-appbar';

        return <AppBar className={classes.appBar}>
            <Toolbar className={classes.headerToolbar}>
                <IconButton
                    color="inherit"
                    onClick={handleDrawerToggle}
                    className={classes.menuIcon}
                ><MenuIcon /></IconButton>
                {switcher}
                {!loading && !errorSet && <div className={classes.userWrapper}>
                    <Avatar
                        aria-owns={open ? userMenuId : undefined}
                        aria-haspopup={true}
                        onClick={this.handleMenu}
                    >{initials || <PersonIcon />}</Avatar>
                    <Menu
                        id={userMenuId}
                        anchorEl={anchorEl}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={open}
                        onClose={this.handleClose}
                    >
                        {menuItems.map((el, index) => {
                            if(!item[el]) return;
                            return <MenuItem
                                key={index}
                                onClick={this.handleClose}
                                className={`${classes.noActionItem} ${classes.item}`}
                            >{item[el]}</MenuItem>;
                        })}
                        <Divider />
                        <MenuItem
                            className={`${classes.actionItem} ${classes.item}`}
                            onClick={this.handleLogout}
                        ><ListItemIcon><AccountCircleIcon /></ListItemIcon> Sign out</MenuItem>;
                    </Menu>
                </div>}
            </Toolbar>
        </AppBar>;
    }
}


// connect: [mapStateToProps], [mapDispatchToProps], [mergeProps], [options]
/*
 // used if three params are passed; ownProps — the original props received by the component
 const mergeProps = (stateProps, dispatchProps, ownProps) => {
 return Object.assign({}, ownProps, stateProps, dispatchProps)
 }
 */

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => ({
    startSetUser: () => dispatch(startSetUser()),
    startLogout: () => dispatch(startLogout()),
});

const options = {
    areStatesEqual: (next, prev) => {
        return _.isEqual(prev.user, next.user);
    }
};

export default compose(
    withStyles(headerStyle),
    connect(mapStateToProps, mapDispatchToProps, null, options),
)(Header);

