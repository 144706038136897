import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Notification from '../../components/general/Notification';
import ActionButtonComp from '../general/ActionButtonComp';

export default class LogoutErrorNotification extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            open: true,
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    handleClick = () => {
        this.setState({ open: false });
        this.props.handleAction();
    };

    render() {
        const action = <ActionButtonComp
            key="logout"
            size="small"
            active={true}
            action={this.handleClick}
            title="Reload"
        />;

        return <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            autoHideDuration={4000}
            open={this.state.open}
            onClose={this.handleClose}
        >
            <Notification
                action={action}
                onClose={this.handleClose}
                message="Logout error has occurred. Please reload and try again."

            />
        </Snackbar>
    }
}
