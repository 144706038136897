import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _ from 'lodash';
// own components
import InputTextComp from '../general/InputTextComp';
import SelectComp from '../general/SelectComp';
import ActionButtonComp from '../general/ActionButtonComp';
// styles
import { withStyles } from '@material-ui/core/styles';
import toolbarStyle from '../../styles/jss/components/general/toolbarStyle';

const DEFAULT_INACTIVE_OPTION = 'noSelection';
const serverTypeOptions = [
    { key: DEFAULT_INACTIVE_OPTION, value: 'No selection'       },
    { key: 'master',                value: 'Master'             },
    { key: 'slave',                 value: 'Slave'              },
    { key: 'on-premises',                 value: 'On-Premises Stack' }
];
const fields = [
    { id: 'hostname',       label: 'Hostname',       placeholder: '500000xxxx',                  notEmpty: true,                     max: 150 },
    { id: 'type',           label: 'Type',           options: serverTypeOptions,                 notEmpty: true,                              },
    { id: 'tag',            label: 'Tag',            placeholder: 'e.g. KAZ',                    notEmpty: false,                    max: 255 },
    { id: 'browser_url1',   label: 'Browser URL 1',  placeholder: 'e.g. http://some/address/1',  notEmpty: false,  fullWidth: true,  max: 500 },
    { id: 'browser_url2',   label: 'Browser URL 2',  placeholder: 'e.g. http://some/address/2',  notEmpty: false,  fullWidth: true,  max: 500 },
    { id: 'browser_url3',   label: 'Browser URL 3',  placeholder: 'e.g. http://some/address/3',  notEmpty: false,  fullWidth: true,  max: 500 },
    { id: 'browser_url4',   label: 'Browser URL 4',  placeholder: 'e.g. http://some/address/4',  notEmpty: false,  fullWidth: true,  max: 500 },
    { id: 'ntp_servers',    label: 'NTP servers (separated by comma)',    placeholder: 'e.g. 0.pool.ntp.org, 1.pool.ntp.org',  notEmpty: false,  fullWidth: true,  max: 500 },
    { id: 'documentation_url', label: 'Documentation URL (Link to Confluence page)',  placeholder: 'e.g. https://wiki',  notEmpty: false,  fullWidth: true,  max: 290 },
    { id: 'notes',           type: 'textarea',  label: 'Notes',  placeholder: '',  notEmpty: false,  fullWidth: true,  max: 65000, multiline: true},
];

class EditServerForm extends React.Component {

    constructor(props){
        super(props);

        const {
            hostname, type, tag, browser_url1, browser_url2, browser_url3, browser_url4, notes, documentation_url, ntp_servers
        } = this.props._server.item;

        this.state = {
            hostname: hostname || '',
            type: type || '',
            tag: tag || '',
            browser_url1: browser_url1 || '',
            browser_url2: browser_url2 || '',
            browser_url3: browser_url3 || '',
            browser_url4: browser_url4 || '',
            notes: notes || '',
            documentation_url: documentation_url || '',
            ntp_servers: ntp_servers || '',
            errors: {},
        };
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        return !_.isEqual(this.state, nextState);
    };

    handleChange = (name, value) => {
        let errors = this.state.errors;
        delete errors[name];

        this.setState({
            [name]: value,
            errors
        });
    };

    checkFieldErrors = () => {
        let data = { ...this.state };
        delete data.errors;
        let errors = {};

        const keys = Object.keys(data);
        keys.forEach((key) => {
            const [el] = _.filter(fields, (field) => { return field.id === key });
            const value = data[key].trim();

            if(el.notEmpty && (_.isEmpty(value) || value === DEFAULT_INACTIVE_OPTION)) {
                errors[key] = `Field '${el.label}' cannot be empty`;
            }else if(el.max && !_.isEmpty(value) && value.length > el.max){
                errors[key] = `'${el.label}' value must be min. 1 and max. ${el.max} characters long.`;
            }
        });

        return errors;
    };

    onSubmit = (evt) => {
        evt.preventDefault();

        const errors = this.checkFieldErrors();
        this.setState({ errors });

        if(Object.keys(errors).length < 1){
            let data = { ...this.state };
            delete data.errors;
            this.props.onSubmit(data);
        }
    };

    render(){
        const errors = this.state.errors;
        const { classes, handleCancel } = this.props;

        return <form>
            <div className={classes.inputsContainer}>
                {fields.map((el, index) => {
                    let value = this.state[el.id] || '';
                    if(el.options){
                        let inactive = !value || value === DEFAULT_INACTIVE_OPTION;
                        value = inactive ? DEFAULT_INACTIVE_OPTION : value;
                        return <SelectComp
                            key={index}
                            label={el.label}
                            name={el.id}
                            value={value}
                            options={el.options}
                            inactive={inactive}
                            action={this.handleChange}
                            fixedWidth={true}
                            notEmpty={el.notEmpty}
                            helperText={errors[el.id] || ''}
                            error={!!(errors[el.id])}
                        />;
                    } else{
                        return <InputTextComp
                            key={index}
                            name={el.id}
                            label={el.label}
                            value={value}
                            placeholder={el.placeholder}
                            action={this.handleChange}
                            fullWidth={el.fullWidth ? 'fullWidth' : ''}
                            notEmpty={el.notEmpty}
                            helperText={errors[el.id] || ''}
                            error={!!(errors[el.id])}
                            multiline={!!el.multiline}
                        />;
                    }
                })}
            </div>
            <div className={classes.footerBtnWrapper}>
                <ActionButtonComp
                    active={false}
                    action={handleCancel}
                    title="Cancel"
                />
                <ActionButtonComp
                    active={true}
                    action={this.onSubmit}
                    title="Save"
                />
            </div>
        </form>;
    };
}

const mapStateToProps = (state) => ({
    _server: state.server,
});

const options = {
};

export default compose(
    withStyles(toolbarStyle),
    connect(mapStateToProps, undefined, null, options),
)(EditServerForm);

