import {
    dangerBoxShadow,
} from "../../material-base";

const notificationErrorMsgStyle = theme => ({
    notificationErrorMsg: {
        color: theme.palette.error.main,
        ...dangerBoxShadow,
        border: `1px solid ${theme.palette.error.main}`,
        width: 'calc(100% + 55px)',
        margin: `${theme.spacing.unit*2}px 0`,
    },
    list: {
        maxHeight: 260,
        overflowY:'auto',
    },
    errorIcon: {
        fontSize: 32,
        marginRight: theme.spacing.unit
    },
});

export default notificationErrorMsgStyle;

