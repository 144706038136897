import React from 'react';
// routes
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import routes from './routes';

const SwitchComponent = (props) => {
    return <Switch>
        {routes.map((comp) => {
            const component = props.header ? comp.title : comp.component;
            if(comp.isPublic === true){
                return <PublicRoute
                    path={comp.path}
                    component={component}
                    exact={comp.exact}
                    key={comp.component}
                />;
            }
            if(comp.isPublic === false){
                return <PrivateRoute
                    path={comp.path}
                    component={component}
                    exact={comp.exact}
                    key={comp.component}
                />;
            }
            return <Route
                component={component}
                key={comp.component}
            />;
        })}
    </Switch>;
};

export default SwitchComponent;
