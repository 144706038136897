import React from 'react';
// Material UI components
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
// style
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/general/selectCompStyle';

const SelectComp = ({
    classes,
    label,
    notEmpty,
    name,
    value,
    options,
    inactive,
    action,
    fixedWidth,
    helperText,
    error
}) => {

    const handleOnChange = (evt) => {
        const name = evt.target.name;
        const value = evt.target.value;
        action(name, value);
    };

    const active = inactive ? '' : 'active';
    fixedWidth = fixedWidth ? 'fixedWidth' : '';
    label = label ? (notEmpty ? `${label} *` : label) : '';
    const errorSelect = error ? 'error' : '';

    return <FormControl className={`${classes.selectWrapper} ${fixedWidth}`}>
        {label && <InputLabel
            focused={false}
            htmlFor={name}
            className={`${classes.selectLabel} ${active} ${errorSelect}`}
        >{label}</InputLabel>}
        <Select
            name={name}
            className={`${classes.selectRows} ${active} ${errorSelect}`}
            value={value}
            onChange={handleOnChange}
        >
            {options.map((item) => {
                return <MenuItem
                    value={item.key}
                    key={item.key}
                    className={classes.selectItem}
                >{item.value}</MenuItem>
            })}
        </Select>
        {helperText && <FormHelperText
            className={error ? classes.errorSelect : ''}
        >{helperText}</FormHelperText>}
    </FormControl>;
};

export default withStyles(style)(SelectComp);