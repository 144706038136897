import React from 'react';
// Material UI components
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import IconClear from '@material-ui/icons/Clear';
import IconSearch from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
// style
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/general/inputSearchCompStyle';

const InputSearchComp = ({
    classes,
    name,
    placeholder,
    value,
    inProgress,
    actionClear,
    actionChange,
    className,
    focused
}) => {
    const active = value.length > 0 ? 'active' : '';

    const focusInputField = input => {
        if(focused && input) {
            setTimeout(() => {
                input.focus();
            }, 400);
        }
    };

    return <TextField
        name={name}
        type="search"
        placeholder={placeholder}
        value={value}
        className={`${classes.inputSearch} ${className} ${active}`}
        InputProps={{
            disableUnderline: true,
            endAdornment: (
                <InputAdornment>
                    <IconButton
                        className="searchBtn"
                        onClick={actionClear}
                    >{inProgress ? (
                        <CircularProgress size={20} />
                    ) : (
                        value ? <IconClear/> : <IconSearch/>
                    )}</IconButton>
                </InputAdornment>
            ),
            classes: {
                focused: classes.cssFocused
            },
        }}
        onChange={actionChange}
        inputRef={focusInputField}
    />
};

export default withStyles(style)(InputSearchComp);