import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import createHistory from 'history/createBrowserHistory';
// actions
import { startSetServers } from '../../actions/servers/servers';
import { setServersFilters } from '../../actions/servers/filters';
import { getFilters } from '../../actions/libs/get-query-params';
// Material UI components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ReplayIcon from '@material-ui/icons/Replay';
// own components
import Loader from '../general/Loader';
import ServersTableToolbar from './ServersToolbar';
import ErrorMsg from '../general/ErrorMsg';
import InfoMsg from '../general/InfoMsg';
import ServersTable from './ServersTable';
// styles
import { withStyles } from '@material-ui/core/styles';
import pageStyle from '../../styles/jss/components/general/pageStyle';

const localHistory = createHistory();

class ServersPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selected: [],
        }
    }

    componentDidMount(){
        // check query and update
        const filters = getFilters('servers');
        if(filters){
            this.setState({ selected: [] });
            this.props.setServersFilters(filters);
        }
        this.props.startSetServers(filters);
    }

    handleRequestSort = _sortBy => event => {
        if(_sortBy){
            const { sortBy, order, ...rest } = this.props.serversFilters;
            let _order = 'asc';
            if(sortBy === _sortBy && order === 'asc') _order = 'desc';
            const filters = {
                ...rest,
                sortBy: _sortBy,
                order: _order
            };
            this.props.setServersFilters(filters);
            this.getServers();
        }
    };

    clearMultipleChoose = () => {
        this.setState({ selected: [] });
    };

    handleSelectAllClick = (event, checked) => {
        if(!checked) {
            this.clearMultipleChoose();
            return;
        }
        const data = this.props.serversObj.data;
        this.setState(state => ({ selected: data.map(n => n.id) }));
    };

    handleSelectServer = (id) => (evt) => {
        evt.stopPropagation();

        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };

    getServers = () => {
        this.clearMultipleChoose();

        // get data
        this.props.startSetServers()
            .then(() => {
                // generate / change url query
                let params = '/servers?';
                const filterKeys = Object.keys(this.props.serversFilters);
                filterKeys.forEach((key) => {
                    if(key !== 'search'){
                        params = `${params}${key}=${this.props.serversFilters[key]}&`;
                    }else{
                        const [ all, advanced ] = this.props.serversFilters.search;
                        if(all !== '') params = `${params}all=${all}&`;
                        advanced.forEach((el) => {
                            const [ searchKey ] = Object.keys(el);
                            params = `${params}${searchKey}=${el[searchKey]}&`;
                        });
                    }
                });
                params = params.substring(0, params.length - 1);
                localHistory.push(params);
            });
    };

    render() {
        const { selected } = this.state;
        const { classes, serversObj, serversFilters, history } = this.props;
        const { sortBy, order, serverType } = serversFilters;

        const { data, loading, error } = serversObj;
        const isLoadingError = !loading && error;
        const isSuccessWithoutData = !loading && !error && data.length === 0;
        const isSuccessWithData = !error && data.length > 0;

        return <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                <ServersTableToolbar
                    clearMultipleChoose={this.clearMultipleChoose}
                    selected={selected}
                    getServers={this.getServers}
                    history={history}
                />
                {loading && <Loader forTable={data.length > 0}/>}
                {isLoadingError && <ErrorMsg
                    size="small"
                    msg={`An error has occurred. ${error.message}`}
                    action={this.getServers}
                    actionTitle="Retry loading"
                    actionIcon={<ReplayIcon/>}
                />}
                {isSuccessWithoutData && <InfoMsg
                    size="small"
                    msg="No servers found."
                />}
                {isSuccessWithData && <ServersTable
                    data={data}
                    actionSort={this.handleRequestSort}
                    actionSelectAll={this.handleSelectAllClick}
                    actionSelectServer={this.handleSelectServer}
                    sortBy={sortBy}
                    order={order}
                    serverType={serverType}
                    selected={selected}
                    history={history}
                />}
            </CardContent>
        </Card>
    }
}

const mapStateToProps = (state) => {
    return {
        serversObj: state.servers,
        serversFilters: state.serversFilters,
    };
};

const mapDispatchToProps = (dispatch) => ({
    startSetServers: (params) => dispatch(startSetServers(params)),
    setServersFilters: (filters) => dispatch(setServersFilters(filters)),
});

export default compose(
    withStyles(pageStyle),
    connect(mapStateToProps, mapDispatchToProps),
)(ServersPage);

