import config from '../../../config';
import {
    //UserClient,
    UserPromiseClient
} from '../../pb/user_grpc_web_pb';
import {
    LoginByOauth2Req,
    LoginByOauth2CallbackReq,
    LogoutReq,
    GetMeReq,
} from '../../pb/user_pb';
import { CustomerPromiseClient } from '../../pb/customer_grpc_web_pb';
import { ServerPromiseClient } from '../../pb/server_grpc_web_pb';

//const { backend } = config;
// const backendUrl = `${backend.encryption}://${backend.host}:${backend.port}`;
const backendUrl = ``;
export let userPromiseService = new UserPromiseClient(backendUrl);

export let loginByOauth2Req = new LoginByOauth2Req();
export let loginByOauth2CallbackReq = new LoginByOauth2CallbackReq();
export let logoutReq = new LogoutReq();
export let getMeReq = new GetMeReq();

export let customerPromiseService = new CustomerPromiseClient(backendUrl);
export let serverPromiseService = new ServerPromiseClient(backendUrl);
