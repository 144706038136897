const initialState = {
    authToken: null,
    errorLogin: null,
    errorLogout: null
};

const authReducer = (state = initialState, action) => {
    switch (action.type){
        case 'SET_LOGIN_SUCCESS':
            return {
                ...state,
                authToken: action.payload.authToken,
                errorLogin: null
            };
        case 'SET_LOGIN_FAILURE':
            return {
                ...state,
                errorLogin: action.payload.error
            };
        case 'SET_LOGOUT_SUCCESS':
            return {
                ...state,
                authToken: null,
                errorLogout: null
            };
        case 'SET_LOGOUT_FAILURE':
            return {
                ...state,
                errorLogout: action.payload.error
            };
        default:
            return state;
    }
};

export default authReducer;
