const initialState = {
    data: [],
    loading: false,
    error: null
};

const devicesReducer = (state = initialState, action) => {
    switch (action.type){
        case 'SET_DEVICES_LOADING':
            return {
                ...state,
                loading: true
            };
        case 'SET_DEVICES_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload.devices,
                error: null
            };
        case 'SET_DEVICES_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
};

export default devicesReducer;
