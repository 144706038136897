export const setCustomersFilters = ({
    deleted, order, sortBy, search, page, rows
}) => {
    return {
        type: 'SET_CUSTOMERS_FILTERS',
        deleted,
        order,
        sortBy,
        search,
        page,
        rows
    };
};
