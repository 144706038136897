import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// actions
import { startSetServer, startUpdateServer } from '../../actions/servers/server';
// Material UI components
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ReplayIcon from '@material-ui/icons/Replay';
// own components
import EditServerForm from './EditServerForm';
import MassActionNotification from '../general/MassActionNotification';
import Loader from '../general/Loader';
import ErrorMsg from '../general/ErrorMsg';
// styles
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/general/cardCompStyle';

class EditServerPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            saveErr: ''
        };
    }

    /*
    shouldComponentUpdate = (nextProps, nextState) => {
        return !_.isEqual(this.state.selected, nextState.selected)
            || !_.isEqual(this.props.customersObj, nextProps.customersObj)
            || !_.isEqual(this.props.customersFilters, nextProps.customersFilters)

    };
    */

    componentDidMount(){
        if(!this.props._server.item.id) {
            this.handleLoadServer();
        }
    }

    handleLoadServer = () => {
        const [ empty, path, edit, server_id ] = this.props.history.location.pathname.split('/');
        this.props.startSetServer(server_id);
    };

    onSubmit = (data) => {
        const server_id = this.props._server.item.id;
        if(server_id) {
            this.props.startUpdateServer(server_id, data)
                .then(() => {
                    const { error, errorEdit } = this.props._server;
                    if(!error && !errorEdit){
                        this.props.history.push(`/servers/${server_id}`);
                    }else{
                        this.setState({ saveErr: error ? error.message : errorEdit.message });
                    }
                });
        }
    };

    cancelAction = () => {
        this.setState({ saveErr: '' });
    };

    handleCancel = () => {
        const [ empty, path, edit, server_id ] = this.props.history.location.pathname.split('/');
        this.props.history.push(`/servers/${server_id}`);
    };

    render() {
        const { saveErr } = this.state;
        const { classes, _server } = this.props;
        const { item, loading, error, errorEdit } = _server;
        const isLoadingError = !loading && error;
        const isLoadingSuccess = !error && !loading;

        return <Card className={classes.card}>
            <CardHeader
                title="Edit server"
                classes={{'title': classes.cardHeaderTitle}}
            />
            <CardContent className={classes.cardContent}>
                {loading && <Loader size="small" />}
                {isLoadingError && <ErrorMsg
                    size="small"
                    msg={`Loading data error has occurred: ${error.message}`}
                    action={this.handleLoadServer}
                    actionTitle="Retry loading data"
                    actionIcon={<ReplayIcon/>}
                />}
                {saveErr && <MassActionNotification
                    cancelAction={this.cancelAction}
                    noIcon={false}
                    topOriented={true}
                    message={`Error has occurred while updating server: ${errorEdit ? errorEdit.message : ""}`}
                    error={true}
                />}
                {isLoadingSuccess && item.id && <EditServerForm
                    onSubmit={this.onSubmit}
                    handleCancel={this.handleCancel}
                />}
            </CardContent>
        </Card>;
    }
}

const mapStateToProps = (state) => ({
    _server: state.server,
});

const mapDispatchToProps = (dispatch) => ({
    startSetServer: (id) => dispatch(startSetServer(id)),
    startUpdateServer: (id, data) => dispatch(startUpdateServer(id, data)),
});

const options = {
    /*
    areStatesEqual: (next, prev) => {
        return _.isEqual(prev.customersFilters, next.customersFilters)
            && _.isEqual(prev.customers, next.customers);
    }
    */
};

export default compose(
    withStyles(style),
    connect(mapStateToProps, mapDispatchToProps, null, options),
)(EditServerPage);

