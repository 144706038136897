const initialState = {
    data: [],
    loading: true,
    error: null
};

const devicesSoftwareReducer = (state = initialState, action) => {
    switch (action.type){
        case 'SET_DEVICES_SOFTWARE_LOADING':
            return {
                ...state,
                loading: true
            };
        case 'SET_DEVICES_SOFTWARE_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload.devicesSoftware
                    // sort versions
                    .map((deviceSoftware) => {
                       deviceSoftware.versions.sort((a, b) => ('' + a.title).localeCompare(b.title));
                       return deviceSoftware;
                    })
                    // sort devices
                    .sort((a, b) =>  ('' + a.title).localeCompare(b.title)),
                error: null
            };
        case 'SET_DEVICES_SOFTWARE_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
};

export default devicesSoftwareReducer;
