import React from "react";
// Material UI components
import CircularProgress from '@material-ui/core/CircularProgress';
// styles
import withStyles from "@material-ui/core/styles/withStyles";
import style from '../../styles/jss/components/general/loaderStyle';

const Loader = (props) => {
    const { classes, size, forTable, ...rest } = props;
    let _size = size ? size : 'main';

    let loader = <div className={`${classes.loaderWrapper} ${classes[_size]}`}>
        <CircularProgress size={50} />
    </div>;
    if(forTable) loader = <div className={classes.forTable}>{loader}</div>;

    return loader;
};

export default withStyles(style)(Loader);
