import {
} from "../../material-base";

const deviceVersionsSelectionsStyle = theme => ({
    wrapper: {
        margin: `0 0 ${theme.spacing.unit * 2}px ${theme.spacing.unit}px`,
        width: '100%',
        color: theme.palette.common.white,
        '& h3': {
            marginTop: '2px',
            marginBottom: '6px',
            fontSize: '16px',
            color: theme.palette.common.white,
        }
    },
    selectContainer: {
        margin: `${theme.spacing.unit * 3}px 0`,
        width: '100%',
        display: 'flex',
        '&> *': {
            marginLeft: theme.spacing.unit*2,
            '&:first-child': {
                margin: 0
            }
        },
        '& label, label.active': {
            color: 'white!important',
        }
    },
    errorMsg: {
        color: theme.palette.error.light
    }
});

export default deviceVersionsSelectionsStyle;
