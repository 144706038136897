export const setServersFilters = ({
    serverType, order, sortBy, search, page, rows
}) => {
    return {
        type: 'SET_SERVERS_FILTERS',
        serverType,
        order,
        sortBy,
        search,
        page,
        rows
    };
};

