const initialState = {
    item: {},
    loading: true,
    errorSet: null
};

const userReducer = (state = initialState, action) => {
    switch (action.type){
        case 'SET_USER_LOADING':
            return {
                ...state,
                loading: true
            };
        case 'SET_USER_SUCCESS':
            return {
                ...state,
                loading: false,
                item: action.payload.user,
                errorSet: null
            };
        case 'SET_USER_FAILURE':
            return {
                ...state,
                loading: false,
                errorSet: action.payload.error
            };
        default:
            return state;
    }
};

export default userReducer;