import React from 'react';
// Material components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
// own components
import SimpleModal from '../general/SimpleModal';
import ActionButtonComp from '../general/ActionButtonComp';
// styles
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/general/tableStyle';

const NodeDetailsModal = ({ classes, isOpenedModal, handleClose }) => {

    const handleExternalLink = (value) => (evt) => {
        window.open(value, '_blank');
    };

    const data = isOpenedModal;
    let content;
    if(data && data.info && data.info.length > 1){
        content = <Table className={`${classes.mobileTable} modal`}>
            <TableBody>
                <TableRow>
                    <TableCell>Address</TableCell>
                    <TableCell>{data.address}</TableCell>
                </TableRow>
                {data.info.map((el) => {
                    const [ label, value ] = el;
                    return <TableRow key={label} >
                        <TableCell>{label}</TableCell>
                        <TableCell>{value}</TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>;
    }

    const btnLink = (key, value) => {
        return <ActionButtonComp
            active={false}
            action={handleExternalLink(key)}
            title={value}
            size="small"
        />
    };

    const action = <div>
        {data.log_browser_url ? btnLink(data.log_browser_url, 'Log Browser') : ''}
        <span>&nbsp;&nbsp;</span>
        {data.milon_care_url ? btnLink(data.milon_care_url, 'milon CARE') : ''}
    </div>;

    return <SimpleModal
        title="Device Details"
        isOpenedModal={!!isOpenedModal}
        handleClose={handleClose}
        content={content}
        action={action}
    />;
};

export default withStyles(style)(NodeDetailsModal);