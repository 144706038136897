import {
} from "../material-base";

const style = theme => ({
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headerWrapper: {
        position: 'fixed',
        top: '4vw',
        display: 'flex',
        alignItems: 'center',
        fontSize: '5vw',
        color: theme.palette.common.white,
        [theme.breakpoints.up('lg')]: {
            fontSize: '4em',
        }
    },
    logo: {
        width: '13vw',
        marginRight: '2vw',
        [theme.breakpoints.up('lg')]: {
            width: 200,
        }
    },
    card: {
        width: '400px',
        maxWidth: '96vw'
    },
    cardHeader: {
        textAlign: 'center',
        '& *': {
            fontWeight: 300
        }
    },
    errorMsg: {
        marginBottom: theme.spacing.unit*2
    }
});

export default style;