import React  from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
// actions
import {
    AUTH_PATHNAME,
    DEFAULT_SIGNED_IN_PATHNAME,
    SIGN_IN_ERROR_PATHNAME
}  from './actions/libs/app-globals';
import configureStore from './store/configureStore';
import { startLogin } from './actions/auth/auth';
// layout
import PrimaryLayout from './layouts/PrimaryLayout';
import SignedOutLayout from './layouts/SingedOutLayout';
// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './styles/jss/material-theme';
import './styles/sass/styles.sass';
import 'typeface-roboto/index.css';

const store = configureStore();
const history = createHistory();

const renderApp = (authToken) => {
    const jsx = (
        <Provider store={store}>
            <MuiThemeProvider theme={theme}>
                <Router history={history}>
                    {authToken ? <PrimaryLayout /> : <SignedOutLayout/>}
                </Router>
            </MuiThemeProvider>
        </Provider>
    );
    ReactDOM.render(jsx, document.getElementById('app'));
};
store.dispatch(startLogin())
    .then((response) => {
        const { authToken, redirect } = response;
        if((!redirect && history.location.pathname === AUTH_PATHNAME) || (redirect)){
            history.push(DEFAULT_SIGNED_IN_PATHNAME);
        }
        if(authToken) renderApp(authToken);
    })
    .catch((err) => {
        history.push(SIGN_IN_ERROR_PATHNAME);
        renderApp();
    });