const initialState = {
    data: [],
    pages: 1,
    loading: true,
    error: null
};

const logsReducer = (state = initialState, action) => {
    switch (action.type){
        case 'SET_LOGS_LOADING':
            return {
                ...state,
                loading: true
            };
        case 'SET_LOGS_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload.logs,
                pages: action.payload.pages,
                error: null
            };
        case 'SET_LOGS_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
};

export default logsReducer;