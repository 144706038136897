/**
 * @fileoverview gRPC-Web generated client stub for server
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: server.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.server = require('./server_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.server.ServerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.server.ServerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.RegisterReq,
 *   !proto.server.RegisterResp>}
 */
const methodDescriptor_Server_Register = new grpc.web.MethodDescriptor(
  '/server.Server/Register',
  grpc.web.MethodType.UNARY,
  proto.server.RegisterReq,
  proto.server.RegisterResp,
  /**
   * @param {!proto.server.RegisterReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.RegisterResp.deserializeBinary
);


/**
 * @param {!proto.server.RegisterReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.server.RegisterResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.RegisterResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.register =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.Server/Register',
      request,
      metadata || {},
      methodDescriptor_Server_Register,
      callback);
};


/**
 * @param {!proto.server.RegisterReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.RegisterResp>}
 *     Promise that resolves to the response
 */
proto.server.ServerPromiseClient.prototype.register =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.Server/Register',
      request,
      metadata || {},
      methodDescriptor_Server_Register);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.LoginReq,
 *   !proto.server.LoginResp>}
 */
const methodDescriptor_Server_Login = new grpc.web.MethodDescriptor(
  '/server.Server/Login',
  grpc.web.MethodType.UNARY,
  proto.server.LoginReq,
  proto.server.LoginResp,
  /**
   * @param {!proto.server.LoginReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.LoginResp.deserializeBinary
);


/**
 * @param {!proto.server.LoginReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.server.LoginResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.LoginResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.Server/Login',
      request,
      metadata || {},
      methodDescriptor_Server_Login,
      callback);
};


/**
 * @param {!proto.server.LoginReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.LoginResp>}
 *     Promise that resolves to the response
 */
proto.server.ServerPromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.Server/Login',
      request,
      metadata || {},
      methodDescriptor_Server_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.VpnLoginReq,
 *   !proto.server.VpnLoginResp>}
 */
const methodDescriptor_Server_VpnLogin = new grpc.web.MethodDescriptor(
  '/server.Server/VpnLogin',
  grpc.web.MethodType.UNARY,
  proto.server.VpnLoginReq,
  proto.server.VpnLoginResp,
  /**
   * @param {!proto.server.VpnLoginReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.VpnLoginResp.deserializeBinary
);


/**
 * @param {!proto.server.VpnLoginReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.server.VpnLoginResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.VpnLoginResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.vpnLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.Server/VpnLogin',
      request,
      metadata || {},
      methodDescriptor_Server_VpnLogin,
      callback);
};


/**
 * @param {!proto.server.VpnLoginReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.VpnLoginResp>}
 *     Promise that resolves to the response
 */
proto.server.ServerPromiseClient.prototype.vpnLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.Server/VpnLogin',
      request,
      metadata || {},
      methodDescriptor_Server_VpnLogin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.GetMqttSettingsReq,
 *   !proto.server.GetMqttSettingsResp>}
 */
const methodDescriptor_Server_GetMqttSettings = new grpc.web.MethodDescriptor(
  '/server.Server/GetMqttSettings',
  grpc.web.MethodType.UNARY,
  proto.server.GetMqttSettingsReq,
  proto.server.GetMqttSettingsResp,
  /**
   * @param {!proto.server.GetMqttSettingsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.GetMqttSettingsResp.deserializeBinary
);


/**
 * @param {!proto.server.GetMqttSettingsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.server.GetMqttSettingsResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.GetMqttSettingsResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.getMqttSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.Server/GetMqttSettings',
      request,
      metadata || {},
      methodDescriptor_Server_GetMqttSettings,
      callback);
};


/**
 * @param {!proto.server.GetMqttSettingsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.GetMqttSettingsResp>}
 *     Promise that resolves to the response
 */
proto.server.ServerPromiseClient.prototype.getMqttSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.Server/GetMqttSettings',
      request,
      metadata || {},
      methodDescriptor_Server_GetMqttSettings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.GetUpdateVersionReq,
 *   !proto.server.GetUpdateVersionResp>}
 */
const methodDescriptor_Server_GetUpdateVersion = new grpc.web.MethodDescriptor(
  '/server.Server/GetUpdateVersion',
  grpc.web.MethodType.UNARY,
  proto.server.GetUpdateVersionReq,
  proto.server.GetUpdateVersionResp,
  /**
   * @param {!proto.server.GetUpdateVersionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.GetUpdateVersionResp.deserializeBinary
);


/**
 * @param {!proto.server.GetUpdateVersionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.server.GetUpdateVersionResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.GetUpdateVersionResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.getUpdateVersion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.Server/GetUpdateVersion',
      request,
      metadata || {},
      methodDescriptor_Server_GetUpdateVersion,
      callback);
};


/**
 * @param {!proto.server.GetUpdateVersionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.GetUpdateVersionResp>}
 *     Promise that resolves to the response
 */
proto.server.ServerPromiseClient.prototype.getUpdateVersion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.Server/GetUpdateVersion',
      request,
      metadata || {},
      methodDescriptor_Server_GetUpdateVersion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.GetTimeReq,
 *   !proto.server.GetTimeResp>}
 */
const methodDescriptor_Server_GetTime = new grpc.web.MethodDescriptor(
  '/server.Server/GetTime',
  grpc.web.MethodType.UNARY,
  proto.server.GetTimeReq,
  proto.server.GetTimeResp,
  /**
   * @param {!proto.server.GetTimeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.GetTimeResp.deserializeBinary
);


/**
 * @param {!proto.server.GetTimeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.server.GetTimeResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.GetTimeResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.getTime =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.Server/GetTime',
      request,
      metadata || {},
      methodDescriptor_Server_GetTime,
      callback);
};


/**
 * @param {!proto.server.GetTimeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.GetTimeResp>}
 *     Promise that resolves to the response
 */
proto.server.ServerPromiseClient.prototype.getTime =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.Server/GetTime',
      request,
      metadata || {},
      methodDescriptor_Server_GetTime);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.ListServersReq,
 *   !proto.server.ListServersResp>}
 */
const methodDescriptor_Server_List = new grpc.web.MethodDescriptor(
  '/server.Server/List',
  grpc.web.MethodType.UNARY,
  proto.server.ListServersReq,
  proto.server.ListServersResp,
  /**
   * @param {!proto.server.ListServersReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.ListServersResp.deserializeBinary
);


/**
 * @param {!proto.server.ListServersReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.server.ListServersResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.ListServersResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.Server/List',
      request,
      metadata || {},
      methodDescriptor_Server_List,
      callback);
};


/**
 * @param {!proto.server.ListServersReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.ListServersResp>}
 *     Promise that resolves to the response
 */
proto.server.ServerPromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.Server/List',
      request,
      metadata || {},
      methodDescriptor_Server_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.GetServerReq,
 *   !proto.server.GetServerResp>}
 */
const methodDescriptor_Server_Get = new grpc.web.MethodDescriptor(
  '/server.Server/Get',
  grpc.web.MethodType.UNARY,
  proto.server.GetServerReq,
  proto.server.GetServerResp,
  /**
   * @param {!proto.server.GetServerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.GetServerResp.deserializeBinary
);


/**
 * @param {!proto.server.GetServerReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.server.GetServerResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.GetServerResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.Server/Get',
      request,
      metadata || {},
      methodDescriptor_Server_Get,
      callback);
};


/**
 * @param {!proto.server.GetServerReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.GetServerResp>}
 *     Promise that resolves to the response
 */
proto.server.ServerPromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.Server/Get',
      request,
      metadata || {},
      methodDescriptor_Server_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.UpdateServerReq,
 *   !proto.server.UpdateServerResp>}
 */
const methodDescriptor_Server_Update = new grpc.web.MethodDescriptor(
  '/server.Server/Update',
  grpc.web.MethodType.UNARY,
  proto.server.UpdateServerReq,
  proto.server.UpdateServerResp,
  /**
   * @param {!proto.server.UpdateServerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UpdateServerResp.deserializeBinary
);


/**
 * @param {!proto.server.UpdateServerReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.server.UpdateServerResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.UpdateServerResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.Server/Update',
      request,
      metadata || {},
      methodDescriptor_Server_Update,
      callback);
};


/**
 * @param {!proto.server.UpdateServerReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.UpdateServerResp>}
 *     Promise that resolves to the response
 */
proto.server.ServerPromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.Server/Update',
      request,
      metadata || {},
      methodDescriptor_Server_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.AssignToCustomerServerReq,
 *   !proto.server.AssignToCustomerServerResp>}
 */
const methodDescriptor_Server_AssignToCustomer = new grpc.web.MethodDescriptor(
  '/server.Server/AssignToCustomer',
  grpc.web.MethodType.UNARY,
  proto.server.AssignToCustomerServerReq,
  proto.server.AssignToCustomerServerResp,
  /**
   * @param {!proto.server.AssignToCustomerServerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.AssignToCustomerServerResp.deserializeBinary
);


/**
 * @param {!proto.server.AssignToCustomerServerReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.server.AssignToCustomerServerResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.AssignToCustomerServerResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.assignToCustomer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.Server/AssignToCustomer',
      request,
      metadata || {},
      methodDescriptor_Server_AssignToCustomer,
      callback);
};


/**
 * @param {!proto.server.AssignToCustomerServerReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.AssignToCustomerServerResp>}
 *     Promise that resolves to the response
 */
proto.server.ServerPromiseClient.prototype.assignToCustomer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.Server/AssignToCustomer',
      request,
      metadata || {},
      methodDescriptor_Server_AssignToCustomer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.DeleteServerReq,
 *   !proto.server.DeleteServerResp>}
 */
const methodDescriptor_Server_Delete = new grpc.web.MethodDescriptor(
  '/server.Server/Delete',
  grpc.web.MethodType.UNARY,
  proto.server.DeleteServerReq,
  proto.server.DeleteServerResp,
  /**
   * @param {!proto.server.DeleteServerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.DeleteServerResp.deserializeBinary
);


/**
 * @param {!proto.server.DeleteServerReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.server.DeleteServerResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.DeleteServerResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.Server/Delete',
      request,
      metadata || {},
      methodDescriptor_Server_Delete,
      callback);
};


/**
 * @param {!proto.server.DeleteServerReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.DeleteServerResp>}
 *     Promise that resolves to the response
 */
proto.server.ServerPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.Server/Delete',
      request,
      metadata || {},
      methodDescriptor_Server_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.UndeleteServerReq,
 *   !proto.server.UndeleteServerResp>}
 */
const methodDescriptor_Server_Undelete = new grpc.web.MethodDescriptor(
  '/server.Server/Undelete',
  grpc.web.MethodType.UNARY,
  proto.server.UndeleteServerReq,
  proto.server.UndeleteServerResp,
  /**
   * @param {!proto.server.UndeleteServerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.UndeleteServerResp.deserializeBinary
);


/**
 * @param {!proto.server.UndeleteServerReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.server.UndeleteServerResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.UndeleteServerResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.undelete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.Server/Undelete',
      request,
      metadata || {},
      methodDescriptor_Server_Undelete,
      callback);
};


/**
 * @param {!proto.server.UndeleteServerReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.UndeleteServerResp>}
 *     Promise that resolves to the response
 */
proto.server.ServerPromiseClient.prototype.undelete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.Server/Undelete',
      request,
      metadata || {},
      methodDescriptor_Server_Undelete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.VpnStartServerReq,
 *   !proto.server.VpnStartServerResp>}
 */
const methodDescriptor_Server_VpnStart = new grpc.web.MethodDescriptor(
  '/server.Server/VpnStart',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.server.VpnStartServerReq,
  proto.server.VpnStartServerResp,
  /**
   * @param {!proto.server.VpnStartServerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.VpnStartServerResp.deserializeBinary
);


/**
 * @param {!proto.server.VpnStartServerReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.server.VpnStartServerResp>}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.vpnStart =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/server.Server/VpnStart',
      request,
      metadata || {},
      methodDescriptor_Server_VpnStart);
};


/**
 * @param {!proto.server.VpnStartServerReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.server.VpnStartServerResp>}
 *     The XHR Node Readable Stream
 */
proto.server.ServerPromiseClient.prototype.vpnStart =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/server.Server/VpnStart',
      request,
      metadata || {},
      methodDescriptor_Server_VpnStart);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.VpnStopServerReq,
 *   !proto.server.VpnStopServerResp>}
 */
const methodDescriptor_Server_VpnStop = new grpc.web.MethodDescriptor(
  '/server.Server/VpnStop',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.server.VpnStopServerReq,
  proto.server.VpnStopServerResp,
  /**
   * @param {!proto.server.VpnStopServerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.VpnStopServerResp.deserializeBinary
);


/**
 * @param {!proto.server.VpnStopServerReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.server.VpnStopServerResp>}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.vpnStop =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/server.Server/VpnStop',
      request,
      metadata || {},
      methodDescriptor_Server_VpnStop);
};


/**
 * @param {!proto.server.VpnStopServerReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.server.VpnStopServerResp>}
 *     The XHR Node Readable Stream
 */
proto.server.ServerPromiseClient.prototype.vpnStop =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/server.Server/VpnStop',
      request,
      metadata || {},
      methodDescriptor_Server_VpnStop);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.BrowserRestartServerReq,
 *   !proto.server.BrowserRestartServerResp>}
 */
const methodDescriptor_Server_BrowserRestart = new grpc.web.MethodDescriptor(
  '/server.Server/BrowserRestart',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.server.BrowserRestartServerReq,
  proto.server.BrowserRestartServerResp,
  /**
   * @param {!proto.server.BrowserRestartServerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.BrowserRestartServerResp.deserializeBinary
);


/**
 * @param {!proto.server.BrowserRestartServerReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.server.BrowserRestartServerResp>}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.browserRestart =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/server.Server/BrowserRestart',
      request,
      metadata || {},
      methodDescriptor_Server_BrowserRestart);
};


/**
 * @param {!proto.server.BrowserRestartServerReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.server.BrowserRestartServerResp>}
 *     The XHR Node Readable Stream
 */
proto.server.ServerPromiseClient.prototype.browserRestart =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/server.Server/BrowserRestart',
      request,
      metadata || {},
      methodDescriptor_Server_BrowserRestart);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.ServerRestartServerReq,
 *   !proto.server.ServerRestartServerResp>}
 */
const methodDescriptor_Server_ServerRestart = new grpc.web.MethodDescriptor(
  '/server.Server/ServerRestart',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.server.ServerRestartServerReq,
  proto.server.ServerRestartServerResp,
  /**
   * @param {!proto.server.ServerRestartServerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.ServerRestartServerResp.deserializeBinary
);


/**
 * @param {!proto.server.ServerRestartServerReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.server.ServerRestartServerResp>}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.serverRestart =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/server.Server/ServerRestart',
      request,
      metadata || {},
      methodDescriptor_Server_ServerRestart);
};


/**
 * @param {!proto.server.ServerRestartServerReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.server.ServerRestartServerResp>}
 *     The XHR Node Readable Stream
 */
proto.server.ServerPromiseClient.prototype.serverRestart =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/server.Server/ServerRestart',
      request,
      metadata || {},
      methodDescriptor_Server_ServerRestart);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.SoftwareUpdateVersionsServerReq,
 *   !proto.server.SoftwareUpdateVersionsServerResp>}
 */
const methodDescriptor_Server_SoftwareUpdateVersions = new grpc.web.MethodDescriptor(
  '/server.Server/SoftwareUpdateVersions',
  grpc.web.MethodType.UNARY,
  proto.server.SoftwareUpdateVersionsServerReq,
  proto.server.SoftwareUpdateVersionsServerResp,
  /**
   * @param {!proto.server.SoftwareUpdateVersionsServerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.SoftwareUpdateVersionsServerResp.deserializeBinary
);


/**
 * @param {!proto.server.SoftwareUpdateVersionsServerReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.server.SoftwareUpdateVersionsServerResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.SoftwareUpdateVersionsServerResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.softwareUpdateVersions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.Server/SoftwareUpdateVersions',
      request,
      metadata || {},
      methodDescriptor_Server_SoftwareUpdateVersions,
      callback);
};


/**
 * @param {!proto.server.SoftwareUpdateVersionsServerReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.SoftwareUpdateVersionsServerResp>}
 *     Promise that resolves to the response
 */
proto.server.ServerPromiseClient.prototype.softwareUpdateVersions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.Server/SoftwareUpdateVersions',
      request,
      metadata || {},
      methodDescriptor_Server_SoftwareUpdateVersions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.SoftwareUpdateServerReq,
 *   !proto.server.SoftwareUpdateServerResp>}
 */
const methodDescriptor_Server_SoftwareUpdate = new grpc.web.MethodDescriptor(
  '/server.Server/SoftwareUpdate',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.server.SoftwareUpdateServerReq,
  proto.server.SoftwareUpdateServerResp,
  /**
   * @param {!proto.server.SoftwareUpdateServerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.SoftwareUpdateServerResp.deserializeBinary
);


/**
 * @param {!proto.server.SoftwareUpdateServerReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.server.SoftwareUpdateServerResp>}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.softwareUpdate =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/server.Server/SoftwareUpdate',
      request,
      metadata || {},
      methodDescriptor_Server_SoftwareUpdate);
};


/**
 * @param {!proto.server.SoftwareUpdateServerReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.server.SoftwareUpdateServerResp>}
 *     The XHR Node Readable Stream
 */
proto.server.ServerPromiseClient.prototype.softwareUpdate =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/server.Server/SoftwareUpdate',
      request,
      metadata || {},
      methodDescriptor_Server_SoftwareUpdate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.ScreenshotsServerReq,
 *   !proto.server.ScreenshotsServerResp>}
 */
const methodDescriptor_Server_Screenshots = new grpc.web.MethodDescriptor(
  '/server.Server/Screenshots',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.server.ScreenshotsServerReq,
  proto.server.ScreenshotsServerResp,
  /**
   * @param {!proto.server.ScreenshotsServerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.ScreenshotsServerResp.deserializeBinary
);


/**
 * @param {!proto.server.ScreenshotsServerReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.server.ScreenshotsServerResp>}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.screenshots =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/server.Server/Screenshots',
      request,
      metadata || {},
      methodDescriptor_Server_Screenshots);
};


/**
 * @param {!proto.server.ScreenshotsServerReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.server.ScreenshotsServerResp>}
 *     The XHR Node Readable Stream
 */
proto.server.ServerPromiseClient.prototype.screenshots =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/server.Server/Screenshots',
      request,
      metadata || {},
      methodDescriptor_Server_Screenshots);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.ScreenshotsResultServerReq,
 *   !proto.server.ScreenshotsResultServerResp>}
 */
const methodDescriptor_Server_ScreenshotsResult = new grpc.web.MethodDescriptor(
  '/server.Server/ScreenshotsResult',
  grpc.web.MethodType.UNARY,
  proto.server.ScreenshotsResultServerReq,
  proto.server.ScreenshotsResultServerResp,
  /**
   * @param {!proto.server.ScreenshotsResultServerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.ScreenshotsResultServerResp.deserializeBinary
);


/**
 * @param {!proto.server.ScreenshotsResultServerReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.server.ScreenshotsResultServerResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.ScreenshotsResultServerResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.screenshotsResult =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.Server/ScreenshotsResult',
      request,
      metadata || {},
      methodDescriptor_Server_ScreenshotsResult,
      callback);
};


/**
 * @param {!proto.server.ScreenshotsResultServerReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.ScreenshotsResultServerResp>}
 *     Promise that resolves to the response
 */
proto.server.ServerPromiseClient.prototype.screenshotsResult =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.Server/ScreenshotsResult',
      request,
      metadata || {},
      methodDescriptor_Server_ScreenshotsResult);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.NetScanServerReq,
 *   !proto.server.NetScanServerResp>}
 */
const methodDescriptor_Server_NetScan = new grpc.web.MethodDescriptor(
  '/server.Server/NetScan',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.server.NetScanServerReq,
  proto.server.NetScanServerResp,
  /**
   * @param {!proto.server.NetScanServerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.NetScanServerResp.deserializeBinary
);


/**
 * @param {!proto.server.NetScanServerReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.server.NetScanServerResp>}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.netScan =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/server.Server/NetScan',
      request,
      metadata || {},
      methodDescriptor_Server_NetScan);
};


/**
 * @param {!proto.server.NetScanServerReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.server.NetScanServerResp>}
 *     The XHR Node Readable Stream
 */
proto.server.ServerPromiseClient.prototype.netScan =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/server.Server/NetScan',
      request,
      metadata || {},
      methodDescriptor_Server_NetScan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.NetScanResultServerReq,
 *   !proto.server.NetScanResultServerResp>}
 */
const methodDescriptor_Server_NetScanResult = new grpc.web.MethodDescriptor(
  '/server.Server/NetScanResult',
  grpc.web.MethodType.UNARY,
  proto.server.NetScanResultServerReq,
  proto.server.NetScanResultServerResp,
  /**
   * @param {!proto.server.NetScanResultServerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.NetScanResultServerResp.deserializeBinary
);


/**
 * @param {!proto.server.NetScanResultServerReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.server.NetScanResultServerResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.server.NetScanResultServerResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.netScanResult =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/server.Server/NetScanResult',
      request,
      metadata || {},
      methodDescriptor_Server_NetScanResult,
      callback);
};


/**
 * @param {!proto.server.NetScanResultServerReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.server.NetScanResultServerResp>}
 *     Promise that resolves to the response
 */
proto.server.ServerPromiseClient.prototype.netScanResult =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/server.Server/NetScanResult',
      request,
      metadata || {},
      methodDescriptor_Server_NetScanResult);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.LogFileRequestServerReq,
 *   !proto.server.LogFileRequestServerResp>}
 */
const methodDescriptor_Server_LogFileRequest = new grpc.web.MethodDescriptor(
  '/server.Server/LogFileRequest',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.server.LogFileRequestServerReq,
  proto.server.LogFileRequestServerResp,
  /**
   * @param {!proto.server.LogFileRequestServerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.LogFileRequestServerResp.deserializeBinary
);


/**
 * @param {!proto.server.LogFileRequestServerReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.server.LogFileRequestServerResp>}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.logFileRequest =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/server.Server/LogFileRequest',
      request,
      metadata || {},
      methodDescriptor_Server_LogFileRequest);
};


/**
 * @param {!proto.server.LogFileRequestServerReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.server.LogFileRequestServerResp>}
 *     The XHR Node Readable Stream
 */
proto.server.ServerPromiseClient.prototype.logFileRequest =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/server.Server/LogFileRequest',
      request,
      metadata || {},
      methodDescriptor_Server_LogFileRequest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.server.CommandExecServerReq,
 *   !proto.server.CommandExecServerResp>}
 */
const methodDescriptor_Server_CommandExec = new grpc.web.MethodDescriptor(
  '/server.Server/CommandExec',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.server.CommandExecServerReq,
  proto.server.CommandExecServerResp,
  /**
   * @param {!proto.server.CommandExecServerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.server.CommandExecServerResp.deserializeBinary
);


/**
 * @param {!proto.server.CommandExecServerReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.server.CommandExecServerResp>}
 *     The XHR Node Readable Stream
 */
proto.server.ServerClient.prototype.commandExec =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/server.Server/CommandExec',
      request,
      metadata || {},
      methodDescriptor_Server_CommandExec);
};


/**
 * @param {!proto.server.CommandExecServerReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.server.CommandExecServerResp>}
 *     The XHR Node Readable Stream
 */
proto.server.ServerPromiseClient.prototype.commandExec =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/server.Server/CommandExec',
      request,
      metadata || {},
      methodDescriptor_Server_CommandExec);
};


module.exports = proto.server;

