import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// actions
import { startGetLog } from '../../actions/logs/log';
// Material UI
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CloudIcon from '@material-ui/icons/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';
// own components
import ActionButtonComp from '../general/ActionButtonComp';
import ChipComp from '../general/ChipComp';
import DeviceInfoModal from './DeviceInfoModal';
import LoadingProgressMessage from './LoadingProgressMessage';
import MassActionNotification from '../general/MassActionNotification';
// style
import { withStyles } from '@material-ui/core/styles';
import tableStyle from '../../styles/jss/components/general/tableStyle';

const deviceColumns = [
    { key: 'ip',                label: 'IP address' },
    { key: 'mac',               label: 'MAC address' },
    { key: 'device_id',         label: 'Device ID' },
    { key: 'mac_vendor',        label: 'MAC vendor' },
    { key: 'log_file',          label: 'Log file'},
    { key: 'device_version',    label: 'SW Version'},
    { key: 'device_type_name',  label: 'Device type'},
    { key: 'node_id',           label: 'Node ID'},
    { key: 'node_group_id',     label: 'NG ID'},
    { key: 'node_group_name',   label: 'NG name'},
    { key: 'node_group_type',   label: 'NG type'},
    { key: 'studio_id',         label: 'Studio ID'},
    { key: 'external_links',    label: 'External links'}
];

class DevicesInNetworkTable extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            isOpenedModal: '',
            activeLogId: '',
            logError: ''
        }
    }

    onHandleDetails = (device_info) => (evt) => {
        this.setState({ isOpenedModal: device_info });
    };

    handleCloseModal = () => {
        this.setState({ isOpenedModal: '' });
    };

    handleExternalLink = (link) => (evt) => {
        window.open(link, '_blank');
    };

    handleRequestLogFile = (data) => (evt) => {
        if(!this.props._log.loading){
            const [ empty, path, server_id ] = this.props.history.location.pathname.split('/');

            this.setState({ activeLogId: data.id });
            this.props.startGetLog(server_id, data.id)
                .then((response) => { this.setState({ activeLogId: '' }); })
                .catch((err) => {
                    this.setState({
                        activeLogId: '',
                        logError: err.message
                    });
                });
        }
    };

    handleDownloadLogFile = ({ logfile_url }) => (evt) => {
        if(logfile_url){
            window.location.href = logfile_url;
        }
    };

    cancelAction = () => {
        this.setState({ logError: '' });
    };

    render() {
        const { isOpenedModal, activeLogId, logError } = this.state;
        const { classes, _devices, _log } = this.props;
        const { data } = _devices;
        const { item, loading, stateText } = _log;

        const getValue = (columnId, device) => {
            let value = device[columnId];

            const btn = (action, title, tooltip = "", icon = null) => {
                return <ActionButtonComp
                    size="small"
                    action={action}
                    tooltip={tooltip}
                    title={title}
                    icon={icon}
                    className="tblItemComp"
                />;
            };

            switch(columnId){
                case 'mac':
                    return value = device.device_info.length > 0 ? <ChipComp
                        key={device.mac}
                        value={device.mac}
                        action={this.onHandleDetails(device.device_info)}
                        label={device.mac}
                        clickable={true}
                        className="tblCellItem"
                    /> : device.mac;
                case 'external_links':
                    return value = <div>
                        {device.log_browser_url ? btn(this.handleExternalLink(device.log_browser_url), "Log Browser") : '' }
                        {device.milon_care_url ? btn(this.handleExternalLink(device.milon_care_url), "milon CARE") : ''}
                    </div>;
                case 'log_file':
                    const fileName = device.logfile_url;
                    return value = <div>
                        {btn(this.handleRequestLogFile(device),
                            <div>Request log {activeLogId === device.id ? <CircularProgress size={18} color="inherit"/> : ''}</div>)}
                        {fileName && btn(this.handleDownloadLogFile(device), 'Download',
                            `Download file (created at ${device.logfile_created_at})`, <CloudIcon/>)}
                    </div>;
            }

            return (value || '---');
        };

        return <div>
            <Table className={`${classes.mainTable} cardTable DevicesInNetworkTable`}>
                <TableHead>
                    <TableRow>{deviceColumns.map((el) => {
                        return <TableCell key={el.key}>{el.label}</TableCell>
                    })}</TableRow>
                </TableHead>
                <TableBody>
                    {data.map((device, index) => {
                        return <TableRow key={index}>
                            {deviceColumns.map((el) => {
                                const key = el.key;
                                const value = getValue(key, device);
                                return <TableCell key={key}>{value}</TableCell>;
                            })}
                        </TableRow>
                    })}
                </TableBody>
            </Table>
            <DeviceInfoModal
                isOpenedModal={isOpenedModal}
                handleClose={this.handleCloseModal}
            />
            {((loading && stateText) || logError) && <MassActionNotification
                cancelAction={this.cancelAction}
                noIcon={loading && stateText}
                topOriented={true}
                error={logError}
                message={loading && stateText ?
                    <LoadingProgressMessage msg={`Log file request progress: ${stateText}.`} /> :
                    `Log file request error: ${logError}.`}
            />}
        </div>;
    }
}

const mapStateToProps = (state) => {
    return {
        _devices: state.devices,
        _log: state.log
    };
};

const mapDispatchToProps = (dispatch) => ({
    startGetLog: (server_id, net_scan_id) => dispatch(startGetLog(server_id, net_scan_id)),
});

export default compose(
    withStyles(tableStyle),
    connect(mapStateToProps, mapDispatchToProps),
)(DevicesInNetworkTable);

