import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// Material UI
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import FileCopyIcon from '@material-ui/icons/Copyright';
import IconButton from '@material-ui/core/IconButton';
// own components
import SelectComp from '../general/SelectComp';
import ChipComp from '../general/ChipComp';
// style
import { withStyles } from '@material-ui/core/styles';
import tableStyle from '../../styles/jss/components/general/tableStyle';
import TruncateComp from "../general/TruncateComp";


const serverColumns = [
    { key: 'is_online',         copy: false,    label: 'Server status' },
    { key: 'hostname',          copy: false,    label: 'Hostname' },
    { key: 'tag',               copy: false,    label: 'Tag' },
    { key: 'type',              copy: false,    label: 'Server type' },
    { key: 'os',                copy: false,    label: 'Operating system' },
    { key: 'notes',              copy: false,    label: 'Notes' },
    { key: 'lan_mac',           copy: true,     label: 'LAN MAC' },
    { key: 'lan_ipv4',          copy: true,     label: 'Internal IPv4 address (LAN)' },
    { key: 'lan_ipv6',          copy: true,     label: 'Internal IPv6 address (LAN)' },
    { key: 'wan_ipv4',          copy: true,     label: 'External IPv4 address (WAN)' },
    { key: 'wan_ipv4_ptr',      copy: true,     label: 'External IPv4 reverse DNS' },
    { key: 'wan_ipv6',          copy: true,     label: 'External IPv6 address (WAN)' },
    { key: 'wan_ipv6_ptr',      copy: true,     label: 'External IPv6 reverse DNS' },
    { key: 'vpn_status',        copy: false,    label: 'VPN status' },
    { key: 'vpn_ipv4',          copy: true,     label: 'VPN IPv4 address' },
    { key: 'vpn_ipv6',          copy: true,     label: 'VPN IPv6 address' },
    { key: 'hw_vendor',         copy: false,    label: 'System manufacturer' },
    { key: 'hw_product',        copy: false,    label: 'System product name' },
    { key: 'hw_serial',         copy: false,    label: 'Hardware serial' },
    { key: 'created_at',        copy: false,    label: 'Created' },
    { key: 'updated_at',        copy: false,    label: 'Updated' },
    { key: 'up_since',          copy: false,    label: 'Up since' },
    { key: 'connected_since',   copy: false,    label: 'Connected since' },
    { key: 'last_seen_at',      copy: false,    label: 'Last seen' },
    { key: 'deleted_at',        copy: false,    label: 'Deleted' },
    { key: 'load',              copy: false,    label: 'Workload' },
    { key: 'root_usage',        copy: false,    label: 'Root-disk usage' },
    { key: 'boot_usage',        copy: false,    label: 'Boot-disk usage' },
    { key: 'memory_usage',      copy: false,    label: 'Memory usage' },
    { key: 'swap_usage',        copy: false,    label: 'Swap usage' },
    { key: 'sensors',           copy: false,    label: 'Sensors' },
    { key: 'ups_status',        copy: false,    label: 'UPS Status' },
    { key: 'ups_model',         copy: false,    label: 'UPS Model' },
    { key: 'ups_battery',       copy: false,    label: 'UPS Battery' },
    { key: 'ups_outages',       copy: false,    label: 'UPS Outages' },
    { key: 'time_delta',        copy: false,    label: 'System clock delta' },
    { key: 'agent_version',     copy: false,    label: 'Agent version' },
    // TODO
 //   { key: 'swVersion',         copy: false,    label: 'Client software version' },
 //   { key: 'sensorInfo',        copy: false,    label: 'Sensor information' },
 //   { key: 'usvInfo',           copy: false,    label: 'USV information' },
 //   { key: 'selfTestInfo',      copy: false,    label: 'Self test information' }
];


const ServerDataTable = (props) => {

    const onHandleCopyText = (evt) => {
        const str = evt.currentTarget.value;
        // create new element
        let el = document.createElement('textarea');
        // set text to element
        el.value = str;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
    };

    const { classes, _server, actionFnkOptions, serverAction, handleServerAction, defaultServerAction } = props;
    const { item } = _server;

    const getValue = (columnId, value) => {

        const getUsageValues = (total, used, dataSize) => {
            let value;
            const free = used && total ? ` (${total - used} ${dataSize} free)` : '';
            if(used || total) value = `${used || '---'} ${dataSize} of ${total || '---'} ${dataSize} ${free}`;
            return value;
        };

        switch(columnId){
            case 'is_online':
                const status = value === true ? 'online' : 'offline';
                return value = <span className={status}>{status.toUpperCase()}</span>;
            case 'os':
                return value = <div>
                    <div>OS: {value || '---'}</div>
                    <div>Kernel: {item.kernel || '---'}</div>
                </div>;
            case 'load':
                const renderLoad = (value, title) => {
                    if(!value) return '';
                    return <ChipComp
                        value={value}
                        label={value}
                        title={title}
                        className="tblCellItem"
                    />
                };
                return value = <div>
                    {!item.load1 && !item.load5 && !item.load15 ? '---' : ''}
                    {renderLoad(item.load1, 'Load1 (1 minute)')}
                    {renderLoad(item.load5, 'Load5 (5 minutes)')}
                    {renderLoad(item.load15, 'Load15 (15 minutes)')}
                </div>;
            case 'root_usage':
                return value = getUsageValues(item.disk_root_total, item.disk_root_used, 'GB');
            case 'boot_usage':
                return value = getUsageValues(item.disk_boot_total, item.disk_boot_used, 'MB');
            case 'memory_usage':
                return value = getUsageValues(item.memory_total, item.memory_used, 'MB');
            case 'swap_usage':
                return value = getUsageValues(item.swap_total, item.swap_used, 'MB');
            case 'ups_status':
                if (!item.ups_status) {
                    return value = '';
                }
                value = item.ups_status
                if (item.ups_internal_temp) {
                    value += ` (${item.ups_internal_temp} °C)`;
                }
                return value
            case 'ups_model':
                if (!item.ups_model || !item.ups_serial_number) {
                    return value = '';
                }
                return value = `${item.ups_model} (SN ${item.ups_serial_number})`;
            case 'ups_battery':
                if (item.ups_time_left == "00:00:00" && !item.ups_battery_charge_percent && !item.ups_battery_date) {
                    return value = '';
                }
                return value = `${item.ups_time_left} (${item.ups_battery_charge_percent}%)
                 left; from ${item.ups_battery_date}`;
            case 'ups_outages':
                if (!item.ups_xoff_battery && !item.ups_xon_battery) {
                    return value = "";
                }

                return value = <div>
                    last power-loss: {item.ups_xon_battery ? item.ups_xon_battery : '-'}<br />
                    last power-restore: {item.ups_xoff_battery ? item.ups_xoff_battery : '-'}<br />
                    number of outages: {item.ups_number_transfers}
                </div>;

            case 'sensors':
                if (!item.sensors.length) {
                    return value = "";
                }

                return value = <table className={classes.sensorTable}>
                    <thead>
                        <tr>
                            <td>Sensor</td>
                            <td className="value">Value</td>
                        </tr>
                    </thead>
                    <tbody>
                        {item.sensors.map((sensor) => {
                            const name = [sensor.getDevice(), sensor.getGroup(), sensor.getName(), sensor.getLabel()].
                                filter((name) => !!name).
                                join("::");

                            let unit = "";
                            switch (sensor.getType()) {
                                case "temp":
                                    unit = " °C";
                                    break
                                case "fan":
                                    unit = " rpm";
                                    break;
                            }

                            return <tr key={`${sensor.getDevice()}-${sensor.getName()}`}>
                                <td>{name}</td>
                                <td className="value">{sensor.getValue()}{unit}</td>
                            </tr>;
                        })}
                    </tbody>
                </table>;

        }

        // return (value || '---');
        return value;
    };

    return <Table className={classes.mobileTable}>
        <TableBody>
            <TableRow>
                <TableCell>Server action</TableCell>
                <TableCell>
                    <SelectComp
                        name="serverAction"
                        value={serverAction}
                        options={actionFnkOptions}
                        inactive={serverAction === defaultServerAction}
                        action={handleServerAction}
                        fixedWidth={true}
                    />
                </TableCell>
            </TableRow>
            {serverColumns.map((el) => {
                const key = el.key;
                const value = getValue(key, item[key]);

                // if (item[key] === "") {
                if (value === null || value === "") {
                    return;
                }

                if (key === "notes") {
                    return <React.Fragment key={key}>
                        <TableRow>
                            <TableCell>Notes</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2} className={"notes"}>
                                <TruncateComp lines={10}>
                                    <span>{value}</span>
                                </TruncateComp>
                            </TableCell>
                        </TableRow>
                    </React.Fragment>;
                }

                return <TableRow key={key}>
                    <TableCell>{el.label}</TableCell>
                    <TableCell>
                        {value}
                        {document.queryCommandSupported('copy') && el.copy && value!== '---' && <IconButton
                            color="secondary"
                            className="copyTextBtn"
                            title="copy to clipboard"
                            value={value}
                            onClick={onHandleCopyText}
                        ><FileCopyIcon /></IconButton>}
                    </TableCell>
                </TableRow>
            })}

        </TableBody>
    </Table>;
};

const mapStateToProps = (state) => {
    return {
        _server: state.server
    };
};

export default compose(
    withStyles(tableStyle),
    connect(mapStateToProps, undefined),
)(ServerDataTable);
