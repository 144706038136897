import {
    NetScanResultServerReq,
    NetScanServerReq
} from '../../pb/server_pb';
import { serverPromiseService } from '../libs/request-clients';
import { getMetadata, handleResponseError, getDateTime } from '../libs/request-helpers';

export const setDevicesLoading = () => ({
    type: 'SET_DEVICES_LOADING'
});

export const setDevicesSuccess = (devices) => ({
    type: 'SET_DEVICES_SUCCESS',
    payload: { devices }
});

export const setDevicesError = (error) => ({
    type: 'SET_DEVICES_FAILURE',
    payload: { error }
});

export const startSetDevices = (server_id) => {
    return (dispatch, getState) => {
        server_id = parseInt(server_id, 10);
        dispatch(setDevicesLoading());

        let netScanResultServerReq = new NetScanResultServerReq();
        netScanResultServerReq.setId(server_id);

        return serverPromiseService.netScanResult(netScanResultServerReq, getMetadata())
            .then((response) => {
                const _devices = response.getHostsList();
                let devices = [];
                _devices.forEach((_device) => {
                    const device = {
                        id:                 _device.getId(),
                        ip:                 _device.getIp(),
                        mac:                _device.getMac(),
                        mac_vendor:         _device.getMacVendor(),
                        logfile_url:        _device.getLogfileUrl(),
                        logfile_created_at: getDateTime(_device.getLogfileCreatedAt()),
                        device_id:          _device.getDeviceId(),
                        device_info:        _device.getDeviceInfoMap().getEntryList(),
                        device_version:     _device.getDeviceVersion(),
                        device_type_name:   _device.getDeviceTypeName(),
                        node_id:            _device.getNodeId(),
                        node_group_id:      _device.getNodeGroupId(),
                        node_group_name:    _device.getNodeGroupName(),
                        node_group_type:    _device.getNodeGroupType(),
                        studio_id:          _device.getStudioId(),
                        log_browser_url:    _device.getLogBrowserUrl(),
                        milon_care_url:     _device.getMilonCareUrl()
                    };
                    devices.push(device);
                });
                dispatch(setDevicesSuccess(devices));
            })
            .catch((err) => {
                dispatch(setDevicesError(err));
                handleResponseError(dispatch, err);
            });
    };
};

export const startNetScan = (server_id) => {
    return (dispatch, getState) => {
        server_id = parseInt(server_id, 10);

        let netScanServerReq = new NetScanServerReq();
        netScanServerReq.setIdList([server_id]);
        netScanServerReq.setWaitForResult(true); // true for one server
        let stream = serverPromiseService.netScan(netScanServerReq, getMetadata());

        return new Promise((resolve, reject) => {

            stream.on('error', (error) => {
                error.message = `Net scan error occurred: ${error.message}`;
                reject(error);
            });

            stream.on('data', (response) => {});

            stream.on('status', (status) => {
                if(status.code !== 0) {
                    const message = `Net scan error occurred: ${status.details}`;
                    const err = { code: status.code, message: message };
                    reject(err);
                    return;
                }

                // Success
                resolve({ completed: true });
            });
        });
    };
};




