import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _ from 'lodash';
// actions
import { setServersFilters } from '../../actions/servers/filters';
import {
    startDeleteServers,
    startUndeleteServers,
    startMultipleVpnConnection,
    stopMultipleVpnConnection,
    startMultipleRestartBrowsers,
    startRestartMultipleServers
} from '../../actions/servers/servers';
// Material UI components
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse';
// own components
import DatePickerComp from '../general/DatePickerComp';
import MassActionNotification from '../general/MassActionNotification';
import SearchCustomers from './SearchCustomers';
import DeviceVersionsSelections from './DeviceVersionsSelection';
import AdvSrcBtnContainer from '../general/AdvSrcBtnContainer';
import SearchHeaderComp from '../general/SearchHeaderComp';
import PaginationComp from '../general/PaginationComp';
import InputTextComp from '../general/InputTextComp';
import SelectComp from '../general/SelectComp';
// styles
import { withStyles } from '@material-ui/core/styles';
import toolbarStyle from '../../styles/jss/components/general/toolbarStyle';

const serverTypes = [
    { active:   'Active' },
    { new:      'New' },
    { deleted:  'Deleted' }
];
const nodeGroupOptions = [
    { key: 'all',   value: 'All' },
    { key: 'kaz',   value: 'Strength endurance' },
    { key: 'cz',    value: 'Endurance' },
    { key: 'krz',   value: 'Strength' },
    { key: 'fg',    value: 'Free Devices' },
    { key: 'qf',    value: 'Q Free' }
];
const server_status_options = [
    { key: '0',     value: 'ALL' },
    { key: '1',     value: 'ONLINE' },
    { key: '2',     value: 'OFFLINE' },
];

const vpn_status_options = [
    { key: '0',     value: 'ALL' },
    { key: '1',     value: 'DISABLED' },
    { key: '2',     value: 'DISABLED_PENDING' },
    { key: '3',     value: 'ACTIVE_PENDING' },
    { key: '4',     value: 'ACTIVE' },
];
const searchData = [
    { id: 'id',             label: 'Customer number',   placeholder: 'e.g. 3454',               types: ['active', 'deleted'] },
    { id: 'milon_care_id',  label: 'Milon CARE ID',     placeholder: 'e.g. 54',                 types: ['active', 'deleted'] },
    { id: 'name',           label: 'Customer name',     placeholder: 'e.g. Fit Body',           types: ['active', 'deleted'] },
    { id: 'contact_person', label: 'Contact person',    placeholder: 'e.g. Marten Mustermann',  types: ['active', 'deleted'] },
    { id: 'street',         label: 'Street',            placeholder: 'e.g. Leopoldstraße',      types: ['active', 'deleted'] },
    { id: 'zip',            label: 'Zip',               placeholder: 'e.g. 80803',              types: ['active', 'deleted'] },
    { id: 'city',           label: 'City',              placeholder: 'e.g. München',            types: ['active', 'deleted'] },
    { id: 'country',        label: 'Country',           placeholder: 'e.g. Germany',            types: ['active', 'deleted'] },
//    { id: 'node_group',     label: 'Circle group',      options: nodeGroupOptions,              types: ['active', 'deleted'] },
//    { id: 'device_type',    label: 'Device',            placeholder: 'e.g. Q LAT PULL V2',      types: ['active', 'deleted'] },
//    { id: 'device_version', label: 'Devices versions',  placeholder: 'e.g. 1.0.1',              types: ['active', 'deleted'] },
    { id: 'tag',            label: 'Tag',               placeholder: 'e.g. KAZ, KRZ, FG',       types: ['active', 'deleted'] },
    { id: 'hostname',       label: 'Hostname',          placeholder: '5000xxxx',                types: ['active', 'new', 'deleted'] },
    { id: 'notes',          label: 'Notes',             placeholder: 'my notes',                types: ['active', 'deleted'] },
    { id: 'os',             label: 'OS',                placeholder: 'ubuntu 20.04',            types: ['active', 'new', 'deleted'] },
    { id: 'lan_mac',        label: 'MAC address',       placeholder: 'e.g. 51:0c:24:ffff:12',   types: ['active', 'new', 'deleted'] },
    { id: 'hw_serial',      label: 'Serial number',     placeholder: 'e.g. 12.34.87',           types: ['active', 'new', 'deleted'] },
    { id: 'is_online',      label: 'Server status',     options: server_status_options,         types: ['active', 'new'] },
    { id: 'vpn_status',     label: 'VPN status',        options: vpn_status_options,            types: ['active'] },
//    { id: 'created',        label: 'Created',           placeholder: 'DD-MM-YYYY',              types: ['new']},
];

const DEFAULT_MASS_ACTION = 'empty';

const massFnkOptions = [
    { key: DEFAULT_MASS_ACTION, value: 'Mass action',       types: ['active', 'deleted', 'new'] },
    { key: 'startVpn',          value: 'Start VPN',         types: ['active'],                      toBeConfirmed: false },
    { key: 'stopVpn',           value: 'End VPN',           types: ['active'],                      toBeConfirmed: false },
    { key: 'restartBrowsers',   value: 'Restart browsers',  types: ['active'],                      toBeConfirmed: true },
    { key: 'restartServers',    value: 'Restart servers',   types: ['active'],                      toBeConfirmed: true },
    { key: 'updateSoftware',    value: 'Update software',   types: ['active'],                      toBeConfirmed: true },
    { key: 'assignServers',     value: 'Assign to customer',types: ['active', 'new'],               toBeConfirmed: true },
    { key: 'delete',            value: 'Delete servers',    types: ['active', 'new'],               toBeConfirmed: true },
    { key: 'restore',           value: 'Restore servers',   types: ['deleted'],                     toBeConfirmed: false }
];
const WAIT_INTERVAL = 700;

class ServersTableToolbar extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isAdvancedSearch: this.props.serversFilters.search[1].length > 0,
            massAction: DEFAULT_MASS_ACTION,
            vpnStartSuccess: false,
            vpnStartError: '',
            vpnStopSuccess: false,
            vpnStopError: '',
            restartBrowsersSuccess: false,
            restartBrowsersError: '',
            restartServersSuccess: false,
            restartServersError: '',
        }
    }

    componentWillMount() {
        this.timer = null;
    }

    clearSearch = () => {
        const search = this.props.serversFilters.search;
        if(search[0].length > 0){
            this.setSearch('');
        }
    };

    setSearch = (searchStr) => {
        clearTimeout(this.timer);
        const { ...rest } = this.props.serversFilters;
        const filters = {
            ...rest,
            search: [searchStr,[]],
            page: 1
        };
        this.props.setServersFilters(filters);
        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
    };

    onHandleChange = (e) => {
        const searchStr = e.currentTarget.value;
        this.setSearch(searchStr);
    };

    triggerChange = () => {
        const { ...rest } = this.props.serversFilters;
        const filters = {
            ...rest,
            page: 1
        };
        this.props.setServersFilters(filters);
        this.props.getServers();
    };

    onHandleAdvancedSearch = () => {
        const search = this.props.serversFilters.search;
        // if(search[1].length > 0){
        this.triggerChange();
        // }
    };

    onHandleResetSearch = () => {
        const { search, ...rest } = this.props.serversFilters;
        if(search[1].length > 0) {
            const filters = {
                ...rest,
                search: ['', []],
                page: 1
            };
            this.props.setServersFilters(filters);
            this.props.getServers();
        }
    };

    onAdvancedSearchChange = (name, value) => {
        this.changeSearchFilters(name, value);
    };

    changeSearchFilters = (id, value) => {
        const { search, ...rest } = this.props.serversFilters;
        let params = search[1];
        const filteredParams = _.filter(params, (el) => { return el[id] });
        if(filteredParams.length > 0){
            params.forEach((el, index) => {
                const [key] = Object.keys(el);
                if(key === id) params[index][key] = value;
            });
            params = _.filter(params, (el) => {
                let [ v ] = _.values(el);
                // for select all
                if(_.indexOf(['nodeGroup', 'serverStatus', 'vpn_status'], id) !== -1){
                    v = v === 'all' ? '' : v;
                }

                return v !== '';
            });
        }else{
            if(value) params.push({ [id]: value });
        }

        const filters = {
            ...rest,
            search: ['', params],
            page: 1
        };
        this.props.setServersFilters(filters);
    };

    handleDateCreated = (name, date) => {
        const t = date ? date.valueOf(): '';
        this.changeSearchFilters(name, t);
    };

    triggerAdvancedSearch = () => {
        const isAdvancedSearch = this.state.isAdvancedSearch;
        this.setState({ isAdvancedSearch: !isAdvancedSearch });
    };

    handleSelectChange = (name, value) => {
        this.changeSearchFilters(name, value);
    };

    handleServersType = (key) => (evt) => {
        const { ...rest } = this.props.serversFilters;
        if(key !== rest.serverType) {
            this.setState({massAction: DEFAULT_MASS_ACTION});
            const filters = {
                ...rest,
                page: 1,
                sortBy: 'hostname',
                order: 'asc',
                search: ['', []],
                serverType: key
            };
            this.props.setServersFilters(filters);
            this.props.getServers();
        }
    };

    handlePageNumberChange = (name, value, allowUpdate) => {
        if(allowUpdate){
            const { ...rest } = this.props.serversFilters;
            const filters = {
                ...rest,
                page: value
            };
            this.props.setServersFilters(filters);
        }
    };

    handleNavigate = ( direction ) => ( evt ) => {
        const pages = this.props.serversObj.pages;
        const { page, ...rest } = this.props.serversFilters;
        const isDirectionNext = (direction === 'next') && (page <= pages && page !== pages && page > 0);
        const isDirectionPrev = (direction === 'prev') && (page <= pages && page !== 1 && page > 0);
        if(isDirectionNext || isDirectionPrev){
            const newPage = isDirectionNext ? page + 1 : page - 1;
            const filters = {
                ...rest,
                page: newPage
            };
            this.props.setServersFilters(filters);
            this.props.getServers();
        }
    };

    handlePageChange = (name, value) => {
        const { ...rest } = this.props.serversFilters;
        const filters = {
            ...rest,
            page: value
        };
        this.props.setServersFilters(filters);
        this.props.getServers();
    };

    handleRowsChange = (name, value) => {
        const { rows, ...rest } = this.props.serversFilters;
        if(value !== rows){
            const filters = {
                ...rest,
                page: 1,
                rows: value
            };
            this.props.setServersFilters(filters);
            this.props.getServers();
        }
    };

    handleClearSelection = () => {
       this.props.clearMultipleChoose();
       this.setState({ massAction: DEFAULT_MASS_ACTION });
    };

    handleMassAction = (name, value) => {
        if(this.props.selected.length <= 0) value = DEFAULT_MASS_ACTION;

        if(value !== this.state[name]){
            this.setState({ [name]: value });
            let [ action ] = _.filter(massFnkOptions, (el) => { return el.key === value });
            if(value !== DEFAULT_MASS_ACTION && action && !action.toBeConfirmed){
                const server_ids = this.props.selected;

                if(value === 'startVpn'){
                    this.props.startMultipleVpnConnection(server_ids)
                        .then(() => {       this.setState({ vpnStartSuccess: true });       })
                        .catch((err) => {   this.setState({ vpnStartError: err.message });  });

                }else if(value === 'stopVpn'){
                    this.props.stopMultipleVpnConnection(server_ids)
                        .then(() => {       this.setState({ vpnStopSuccess: true });        })
                        .catch((err) => {   this.setState({ vpnStopError: err.message });   });
                }
            }
        }
    };

    handleAction = (evt) => {
        const action = this.state.massAction;
        const server_ids = this.props.selected;

        if(action === 'delete'){
            this.props.startDeleteServers(server_ids)
                .then(() => { this.handleClearSelection(); });

        }else if(action === 'restore') {
            this.props.startUndeleteServers(server_ids)
                .then(() => { this.handleClearSelection(); });

        }else{
            this.handleClearSelection();

            // stream functions
            if(action === 'restartBrowsers'){
                this.props.startMultipleRestartBrowsers(server_ids)
                    .then(() => {       this.setState({restartBrowsersSuccess: true});          })
                    .catch((err) => {   this.setState({restartBrowsersError: err.message});     });

            }else if(action === 'restartServers'){
                this.props.startRestartMultipleServers(server_ids)
                    .then(() => {       this.setState({restartServersSuccess: true});          })
                    .catch((err) => {   this.setState({restartServersError: err.message});     });
            }

        }
    };

    cancelAction = (evt) => {
        this.setState({
            massAction: DEFAULT_MASS_ACTION,
            vpnStartSuccess: false,
            vpnStartError: '',
            vpnStopSuccess: false,
            vpnStopError: '',
            restartBrowsersSuccess: false,
            restartBrowsersError: '',
            restartServersSuccess: false,
            restartServersError: '',
        });
    };

    render(){
        const {
            isAdvancedSearch, massAction,
            vpnStartSuccess, vpnStartError,
            vpnStopSuccess, vpnStopError,
            restartBrowsersSuccess, restartBrowsersError,
            restartServersSuccess, restartServersError
        } = this.state;
        const { classes, serversFilters, serversObj, selected, history } = this.props;
        const { page, rows, search, serverType } = serversFilters;
        const { pages, items, loading } = serversObj;
        const [ searchAll, searchQuery ] = search;
        const resetAdvancedSearch = searchQuery.length > 0;

        // mass action variables
        let noIcon, topOriented, actionName, actionKey, message, errorNotification = false;
        if(massAction === 'delete'){
            actionName = "Delete";
            actionKey = "delete";
            message = "Do you really want to delete chosen servers?";
        }else if(massAction === 'restartServers'){
            actionName = "Restart";
            actionKey = "restart servers";
            message = "Do you really want to restart servers?";
        }else if(massAction === 'restartBrowsers'){
            actionName = "Restart";
            actionKey = "restart browsers";
            message = "Do you really want to restart browsers of chosen servers?";
        }else if(massAction === 'restore'){
            actionName = "Restore";
            actionKey = "restore";
            message = "Do you really want to restore servers?";
        }else if(massAction === 'assignServers'){
            noIcon = true;
            topOriented = true;
            message = <SearchCustomers history={history} selectedServers={selected}/>;
        }else if(massAction === 'updateSoftware'){
            noIcon = true;
            topOriented = true;
            message = <DeviceVersionsSelections selectedServers={selected} />;
        }else if(vpnStartSuccess || vpnStopSuccess || restartBrowsersSuccess || restartServersSuccess){
            noIcon = true;
            if(vpnStartSuccess) message = "VPN connection to servers";
            else if(vpnStopSuccess) message = "VPN disconnection to servers";
            else if(restartBrowsersSuccess) message = "Restarting of browsers";
            else if(restartServersSuccess) message = "Restarting of servers";
            message = `${message} has been triggered successfully. The process can take some time. Please wait...`;
        }else if(vpnStartError || vpnStopError || restartBrowsersError || restartServersError){
            errorNotification = true;
            if(vpnStartError) message = `Triggering of VPN connection to servers has failed: ${vpnStartError}`;
            else if(vpnStopError) message = `Triggering of VPN disconnection to servers has failed: ${vpnStopError}`;
            else if(restartBrowsersError) message = `Triggering of restarting browsers has failed: ${restartBrowsersError}`;
            else if(restartServersError) message = `Triggering of restarting servers has failed: ${restartServersError}`;
        }

        const _massFnkOptions = _.filter(massFnkOptions, (el) => { return _.indexOf(el.types, serverType) !== -1 });
        const showNotification = message
            || vpnStartSuccess || vpnStartError
            || vpnStopSuccess || vpnStopError
            || restartBrowsersSuccess || restartBrowsersError
            || restartServersSuccess || restartServersError;

        return <div>
            <div className={classes.root}>
                <div className={classes.toolbarBtnContainer}>
                    {serverTypes.map((el) => {
                        const [ key ] = Object.keys(el);
                        return <Button
                            key={key}
                            variant="contained"
                            color={serversFilters.serverType === key ? "primary" : "inherit"}
                            onClick={this.handleServersType(key)}
                        >{el[key]}</Button>
                    })}
                </div>
                <SelectComp
                    name="massAction"
                    value={massAction}
                    options={_massFnkOptions}
                    inactive={!selected.length > 0}
                    action={this.handleMassAction}
                />
                <PaginationComp
                    handleNavigate={this.handleNavigate}
                    page={page}
                    pages={pages}
                    items={items}
                    handlePageChange={this.handlePageChange}
                    handlePageNumberChange={this.handlePageNumberChange}
                    rows={rows}
                    handleRowsChange={this.handleRowsChange}
                    toolbarClass="pagesContainer"
                />
            </div>
            {showNotification && <MassActionNotification
                cancelAction={this.cancelAction}
                handleAction={this.handleAction}
                noIcon={noIcon}
                topOriented={topOriented}
                actionName={actionName}
                actionKey={actionKey}
                message={message}
                error={errorNotification}
            />}
            <div className={classes.searchContainer}>
                <SearchHeaderComp
                    searchId="search-all"
                    searchAll={searchAll}
                    searching={searchAll.length > 0 && loading}
                    clearSearch={this.clearSearch}
                    searchQuery={searchQuery}
                    triggerAdvancedSearch={this.triggerAdvancedSearch}
                    isAdvancedSearch={isAdvancedSearch}
                    onHandleChange={this.onHandleChange}
                />
                <Collapse
                    className={classes.collapse}
                    in={isAdvancedSearch}
                    timeout={{enter: 270, exit:0}}
                    unmountOnExit
                >
                    <form className={classes.inputsContainer}>
                        {searchData.map((el) => {
                            let [ valueObj ] = _.filter(searchQuery, (query) => {
                                let [ key ] = Object.keys(query);
                                return key === el.id;
                            });
                            let value = valueObj ? valueObj[el.id] : '';
                            if(_.indexOf(el.types, serverType) === -1) return;

                            if(el.id === 'created'){
                                const date = value ? new Date(parseInt(value, 10)) : null;
                                return <DatePickerComp
                                    key={el.id}
                                    label={el.label}
                                    maxDate={new Date()}
                                    name={el.id}
                                    placeholderText={el.placeholder}
                                    date={date}
                                    dateFormat="dd-MM-yyyy"
                                    handleChangeDate={this.handleDateCreated}
                                    isClearable={true}
                                />;
                            }else if(el.options){
                                let inactive = !value || value === '0';
                                value = inactive ? '0': value;
                                return <SelectComp
                                    key={el.id}
                                    label={el.label}
                                    name={el.id}
                                    value={value}
                                    options={el.options}
                                    inactive={inactive}
                                    action={this.handleSelectChange}
                                    fixedWidth={true}
                                />;
                            }else{
                                return <InputTextComp
                                    key={el.id}
                                    name={el.id}
                                    label={el.label}
                                    value={value}
                                    placeholder={el.placeholder}
                                    action={this.onAdvancedSearchChange}
                                />;
                            }
                        })}
                    </form>
                    <AdvSrcBtnContainer
                        onHandleAdvancedSearch={this.onHandleAdvancedSearch}
                        onHandleResetSearch={this.onHandleResetSearch}
                        resetAdvancedSearch={resetAdvancedSearch}
                    />
                </Collapse>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        serversFilters: state.serversFilters,
        serversObj: state.servers,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setServersFilters: (filters) => dispatch(setServersFilters(filters)),
    startDeleteServers: (server_ids) => dispatch(startDeleteServers(server_ids)),
    startUndeleteServers: (server_ids) => dispatch(startUndeleteServers(server_ids)),
    startMultipleVpnConnection: (server_ids) => dispatch(startMultipleVpnConnection(server_ids)),
    stopMultipleVpnConnection: (server_ids) => dispatch(stopMultipleVpnConnection(server_ids)),
    startMultipleRestartBrowsers: (server_ids) => dispatch(startMultipleRestartBrowsers(server_ids)),
    startRestartMultipleServers: (server_ids) => dispatch(startRestartMultipleServers(server_ids)),
});

export default compose(
    withStyles(toolbarStyle),
    connect(mapStateToProps, mapDispatchToProps),
)(ServersTableToolbar);

