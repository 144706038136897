import {
    root
} from "../material-base";

const signedOutLayoutStyle = theme => ({
    root: {
        ...root,
        backgroundColor: 'none',
        backgroundImage: 'url("/images/bg.jpg")',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
    }
});

export default signedOutLayoutStyle;
