import React from 'react';
// Material components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
// own components
import SimpleModal from '../general/SimpleModal';
// styles
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/general/tableStyle';

const DeviceInfoModal = ({ classes, isOpenedModal, handleClose }) => {

    const data = isOpenedModal;
    let content;
    if(data.length > 1){
        content = <Table className={`${classes.mobileTable} modal`}>
            <TableBody>
                {data.map((el) => {
                    const [ label, value ] = el;
                    return <TableRow key={label} >
                        <TableCell>{label}</TableCell>
                        <TableCell>{value}</TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>;
    }

    return <SimpleModal
        title="Device Details"
        isOpenedModal={!!isOpenedModal}
        handleClose={handleClose}
        content={content}
    />;
};

export default withStyles(style)(DeviceInfoModal);