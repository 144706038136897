import React from "react";
// Material UI components
import LinearProgress from '@material-ui/core/LinearProgress';
// styles
import withStyles from "@material-ui/core/styles/withStyles";
import style from '../../styles/jss/components/servers/loadingProgressMessageStyle';

const LoadingProgressMessage = ({ classes, msg }) => {

    return <div className={classes.container}>
        <div>{msg}</div>
        <div className={classes.lastTextChild}>Please wait...</div>
        <LinearProgress color="secondary" />
    </div>;
};

export default withStyles(style)(LoadingProgressMessage);



