import {
    successBoxShadow,
    infoBoxShadow,
    dangerBoxShadow,
} from "../../material-base";

const notificationStyle = theme => ({
    success: {
        backgroundColor: theme.palette.secondary.dark,
        ...successBoxShadow,
    },
    error: {
        backgroundColor: theme.palette.error.main,
        ...dangerBoxShadow
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
        ...infoBoxShadow
    },
    warning: {
        //backgroundColor: theme.palette.secondary2.dark,
    },
    msgIcon: {
        fontSize: 30,
        marginRight: '25px'
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    notification: {
        width: '100%',
        [theme.breakpoints.up("sm")]: {
            width: '600px',
        },
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        '&.topOriented': {
            alignItems: 'flex-start',
        }
    },
    topOriented: {
        width: '100%'
    }
});

export default notificationStyle;

