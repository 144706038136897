import React from 'react';
// Material UI components
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import DoneAll from '@material-ui/icons/DoneAll';
import Error from '@material-ui/icons/ErrorOutline';
import Warning from '@material-ui/icons/Warning';
import NotificationIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
//
import NotificationErrorMsg from '../general/NotificationErrorMsg';
// styles
import { withStyles } from '@material-ui/core/styles';
import notificationStyle from '../../styles/jss/components/general/notificationsStyle';


const Notification = ({ classes, message, onClose, variant, action, noIcon, topOriented, massActionErr, ...other }) => {

    let icon;
    if(variant === 'error'){
        icon = <Error className={classes.msgIcon} />
    }else if(variant === 'warning') {
        icon = <Warning className={classes.msgIcon} />;
    }else if(variant === 'success'){
        icon = <DoneAll className={classes.msgIcon} />;
    }else{
        icon = <NotificationIcon className={classes.msgIcon} />;
    }

    if(noIcon) icon = '';

    let submitAction = action;
    let exitAction = <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={onClose}
    >
        <CloseIcon className={classes.icon} />
    </IconButton>;

    const actions = submitAction ? [ submitAction, exitAction ] : [ exitAction ];

    return <SnackbarContent
        classes={{ message: topOriented ? classes.topOriented : '' }}
        className={`${classes[variant]} ${classes.notification} ${topOriented ? 'topOriented' : ''}`}
        message={
            <span className={classes.message}>
                {icon}
                {message}
                {massActionErr && <NotificationErrorMsg
                    errorMsg={massActionErr}
                />}
            </span>
        }
        action={actions}
        {...other}
    />;
};

export default withStyles(notificationStyle)(Notification);
