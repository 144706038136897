import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _ from 'lodash';
// actions
import { getVersionsOptions, DEFAULT_SOFTWARE_VERSION, DELETE_SOFTWARE } from '../../actions/devices/software';
import { startUpdateServerDeviceVersions } from '../../actions/devices/update';
// Material UI components
import CircularProgress from '@material-ui/core/CircularProgress';
// own components
import SelectComp from '../general/SelectComp';
import ActionButtonComp from '../general/ActionButtonComp';
import MassActionNotification from '../general/MassActionNotification';
import LoadingProgressMessage from '../servers/LoadingProgressMessage';
// style
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/servers/devicesUpdatesStyle';

class DevicesUpdates extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            versionUpdateError: '',
            updateVersions: this.getDevicesSoftware(),
        }
    }

    getDevicesSoftware = () => {
        const { loading, data, error } = this.props._devicesSoftware;
        let updateVersions = {};
        if(!loading && !error && data.length > 0){
            data.forEach((deviceVersion) => {
                updateVersions[deviceVersion.id] = DEFAULT_SOFTWARE_VERSION;
            });
        }
        return updateVersions;
    };

    handleSelectAction = (name, value) => {
        let updateVersions = this.state.updateVersions;
        updateVersions[name] = value;
        this.setState({ updateVersions });
    };

    cancelAction = (evt) => {
        this.setState({
            versionUpdateError: ''
        });
    };

    handleUpdateDeviceVersions = () => {
        const { _devicesUpdate, _server } = this.props;
        const versionUpdateLoading = _devicesUpdate.loading && _devicesUpdate.serverId === _server.item.id;
        if(!versionUpdateLoading) {
            let updateDeviceVersions = [];
            const updateVersions = this.state.updateVersions;
            const keys = Object.keys(updateVersions);
            keys.forEach((key) => {
                if (updateVersions[key] !== DEFAULT_SOFTWARE_VERSION) {
                    updateDeviceVersions.push({software_id: key, version_id: updateVersions[key]});
                }
            });

            const [empty, path, server_id] = this.props.history.location.pathname.split('/');

            if (updateDeviceVersions.length > 0 && server_id) {
                this.props.startUpdateServerDeviceVersions(server_id, updateDeviceVersions)
                    .then((response) => {
                        this.setState({ updateVersions: this.getDevicesSoftware() });
                    })
                    .catch((err) => {
                        this.setState({ versionUpdateError: err.message })
                    });
            }
        }
    };

    render() {
        const { updateVersions, versionUpdateError } = this.state;
        const { classes, _devicesSoftware, _server, _devicesUpdate } = this.props;
        const versionUpdateLoading = _devicesUpdate.loading && _devicesUpdate.serverId === _server.item.id;
        const software_updates = _server.item.software_updates;
        const { loading, data, error } = _devicesSoftware;
        const softwareVersionsFound = software_updates && software_updates.length > 0;

        return <div className={classes.devicesContainer}>
            {data.map(({id, title, versions}) => {
                const options = getVersionsOptions(versions);
                let [ currentItem ] = softwareVersionsFound ? _.filter(software_updates, (el) => { return el.software_id === id }) : [];
                if(!updateVersions[id]) return;
                return <div
                    className={classes.deviceItem}
                    key={id}
                >
                    <SelectComp
                        label={title}
                        name={id}
                        value={updateVersions[id]}
                        options={options}
                        inactive={updateVersions[id] === DEFAULT_SOFTWARE_VERSION}
                        action={this.handleSelectAction}
                        fixedWidth={true}
                    />
                    {currentItem && currentItem.version_id !== DELETE_SOFTWARE && <div className={classes.deviceDetails}>
                        <div>{currentItem.version_id} - current version</div>
                        <div>Updated at {currentItem.updated_at}</div>
                    </div>}
                </div>;
            })}
            {(versionUpdateError || _devicesUpdate.loading && _server.item.id === _devicesUpdate.serverId) && <MassActionNotification
                cancelAction={this.cancelAction}
                noIcon={!versionUpdateError}
                topOriented={true}
                message={versionUpdateError || <LoadingProgressMessage msg={`Devices update is in progress. ${_devicesUpdate.stateText}`}/>}
                error={!!versionUpdateError}
            />}
            <ActionButtonComp
                active={true}
                action={this.handleUpdateDeviceVersions}
                title={!versionUpdateLoading ? 'Update device versions' :
                    <span>Update is in process... <CircularProgress size={20} color="inherit"/></span>}
            />
        </div>
    }
}
const mapStateToProps = (state) => {
    return {
        _server: state.server,
        _devicesSoftware:  state.devicesSoftware,
        _devicesUpdate: state.devicesUpdate
    };
};

const mapDispatchToProps = (dispatch) => ({
    startUpdateServerDeviceVersions: (server_id, deviceVersions) => dispatch(startUpdateServerDeviceVersions(server_id, deviceVersions)),
});

export default compose(
    withStyles(style),
    connect(mapStateToProps, mapDispatchToProps),
)(DevicesUpdates);
