import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Route, Redirect } from 'react-router-dom';
// actions
import { getAuthTokenGlobal } from '../actions/libs/cookie';
import { startLogin } from '../actions/auth/auth';
import { AUTH_PATHNAME } from '../actions/libs/app-globals';

class PrivateRoute extends React.Component {

    shouldComponentUpdate = (nextProps, nextState) => {
        // restart login if no authToken in client
        const authToken = getAuthTokenGlobal();
        if(!authToken) this.props.startLogin();

        return !_.isEqual(this.props, nextProps);
    };

    render() {
        const { component: Component, ...rest } = this.props;
        const isAuthenticated = !!getAuthTokenGlobal();

        return <Route {...rest} component={(props) => {
            const properties = Object.assign({...rest}, {...props});

            return isAuthenticated ?
                <Component {...properties} /> :
                <Redirect to={AUTH_PATHNAME}/>
        }}/>
    };
}

const mapDispatchToProps = (dispatch) => ({
    startLogin: () => dispatch(startLogin())
});

export default connect(undefined, mapDispatchToProps)(PrivateRoute);
