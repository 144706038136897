const initialState = {
    serverId: '',
    loading: false,
    stateText: '',
    error: null
};

const serverRestartReducer = (state = initialState, action) => {
    switch (action.type){
        case 'SET_SERVER_RESTART_LOADING_PROGRESS':
            return {
                ...state,
                loading: true,
                serverId: action.payload.serverId,
                stateText: action.payload.stateText
            };
        case 'SET_SERVER_RESTART_SUCCESS':
            return {
                ...state,
                stateText: '',
                loading: false,
                serverId: '',
                error: null
            };
        case 'SET_SERVER_RESTART_FAILURE':
            return {
                ...state,
                serverId: '',
                loading: false,
                stateText: '',
                error: action.payload.error
            };
        default:
            return state;
    }
};

export default serverRestartReducer;
