// source: customer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var server_pb = require('./server_pb.js');
goog.object.extend(proto, server_pb);
goog.exportSymbol('proto.customer.Device', null, global);
goog.exportSymbol('proto.customer.DeviceType', null, global);
goog.exportSymbol('proto.customer.GetCustomerReq', null, global);
goog.exportSymbol('proto.customer.GetCustomerResp', null, global);
goog.exportSymbol('proto.customer.ListCustomerResp', null, global);
goog.exportSymbol('proto.customer.ListCustomersReq', null, global);
goog.exportSymbol('proto.customer.ListCustomersResp', null, global);
goog.exportSymbol('proto.customer.Node', null, global);
goog.exportSymbol('proto.customer.NodeGroup', null, global);
goog.exportSymbol('proto.customer.Server', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.ListCustomersReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.ListCustomersReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.ListCustomersReq.displayName = 'proto.customer.ListCustomersReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.ListCustomersResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.customer.ListCustomersResp.repeatedFields_, null);
};
goog.inherits(proto.customer.ListCustomersResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.ListCustomersResp.displayName = 'proto.customer.ListCustomersResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.ListCustomerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.ListCustomerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.ListCustomerResp.displayName = 'proto.customer.ListCustomerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.GetCustomerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.GetCustomerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.GetCustomerReq.displayName = 'proto.customer.GetCustomerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.GetCustomerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.customer.GetCustomerResp.repeatedFields_, null);
};
goog.inherits(proto.customer.GetCustomerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.GetCustomerResp.displayName = 'proto.customer.GetCustomerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.NodeGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.customer.NodeGroup.repeatedFields_, null);
};
goog.inherits(proto.customer.NodeGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.NodeGroup.displayName = 'proto.customer.NodeGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.Node = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.customer.Node.repeatedFields_, null);
};
goog.inherits(proto.customer.Node, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.Node.displayName = 'proto.customer.Node';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.Device = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.Device, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.Device.displayName = 'proto.customer.Device';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.DeviceType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.DeviceType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.DeviceType.displayName = 'proto.customer.DeviceType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.Server = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.Server, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.Server.displayName = 'proto.customer.Server';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.ListCustomersReq.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.ListCustomersReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.ListCustomersReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.ListCustomersReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemsPerPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sortQuery: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    query: jspb.Message.getFieldWithDefault(msg, 6, ""),
    id: jspb.Message.getFieldWithDefault(msg, 7, 0),
    number: jspb.Message.getFieldWithDefault(msg, 8, 0),
    milonCareId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    name: jspb.Message.getFieldWithDefault(msg, 10, ""),
    contactPerson: jspb.Message.getFieldWithDefault(msg, 11, ""),
    street: jspb.Message.getFieldWithDefault(msg, 12, ""),
    zip: jspb.Message.getFieldWithDefault(msg, 13, ""),
    city: jspb.Message.getFieldWithDefault(msg, 14, ""),
    country: jspb.Message.getFieldWithDefault(msg, 15, ""),
    telephone: jspb.Message.getFieldWithDefault(msg, 16, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 17, ""),
    email: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.ListCustomersReq}
 */
proto.customer.ListCustomersReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.ListCustomersReq;
  return proto.customer.ListCustomersReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.ListCustomersReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.ListCustomersReq}
 */
proto.customer.ListCustomersReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItemsPerPage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortQuery(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumber(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMilonCareId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPerson(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setZip(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelephone(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.ListCustomersReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.ListCustomersReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.ListCustomersReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.ListCustomersReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getItemsPerPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSortQuery();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getNumber();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getMilonCareId();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getContactPerson();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getZip();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getTelephone();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional int32 page_no = 1;
 * @return {number}
 */
proto.customer.ListCustomersReq.prototype.getPageNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.ListCustomersReq} returns this
 */
proto.customer.ListCustomersReq.prototype.setPageNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 items_per_page = 2;
 * @return {number}
 */
proto.customer.ListCustomersReq.prototype.getItemsPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.ListCustomersReq} returns this
 */
proto.customer.ListCustomersReq.prototype.setItemsPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string sort_query = 3;
 * @return {string}
 */
proto.customer.ListCustomersReq.prototype.getSortQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomersReq} returns this
 */
proto.customer.ListCustomersReq.prototype.setSortQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool deleted = 4;
 * @return {boolean}
 */
proto.customer.ListCustomersReq.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.customer.ListCustomersReq} returns this
 */
proto.customer.ListCustomersReq.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string query = 6;
 * @return {string}
 */
proto.customer.ListCustomersReq.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomersReq} returns this
 */
proto.customer.ListCustomersReq.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 id = 7;
 * @return {number}
 */
proto.customer.ListCustomersReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.ListCustomersReq} returns this
 */
proto.customer.ListCustomersReq.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 number = 8;
 * @return {number}
 */
proto.customer.ListCustomersReq.prototype.getNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.ListCustomersReq} returns this
 */
proto.customer.ListCustomersReq.prototype.setNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 milon_care_id = 9;
 * @return {number}
 */
proto.customer.ListCustomersReq.prototype.getMilonCareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.ListCustomersReq} returns this
 */
proto.customer.ListCustomersReq.prototype.setMilonCareId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string name = 10;
 * @return {string}
 */
proto.customer.ListCustomersReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomersReq} returns this
 */
proto.customer.ListCustomersReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string contact_person = 11;
 * @return {string}
 */
proto.customer.ListCustomersReq.prototype.getContactPerson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomersReq} returns this
 */
proto.customer.ListCustomersReq.prototype.setContactPerson = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string street = 12;
 * @return {string}
 */
proto.customer.ListCustomersReq.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomersReq} returns this
 */
proto.customer.ListCustomersReq.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string zip = 13;
 * @return {string}
 */
proto.customer.ListCustomersReq.prototype.getZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomersReq} returns this
 */
proto.customer.ListCustomersReq.prototype.setZip = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string city = 14;
 * @return {string}
 */
proto.customer.ListCustomersReq.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomersReq} returns this
 */
proto.customer.ListCustomersReq.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string country = 15;
 * @return {string}
 */
proto.customer.ListCustomersReq.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomersReq} returns this
 */
proto.customer.ListCustomersReq.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string telephone = 16;
 * @return {string}
 */
proto.customer.ListCustomersReq.prototype.getTelephone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomersReq} returns this
 */
proto.customer.ListCustomersReq.prototype.setTelephone = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string mobile = 17;
 * @return {string}
 */
proto.customer.ListCustomersReq.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomersReq} returns this
 */
proto.customer.ListCustomersReq.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string email = 18;
 * @return {string}
 */
proto.customer.ListCustomersReq.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomersReq} returns this
 */
proto.customer.ListCustomersReq.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.customer.ListCustomersResp.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.ListCustomersResp.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.ListCustomersResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.ListCustomersResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.ListCustomersResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.customer.ListCustomerResp.toObject, includeInstance),
    itemCount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.ListCustomersResp}
 */
proto.customer.ListCustomersResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.ListCustomersResp;
  return proto.customer.ListCustomersResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.ListCustomersResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.ListCustomersResp}
 */
proto.customer.ListCustomersResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCount(value);
      break;
    case 3:
      var value = new proto.customer.ListCustomerResp;
      reader.readMessage(value,proto.customer.ListCustomerResp.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItemCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.ListCustomersResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.ListCustomersResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.ListCustomersResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.ListCustomersResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.customer.ListCustomerResp.serializeBinaryToWriter
    );
  }
  f = message.getItemCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 page_no = 1;
 * @return {number}
 */
proto.customer.ListCustomersResp.prototype.getPageNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.ListCustomersResp} returns this
 */
proto.customer.ListCustomersResp.prototype.setPageNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_count = 2;
 * @return {number}
 */
proto.customer.ListCustomersResp.prototype.getPageCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.ListCustomersResp} returns this
 */
proto.customer.ListCustomersResp.prototype.setPageCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated ListCustomerResp results = 3;
 * @return {!Array<!proto.customer.ListCustomerResp>}
 */
proto.customer.ListCustomersResp.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.customer.ListCustomerResp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.customer.ListCustomerResp, 3));
};


/**
 * @param {!Array<!proto.customer.ListCustomerResp>} value
 * @return {!proto.customer.ListCustomersResp} returns this
*/
proto.customer.ListCustomersResp.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.customer.ListCustomerResp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.customer.ListCustomerResp}
 */
proto.customer.ListCustomersResp.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.customer.ListCustomerResp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customer.ListCustomersResp} returns this
 */
proto.customer.ListCustomersResp.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};


/**
 * optional int32 item_count = 4;
 * @return {number}
 */
proto.customer.ListCustomersResp.prototype.getItemCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.ListCustomersResp} returns this
 */
proto.customer.ListCustomersResp.prototype.setItemCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.ListCustomerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.ListCustomerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.ListCustomerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.ListCustomerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    number: jspb.Message.getFieldWithDefault(msg, 2, 0),
    milonCareId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contactPerson: jspb.Message.getFieldWithDefault(msg, 5, ""),
    street: jspb.Message.getFieldWithDefault(msg, 6, ""),
    zip: jspb.Message.getFieldWithDefault(msg, 7, ""),
    city: jspb.Message.getFieldWithDefault(msg, 8, ""),
    country: jspb.Message.getFieldWithDefault(msg, 9, ""),
    telephone: jspb.Message.getFieldWithDefault(msg, 10, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 11, ""),
    email: jspb.Message.getFieldWithDefault(msg, 12, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deletedAt: (f = msg.getDeletedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.ListCustomerResp}
 */
proto.customer.ListCustomerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.ListCustomerResp;
  return proto.customer.ListCustomerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.ListCustomerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.ListCustomerResp}
 */
proto.customer.ListCustomerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMilonCareId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPerson(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setZip(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelephone(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeletedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.ListCustomerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.ListCustomerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.ListCustomerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.ListCustomerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMilonCareId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContactPerson();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getZip();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTelephone();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeletedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.customer.ListCustomerResp.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.ListCustomerResp} returns this
 */
proto.customer.ListCustomerResp.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 number = 2;
 * @return {number}
 */
proto.customer.ListCustomerResp.prototype.getNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.ListCustomerResp} returns this
 */
proto.customer.ListCustomerResp.prototype.setNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 milon_care_id = 3;
 * @return {number}
 */
proto.customer.ListCustomerResp.prototype.getMilonCareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.ListCustomerResp} returns this
 */
proto.customer.ListCustomerResp.prototype.setMilonCareId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.customer.ListCustomerResp.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomerResp} returns this
 */
proto.customer.ListCustomerResp.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string contact_person = 5;
 * @return {string}
 */
proto.customer.ListCustomerResp.prototype.getContactPerson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomerResp} returns this
 */
proto.customer.ListCustomerResp.prototype.setContactPerson = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string street = 6;
 * @return {string}
 */
proto.customer.ListCustomerResp.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomerResp} returns this
 */
proto.customer.ListCustomerResp.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string zip = 7;
 * @return {string}
 */
proto.customer.ListCustomerResp.prototype.getZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomerResp} returns this
 */
proto.customer.ListCustomerResp.prototype.setZip = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string city = 8;
 * @return {string}
 */
proto.customer.ListCustomerResp.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomerResp} returns this
 */
proto.customer.ListCustomerResp.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string country = 9;
 * @return {string}
 */
proto.customer.ListCustomerResp.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomerResp} returns this
 */
proto.customer.ListCustomerResp.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string telephone = 10;
 * @return {string}
 */
proto.customer.ListCustomerResp.prototype.getTelephone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomerResp} returns this
 */
proto.customer.ListCustomerResp.prototype.setTelephone = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string mobile = 11;
 * @return {string}
 */
proto.customer.ListCustomerResp.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomerResp} returns this
 */
proto.customer.ListCustomerResp.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string email = 12;
 * @return {string}
 */
proto.customer.ListCustomerResp.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.ListCustomerResp} returns this
 */
proto.customer.ListCustomerResp.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.customer.ListCustomerResp.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.customer.ListCustomerResp} returns this
*/
proto.customer.ListCustomerResp.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.ListCustomerResp} returns this
 */
proto.customer.ListCustomerResp.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.ListCustomerResp.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.customer.ListCustomerResp.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.customer.ListCustomerResp} returns this
*/
proto.customer.ListCustomerResp.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.ListCustomerResp} returns this
 */
proto.customer.ListCustomerResp.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.ListCustomerResp.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp deleted_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.customer.ListCustomerResp.prototype.getDeletedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.customer.ListCustomerResp} returns this
*/
proto.customer.ListCustomerResp.prototype.setDeletedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.ListCustomerResp} returns this
 */
proto.customer.ListCustomerResp.prototype.clearDeletedAt = function() {
  return this.setDeletedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.ListCustomerResp.prototype.hasDeletedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.GetCustomerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.GetCustomerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.GetCustomerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.GetCustomerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    getServers: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    getMilonCare: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.GetCustomerReq}
 */
proto.customer.GetCustomerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.GetCustomerReq;
  return proto.customer.GetCustomerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.GetCustomerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.GetCustomerReq}
 */
proto.customer.GetCustomerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGetServers(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGetMilonCare(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.GetCustomerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.GetCustomerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.GetCustomerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.GetCustomerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGetServers();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getGetMilonCare();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.customer.GetCustomerReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.GetCustomerReq} returns this
 */
proto.customer.GetCustomerReq.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool get_servers = 2;
 * @return {boolean}
 */
proto.customer.GetCustomerReq.prototype.getGetServers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.customer.GetCustomerReq} returns this
 */
proto.customer.GetCustomerReq.prototype.setGetServers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool get_milon_care = 3;
 * @return {boolean}
 */
proto.customer.GetCustomerReq.prototype.getGetMilonCare = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.customer.GetCustomerReq} returns this
 */
proto.customer.GetCustomerReq.prototype.setGetMilonCare = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.customer.GetCustomerResp.repeatedFields_ = [20,21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.GetCustomerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.GetCustomerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.GetCustomerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.GetCustomerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    number: jspb.Message.getFieldWithDefault(msg, 2, 0),
    milonCareId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contactPerson: jspb.Message.getFieldWithDefault(msg, 5, ""),
    street: jspb.Message.getFieldWithDefault(msg, 6, ""),
    zip: jspb.Message.getFieldWithDefault(msg, 7, ""),
    city: jspb.Message.getFieldWithDefault(msg, 8, ""),
    country: jspb.Message.getFieldWithDefault(msg, 9, ""),
    telephone: jspb.Message.getFieldWithDefault(msg, 10, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 11, ""),
    email: jspb.Message.getFieldWithDefault(msg, 12, ""),
    homepage: jspb.Message.getFieldWithDefault(msg, 13, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deletedAt: (f = msg.getDeletedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    crmUrl: jspb.Message.getFieldWithDefault(msg, 17, ""),
    logBrowserUrl: jspb.Message.getFieldWithDefault(msg, 18, ""),
    milonCareUrl: jspb.Message.getFieldWithDefault(msg, 19, ""),
    serversList: jspb.Message.toObjectList(msg.getServersList(),
    proto.customer.Server.toObject, includeInstance),
    nodeGroupsList: jspb.Message.toObjectList(msg.getNodeGroupsList(),
    proto.customer.NodeGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.GetCustomerResp}
 */
proto.customer.GetCustomerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.GetCustomerResp;
  return proto.customer.GetCustomerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.GetCustomerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.GetCustomerResp}
 */
proto.customer.GetCustomerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMilonCareId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPerson(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setZip(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelephone(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setHomepage(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeletedAt(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrmUrl(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogBrowserUrl(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setMilonCareUrl(value);
      break;
    case 20:
      var value = new proto.customer.Server;
      reader.readMessage(value,proto.customer.Server.deserializeBinaryFromReader);
      msg.addServers(value);
      break;
    case 21:
      var value = new proto.customer.NodeGroup;
      reader.readMessage(value,proto.customer.NodeGroup.deserializeBinaryFromReader);
      msg.addNodeGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.GetCustomerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.GetCustomerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.GetCustomerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.GetCustomerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMilonCareId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContactPerson();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getZip();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTelephone();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getHomepage();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeletedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCrmUrl();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getLogBrowserUrl();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getMilonCareUrl();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getServersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.customer.Server.serializeBinaryToWriter
    );
  }
  f = message.getNodeGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.customer.NodeGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.customer.GetCustomerResp.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 number = 2;
 * @return {number}
 */
proto.customer.GetCustomerResp.prototype.getNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.setNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 milon_care_id = 3;
 * @return {number}
 */
proto.customer.GetCustomerResp.prototype.getMilonCareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.setMilonCareId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.customer.GetCustomerResp.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string contact_person = 5;
 * @return {string}
 */
proto.customer.GetCustomerResp.prototype.getContactPerson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.setContactPerson = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string street = 6;
 * @return {string}
 */
proto.customer.GetCustomerResp.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string zip = 7;
 * @return {string}
 */
proto.customer.GetCustomerResp.prototype.getZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.setZip = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string city = 8;
 * @return {string}
 */
proto.customer.GetCustomerResp.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string country = 9;
 * @return {string}
 */
proto.customer.GetCustomerResp.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string telephone = 10;
 * @return {string}
 */
proto.customer.GetCustomerResp.prototype.getTelephone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.setTelephone = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string mobile = 11;
 * @return {string}
 */
proto.customer.GetCustomerResp.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string email = 12;
 * @return {string}
 */
proto.customer.GetCustomerResp.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string homepage = 13;
 * @return {string}
 */
proto.customer.GetCustomerResp.prototype.getHomepage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.setHomepage = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.customer.GetCustomerResp.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.customer.GetCustomerResp} returns this
*/
proto.customer.GetCustomerResp.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.GetCustomerResp.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.customer.GetCustomerResp.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.customer.GetCustomerResp} returns this
*/
proto.customer.GetCustomerResp.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.GetCustomerResp.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp deleted_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.customer.GetCustomerResp.prototype.getDeletedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.customer.GetCustomerResp} returns this
*/
proto.customer.GetCustomerResp.prototype.setDeletedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.clearDeletedAt = function() {
  return this.setDeletedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.GetCustomerResp.prototype.hasDeletedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string crm_url = 17;
 * @return {string}
 */
proto.customer.GetCustomerResp.prototype.getCrmUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.setCrmUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string log_browser_url = 18;
 * @return {string}
 */
proto.customer.GetCustomerResp.prototype.getLogBrowserUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.setLogBrowserUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string milon_care_url = 19;
 * @return {string}
 */
proto.customer.GetCustomerResp.prototype.getMilonCareUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.setMilonCareUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * repeated Server servers = 20;
 * @return {!Array<!proto.customer.Server>}
 */
proto.customer.GetCustomerResp.prototype.getServersList = function() {
  return /** @type{!Array<!proto.customer.Server>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.customer.Server, 20));
};


/**
 * @param {!Array<!proto.customer.Server>} value
 * @return {!proto.customer.GetCustomerResp} returns this
*/
proto.customer.GetCustomerResp.prototype.setServersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.customer.Server=} opt_value
 * @param {number=} opt_index
 * @return {!proto.customer.Server}
 */
proto.customer.GetCustomerResp.prototype.addServers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.customer.Server, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.clearServersList = function() {
  return this.setServersList([]);
};


/**
 * repeated NodeGroup node_groups = 21;
 * @return {!Array<!proto.customer.NodeGroup>}
 */
proto.customer.GetCustomerResp.prototype.getNodeGroupsList = function() {
  return /** @type{!Array<!proto.customer.NodeGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.customer.NodeGroup, 21));
};


/**
 * @param {!Array<!proto.customer.NodeGroup>} value
 * @return {!proto.customer.GetCustomerResp} returns this
*/
proto.customer.GetCustomerResp.prototype.setNodeGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.customer.NodeGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.customer.NodeGroup}
 */
proto.customer.GetCustomerResp.prototype.addNodeGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.customer.NodeGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customer.GetCustomerResp} returns this
 */
proto.customer.GetCustomerResp.prototype.clearNodeGroupsList = function() {
  return this.setNodeGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.customer.NodeGroup.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.NodeGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.NodeGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.NodeGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.NodeGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    design: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.customer.Node.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.NodeGroup}
 */
proto.customer.NodeGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.NodeGroup;
  return proto.customer.NodeGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.NodeGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.NodeGroup}
 */
proto.customer.NodeGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesign(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = new proto.customer.Node;
      reader.readMessage(value,proto.customer.Node.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.NodeGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.NodeGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.NodeGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.NodeGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDesign();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.customer.Node.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.customer.NodeGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.NodeGroup} returns this
 */
proto.customer.NodeGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.customer.NodeGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.NodeGroup} returns this
 */
proto.customer.NodeGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string design = 3;
 * @return {string}
 */
proto.customer.NodeGroup.prototype.getDesign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.NodeGroup} returns this
 */
proto.customer.NodeGroup.prototype.setDesign = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.customer.NodeGroup.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.NodeGroup} returns this
 */
proto.customer.NodeGroup.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Node nodes = 5;
 * @return {!Array<!proto.customer.Node>}
 */
proto.customer.NodeGroup.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.customer.Node>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.customer.Node, 5));
};


/**
 * @param {!Array<!proto.customer.Node>} value
 * @return {!proto.customer.NodeGroup} returns this
*/
proto.customer.NodeGroup.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.customer.Node=} opt_value
 * @param {number=} opt_index
 * @return {!proto.customer.Node}
 */
proto.customer.NodeGroup.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.customer.Node, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customer.NodeGroup} returns this
 */
proto.customer.NodeGroup.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.customer.Node.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.Node.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.Node.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.Node} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.Node.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    deviceType: (f = msg.getDeviceType()) && proto.customer.DeviceType.toObject(includeInstance, f),
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.customer.Device.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.Node}
 */
proto.customer.Node.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.Node;
  return proto.customer.Node.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.Node} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.Node}
 */
proto.customer.Node.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.customer.DeviceType;
      reader.readMessage(value,proto.customer.DeviceType.deserializeBinaryFromReader);
      msg.setDeviceType(value);
      break;
    case 3:
      var value = new proto.customer.Device;
      reader.readMessage(value,proto.customer.Device.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.Node.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.Node.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.Node} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.Node.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDeviceType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.customer.DeviceType.serializeBinaryToWriter
    );
  }
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.customer.Device.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.customer.Node.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.Node} returns this
 */
proto.customer.Node.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DeviceType device_type = 2;
 * @return {?proto.customer.DeviceType}
 */
proto.customer.Node.prototype.getDeviceType = function() {
  return /** @type{?proto.customer.DeviceType} */ (
    jspb.Message.getWrapperField(this, proto.customer.DeviceType, 2));
};


/**
 * @param {?proto.customer.DeviceType|undefined} value
 * @return {!proto.customer.Node} returns this
*/
proto.customer.Node.prototype.setDeviceType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.Node} returns this
 */
proto.customer.Node.prototype.clearDeviceType = function() {
  return this.setDeviceType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.Node.prototype.hasDeviceType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Device devices = 3;
 * @return {!Array<!proto.customer.Device>}
 */
proto.customer.Node.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.customer.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.customer.Device, 3));
};


/**
 * @param {!Array<!proto.customer.Device>} value
 * @return {!proto.customer.Node} returns this
*/
proto.customer.Node.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.customer.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.customer.Device}
 */
proto.customer.Node.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.customer.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customer.Node} returns this
 */
proto.customer.Node.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.Device.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.Device.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.Device} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.Device.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    address: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logBrowserUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    milonCareUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    infoMap: (f = msg.getInfoMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.Device}
 */
proto.customer.Device.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.Device;
  return proto.customer.Device.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.Device} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.Device}
 */
proto.customer.Device.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogBrowserUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMilonCareUrl(value);
      break;
    case 5:
      var value = msg.getInfoMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.Device.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.Device.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.Device} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.Device.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogBrowserUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMilonCareUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInfoMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.customer.Device.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.Device} returns this
 */
proto.customer.Device.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string address = 2;
 * @return {string}
 */
proto.customer.Device.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.Device} returns this
 */
proto.customer.Device.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string log_browser_url = 3;
 * @return {string}
 */
proto.customer.Device.prototype.getLogBrowserUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.Device} returns this
 */
proto.customer.Device.prototype.setLogBrowserUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string milon_care_url = 4;
 * @return {string}
 */
proto.customer.Device.prototype.getMilonCareUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.Device} returns this
 */
proto.customer.Device.prototype.setMilonCareUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * map<string, string> info = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.customer.Device.prototype.getInfoMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.customer.Device} returns this
 */
proto.customer.Device.prototype.clearInfoMap = function() {
  this.getInfoMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.DeviceType.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.DeviceType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.DeviceType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.DeviceType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.DeviceType}
 */
proto.customer.DeviceType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.DeviceType;
  return proto.customer.DeviceType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.DeviceType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.DeviceType}
 */
proto.customer.DeviceType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.DeviceType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.DeviceType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.DeviceType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.DeviceType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.customer.DeviceType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.DeviceType} returns this
 */
proto.customer.DeviceType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.customer.DeviceType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.DeviceType} returns this
 */
proto.customer.DeviceType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.Server.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.Server.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.Server} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.Server.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hostname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tag: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    os: jspb.Message.getFieldWithDefault(msg, 5, ""),
    isOnline: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    vpnStatus: jspb.Message.getFieldWithDefault(msg, 7, 0),
    vpnIpv4: jspb.Message.getFieldWithDefault(msg, 8, ""),
    vpnIpv6: jspb.Message.getFieldWithDefault(msg, 9, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    upSince: (f = msg.getUpSince()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    connectedSince: (f = msg.getConnectedSince()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastSeenAt: (f = msg.getLastSeenAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deletedAt: (f = msg.getDeletedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.Server}
 */
proto.customer.Server.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.Server;
  return proto.customer.Server.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.Server} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.Server}
 */
proto.customer.Server.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOs(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOnline(value);
      break;
    case 7:
      var value = /** @type {!proto.server.VpnStatus} */ (reader.readEnum());
      msg.setVpnStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setVpnIpv4(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setVpnIpv6(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpSince(value);
      break;
    case 18:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setConnectedSince(value);
      break;
    case 19:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSeenAt(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeletedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.Server.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.Server.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.Server} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.Server.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getHostname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOs();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIsOnline();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getVpnStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getVpnIpv4();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getVpnIpv6();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpSince();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getConnectedSince();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastSeenAt();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeletedAt();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.customer.Server.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.Server} returns this
 */
proto.customer.Server.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string hostname = 2;
 * @return {string}
 */
proto.customer.Server.prototype.getHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.Server} returns this
 */
proto.customer.Server.prototype.setHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tag = 3;
 * @return {string}
 */
proto.customer.Server.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.Server} returns this
 */
proto.customer.Server.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.customer.Server.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.Server} returns this
 */
proto.customer.Server.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string os = 5;
 * @return {string}
 */
proto.customer.Server.prototype.getOs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.Server} returns this
 */
proto.customer.Server.prototype.setOs = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool is_online = 6;
 * @return {boolean}
 */
proto.customer.Server.prototype.getIsOnline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.customer.Server} returns this
 */
proto.customer.Server.prototype.setIsOnline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional server.VpnStatus vpn_status = 7;
 * @return {!proto.server.VpnStatus}
 */
proto.customer.Server.prototype.getVpnStatus = function() {
  return /** @type {!proto.server.VpnStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.server.VpnStatus} value
 * @return {!proto.customer.Server} returns this
 */
proto.customer.Server.prototype.setVpnStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string vpn_ipv4 = 8;
 * @return {string}
 */
proto.customer.Server.prototype.getVpnIpv4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.Server} returns this
 */
proto.customer.Server.prototype.setVpnIpv4 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string vpn_ipv6 = 9;
 * @return {string}
 */
proto.customer.Server.prototype.getVpnIpv6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.Server} returns this
 */
proto.customer.Server.prototype.setVpnIpv6 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.customer.Server.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.customer.Server} returns this
*/
proto.customer.Server.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.Server} returns this
 */
proto.customer.Server.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.Server.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.customer.Server.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.customer.Server} returns this
*/
proto.customer.Server.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.Server} returns this
 */
proto.customer.Server.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.Server.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Timestamp up_since = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.customer.Server.prototype.getUpSince = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.customer.Server} returns this
*/
proto.customer.Server.prototype.setUpSince = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.Server} returns this
 */
proto.customer.Server.prototype.clearUpSince = function() {
  return this.setUpSince(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.Server.prototype.hasUpSince = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.Timestamp connected_since = 18;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.customer.Server.prototype.getConnectedSince = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 18));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.customer.Server} returns this
*/
proto.customer.Server.prototype.setConnectedSince = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.Server} returns this
 */
proto.customer.Server.prototype.clearConnectedSince = function() {
  return this.setConnectedSince(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.Server.prototype.hasConnectedSince = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.Timestamp last_seen_at = 19;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.customer.Server.prototype.getLastSeenAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 19));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.customer.Server} returns this
*/
proto.customer.Server.prototype.setLastSeenAt = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.Server} returns this
 */
proto.customer.Server.prototype.clearLastSeenAt = function() {
  return this.setLastSeenAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.Server.prototype.hasLastSeenAt = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.Timestamp deleted_at = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.customer.Server.prototype.getDeletedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.customer.Server} returns this
*/
proto.customer.Server.prototype.setDeletedAt = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.Server} returns this
 */
proto.customer.Server.prototype.clearDeletedAt = function() {
  return this.setDeletedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.Server.prototype.hasDeletedAt = function() {
  return jspb.Message.getField(this, 20) != null;
};


goog.object.extend(exports, proto.customer);
