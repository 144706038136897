export const serversFiltersDefault = {
    sortBy: 'hostname',
    order: 'asc',
    serverType: 'active',
    search: ['', []],
    page: 1,
    rows: 150,
};

const serversFiltersReducer = (state = serversFiltersDefault, action) => {
    switch (action.type){
        case 'SET_SERVERS_FILTERS':
            return {
                ...state,
                sortBy: action.sortBy,
                order: action.order,
                serverType: action.serverType,
                search: action.search,
                page: action.page,
                rows: action.rows
            };
        default:
            return state;
    }
};

export default serversFiltersReducer;