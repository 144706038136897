import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// own components
import ActionButtonComp from '../general/ActionButtonComp';
import CustomerTable from './CustomerTable';
// style
import { withStyles } from '@material-ui/core/styles';
import pageStyle from '../../styles/jss/components/general/pageStyle';

const CustomerDetails = (props) => {

    const handleExternalLink = (value) => (evt) => {
        window.open(value, '_blank');
    };

    const btnLink = (key, value) => {
        return <ActionButtonComp
            active={true}
            action={handleExternalLink(key)}
            title={value}
        />
    };

    const { classes, _customer } = props;
    const { item } = _customer;

    return <div>
        <CustomerTable />
        <div className={classes.btnContainer}>
            {item.crm_url ? btnLink(item.crm_url, 'Sales tool') : ''}
            {item.log_browser_url ? btnLink(item.log_browser_url, 'Log Browser') : ''}
            {item.milon_care_url ? btnLink(item.milon_care_url, 'milon CARE') : ''}
            {item.map_url ? btnLink(item.map_url, 'Map') : ''}
        </div>
    </div>;
};

const mapStateToProps = (state) => {
    return {
        _customer: state.customer
    };
};

export default compose(
    withStyles(pageStyle),
    connect(mapStateToProps, null),
)(CustomerDetails);
