import {
    infoBoxShadow,
    orangeColor,
    blueColor,
    grayColor
} from "../../material-base";

const style = theme => {
    return {
        chip: {
            ...infoBoxShadow,
            margin: `0 ${theme.spacing.unit/2}px ${theme.spacing.unit/2}px 0`,
            '&.tblCellItem': {
                height: 28
            },
            '&.tag':{
                height: 26,
                margin: `0 ${theme.spacing.unit/2}px 0 0`,
                boxShadow: 'none',
                '& span':{
                    textTransform: 'uppercase',
                    fontWeight: 500,
                    fontSize: 14,
                    padding: `0 ${theme.spacing.unit}px`,
                    color: theme.palette.common.white
                },
                '&.fg': {
                    backgroundColor: theme.palette.secondary.dark,
                },
                '&.kz': {
                    backgroundColor: theme.palette.common.black,
                },
                '&.az': {
                    backgroundColor: orangeColor,
                },
                '&.ka': {
                    backgroundColor: blueColor
                },
                '&.qf': {
                    backgroundColor: theme.palette.primary.dark,

                }
            }
        }
    }
};

export default style;
