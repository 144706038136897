import React from 'react';
// Material UI components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowIcon from '@material-ui/icons/ArrowUpward';
import Checkbox from '@material-ui/core/Checkbox';
// own components
import MultipleStatus from '../general/MultipleStatus';
// style
import { withStyles } from '@material-ui/core/styles';
import tableStyle from '../../styles/jss/components/general/tableStyle';

const columnData = [
    { id: 'number',         label: 'Number',            sort: true,                   },
    { id: 'milon_care_id',  label: 'milon CARE ID',     sort: true,                   },
    { id: 'name',           label: 'Name',              sort: true,                   },
    { id: 'contact_person', label: 'Contact person',    sort: true,                   },
    { id: 'address',        label: 'Address',           sort: true,                   },
//    { id: 'servers',        label: 'Studio servers',    sort: false, onlyActive: true },
//    { id: 'deviceVersion',  label: 'Devices versions',  sort: false, onlyActive: true },
];

const CustomersTable = ({
    classes,
    data,
    actionSort,
    actionSelectAll,
    actionSelectCustomer,
    sortBy,
    order,
    selected,
    history,
    deleted
}) => {

    const isItemSelected = id => {
        return selected.indexOf(id) !== -1;
    };

    const handleCustomerDetails = id => evt => {
        if(!deleted){
            history.push(`/customers/${id}`);
        }
    };

    return <Table className={`${classes.mainTable} CustomersTable`}>
        <TableHead>
            <TableRow className="withAction">
                {deleted && <TableCell>
                    <Checkbox
                        className="checkBox"
                        indeterminate={selected.length > 0 && selected.length < data.length}
                        checked={selected.length === data.length}
                        onChange={actionSelectAll}
                    />
                </TableCell>}
                {columnData.map(column => {
                    if(column.onlyActive && deleted) return;
                    return <TableCell
                        key={column.id}
                        onClick={actionSort(column.sort ? column.id : '')}
                        className={!column.sort ? 'noSort' : ''}
                    >
                        <div className="headerCellContent">
                            <span className={sortBy === column.id ? 'sortColumn': ''}>{column.label}</span>
                            {column.sort && <ArrowIcon
                                className={`sortIcon ${sortBy === column.id ? 'active' : ''} ${sortBy === column.id ? order : ''}`}
                             />}
                        </div>
                    </TableCell>
                })}
            </TableRow>
        </TableHead>
        <TableBody>
            {data.map(n => {
                const isSelected = isItemSelected(n.id);
                return <TableRow
                    key={n.id}
                    onClick={handleCustomerDetails(n.id)}
                    className={`${deleted && isSelected ? 'selected' : ''}
                                ${!deleted ? "withAction" : ''}`}
                >
                    {deleted && <TableCell
                        onClick={actionSelectCustomer(n.id)}
                        className={"withAction"}
                    >
                        <Checkbox
                            className="checkBox"
                            checked={isSelected}
                        />
                    </TableCell>}
                    {columnData.map((item, index) => {
                        if(deleted && item.onlyActive) return;

                        let value = n[item.id];
                        if(item.id === 'servers'){
                            value = value.length > 0 ? <MultipleStatus data={value} /> : '---';
                        }else if(item.id === 'address'){
                            const country = n.country;
                            const city = n.city || n.zip ? `${n.zip} ${n.city}` : '';
                            const street = n.street ? n.street : '';
                            value = <span>
                                {country}
                                {city || street ? <br/> : ''}
                                {city}
                                {city && street ? <br/> : ''}
                                {street}
                            </span>;
                        }else{
                            value = value ? value : '---';
                        }
                        return <TableCell
                            key={index}
                        >{value}</TableCell>
                    })}
                </TableRow>
            })}
        </TableBody>
    </Table>;
};

export default withStyles(tableStyle)(CustomersTable);
