import {
    successBoxShadow,
    infoBoxShadow
} from "../../material-base";

const style = theme => {
    return {
        inputSearch: {
            cursor: 'pointer',
            width: 260,
            '& .searchBtn': {
                ...infoBoxShadow,
                backgroundColor: '#e0e0e0',
                width: 40,
                height: 40,
                position: 'relative',
                right: 20,
            },
            '&.active .searchBtn, &:hover .searchBtn': {
                ...successBoxShadow,
                backgroundColor: theme.palette.secondary.main,
                '& svg': {
                    color: theme.palette.common.white
                },
            },
            '&:hover div::before, & div::after, div::before': {
                borderBottom: 'none'
            },
            '& input': {
                minWidth: 'calc(100% - 40px)',
                borderBottom: `1px solid #ced4da`,
                color: theme.palette.common.white,
                cursor: 'pointer',
                paddingRight: theme.spacing.unit*3
            },
            '&.dark input': {
                color: theme.palette.common.black
            },
            '& input[type=search]::-webkit-search-cancel-button': {
                display: 'none',
            },
            '&.active input, &:hover input': {
                borderBottom: `1px solid ${theme.palette.secondary.main}`
            }
        },
        cssFocused: {
            borderBottom: 'none',
            '& input': {
                borderBottom: `1px solid ${theme.palette.secondary.main}`,
            },
            '& .searchBtn': {
                ...successBoxShadow,
                backgroundColor: theme.palette.secondary.main,
                '& svg': {
                    color: theme.palette.common.white
                },
            }
        },
    }
};

export default style;
