export const logsFiltersDefault = {
    sortBy: 't',
    order: 'asc',
    search: ['', []],
    page: 1,
    rows: 150,

};

const logsFiltersReducer = (state = logsFiltersDefault, action) => {
    switch (action.type){
        case 'SET_LOGS_FILTERS':
            return {
                ...state,
                sortBy: action.sortBy,
                order: action.order,
                search: action.search,
                page: action.page,
                rows: action.rows,
            };
        default:
            return state;
    }
};

export default logsFiltersReducer;