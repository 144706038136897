// source: server.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.server.AssignToCustomerServerReq', null, global);
goog.exportSymbol('proto.server.AssignToCustomerServerResp', null, global);
goog.exportSymbol('proto.server.BrowserRestartServerReq', null, global);
goog.exportSymbol('proto.server.BrowserRestartServerResp', null, global);
goog.exportSymbol('proto.server.CommandExecServerReq', null, global);
goog.exportSymbol('proto.server.CommandExecServerResp', null, global);
goog.exportSymbol('proto.server.DeleteServerReq', null, global);
goog.exportSymbol('proto.server.DeleteServerResp', null, global);
goog.exportSymbol('proto.server.GetMqttSettingsReq', null, global);
goog.exportSymbol('proto.server.GetMqttSettingsResp', null, global);
goog.exportSymbol('proto.server.GetServerReq', null, global);
goog.exportSymbol('proto.server.GetServerResp', null, global);
goog.exportSymbol('proto.server.GetServerSensorResp', null, global);
goog.exportSymbol('proto.server.GetSoftwareUpdateServerResp', null, global);
goog.exportSymbol('proto.server.GetTimeReq', null, global);
goog.exportSymbol('proto.server.GetTimeResp', null, global);
goog.exportSymbol('proto.server.GetUpdateVersionReq', null, global);
goog.exportSymbol('proto.server.GetUpdateVersionResp', null, global);
goog.exportSymbol('proto.server.ListServerCustomerResp', null, global);
goog.exportSymbol('proto.server.ListServerResp', null, global);
goog.exportSymbol('proto.server.ListServersCustomerFiltersReq', null, global);
goog.exportSymbol('proto.server.ListServersReq', null, global);
goog.exportSymbol('proto.server.ListServersResp', null, global);
goog.exportSymbol('proto.server.LogFileRequestServerReq', null, global);
goog.exportSymbol('proto.server.LogFileRequestServerResp', null, global);
goog.exportSymbol('proto.server.LoginReq', null, global);
goog.exportSymbol('proto.server.LoginResp', null, global);
goog.exportSymbol('proto.server.NetScanResultHostServerResp', null, global);
goog.exportSymbol('proto.server.NetScanResultServerReq', null, global);
goog.exportSymbol('proto.server.NetScanResultServerResp', null, global);
goog.exportSymbol('proto.server.NetScanServerReq', null, global);
goog.exportSymbol('proto.server.NetScanServerResp', null, global);
goog.exportSymbol('proto.server.RegisterReq', null, global);
goog.exportSymbol('proto.server.RegisterResp', null, global);
goog.exportSymbol('proto.server.ScreenshotResultServerResp', null, global);
goog.exportSymbol('proto.server.ScreenshotServerResp', null, global);
goog.exportSymbol('proto.server.ScreenshotsResultServerReq', null, global);
goog.exportSymbol('proto.server.ScreenshotsResultServerResp', null, global);
goog.exportSymbol('proto.server.ScreenshotsServerReq', null, global);
goog.exportSymbol('proto.server.ScreenshotsServerResp', null, global);
goog.exportSymbol('proto.server.ServerConnectedStatus', null, global);
goog.exportSymbol('proto.server.ServerRestartServerReq', null, global);
goog.exportSymbol('proto.server.ServerRestartServerResp', null, global);
goog.exportSymbol('proto.server.ServerStatus', null, global);
goog.exportSymbol('proto.server.SoftwareUpdateServerReq', null, global);
goog.exportSymbol('proto.server.SoftwareUpdateServerResp', null, global);
goog.exportSymbol('proto.server.SoftwareUpdateVersionReq', null, global);
goog.exportSymbol('proto.server.SoftwareUpdateVersionServerResp', null, global);
goog.exportSymbol('proto.server.SoftwareUpdateVersionsServerReq', null, global);
goog.exportSymbol('proto.server.SoftwareUpdateVersionsServerResp', null, global);
goog.exportSymbol('proto.server.SoftwareUpdateVersionsSoftwareServerResp', null, global);
goog.exportSymbol('proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp', null, global);
goog.exportSymbol('proto.server.UndeleteServerReq', null, global);
goog.exportSymbol('proto.server.UndeleteServerResp', null, global);
goog.exportSymbol('proto.server.UpdateServerReq', null, global);
goog.exportSymbol('proto.server.UpdateServerResp', null, global);
goog.exportSymbol('proto.server.VpnLoginReq', null, global);
goog.exportSymbol('proto.server.VpnLoginResp', null, global);
goog.exportSymbol('proto.server.VpnStartServerReq', null, global);
goog.exportSymbol('proto.server.VpnStartServerResp', null, global);
goog.exportSymbol('proto.server.VpnStatus', null, global);
goog.exportSymbol('proto.server.VpnStopServerReq', null, global);
goog.exportSymbol('proto.server.VpnStopServerResp', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.RegisterReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.RegisterReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.RegisterReq.displayName = 'proto.server.RegisterReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.RegisterResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.RegisterResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.RegisterResp.displayName = 'proto.server.RegisterResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.LoginReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.LoginReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.LoginReq.displayName = 'proto.server.LoginReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.LoginResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.LoginResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.LoginResp.displayName = 'proto.server.LoginResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.VpnLoginReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.VpnLoginReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.VpnLoginReq.displayName = 'proto.server.VpnLoginReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.VpnLoginResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.VpnLoginResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.VpnLoginResp.displayName = 'proto.server.VpnLoginResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.GetMqttSettingsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.GetMqttSettingsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.GetMqttSettingsReq.displayName = 'proto.server.GetMqttSettingsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.GetMqttSettingsResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.GetMqttSettingsResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.GetMqttSettingsResp.displayName = 'proto.server.GetMqttSettingsResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.GetTimeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.GetTimeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.GetTimeReq.displayName = 'proto.server.GetTimeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.GetTimeResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.GetTimeResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.GetTimeResp.displayName = 'proto.server.GetTimeResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.GetUpdateVersionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.GetUpdateVersionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.GetUpdateVersionReq.displayName = 'proto.server.GetUpdateVersionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.GetUpdateVersionResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.GetUpdateVersionResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.GetUpdateVersionResp.displayName = 'proto.server.GetUpdateVersionResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.ListServersReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.ListServersReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.ListServersReq.displayName = 'proto.server.ListServersReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.ListServersCustomerFiltersReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.ListServersCustomerFiltersReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.ListServersCustomerFiltersReq.displayName = 'proto.server.ListServersCustomerFiltersReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.ListServersResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.ListServersResp.repeatedFields_, null);
};
goog.inherits(proto.server.ListServersResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.ListServersResp.displayName = 'proto.server.ListServersResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.ListServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.ListServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.ListServerResp.displayName = 'proto.server.ListServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.ListServerCustomerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.ListServerCustomerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.ListServerCustomerResp.displayName = 'proto.server.ListServerCustomerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.GetServerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.GetServerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.GetServerReq.displayName = 'proto.server.GetServerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.GetServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.GetServerResp.repeatedFields_, null);
};
goog.inherits(proto.server.GetServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.GetServerResp.displayName = 'proto.server.GetServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.GetServerSensorResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.GetServerSensorResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.GetServerSensorResp.displayName = 'proto.server.GetServerSensorResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.GetSoftwareUpdateServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.GetSoftwareUpdateServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.GetSoftwareUpdateServerResp.displayName = 'proto.server.GetSoftwareUpdateServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.UpdateServerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.UpdateServerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.UpdateServerReq.displayName = 'proto.server.UpdateServerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.UpdateServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.UpdateServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.UpdateServerResp.displayName = 'proto.server.UpdateServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.AssignToCustomerServerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.AssignToCustomerServerReq.repeatedFields_, null);
};
goog.inherits(proto.server.AssignToCustomerServerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.AssignToCustomerServerReq.displayName = 'proto.server.AssignToCustomerServerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.AssignToCustomerServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.AssignToCustomerServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.AssignToCustomerServerResp.displayName = 'proto.server.AssignToCustomerServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.DeleteServerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.DeleteServerReq.repeatedFields_, null);
};
goog.inherits(proto.server.DeleteServerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.DeleteServerReq.displayName = 'proto.server.DeleteServerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.DeleteServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.DeleteServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.DeleteServerResp.displayName = 'proto.server.DeleteServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.UndeleteServerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.UndeleteServerReq.repeatedFields_, null);
};
goog.inherits(proto.server.UndeleteServerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.UndeleteServerReq.displayName = 'proto.server.UndeleteServerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.UndeleteServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.UndeleteServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.UndeleteServerResp.displayName = 'proto.server.UndeleteServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.VpnStartServerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.VpnStartServerReq.repeatedFields_, null);
};
goog.inherits(proto.server.VpnStartServerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.VpnStartServerReq.displayName = 'proto.server.VpnStartServerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.VpnStartServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.VpnStartServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.VpnStartServerResp.displayName = 'proto.server.VpnStartServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.VpnStopServerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.VpnStopServerReq.repeatedFields_, null);
};
goog.inherits(proto.server.VpnStopServerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.VpnStopServerReq.displayName = 'proto.server.VpnStopServerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.VpnStopServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.VpnStopServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.VpnStopServerResp.displayName = 'proto.server.VpnStopServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.BrowserRestartServerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.BrowserRestartServerReq.repeatedFields_, null);
};
goog.inherits(proto.server.BrowserRestartServerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.BrowserRestartServerReq.displayName = 'proto.server.BrowserRestartServerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.BrowserRestartServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.BrowserRestartServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.BrowserRestartServerResp.displayName = 'proto.server.BrowserRestartServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.ServerRestartServerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.ServerRestartServerReq.repeatedFields_, null);
};
goog.inherits(proto.server.ServerRestartServerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.ServerRestartServerReq.displayName = 'proto.server.ServerRestartServerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.ServerRestartServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.ServerRestartServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.ServerRestartServerResp.displayName = 'proto.server.ServerRestartServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.SoftwareUpdateVersionsServerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.SoftwareUpdateVersionsServerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.SoftwareUpdateVersionsServerReq.displayName = 'proto.server.SoftwareUpdateVersionsServerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.SoftwareUpdateVersionsServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.SoftwareUpdateVersionsServerResp.repeatedFields_, null);
};
goog.inherits(proto.server.SoftwareUpdateVersionsServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.SoftwareUpdateVersionsServerResp.displayName = 'proto.server.SoftwareUpdateVersionsServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.SoftwareUpdateVersionsSoftwareServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.SoftwareUpdateVersionsSoftwareServerResp.repeatedFields_, null);
};
goog.inherits(proto.server.SoftwareUpdateVersionsSoftwareServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.SoftwareUpdateVersionsSoftwareServerResp.displayName = 'proto.server.SoftwareUpdateVersionsSoftwareServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.displayName = 'proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.SoftwareUpdateServerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.SoftwareUpdateServerReq.repeatedFields_, null);
};
goog.inherits(proto.server.SoftwareUpdateServerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.SoftwareUpdateServerReq.displayName = 'proto.server.SoftwareUpdateServerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.SoftwareUpdateVersionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.SoftwareUpdateVersionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.SoftwareUpdateVersionReq.displayName = 'proto.server.SoftwareUpdateVersionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.SoftwareUpdateServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.SoftwareUpdateServerResp.repeatedFields_, null);
};
goog.inherits(proto.server.SoftwareUpdateServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.SoftwareUpdateServerResp.displayName = 'proto.server.SoftwareUpdateServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.SoftwareUpdateVersionServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.SoftwareUpdateVersionServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.SoftwareUpdateVersionServerResp.displayName = 'proto.server.SoftwareUpdateVersionServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.ScreenshotsServerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.ScreenshotsServerReq.repeatedFields_, null);
};
goog.inherits(proto.server.ScreenshotsServerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.ScreenshotsServerReq.displayName = 'proto.server.ScreenshotsServerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.ScreenshotsServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.ScreenshotsServerResp.repeatedFields_, null);
};
goog.inherits(proto.server.ScreenshotsServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.ScreenshotsServerResp.displayName = 'proto.server.ScreenshotsServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.ScreenshotServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.ScreenshotServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.ScreenshotServerResp.displayName = 'proto.server.ScreenshotServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.ScreenshotsResultServerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.ScreenshotsResultServerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.ScreenshotsResultServerReq.displayName = 'proto.server.ScreenshotsResultServerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.ScreenshotsResultServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.ScreenshotsResultServerResp.repeatedFields_, null);
};
goog.inherits(proto.server.ScreenshotsResultServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.ScreenshotsResultServerResp.displayName = 'proto.server.ScreenshotsResultServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.ScreenshotResultServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.ScreenshotResultServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.ScreenshotResultServerResp.displayName = 'proto.server.ScreenshotResultServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.NetScanServerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.NetScanServerReq.repeatedFields_, null);
};
goog.inherits(proto.server.NetScanServerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.NetScanServerReq.displayName = 'proto.server.NetScanServerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.NetScanServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.NetScanServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.NetScanServerResp.displayName = 'proto.server.NetScanServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.NetScanResultServerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.NetScanResultServerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.NetScanResultServerReq.displayName = 'proto.server.NetScanResultServerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.NetScanResultServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.NetScanResultServerResp.repeatedFields_, null);
};
goog.inherits(proto.server.NetScanResultServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.NetScanResultServerResp.displayName = 'proto.server.NetScanResultServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.NetScanResultHostServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.NetScanResultHostServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.NetScanResultHostServerResp.displayName = 'proto.server.NetScanResultHostServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.LogFileRequestServerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.LogFileRequestServerReq.repeatedFields_, null);
};
goog.inherits(proto.server.LogFileRequestServerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.LogFileRequestServerReq.displayName = 'proto.server.LogFileRequestServerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.LogFileRequestServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.LogFileRequestServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.LogFileRequestServerResp.displayName = 'proto.server.LogFileRequestServerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.CommandExecServerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.server.CommandExecServerReq.repeatedFields_, null);
};
goog.inherits(proto.server.CommandExecServerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.CommandExecServerReq.displayName = 'proto.server.CommandExecServerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.server.CommandExecServerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.server.CommandExecServerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.server.CommandExecServerResp.displayName = 'proto.server.CommandExecServerResp';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.RegisterReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.RegisterReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.RegisterReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.RegisterReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    secret: jspb.Message.getFieldWithDefault(msg, 1, ""),
    certificateRequest: msg.getCertificateRequest_asB64(),
    hostname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    browserUrl1: jspb.Message.getFieldWithDefault(msg, 5, ""),
    browserUrl2: jspb.Message.getFieldWithDefault(msg, 6, ""),
    browserUrl3: jspb.Message.getFieldWithDefault(msg, 7, ""),
    browserUrl4: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.RegisterReq}
 */
proto.server.RegisterReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.RegisterReq;
  return proto.server.RegisterReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.RegisterReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.RegisterReq}
 */
proto.server.RegisterReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecret(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCertificateRequest(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrowserUrl1(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrowserUrl2(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrowserUrl3(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrowserUrl4(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.RegisterReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.RegisterReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.RegisterReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.RegisterReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSecret();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCertificateRequest_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getHostname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBrowserUrl1();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBrowserUrl2();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBrowserUrl3();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBrowserUrl4();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string secret = 1;
 * @return {string}
 */
proto.server.RegisterReq.prototype.getSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.RegisterReq} returns this
 */
proto.server.RegisterReq.prototype.setSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes certificate_request = 2;
 * @return {string}
 */
proto.server.RegisterReq.prototype.getCertificateRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes certificate_request = 2;
 * This is a type-conversion wrapper around `getCertificateRequest()`
 * @return {string}
 */
proto.server.RegisterReq.prototype.getCertificateRequest_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCertificateRequest()));
};


/**
 * optional bytes certificate_request = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCertificateRequest()`
 * @return {!Uint8Array}
 */
proto.server.RegisterReq.prototype.getCertificateRequest_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCertificateRequest()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.server.RegisterReq} returns this
 */
proto.server.RegisterReq.prototype.setCertificateRequest = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string hostname = 3;
 * @return {string}
 */
proto.server.RegisterReq.prototype.getHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.RegisterReq} returns this
 */
proto.server.RegisterReq.prototype.setHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.server.RegisterReq.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.RegisterReq} returns this
 */
proto.server.RegisterReq.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string browser_url1 = 5;
 * @return {string}
 */
proto.server.RegisterReq.prototype.getBrowserUrl1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.RegisterReq} returns this
 */
proto.server.RegisterReq.prototype.setBrowserUrl1 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string browser_url2 = 6;
 * @return {string}
 */
proto.server.RegisterReq.prototype.getBrowserUrl2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.RegisterReq} returns this
 */
proto.server.RegisterReq.prototype.setBrowserUrl2 = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string browser_url3 = 7;
 * @return {string}
 */
proto.server.RegisterReq.prototype.getBrowserUrl3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.RegisterReq} returns this
 */
proto.server.RegisterReq.prototype.setBrowserUrl3 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string browser_url4 = 8;
 * @return {string}
 */
proto.server.RegisterReq.prototype.getBrowserUrl4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.RegisterReq} returns this
 */
proto.server.RegisterReq.prototype.setBrowserUrl4 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.RegisterResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.RegisterResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.RegisterResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.RegisterResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    login: jspb.Message.getFieldWithDefault(msg, 1, ""),
    secret: jspb.Message.getFieldWithDefault(msg, 2, ""),
    certificateAuthority: msg.getCertificateAuthority_asB64(),
    certificate: msg.getCertificate_asB64(),
    vpnSecret: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.RegisterResp}
 */
proto.server.RegisterResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.RegisterResp;
  return proto.server.RegisterResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.RegisterResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.RegisterResp}
 */
proto.server.RegisterResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecret(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCertificateAuthority(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCertificate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVpnSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.RegisterResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.RegisterResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.RegisterResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.RegisterResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSecret();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCertificateAuthority_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getCertificate_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getVpnSecret();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string login = 1;
 * @return {string}
 */
proto.server.RegisterResp.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.RegisterResp} returns this
 */
proto.server.RegisterResp.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secret = 2;
 * @return {string}
 */
proto.server.RegisterResp.prototype.getSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.RegisterResp} returns this
 */
proto.server.RegisterResp.prototype.setSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes certificate_authority = 3;
 * @return {string}
 */
proto.server.RegisterResp.prototype.getCertificateAuthority = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes certificate_authority = 3;
 * This is a type-conversion wrapper around `getCertificateAuthority()`
 * @return {string}
 */
proto.server.RegisterResp.prototype.getCertificateAuthority_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCertificateAuthority()));
};


/**
 * optional bytes certificate_authority = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCertificateAuthority()`
 * @return {!Uint8Array}
 */
proto.server.RegisterResp.prototype.getCertificateAuthority_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCertificateAuthority()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.server.RegisterResp} returns this
 */
proto.server.RegisterResp.prototype.setCertificateAuthority = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bytes certificate = 4;
 * @return {string}
 */
proto.server.RegisterResp.prototype.getCertificate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes certificate = 4;
 * This is a type-conversion wrapper around `getCertificate()`
 * @return {string}
 */
proto.server.RegisterResp.prototype.getCertificate_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCertificate()));
};


/**
 * optional bytes certificate = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCertificate()`
 * @return {!Uint8Array}
 */
proto.server.RegisterResp.prototype.getCertificate_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCertificate()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.server.RegisterResp} returns this
 */
proto.server.RegisterResp.prototype.setCertificate = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional string vpn_secret = 5;
 * @return {string}
 */
proto.server.RegisterResp.prototype.getVpnSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.RegisterResp} returns this
 */
proto.server.RegisterResp.prototype.setVpnSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.LoginReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.LoginReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.LoginReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.LoginReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    login: jspb.Message.getFieldWithDefault(msg, 1, ""),
    secret: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.LoginReq}
 */
proto.server.LoginReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.LoginReq;
  return proto.server.LoginReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.LoginReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.LoginReq}
 */
proto.server.LoginReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.LoginReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.LoginReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.LoginReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.LoginReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSecret();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string login = 1;
 * @return {string}
 */
proto.server.LoginReq.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.LoginReq} returns this
 */
proto.server.LoginReq.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secret = 2;
 * @return {string}
 */
proto.server.LoginReq.prototype.getSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.LoginReq} returns this
 */
proto.server.LoginReq.prototype.setSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.LoginResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.LoginResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.LoginResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.LoginResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expiresAt: (f = msg.getExpiresAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.LoginResp}
 */
proto.server.LoginResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.LoginResp;
  return proto.server.LoginResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.LoginResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.LoginResp}
 */
proto.server.LoginResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionToken(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.LoginResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.LoginResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.LoginResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.LoginResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string session_token = 1;
 * @return {string}
 */
proto.server.LoginResp.prototype.getSessionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.LoginResp} returns this
 */
proto.server.LoginResp.prototype.setSessionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp expires_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.LoginResp.prototype.getExpiresAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.LoginResp} returns this
*/
proto.server.LoginResp.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.LoginResp} returns this
 */
proto.server.LoginResp.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.LoginResp.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.VpnLoginReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.VpnLoginReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.VpnLoginReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.VpnLoginReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    login: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vpnSecret: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.VpnLoginReq}
 */
proto.server.VpnLoginReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.VpnLoginReq;
  return proto.server.VpnLoginReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.VpnLoginReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.VpnLoginReq}
 */
proto.server.VpnLoginReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVpnSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.VpnLoginReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.VpnLoginReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.VpnLoginReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.VpnLoginReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVpnSecret();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string login = 1;
 * @return {string}
 */
proto.server.VpnLoginReq.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.VpnLoginReq} returns this
 */
proto.server.VpnLoginReq.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vpn_secret = 2;
 * @return {string}
 */
proto.server.VpnLoginReq.prototype.getVpnSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.VpnLoginReq} returns this
 */
proto.server.VpnLoginReq.prototype.setVpnSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.VpnLoginResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.VpnLoginResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.VpnLoginResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.VpnLoginResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.VpnLoginResp}
 */
proto.server.VpnLoginResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.VpnLoginResp;
  return proto.server.VpnLoginResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.VpnLoginResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.VpnLoginResp}
 */
proto.server.VpnLoginResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.VpnLoginResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.VpnLoginResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.VpnLoginResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.VpnLoginResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.server.VpnLoginResp.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.VpnLoginResp} returns this
 */
proto.server.VpnLoginResp.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.GetMqttSettingsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.GetMqttSettingsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.GetMqttSettingsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetMqttSettingsReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.GetMqttSettingsReq}
 */
proto.server.GetMqttSettingsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.GetMqttSettingsReq;
  return proto.server.GetMqttSettingsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.GetMqttSettingsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.GetMqttSettingsReq}
 */
proto.server.GetMqttSettingsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.GetMqttSettingsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.GetMqttSettingsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.GetMqttSettingsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetMqttSettingsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.GetMqttSettingsResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.GetMqttSettingsResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.GetMqttSettingsResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetMqttSettingsResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    serverId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    addr: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    password: jspb.Message.getFieldWithDefault(msg, 4, ""),
    channelIn: jspb.Message.getFieldWithDefault(msg, 5, ""),
    channelOut: jspb.Message.getFieldWithDefault(msg, 6, ""),
    expiresAt: (f = msg.getExpiresAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.GetMqttSettingsResp}
 */
proto.server.GetMqttSettingsResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.GetMqttSettingsResp;
  return proto.server.GetMqttSettingsResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.GetMqttSettingsResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.GetMqttSettingsResp}
 */
proto.server.GetMqttSettingsResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setServerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddr(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelIn(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelOut(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.GetMqttSettingsResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.GetMqttSettingsResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.GetMqttSettingsResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetMqttSettingsResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServerId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAddr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getChannelIn();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getChannelOut();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 server_id = 1;
 * @return {number}
 */
proto.server.GetMqttSettingsResp.prototype.getServerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetMqttSettingsResp} returns this
 */
proto.server.GetMqttSettingsResp.prototype.setServerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string addr = 2;
 * @return {string}
 */
proto.server.GetMqttSettingsResp.prototype.getAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetMqttSettingsResp} returns this
 */
proto.server.GetMqttSettingsResp.prototype.setAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.server.GetMqttSettingsResp.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetMqttSettingsResp} returns this
 */
proto.server.GetMqttSettingsResp.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string password = 4;
 * @return {string}
 */
proto.server.GetMqttSettingsResp.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetMqttSettingsResp} returns this
 */
proto.server.GetMqttSettingsResp.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string channel_in = 5;
 * @return {string}
 */
proto.server.GetMqttSettingsResp.prototype.getChannelIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetMqttSettingsResp} returns this
 */
proto.server.GetMqttSettingsResp.prototype.setChannelIn = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string channel_out = 6;
 * @return {string}
 */
proto.server.GetMqttSettingsResp.prototype.getChannelOut = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetMqttSettingsResp} returns this
 */
proto.server.GetMqttSettingsResp.prototype.setChannelOut = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp expires_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.GetMqttSettingsResp.prototype.getExpiresAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.GetMqttSettingsResp} returns this
*/
proto.server.GetMqttSettingsResp.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.GetMqttSettingsResp} returns this
 */
proto.server.GetMqttSettingsResp.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.GetMqttSettingsResp.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.GetTimeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.GetTimeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.GetTimeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetTimeReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.GetTimeReq}
 */
proto.server.GetTimeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.GetTimeReq;
  return proto.server.GetTimeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.GetTimeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.GetTimeReq}
 */
proto.server.GetTimeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.GetTimeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.GetTimeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.GetTimeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetTimeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.GetTimeResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.GetTimeResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.GetTimeResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetTimeResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.GetTimeResp}
 */
proto.server.GetTimeResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.GetTimeResp;
  return proto.server.GetTimeResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.GetTimeResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.GetTimeResp}
 */
proto.server.GetTimeResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.GetTimeResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.GetTimeResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.GetTimeResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetTimeResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.GetTimeResp.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.GetTimeResp} returns this
*/
proto.server.GetTimeResp.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.GetTimeResp} returns this
 */
proto.server.GetTimeResp.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.GetTimeResp.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.GetUpdateVersionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.GetUpdateVersionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.GetUpdateVersionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetUpdateVersionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    login: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hostname: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.GetUpdateVersionReq}
 */
proto.server.GetUpdateVersionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.GetUpdateVersionReq;
  return proto.server.GetUpdateVersionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.GetUpdateVersionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.GetUpdateVersionReq}
 */
proto.server.GetUpdateVersionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.GetUpdateVersionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.GetUpdateVersionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.GetUpdateVersionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetUpdateVersionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHostname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string login = 1;
 * @return {string}
 */
proto.server.GetUpdateVersionReq.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetUpdateVersionReq} returns this
 */
proto.server.GetUpdateVersionReq.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hostname = 2;
 * @return {string}
 */
proto.server.GetUpdateVersionReq.prototype.getHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetUpdateVersionReq} returns this
 */
proto.server.GetUpdateVersionReq.prototype.setHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.GetUpdateVersionResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.GetUpdateVersionResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.GetUpdateVersionResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetUpdateVersionResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.GetUpdateVersionResp}
 */
proto.server.GetUpdateVersionResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.GetUpdateVersionResp;
  return proto.server.GetUpdateVersionResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.GetUpdateVersionResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.GetUpdateVersionResp}
 */
proto.server.GetUpdateVersionResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.GetUpdateVersionResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.GetUpdateVersionResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.GetUpdateVersionResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetUpdateVersionResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string version = 1;
 * @return {string}
 */
proto.server.GetUpdateVersionResp.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetUpdateVersionResp} returns this
 */
proto.server.GetUpdateVersionResp.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.ListServersReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.ListServersReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.ListServersReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ListServersReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemsPerPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sortQuery: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    query: jspb.Message.getFieldWithDefault(msg, 6, ""),
    customer: (f = msg.getCustomer()) && proto.server.ListServersCustomerFiltersReq.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    id: jspb.Message.getFieldWithDefault(msg, 9, 0),
    hostname: jspb.Message.getFieldWithDefault(msg, 10, ""),
    tag: jspb.Message.getFieldWithDefault(msg, 11, ""),
    type: jspb.Message.getFieldWithDefault(msg, 12, ""),
    lanMac: jspb.Message.getFieldWithDefault(msg, 13, ""),
    hwVendor: jspb.Message.getFieldWithDefault(msg, 14, ""),
    hwProduct: jspb.Message.getFieldWithDefault(msg, 15, ""),
    hwSerial: jspb.Message.getFieldWithDefault(msg, 16, ""),
    vpnStatus: jspb.Message.getFieldWithDefault(msg, 17, 0),
    isOnline: jspb.Message.getFieldWithDefault(msg, 18, 0),
    notes: jspb.Message.getFieldWithDefault(msg, 19, ""),
    os: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.ListServersReq}
 */
proto.server.ListServersReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.ListServersReq;
  return proto.server.ListServersReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.ListServersReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.ListServersReq}
 */
proto.server.ListServersReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItemsPerPage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortQuery(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 7:
      var value = new proto.server.ListServersCustomerFiltersReq;
      reader.readMessage(value,proto.server.ListServersCustomerFiltersReq.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    case 8:
      var value = /** @type {!proto.server.ServerStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostname(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanMac(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setHwVendor(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setHwProduct(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setHwSerial(value);
      break;
    case 17:
      var value = /** @type {!proto.server.VpnStatus} */ (reader.readEnum());
      msg.setVpnStatus(value);
      break;
    case 18:
      var value = /** @type {!proto.server.ServerConnectedStatus} */ (reader.readEnum());
      msg.setIsOnline(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setOs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.ListServersReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.ListServersReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.ListServersReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ListServersReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getItemsPerPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSortQuery();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.server.ListServersCustomerFiltersReq.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getHostname();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLanMac();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getHwVendor();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getHwProduct();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getHwSerial();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getVpnStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getIsOnline();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getOs();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional int32 page_no = 1;
 * @return {number}
 */
proto.server.ListServersReq.prototype.getPageNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.setPageNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 items_per_page = 2;
 * @return {number}
 */
proto.server.ListServersReq.prototype.getItemsPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.setItemsPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string sort_query = 3;
 * @return {string}
 */
proto.server.ListServersReq.prototype.getSortQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.setSortQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool deleted = 4;
 * @return {boolean}
 */
proto.server.ListServersReq.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string query = 6;
 * @return {string}
 */
proto.server.ListServersReq.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional ListServersCustomerFiltersReq customer = 7;
 * @return {?proto.server.ListServersCustomerFiltersReq}
 */
proto.server.ListServersReq.prototype.getCustomer = function() {
  return /** @type{?proto.server.ListServersCustomerFiltersReq} */ (
    jspb.Message.getWrapperField(this, proto.server.ListServersCustomerFiltersReq, 7));
};


/**
 * @param {?proto.server.ListServersCustomerFiltersReq|undefined} value
 * @return {!proto.server.ListServersReq} returns this
*/
proto.server.ListServersReq.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ListServersReq.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ServerStatus status = 8;
 * @return {!proto.server.ServerStatus}
 */
proto.server.ListServersReq.prototype.getStatus = function() {
  return /** @type {!proto.server.ServerStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.server.ServerStatus} value
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int32 id = 9;
 * @return {number}
 */
proto.server.ListServersReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string hostname = 10;
 * @return {string}
 */
proto.server.ListServersReq.prototype.getHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.setHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string tag = 11;
 * @return {string}
 */
proto.server.ListServersReq.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string type = 12;
 * @return {string}
 */
proto.server.ListServersReq.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string lan_mac = 13;
 * @return {string}
 */
proto.server.ListServersReq.prototype.getLanMac = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.setLanMac = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string hw_vendor = 14;
 * @return {string}
 */
proto.server.ListServersReq.prototype.getHwVendor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.setHwVendor = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string hw_product = 15;
 * @return {string}
 */
proto.server.ListServersReq.prototype.getHwProduct = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.setHwProduct = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string hw_serial = 16;
 * @return {string}
 */
proto.server.ListServersReq.prototype.getHwSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.setHwSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional VpnStatus vpn_status = 17;
 * @return {!proto.server.VpnStatus}
 */
proto.server.ListServersReq.prototype.getVpnStatus = function() {
  return /** @type {!proto.server.VpnStatus} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.server.VpnStatus} value
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.setVpnStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional ServerConnectedStatus is_online = 18;
 * @return {!proto.server.ServerConnectedStatus}
 */
proto.server.ListServersReq.prototype.getIsOnline = function() {
  return /** @type {!proto.server.ServerConnectedStatus} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.server.ServerConnectedStatus} value
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.setIsOnline = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional string notes = 19;
 * @return {string}
 */
proto.server.ListServersReq.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string os = 20;
 * @return {string}
 */
proto.server.ListServersReq.prototype.getOs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersReq} returns this
 */
proto.server.ListServersReq.prototype.setOs = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.ListServersCustomerFiltersReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.ListServersCustomerFiltersReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.ListServersCustomerFiltersReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ListServersCustomerFiltersReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    number: jspb.Message.getFieldWithDefault(msg, 2, 0),
    milonCareId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contactPerson: jspb.Message.getFieldWithDefault(msg, 5, ""),
    street: jspb.Message.getFieldWithDefault(msg, 6, ""),
    zip: jspb.Message.getFieldWithDefault(msg, 7, ""),
    city: jspb.Message.getFieldWithDefault(msg, 8, ""),
    country: jspb.Message.getFieldWithDefault(msg, 9, ""),
    telephone: jspb.Message.getFieldWithDefault(msg, 10, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 11, ""),
    email: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.ListServersCustomerFiltersReq}
 */
proto.server.ListServersCustomerFiltersReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.ListServersCustomerFiltersReq;
  return proto.server.ListServersCustomerFiltersReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.ListServersCustomerFiltersReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.ListServersCustomerFiltersReq}
 */
proto.server.ListServersCustomerFiltersReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMilonCareId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPerson(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setZip(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelephone(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.ListServersCustomerFiltersReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.ListServersCustomerFiltersReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.ListServersCustomerFiltersReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ListServersCustomerFiltersReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMilonCareId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContactPerson();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getZip();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTelephone();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.server.ListServersCustomerFiltersReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.ListServersCustomerFiltersReq} returns this
 */
proto.server.ListServersCustomerFiltersReq.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 number = 2;
 * @return {number}
 */
proto.server.ListServersCustomerFiltersReq.prototype.getNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.ListServersCustomerFiltersReq} returns this
 */
proto.server.ListServersCustomerFiltersReq.prototype.setNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 milon_care_id = 3;
 * @return {number}
 */
proto.server.ListServersCustomerFiltersReq.prototype.getMilonCareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.ListServersCustomerFiltersReq} returns this
 */
proto.server.ListServersCustomerFiltersReq.prototype.setMilonCareId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.server.ListServersCustomerFiltersReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersCustomerFiltersReq} returns this
 */
proto.server.ListServersCustomerFiltersReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string contact_person = 5;
 * @return {string}
 */
proto.server.ListServersCustomerFiltersReq.prototype.getContactPerson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersCustomerFiltersReq} returns this
 */
proto.server.ListServersCustomerFiltersReq.prototype.setContactPerson = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string street = 6;
 * @return {string}
 */
proto.server.ListServersCustomerFiltersReq.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersCustomerFiltersReq} returns this
 */
proto.server.ListServersCustomerFiltersReq.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string zip = 7;
 * @return {string}
 */
proto.server.ListServersCustomerFiltersReq.prototype.getZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersCustomerFiltersReq} returns this
 */
proto.server.ListServersCustomerFiltersReq.prototype.setZip = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string city = 8;
 * @return {string}
 */
proto.server.ListServersCustomerFiltersReq.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersCustomerFiltersReq} returns this
 */
proto.server.ListServersCustomerFiltersReq.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string country = 9;
 * @return {string}
 */
proto.server.ListServersCustomerFiltersReq.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersCustomerFiltersReq} returns this
 */
proto.server.ListServersCustomerFiltersReq.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string telephone = 10;
 * @return {string}
 */
proto.server.ListServersCustomerFiltersReq.prototype.getTelephone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersCustomerFiltersReq} returns this
 */
proto.server.ListServersCustomerFiltersReq.prototype.setTelephone = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string mobile = 11;
 * @return {string}
 */
proto.server.ListServersCustomerFiltersReq.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersCustomerFiltersReq} returns this
 */
proto.server.ListServersCustomerFiltersReq.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string email = 12;
 * @return {string}
 */
proto.server.ListServersCustomerFiltersReq.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServersCustomerFiltersReq} returns this
 */
proto.server.ListServersCustomerFiltersReq.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.ListServersResp.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.ListServersResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.ListServersResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.ListServersResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ListServersResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.server.ListServerResp.toObject, includeInstance),
    itemCount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.ListServersResp}
 */
proto.server.ListServersResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.ListServersResp;
  return proto.server.ListServersResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.ListServersResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.ListServersResp}
 */
proto.server.ListServersResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCount(value);
      break;
    case 3:
      var value = new proto.server.ListServerResp;
      reader.readMessage(value,proto.server.ListServerResp.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItemCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.ListServersResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.ListServersResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.ListServersResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ListServersResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageNo();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.server.ListServerResp.serializeBinaryToWriter
    );
  }
  f = message.getItemCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 page_no = 1;
 * @return {number}
 */
proto.server.ListServersResp.prototype.getPageNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.ListServersResp} returns this
 */
proto.server.ListServersResp.prototype.setPageNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_count = 2;
 * @return {number}
 */
proto.server.ListServersResp.prototype.getPageCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.ListServersResp} returns this
 */
proto.server.ListServersResp.prototype.setPageCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated ListServerResp results = 3;
 * @return {!Array<!proto.server.ListServerResp>}
 */
proto.server.ListServersResp.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.server.ListServerResp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.server.ListServerResp, 3));
};


/**
 * @param {!Array<!proto.server.ListServerResp>} value
 * @return {!proto.server.ListServersResp} returns this
*/
proto.server.ListServersResp.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.server.ListServerResp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.server.ListServerResp}
 */
proto.server.ListServersResp.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.server.ListServerResp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.ListServersResp} returns this
 */
proto.server.ListServersResp.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};


/**
 * optional int32 item_count = 4;
 * @return {number}
 */
proto.server.ListServersResp.prototype.getItemCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.ListServersResp} returns this
 */
proto.server.ListServersResp.prototype.setItemCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.ListServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.ListServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.ListServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ListServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    customer: (f = msg.getCustomer()) && proto.server.ListServerCustomerResp.toObject(includeInstance, f),
    hostname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tag: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, ""),
    os: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isOnline: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    lanMac: jspb.Message.getFieldWithDefault(msg, 8, ""),
    hwVendor: jspb.Message.getFieldWithDefault(msg, 9, ""),
    hwProduct: jspb.Message.getFieldWithDefault(msg, 10, ""),
    hwSerial: jspb.Message.getFieldWithDefault(msg, 11, ""),
    vpnStatus: jspb.Message.getFieldWithDefault(msg, 12, 0),
    vpnIpv4: jspb.Message.getFieldWithDefault(msg, 13, ""),
    vpnIpv6: jspb.Message.getFieldWithDefault(msg, 14, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    upSince: (f = msg.getUpSince()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    connectedSince: (f = msg.getConnectedSince()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastSeenAt: (f = msg.getLastSeenAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deletedAt: (f = msg.getDeletedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.ListServerResp}
 */
proto.server.ListServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.ListServerResp;
  return proto.server.ListServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.ListServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.ListServerResp}
 */
proto.server.ListServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.server.ListServerCustomerResp;
      reader.readMessage(value,proto.server.ListServerCustomerResp.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOs(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOnline(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanMac(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setHwVendor(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setHwProduct(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setHwSerial(value);
      break;
    case 12:
      var value = /** @type {!proto.server.VpnStatus} */ (reader.readEnum());
      msg.setVpnStatus(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setVpnIpv4(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setVpnIpv6(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpSince(value);
      break;
    case 18:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setConnectedSince(value);
      break;
    case 19:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSeenAt(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeletedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.ListServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.ListServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.ListServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ListServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.server.ListServerCustomerResp.serializeBinaryToWriter
    );
  }
  f = message.getHostname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOs();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsOnline();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getLanMac();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getHwVendor();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getHwProduct();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getHwSerial();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getVpnStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getVpnIpv4();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getVpnIpv6();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpSince();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getConnectedSince();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastSeenAt();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeletedAt();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.server.ListServerResp.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ListServerCustomerResp customer = 2;
 * @return {?proto.server.ListServerCustomerResp}
 */
proto.server.ListServerResp.prototype.getCustomer = function() {
  return /** @type{?proto.server.ListServerCustomerResp} */ (
    jspb.Message.getWrapperField(this, proto.server.ListServerCustomerResp, 2));
};


/**
 * @param {?proto.server.ListServerCustomerResp|undefined} value
 * @return {!proto.server.ListServerResp} returns this
*/
proto.server.ListServerResp.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ListServerResp.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string hostname = 3;
 * @return {string}
 */
proto.server.ListServerResp.prototype.getHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.setHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tag = 4;
 * @return {string}
 */
proto.server.ListServerResp.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.server.ListServerResp.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string os = 6;
 * @return {string}
 */
proto.server.ListServerResp.prototype.getOs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.setOs = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_online = 7;
 * @return {boolean}
 */
proto.server.ListServerResp.prototype.getIsOnline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.setIsOnline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string lan_mac = 8;
 * @return {string}
 */
proto.server.ListServerResp.prototype.getLanMac = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.setLanMac = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string hw_vendor = 9;
 * @return {string}
 */
proto.server.ListServerResp.prototype.getHwVendor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.setHwVendor = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string hw_product = 10;
 * @return {string}
 */
proto.server.ListServerResp.prototype.getHwProduct = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.setHwProduct = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string hw_serial = 11;
 * @return {string}
 */
proto.server.ListServerResp.prototype.getHwSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.setHwSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional VpnStatus vpn_status = 12;
 * @return {!proto.server.VpnStatus}
 */
proto.server.ListServerResp.prototype.getVpnStatus = function() {
  return /** @type {!proto.server.VpnStatus} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.server.VpnStatus} value
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.setVpnStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string vpn_ipv4 = 13;
 * @return {string}
 */
proto.server.ListServerResp.prototype.getVpnIpv4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.setVpnIpv4 = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string vpn_ipv6 = 14;
 * @return {string}
 */
proto.server.ListServerResp.prototype.getVpnIpv6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.setVpnIpv6 = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.ListServerResp.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.ListServerResp} returns this
*/
proto.server.ListServerResp.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ListServerResp.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.ListServerResp.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.ListServerResp} returns this
*/
proto.server.ListServerResp.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ListServerResp.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Timestamp up_since = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.ListServerResp.prototype.getUpSince = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.ListServerResp} returns this
*/
proto.server.ListServerResp.prototype.setUpSince = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.clearUpSince = function() {
  return this.setUpSince(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ListServerResp.prototype.hasUpSince = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.Timestamp connected_since = 18;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.ListServerResp.prototype.getConnectedSince = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 18));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.ListServerResp} returns this
*/
proto.server.ListServerResp.prototype.setConnectedSince = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.clearConnectedSince = function() {
  return this.setConnectedSince(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ListServerResp.prototype.hasConnectedSince = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.Timestamp last_seen_at = 19;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.ListServerResp.prototype.getLastSeenAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 19));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.ListServerResp} returns this
*/
proto.server.ListServerResp.prototype.setLastSeenAt = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.clearLastSeenAt = function() {
  return this.setLastSeenAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ListServerResp.prototype.hasLastSeenAt = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.Timestamp deleted_at = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.ListServerResp.prototype.getDeletedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.ListServerResp} returns this
*/
proto.server.ListServerResp.prototype.setDeletedAt = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ListServerResp} returns this
 */
proto.server.ListServerResp.prototype.clearDeletedAt = function() {
  return this.setDeletedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ListServerResp.prototype.hasDeletedAt = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.ListServerCustomerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.ListServerCustomerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.ListServerCustomerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ListServerCustomerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    number: jspb.Message.getFieldWithDefault(msg, 2, 0),
    milonCareId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contactPerson: jspb.Message.getFieldWithDefault(msg, 5, ""),
    street: jspb.Message.getFieldWithDefault(msg, 6, ""),
    zip: jspb.Message.getFieldWithDefault(msg, 7, ""),
    city: jspb.Message.getFieldWithDefault(msg, 8, ""),
    country: jspb.Message.getFieldWithDefault(msg, 9, ""),
    telephone: jspb.Message.getFieldWithDefault(msg, 10, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 11, ""),
    email: jspb.Message.getFieldWithDefault(msg, 12, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deletedAt: (f = msg.getDeletedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.ListServerCustomerResp}
 */
proto.server.ListServerCustomerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.ListServerCustomerResp;
  return proto.server.ListServerCustomerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.ListServerCustomerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.ListServerCustomerResp}
 */
proto.server.ListServerCustomerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMilonCareId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPerson(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setZip(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelephone(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeletedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.ListServerCustomerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.ListServerCustomerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.ListServerCustomerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ListServerCustomerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMilonCareId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContactPerson();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getZip();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTelephone();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeletedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.server.ListServerCustomerResp.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.ListServerCustomerResp} returns this
 */
proto.server.ListServerCustomerResp.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 number = 2;
 * @return {number}
 */
proto.server.ListServerCustomerResp.prototype.getNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.ListServerCustomerResp} returns this
 */
proto.server.ListServerCustomerResp.prototype.setNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 milon_care_id = 3;
 * @return {number}
 */
proto.server.ListServerCustomerResp.prototype.getMilonCareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.ListServerCustomerResp} returns this
 */
proto.server.ListServerCustomerResp.prototype.setMilonCareId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.server.ListServerCustomerResp.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerCustomerResp} returns this
 */
proto.server.ListServerCustomerResp.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string contact_person = 5;
 * @return {string}
 */
proto.server.ListServerCustomerResp.prototype.getContactPerson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerCustomerResp} returns this
 */
proto.server.ListServerCustomerResp.prototype.setContactPerson = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string street = 6;
 * @return {string}
 */
proto.server.ListServerCustomerResp.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerCustomerResp} returns this
 */
proto.server.ListServerCustomerResp.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string zip = 7;
 * @return {string}
 */
proto.server.ListServerCustomerResp.prototype.getZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerCustomerResp} returns this
 */
proto.server.ListServerCustomerResp.prototype.setZip = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string city = 8;
 * @return {string}
 */
proto.server.ListServerCustomerResp.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerCustomerResp} returns this
 */
proto.server.ListServerCustomerResp.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string country = 9;
 * @return {string}
 */
proto.server.ListServerCustomerResp.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerCustomerResp} returns this
 */
proto.server.ListServerCustomerResp.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string telephone = 10;
 * @return {string}
 */
proto.server.ListServerCustomerResp.prototype.getTelephone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerCustomerResp} returns this
 */
proto.server.ListServerCustomerResp.prototype.setTelephone = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string mobile = 11;
 * @return {string}
 */
proto.server.ListServerCustomerResp.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerCustomerResp} returns this
 */
proto.server.ListServerCustomerResp.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string email = 12;
 * @return {string}
 */
proto.server.ListServerCustomerResp.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ListServerCustomerResp} returns this
 */
proto.server.ListServerCustomerResp.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.ListServerCustomerResp.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.ListServerCustomerResp} returns this
*/
proto.server.ListServerCustomerResp.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ListServerCustomerResp} returns this
 */
proto.server.ListServerCustomerResp.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ListServerCustomerResp.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.ListServerCustomerResp.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.ListServerCustomerResp} returns this
*/
proto.server.ListServerCustomerResp.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ListServerCustomerResp} returns this
 */
proto.server.ListServerCustomerResp.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ListServerCustomerResp.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp deleted_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.ListServerCustomerResp.prototype.getDeletedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.ListServerCustomerResp} returns this
*/
proto.server.ListServerCustomerResp.prototype.setDeletedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ListServerCustomerResp} returns this
 */
proto.server.ListServerCustomerResp.prototype.clearDeletedAt = function() {
  return this.setDeletedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ListServerCustomerResp.prototype.hasDeletedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.GetServerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.GetServerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.GetServerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetServerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.GetServerReq}
 */
proto.server.GetServerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.GetServerReq;
  return proto.server.GetServerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.GetServerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.GetServerReq}
 */
proto.server.GetServerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.GetServerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.GetServerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.GetServerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetServerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.server.GetServerReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerReq} returns this
 */
proto.server.GetServerReq.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.GetServerResp.repeatedFields_ = [39,64];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.GetServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.GetServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.GetServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hostname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tag: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, ""),
    os: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isOnline: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    lanMac: jspb.Message.getFieldWithDefault(msg, 8, ""),
    lanIpv4: jspb.Message.getFieldWithDefault(msg, 9, ""),
    lanIpv6: jspb.Message.getFieldWithDefault(msg, 10, ""),
    wanIpv4: jspb.Message.getFieldWithDefault(msg, 11, ""),
    wanIpv6: jspb.Message.getFieldWithDefault(msg, 12, ""),
    hwVendor: jspb.Message.getFieldWithDefault(msg, 13, ""),
    hwProduct: jspb.Message.getFieldWithDefault(msg, 14, ""),
    hwSerial: jspb.Message.getFieldWithDefault(msg, 15, ""),
    vpnStatus: jspb.Message.getFieldWithDefault(msg, 16, 0),
    vpnIpv4: jspb.Message.getFieldWithDefault(msg, 17, ""),
    vpnIpv6: jspb.Message.getFieldWithDefault(msg, 18, ""),
    kernel: jspb.Message.getFieldWithDefault(msg, 19, ""),
    diskRootTotal: jspb.Message.getFieldWithDefault(msg, 20, 0),
    diskRootUsed: jspb.Message.getFieldWithDefault(msg, 21, 0),
    diskBootTotal: jspb.Message.getFieldWithDefault(msg, 22, 0),
    diskBootUsed: jspb.Message.getFieldWithDefault(msg, 23, 0),
    memoryTotal: jspb.Message.getFieldWithDefault(msg, 24, 0),
    memoryUsed: jspb.Message.getFieldWithDefault(msg, 25, 0),
    swapTotal: jspb.Message.getFieldWithDefault(msg, 26, 0),
    swapUsed: jspb.Message.getFieldWithDefault(msg, 27, 0),
    load1: jspb.Message.getFloatingPointFieldWithDefault(msg, 28, 0.0),
    load5: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0),
    load15: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    browserUrl1: jspb.Message.getFieldWithDefault(msg, 31, ""),
    browserUrl2: jspb.Message.getFieldWithDefault(msg, 32, ""),
    browserUrl3: jspb.Message.getFieldWithDefault(msg, 33, ""),
    browserUrl4: jspb.Message.getFieldWithDefault(msg, 34, ""),
    agentVersionCurrent: jspb.Message.getFieldWithDefault(msg, 35, ""),
    agentVersionShould: jspb.Message.getFieldWithDefault(msg, 36, ""),
    timeDelta: jspb.Message.getFieldWithDefault(msg, 37, 0),
    softwareUpdatesList: jspb.Message.toObjectList(msg.getSoftwareUpdatesList(),
    proto.server.GetSoftwareUpdateServerResp.toObject, includeInstance),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    upSince: (f = msg.getUpSince()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    connectedSince: (f = msg.getConnectedSince()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastSeenAt: (f = msg.getLastSeenAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deletedAt: (f = msg.getDeletedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    notes: jspb.Message.getFieldWithDefault(msg, 45, ""),
    documentationUrl: jspb.Message.getFieldWithDefault(msg, 46, ""),
    wanIpv4Ptr: jspb.Message.getFieldWithDefault(msg, 47, ""),
    wanIpv6Ptr: jspb.Message.getFieldWithDefault(msg, 48, ""),
    upsStatus: jspb.Message.getFieldWithDefault(msg, 49, ""),
    upsModel: jspb.Message.getFieldWithDefault(msg, 50, ""),
    upsSerialNumber: jspb.Message.getFieldWithDefault(msg, 51, ""),
    upsFirmware: jspb.Message.getFieldWithDefault(msg, 52, ""),
    upsMinimumTimeLeft: jspb.Message.getFieldWithDefault(msg, 53, 0),
    upsMinimumBatteryChargePercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 54, 0.0),
    upsLoadPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 55, 0.0),
    upsBatteryChargePercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 56, 0.0),
    upsXonBattery: (f = msg.getUpsXonBattery()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    upsXoffBattery: (f = msg.getUpsXoffBattery()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    upsTimeLeft: jspb.Message.getFieldWithDefault(msg, 59, 0),
    upsTimeOnBattery: jspb.Message.getFieldWithDefault(msg, 60, 0),
    upsInternalTemp: jspb.Message.getFloatingPointFieldWithDefault(msg, 61, 0.0),
    upsBatteryDate: (f = msg.getUpsBatteryDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    upsNumberTransfers: jspb.Message.getFieldWithDefault(msg, 63, 0),
    sensorsList: jspb.Message.toObjectList(msg.getSensorsList(),
    proto.server.GetServerSensorResp.toObject, includeInstance),
    ntpServers: jspb.Message.getFieldWithDefault(msg, 65, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.GetServerResp}
 */
proto.server.GetServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.GetServerResp;
  return proto.server.GetServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.GetServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.GetServerResp}
 */
proto.server.GetServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOs(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOnline(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanMac(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanIpv4(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanIpv6(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setWanIpv4(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setWanIpv6(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setHwVendor(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setHwProduct(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setHwSerial(value);
      break;
    case 16:
      var value = /** @type {!proto.server.VpnStatus} */ (reader.readEnum());
      msg.setVpnStatus(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setVpnIpv4(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setVpnIpv6(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setKernel(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDiskRootTotal(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDiskRootUsed(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDiskBootTotal(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDiskBootUsed(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMemoryTotal(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMemoryUsed(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSwapTotal(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSwapUsed(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLoad1(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLoad5(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLoad15(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrowserUrl1(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrowserUrl2(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrowserUrl3(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrowserUrl4(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentVersionCurrent(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentVersionShould(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeDelta(value);
      break;
    case 39:
      var value = new proto.server.GetSoftwareUpdateServerResp;
      reader.readMessage(value,proto.server.GetSoftwareUpdateServerResp.deserializeBinaryFromReader);
      msg.addSoftwareUpdates(value);
      break;
    case 40:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 41:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 42:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpSince(value);
      break;
    case 43:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setConnectedSince(value);
      break;
    case 44:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSeenAt(value);
      break;
    case 67:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeletedAt(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentationUrl(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setWanIpv4Ptr(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setWanIpv6Ptr(value);
      break;
    case 49:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpsStatus(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpsModel(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpsSerialNumber(value);
      break;
    case 52:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpsFirmware(value);
      break;
    case 53:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpsMinimumTimeLeft(value);
      break;
    case 54:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUpsMinimumBatteryChargePercent(value);
      break;
    case 55:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUpsLoadPercent(value);
      break;
    case 56:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUpsBatteryChargePercent(value);
      break;
    case 57:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpsXonBattery(value);
      break;
    case 58:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpsXoffBattery(value);
      break;
    case 59:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpsTimeLeft(value);
      break;
    case 60:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpsTimeOnBattery(value);
      break;
    case 61:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUpsInternalTemp(value);
      break;
    case 62:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpsBatteryDate(value);
      break;
    case 63:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpsNumberTransfers(value);
      break;
    case 64:
      var value = new proto.server.GetServerSensorResp;
      reader.readMessage(value,proto.server.GetServerSensorResp.deserializeBinaryFromReader);
      msg.addSensors(value);
      break;
    case 65:
      var value = /** @type {string} */ (reader.readString());
      msg.setNtpServers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.GetServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.GetServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.GetServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getHostname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOs();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsOnline();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getLanMac();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLanIpv4();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLanIpv6();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getWanIpv4();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getWanIpv6();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getHwVendor();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getHwProduct();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getHwSerial();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getVpnStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getVpnIpv4();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getVpnIpv6();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getKernel();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getDiskRootTotal();
  if (f !== 0) {
    writer.writeUint64(
      20,
      f
    );
  }
  f = message.getDiskRootUsed();
  if (f !== 0) {
    writer.writeUint64(
      21,
      f
    );
  }
  f = message.getDiskBootTotal();
  if (f !== 0) {
    writer.writeUint64(
      22,
      f
    );
  }
  f = message.getDiskBootUsed();
  if (f !== 0) {
    writer.writeUint64(
      23,
      f
    );
  }
  f = message.getMemoryTotal();
  if (f !== 0) {
    writer.writeUint64(
      24,
      f
    );
  }
  f = message.getMemoryUsed();
  if (f !== 0) {
    writer.writeUint64(
      25,
      f
    );
  }
  f = message.getSwapTotal();
  if (f !== 0) {
    writer.writeUint64(
      26,
      f
    );
  }
  f = message.getSwapUsed();
  if (f !== 0) {
    writer.writeUint64(
      27,
      f
    );
  }
  f = message.getLoad1();
  if (f !== 0.0) {
    writer.writeDouble(
      28,
      f
    );
  }
  f = message.getLoad5();
  if (f !== 0.0) {
    writer.writeDouble(
      29,
      f
    );
  }
  f = message.getLoad15();
  if (f !== 0.0) {
    writer.writeDouble(
      30,
      f
    );
  }
  f = message.getBrowserUrl1();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getBrowserUrl2();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getBrowserUrl3();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getBrowserUrl4();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getAgentVersionCurrent();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getAgentVersionShould();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getTimeDelta();
  if (f !== 0) {
    writer.writeInt64(
      37,
      f
    );
  }
  f = message.getSoftwareUpdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      39,
      f,
      proto.server.GetSoftwareUpdateServerResp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpSince();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getConnectedSince();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastSeenAt();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeletedAt();
  if (f != null) {
    writer.writeMessage(
      67,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getDocumentationUrl();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getWanIpv4Ptr();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
  f = message.getWanIpv6Ptr();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getUpsStatus();
  if (f.length > 0) {
    writer.writeString(
      49,
      f
    );
  }
  f = message.getUpsModel();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getUpsSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getUpsFirmware();
  if (f.length > 0) {
    writer.writeString(
      52,
      f
    );
  }
  f = message.getUpsMinimumTimeLeft();
  if (f !== 0) {
    writer.writeInt64(
      53,
      f
    );
  }
  f = message.getUpsMinimumBatteryChargePercent();
  if (f !== 0.0) {
    writer.writeDouble(
      54,
      f
    );
  }
  f = message.getUpsLoadPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      55,
      f
    );
  }
  f = message.getUpsBatteryChargePercent();
  if (f !== 0.0) {
    writer.writeDouble(
      56,
      f
    );
  }
  f = message.getUpsXonBattery();
  if (f != null) {
    writer.writeMessage(
      57,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpsXoffBattery();
  if (f != null) {
    writer.writeMessage(
      58,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpsTimeLeft();
  if (f !== 0) {
    writer.writeInt64(
      59,
      f
    );
  }
  f = message.getUpsTimeOnBattery();
  if (f !== 0) {
    writer.writeInt64(
      60,
      f
    );
  }
  f = message.getUpsInternalTemp();
  if (f !== 0.0) {
    writer.writeDouble(
      61,
      f
    );
  }
  f = message.getUpsBatteryDate();
  if (f != null) {
    writer.writeMessage(
      62,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpsNumberTransfers();
  if (f !== 0) {
    writer.writeInt32(
      63,
      f
    );
  }
  f = message.getSensorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      64,
      f,
      proto.server.GetServerSensorResp.serializeBinaryToWriter
    );
  }
  f = message.getNtpServers();
  if (f.length > 0) {
    writer.writeString(
      65,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 customer_id = 2;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string hostname = 3;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tag = 4;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string os = 6;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getOs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setOs = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_online = 7;
 * @return {boolean}
 */
proto.server.GetServerResp.prototype.getIsOnline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setIsOnline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string lan_mac = 8;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getLanMac = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setLanMac = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string lan_ipv4 = 9;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getLanIpv4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setLanIpv4 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string lan_ipv6 = 10;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getLanIpv6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setLanIpv6 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string wan_ipv4 = 11;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getWanIpv4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setWanIpv4 = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string wan_ipv6 = 12;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getWanIpv6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setWanIpv6 = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string hw_vendor = 13;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getHwVendor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setHwVendor = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string hw_product = 14;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getHwProduct = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setHwProduct = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string hw_serial = 15;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getHwSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setHwSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional VpnStatus vpn_status = 16;
 * @return {!proto.server.VpnStatus}
 */
proto.server.GetServerResp.prototype.getVpnStatus = function() {
  return /** @type {!proto.server.VpnStatus} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.server.VpnStatus} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setVpnStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional string vpn_ipv4 = 17;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getVpnIpv4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setVpnIpv4 = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string vpn_ipv6 = 18;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getVpnIpv6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setVpnIpv6 = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string kernel = 19;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getKernel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setKernel = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional uint64 disk_root_total = 20;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getDiskRootTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setDiskRootTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional uint64 disk_root_used = 21;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getDiskRootUsed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setDiskRootUsed = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional uint64 disk_boot_total = 22;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getDiskBootTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setDiskBootTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional uint64 disk_boot_used = 23;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getDiskBootUsed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setDiskBootUsed = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional uint64 memory_total = 24;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getMemoryTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setMemoryTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional uint64 memory_used = 25;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getMemoryUsed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setMemoryUsed = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional uint64 swap_total = 26;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getSwapTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setSwapTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional uint64 swap_used = 27;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getSwapUsed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setSwapUsed = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional double load1 = 28;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getLoad1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 28, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setLoad1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 28, value);
};


/**
 * optional double load5 = 29;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getLoad5 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setLoad5 = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional double load15 = 30;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getLoad15 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setLoad15 = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional string browser_url1 = 31;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getBrowserUrl1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setBrowserUrl1 = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string browser_url2 = 32;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getBrowserUrl2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setBrowserUrl2 = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string browser_url3 = 33;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getBrowserUrl3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setBrowserUrl3 = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string browser_url4 = 34;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getBrowserUrl4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setBrowserUrl4 = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string agent_version_current = 35;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getAgentVersionCurrent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setAgentVersionCurrent = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string agent_version_should = 36;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getAgentVersionShould = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setAgentVersionShould = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional int64 time_delta = 37;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getTimeDelta = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setTimeDelta = function(value) {
  return jspb.Message.setProto3IntField(this, 37, value);
};


/**
 * repeated GetSoftwareUpdateServerResp software_updates = 39;
 * @return {!Array<!proto.server.GetSoftwareUpdateServerResp>}
 */
proto.server.GetServerResp.prototype.getSoftwareUpdatesList = function() {
  return /** @type{!Array<!proto.server.GetSoftwareUpdateServerResp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.server.GetSoftwareUpdateServerResp, 39));
};


/**
 * @param {!Array<!proto.server.GetSoftwareUpdateServerResp>} value
 * @return {!proto.server.GetServerResp} returns this
*/
proto.server.GetServerResp.prototype.setSoftwareUpdatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 39, value);
};


/**
 * @param {!proto.server.GetSoftwareUpdateServerResp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.server.GetSoftwareUpdateServerResp}
 */
proto.server.GetServerResp.prototype.addSoftwareUpdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 39, opt_value, proto.server.GetSoftwareUpdateServerResp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.clearSoftwareUpdatesList = function() {
  return this.setSoftwareUpdatesList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 40;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.GetServerResp.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 40));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.GetServerResp} returns this
*/
proto.server.GetServerResp.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.GetServerResp.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 41;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.GetServerResp.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 41));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.GetServerResp} returns this
*/
proto.server.GetServerResp.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.GetServerResp.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional google.protobuf.Timestamp up_since = 42;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.GetServerResp.prototype.getUpSince = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 42));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.GetServerResp} returns this
*/
proto.server.GetServerResp.prototype.setUpSince = function(value) {
  return jspb.Message.setWrapperField(this, 42, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.clearUpSince = function() {
  return this.setUpSince(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.GetServerResp.prototype.hasUpSince = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional google.protobuf.Timestamp connected_since = 43;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.GetServerResp.prototype.getConnectedSince = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 43));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.GetServerResp} returns this
*/
proto.server.GetServerResp.prototype.setConnectedSince = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.clearConnectedSince = function() {
  return this.setConnectedSince(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.GetServerResp.prototype.hasConnectedSince = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional google.protobuf.Timestamp last_seen_at = 44;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.GetServerResp.prototype.getLastSeenAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 44));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.GetServerResp} returns this
*/
proto.server.GetServerResp.prototype.setLastSeenAt = function(value) {
  return jspb.Message.setWrapperField(this, 44, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.clearLastSeenAt = function() {
  return this.setLastSeenAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.GetServerResp.prototype.hasLastSeenAt = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional google.protobuf.Timestamp deleted_at = 67;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.GetServerResp.prototype.getDeletedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 67));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.GetServerResp} returns this
*/
proto.server.GetServerResp.prototype.setDeletedAt = function(value) {
  return jspb.Message.setWrapperField(this, 67, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.clearDeletedAt = function() {
  return this.setDeletedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.GetServerResp.prototype.hasDeletedAt = function() {
  return jspb.Message.getField(this, 67) != null;
};


/**
 * optional string notes = 45;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional string documentation_url = 46;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getDocumentationUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setDocumentationUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional string wan_ipv4_ptr = 47;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getWanIpv4Ptr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setWanIpv4Ptr = function(value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};


/**
 * optional string wan_ipv6_ptr = 48;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getWanIpv6Ptr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setWanIpv6Ptr = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional string ups_status = 49;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getUpsStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 49, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setUpsStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 49, value);
};


/**
 * optional string ups_model = 50;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getUpsModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setUpsModel = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string ups_serial_number = 51;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getUpsSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setUpsSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * optional string ups_firmware = 52;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getUpsFirmware = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 52, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setUpsFirmware = function(value) {
  return jspb.Message.setProto3StringField(this, 52, value);
};


/**
 * optional int64 ups_minimum_time_left = 53;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getUpsMinimumTimeLeft = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 53, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setUpsMinimumTimeLeft = function(value) {
  return jspb.Message.setProto3IntField(this, 53, value);
};


/**
 * optional double ups_minimum_battery_charge_percent = 54;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getUpsMinimumBatteryChargePercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 54, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setUpsMinimumBatteryChargePercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 54, value);
};


/**
 * optional double ups_load_percent = 55;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getUpsLoadPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 55, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setUpsLoadPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 55, value);
};


/**
 * optional double ups_battery_charge_percent = 56;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getUpsBatteryChargePercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 56, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setUpsBatteryChargePercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 56, value);
};


/**
 * optional google.protobuf.Timestamp ups_xon_battery = 57;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.GetServerResp.prototype.getUpsXonBattery = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 57));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.GetServerResp} returns this
*/
proto.server.GetServerResp.prototype.setUpsXonBattery = function(value) {
  return jspb.Message.setWrapperField(this, 57, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.clearUpsXonBattery = function() {
  return this.setUpsXonBattery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.GetServerResp.prototype.hasUpsXonBattery = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional google.protobuf.Timestamp ups_xoff_battery = 58;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.GetServerResp.prototype.getUpsXoffBattery = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 58));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.GetServerResp} returns this
*/
proto.server.GetServerResp.prototype.setUpsXoffBattery = function(value) {
  return jspb.Message.setWrapperField(this, 58, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.clearUpsXoffBattery = function() {
  return this.setUpsXoffBattery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.GetServerResp.prototype.hasUpsXoffBattery = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional int64 ups_time_left = 59;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getUpsTimeLeft = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 59, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setUpsTimeLeft = function(value) {
  return jspb.Message.setProto3IntField(this, 59, value);
};


/**
 * optional int64 ups_time_on_battery = 60;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getUpsTimeOnBattery = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 60, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setUpsTimeOnBattery = function(value) {
  return jspb.Message.setProto3IntField(this, 60, value);
};


/**
 * optional double ups_internal_temp = 61;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getUpsInternalTemp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 61, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setUpsInternalTemp = function(value) {
  return jspb.Message.setProto3FloatField(this, 61, value);
};


/**
 * optional google.protobuf.Timestamp ups_battery_date = 62;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.GetServerResp.prototype.getUpsBatteryDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 62));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.GetServerResp} returns this
*/
proto.server.GetServerResp.prototype.setUpsBatteryDate = function(value) {
  return jspb.Message.setWrapperField(this, 62, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.clearUpsBatteryDate = function() {
  return this.setUpsBatteryDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.GetServerResp.prototype.hasUpsBatteryDate = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional int32 ups_number_transfers = 63;
 * @return {number}
 */
proto.server.GetServerResp.prototype.getUpsNumberTransfers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 63, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setUpsNumberTransfers = function(value) {
  return jspb.Message.setProto3IntField(this, 63, value);
};


/**
 * repeated GetServerSensorResp sensors = 64;
 * @return {!Array<!proto.server.GetServerSensorResp>}
 */
proto.server.GetServerResp.prototype.getSensorsList = function() {
  return /** @type{!Array<!proto.server.GetServerSensorResp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.server.GetServerSensorResp, 64));
};


/**
 * @param {!Array<!proto.server.GetServerSensorResp>} value
 * @return {!proto.server.GetServerResp} returns this
*/
proto.server.GetServerResp.prototype.setSensorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 64, value);
};


/**
 * @param {!proto.server.GetServerSensorResp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.server.GetServerSensorResp}
 */
proto.server.GetServerResp.prototype.addSensors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 64, opt_value, proto.server.GetServerSensorResp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.clearSensorsList = function() {
  return this.setSensorsList([]);
};


/**
 * optional string ntp_servers = 65;
 * @return {string}
 */
proto.server.GetServerResp.prototype.getNtpServers = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 65, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerResp} returns this
 */
proto.server.GetServerResp.prototype.setNtpServers = function(value) {
  return jspb.Message.setProto3StringField(this, 65, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.GetServerSensorResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.GetServerSensorResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.GetServerSensorResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetServerSensorResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    device: jspb.Message.getFieldWithDefault(msg, 2, ""),
    group: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    label: jspb.Message.getFieldWithDefault(msg, 5, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.GetServerSensorResp}
 */
proto.server.GetServerSensorResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.GetServerSensorResp;
  return proto.server.GetServerSensorResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.GetServerSensorResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.GetServerSensorResp}
 */
proto.server.GetServerSensorResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevice(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroup(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.GetServerSensorResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.GetServerSensorResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.GetServerSensorResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetServerSensorResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevice();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroup();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.server.GetServerSensorResp.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerSensorResp} returns this
 */
proto.server.GetServerSensorResp.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string device = 2;
 * @return {string}
 */
proto.server.GetServerSensorResp.prototype.getDevice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerSensorResp} returns this
 */
proto.server.GetServerSensorResp.prototype.setDevice = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group = 3;
 * @return {string}
 */
proto.server.GetServerSensorResp.prototype.getGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerSensorResp} returns this
 */
proto.server.GetServerSensorResp.prototype.setGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.server.GetServerSensorResp.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerSensorResp} returns this
 */
proto.server.GetServerSensorResp.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string label = 5;
 * @return {string}
 */
proto.server.GetServerSensorResp.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetServerSensorResp} returns this
 */
proto.server.GetServerSensorResp.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double value = 6;
 * @return {number}
 */
proto.server.GetServerSensorResp.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetServerSensorResp} returns this
 */
proto.server.GetServerSensorResp.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.GetSoftwareUpdateServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.GetSoftwareUpdateServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.GetSoftwareUpdateServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetSoftwareUpdateServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    softwareId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    versionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.GetSoftwareUpdateServerResp}
 */
proto.server.GetSoftwareUpdateServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.GetSoftwareUpdateServerResp;
  return proto.server.GetSoftwareUpdateServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.GetSoftwareUpdateServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.GetSoftwareUpdateServerResp}
 */
proto.server.GetSoftwareUpdateServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSoftwareId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionId(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.GetSoftwareUpdateServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.GetSoftwareUpdateServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.GetSoftwareUpdateServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.GetSoftwareUpdateServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSoftwareId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVersionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.server.GetSoftwareUpdateServerResp.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.GetSoftwareUpdateServerResp} returns this
 */
proto.server.GetSoftwareUpdateServerResp.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string software_id = 2;
 * @return {string}
 */
proto.server.GetSoftwareUpdateServerResp.prototype.getSoftwareId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetSoftwareUpdateServerResp} returns this
 */
proto.server.GetSoftwareUpdateServerResp.prototype.setSoftwareId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string version_id = 3;
 * @return {string}
 */
proto.server.GetSoftwareUpdateServerResp.prototype.getVersionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.GetSoftwareUpdateServerResp} returns this
 */
proto.server.GetSoftwareUpdateServerResp.prototype.setVersionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.GetSoftwareUpdateServerResp.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.GetSoftwareUpdateServerResp} returns this
*/
proto.server.GetSoftwareUpdateServerResp.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.GetSoftwareUpdateServerResp} returns this
 */
proto.server.GetSoftwareUpdateServerResp.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.GetSoftwareUpdateServerResp.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.GetSoftwareUpdateServerResp.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.GetSoftwareUpdateServerResp} returns this
*/
proto.server.GetSoftwareUpdateServerResp.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.GetSoftwareUpdateServerResp} returns this
 */
proto.server.GetSoftwareUpdateServerResp.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.GetSoftwareUpdateServerResp.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.UpdateServerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.UpdateServerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.UpdateServerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UpdateServerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hostname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tag: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, ""),
    browserUrl1: jspb.Message.getFieldWithDefault(msg, 6, ""),
    browserUrl2: jspb.Message.getFieldWithDefault(msg, 7, ""),
    browserUrl3: jspb.Message.getFieldWithDefault(msg, 8, ""),
    browserUrl4: jspb.Message.getFieldWithDefault(msg, 9, ""),
    notes: jspb.Message.getFieldWithDefault(msg, 10, ""),
    documentationUrl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    ntpServers: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.UpdateServerReq}
 */
proto.server.UpdateServerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.UpdateServerReq;
  return proto.server.UpdateServerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.UpdateServerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.UpdateServerReq}
 */
proto.server.UpdateServerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrowserUrl1(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrowserUrl2(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrowserUrl3(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrowserUrl4(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentationUrl(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setNtpServers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.UpdateServerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.UpdateServerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.UpdateServerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UpdateServerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getHostname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBrowserUrl1();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBrowserUrl2();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBrowserUrl3();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBrowserUrl4();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDocumentationUrl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getNtpServers();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.server.UpdateServerReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.UpdateServerReq} returns this
 */
proto.server.UpdateServerReq.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string hostname = 3;
 * @return {string}
 */
proto.server.UpdateServerReq.prototype.getHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.UpdateServerReq} returns this
 */
proto.server.UpdateServerReq.prototype.setHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tag = 4;
 * @return {string}
 */
proto.server.UpdateServerReq.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.UpdateServerReq} returns this
 */
proto.server.UpdateServerReq.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.server.UpdateServerReq.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.UpdateServerReq} returns this
 */
proto.server.UpdateServerReq.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string browser_url1 = 6;
 * @return {string}
 */
proto.server.UpdateServerReq.prototype.getBrowserUrl1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.UpdateServerReq} returns this
 */
proto.server.UpdateServerReq.prototype.setBrowserUrl1 = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string browser_url2 = 7;
 * @return {string}
 */
proto.server.UpdateServerReq.prototype.getBrowserUrl2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.UpdateServerReq} returns this
 */
proto.server.UpdateServerReq.prototype.setBrowserUrl2 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string browser_url3 = 8;
 * @return {string}
 */
proto.server.UpdateServerReq.prototype.getBrowserUrl3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.UpdateServerReq} returns this
 */
proto.server.UpdateServerReq.prototype.setBrowserUrl3 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string browser_url4 = 9;
 * @return {string}
 */
proto.server.UpdateServerReq.prototype.getBrowserUrl4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.UpdateServerReq} returns this
 */
proto.server.UpdateServerReq.prototype.setBrowserUrl4 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string notes = 10;
 * @return {string}
 */
proto.server.UpdateServerReq.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.UpdateServerReq} returns this
 */
proto.server.UpdateServerReq.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string documentation_url = 11;
 * @return {string}
 */
proto.server.UpdateServerReq.prototype.getDocumentationUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.UpdateServerReq} returns this
 */
proto.server.UpdateServerReq.prototype.setDocumentationUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string ntp_servers = 12;
 * @return {string}
 */
proto.server.UpdateServerReq.prototype.getNtpServers = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.UpdateServerReq} returns this
 */
proto.server.UpdateServerReq.prototype.setNtpServers = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.UpdateServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.UpdateServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.UpdateServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UpdateServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.UpdateServerResp}
 */
proto.server.UpdateServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.UpdateServerResp;
  return proto.server.UpdateServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.UpdateServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.UpdateServerResp}
 */
proto.server.UpdateServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.UpdateServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.UpdateServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.UpdateServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UpdateServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.AssignToCustomerServerReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.AssignToCustomerServerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.AssignToCustomerServerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.AssignToCustomerServerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.AssignToCustomerServerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    idList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    customerId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.AssignToCustomerServerReq}
 */
proto.server.AssignToCustomerServerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.AssignToCustomerServerReq;
  return proto.server.AssignToCustomerServerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.AssignToCustomerServerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.AssignToCustomerServerReq}
 */
proto.server.AssignToCustomerServerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addId(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.AssignToCustomerServerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.AssignToCustomerServerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.AssignToCustomerServerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.AssignToCustomerServerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated int64 id = 1;
 * @return {!Array<number>}
 */
proto.server.AssignToCustomerServerReq.prototype.getIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.server.AssignToCustomerServerReq} returns this
 */
proto.server.AssignToCustomerServerReq.prototype.setIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.server.AssignToCustomerServerReq} returns this
 */
proto.server.AssignToCustomerServerReq.prototype.addId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.AssignToCustomerServerReq} returns this
 */
proto.server.AssignToCustomerServerReq.prototype.clearIdList = function() {
  return this.setIdList([]);
};


/**
 * optional int64 customer_id = 2;
 * @return {number}
 */
proto.server.AssignToCustomerServerReq.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.AssignToCustomerServerReq} returns this
 */
proto.server.AssignToCustomerServerReq.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.AssignToCustomerServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.AssignToCustomerServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.AssignToCustomerServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.AssignToCustomerServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.AssignToCustomerServerResp}
 */
proto.server.AssignToCustomerServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.AssignToCustomerServerResp;
  return proto.server.AssignToCustomerServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.AssignToCustomerServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.AssignToCustomerServerResp}
 */
proto.server.AssignToCustomerServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.AssignToCustomerServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.AssignToCustomerServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.AssignToCustomerServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.AssignToCustomerServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.DeleteServerReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.DeleteServerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.DeleteServerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.DeleteServerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.DeleteServerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    idList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.DeleteServerReq}
 */
proto.server.DeleteServerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.DeleteServerReq;
  return proto.server.DeleteServerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.DeleteServerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.DeleteServerReq}
 */
proto.server.DeleteServerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addId(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.DeleteServerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.DeleteServerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.DeleteServerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.DeleteServerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 id = 1;
 * @return {!Array<number>}
 */
proto.server.DeleteServerReq.prototype.getIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.server.DeleteServerReq} returns this
 */
proto.server.DeleteServerReq.prototype.setIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.server.DeleteServerReq} returns this
 */
proto.server.DeleteServerReq.prototype.addId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.DeleteServerReq} returns this
 */
proto.server.DeleteServerReq.prototype.clearIdList = function() {
  return this.setIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.DeleteServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.DeleteServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.DeleteServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.DeleteServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.DeleteServerResp}
 */
proto.server.DeleteServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.DeleteServerResp;
  return proto.server.DeleteServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.DeleteServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.DeleteServerResp}
 */
proto.server.DeleteServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.DeleteServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.DeleteServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.DeleteServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.DeleteServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.UndeleteServerReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.UndeleteServerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.UndeleteServerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.UndeleteServerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UndeleteServerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    idList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.UndeleteServerReq}
 */
proto.server.UndeleteServerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.UndeleteServerReq;
  return proto.server.UndeleteServerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.UndeleteServerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.UndeleteServerReq}
 */
proto.server.UndeleteServerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addId(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.UndeleteServerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.UndeleteServerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.UndeleteServerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UndeleteServerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 id = 1;
 * @return {!Array<number>}
 */
proto.server.UndeleteServerReq.prototype.getIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.server.UndeleteServerReq} returns this
 */
proto.server.UndeleteServerReq.prototype.setIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.server.UndeleteServerReq} returns this
 */
proto.server.UndeleteServerReq.prototype.addId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.UndeleteServerReq} returns this
 */
proto.server.UndeleteServerReq.prototype.clearIdList = function() {
  return this.setIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.UndeleteServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.UndeleteServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.UndeleteServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UndeleteServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.UndeleteServerResp}
 */
proto.server.UndeleteServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.UndeleteServerResp;
  return proto.server.UndeleteServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.UndeleteServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.UndeleteServerResp}
 */
proto.server.UndeleteServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.UndeleteServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.UndeleteServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.UndeleteServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.UndeleteServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.VpnStartServerReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.VpnStartServerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.VpnStartServerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.VpnStartServerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.VpnStartServerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    idList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    waitForResult: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.VpnStartServerReq}
 */
proto.server.VpnStartServerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.VpnStartServerReq;
  return proto.server.VpnStartServerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.VpnStartServerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.VpnStartServerReq}
 */
proto.server.VpnStartServerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addId(values[i]);
      }
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWaitForResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.VpnStartServerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.VpnStartServerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.VpnStartServerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.VpnStartServerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getWaitForResult();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated int64 id = 1;
 * @return {!Array<number>}
 */
proto.server.VpnStartServerReq.prototype.getIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.server.VpnStartServerReq} returns this
 */
proto.server.VpnStartServerReq.prototype.setIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.server.VpnStartServerReq} returns this
 */
proto.server.VpnStartServerReq.prototype.addId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.VpnStartServerReq} returns this
 */
proto.server.VpnStartServerReq.prototype.clearIdList = function() {
  return this.setIdList([]);
};


/**
 * optional bool wait_for_result = 2;
 * @return {boolean}
 */
proto.server.VpnStartServerReq.prototype.getWaitForResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.VpnStartServerReq} returns this
 */
proto.server.VpnStartServerReq.prototype.setWaitForResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.VpnStartServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.VpnStartServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.VpnStartServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.VpnStartServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    completed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    stateText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vpnStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    vpnIpv4: jspb.Message.getFieldWithDefault(msg, 4, ""),
    vpnIpv6: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.VpnStartServerResp}
 */
proto.server.VpnStartServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.VpnStartServerResp;
  return proto.server.VpnStartServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.VpnStartServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.VpnStartServerResp}
 */
proto.server.VpnStartServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateText(value);
      break;
    case 3:
      var value = /** @type {!proto.server.VpnStatus} */ (reader.readEnum());
      msg.setVpnStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVpnIpv4(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVpnIpv6(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.VpnStartServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.VpnStartServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.VpnStartServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.VpnStartServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getStateText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVpnStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getVpnIpv4();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVpnIpv6();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional bool completed = 1;
 * @return {boolean}
 */
proto.server.VpnStartServerResp.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.VpnStartServerResp} returns this
 */
proto.server.VpnStartServerResp.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string state_text = 2;
 * @return {string}
 */
proto.server.VpnStartServerResp.prototype.getStateText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.VpnStartServerResp} returns this
 */
proto.server.VpnStartServerResp.prototype.setStateText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional VpnStatus vpn_status = 3;
 * @return {!proto.server.VpnStatus}
 */
proto.server.VpnStartServerResp.prototype.getVpnStatus = function() {
  return /** @type {!proto.server.VpnStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.server.VpnStatus} value
 * @return {!proto.server.VpnStartServerResp} returns this
 */
proto.server.VpnStartServerResp.prototype.setVpnStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string vpn_ipv4 = 4;
 * @return {string}
 */
proto.server.VpnStartServerResp.prototype.getVpnIpv4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.VpnStartServerResp} returns this
 */
proto.server.VpnStartServerResp.prototype.setVpnIpv4 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string vpn_ipv6 = 5;
 * @return {string}
 */
proto.server.VpnStartServerResp.prototype.getVpnIpv6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.VpnStartServerResp} returns this
 */
proto.server.VpnStartServerResp.prototype.setVpnIpv6 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.VpnStopServerReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.VpnStopServerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.VpnStopServerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.VpnStopServerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.VpnStopServerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    idList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    waitForResult: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.VpnStopServerReq}
 */
proto.server.VpnStopServerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.VpnStopServerReq;
  return proto.server.VpnStopServerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.VpnStopServerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.VpnStopServerReq}
 */
proto.server.VpnStopServerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addId(values[i]);
      }
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWaitForResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.VpnStopServerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.VpnStopServerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.VpnStopServerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.VpnStopServerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getWaitForResult();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated int64 id = 1;
 * @return {!Array<number>}
 */
proto.server.VpnStopServerReq.prototype.getIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.server.VpnStopServerReq} returns this
 */
proto.server.VpnStopServerReq.prototype.setIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.server.VpnStopServerReq} returns this
 */
proto.server.VpnStopServerReq.prototype.addId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.VpnStopServerReq} returns this
 */
proto.server.VpnStopServerReq.prototype.clearIdList = function() {
  return this.setIdList([]);
};


/**
 * optional bool wait_for_result = 2;
 * @return {boolean}
 */
proto.server.VpnStopServerReq.prototype.getWaitForResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.VpnStopServerReq} returns this
 */
proto.server.VpnStopServerReq.prototype.setWaitForResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.VpnStopServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.VpnStopServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.VpnStopServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.VpnStopServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    completed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    stateText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vpnStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    vpnIpv4: jspb.Message.getFieldWithDefault(msg, 4, ""),
    vpnIpv6: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.VpnStopServerResp}
 */
proto.server.VpnStopServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.VpnStopServerResp;
  return proto.server.VpnStopServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.VpnStopServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.VpnStopServerResp}
 */
proto.server.VpnStopServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateText(value);
      break;
    case 3:
      var value = /** @type {!proto.server.VpnStatus} */ (reader.readEnum());
      msg.setVpnStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVpnIpv4(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVpnIpv6(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.VpnStopServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.VpnStopServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.VpnStopServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.VpnStopServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getStateText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVpnStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getVpnIpv4();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVpnIpv6();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional bool completed = 1;
 * @return {boolean}
 */
proto.server.VpnStopServerResp.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.VpnStopServerResp} returns this
 */
proto.server.VpnStopServerResp.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string state_text = 2;
 * @return {string}
 */
proto.server.VpnStopServerResp.prototype.getStateText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.VpnStopServerResp} returns this
 */
proto.server.VpnStopServerResp.prototype.setStateText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional VpnStatus vpn_status = 3;
 * @return {!proto.server.VpnStatus}
 */
proto.server.VpnStopServerResp.prototype.getVpnStatus = function() {
  return /** @type {!proto.server.VpnStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.server.VpnStatus} value
 * @return {!proto.server.VpnStopServerResp} returns this
 */
proto.server.VpnStopServerResp.prototype.setVpnStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string vpn_ipv4 = 4;
 * @return {string}
 */
proto.server.VpnStopServerResp.prototype.getVpnIpv4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.VpnStopServerResp} returns this
 */
proto.server.VpnStopServerResp.prototype.setVpnIpv4 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string vpn_ipv6 = 5;
 * @return {string}
 */
proto.server.VpnStopServerResp.prototype.getVpnIpv6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.VpnStopServerResp} returns this
 */
proto.server.VpnStopServerResp.prototype.setVpnIpv6 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.BrowserRestartServerReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.BrowserRestartServerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.BrowserRestartServerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.BrowserRestartServerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.BrowserRestartServerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    idList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    waitForResult: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.BrowserRestartServerReq}
 */
proto.server.BrowserRestartServerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.BrowserRestartServerReq;
  return proto.server.BrowserRestartServerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.BrowserRestartServerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.BrowserRestartServerReq}
 */
proto.server.BrowserRestartServerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addId(values[i]);
      }
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWaitForResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.BrowserRestartServerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.BrowserRestartServerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.BrowserRestartServerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.BrowserRestartServerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getWaitForResult();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated int64 id = 1;
 * @return {!Array<number>}
 */
proto.server.BrowserRestartServerReq.prototype.getIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.server.BrowserRestartServerReq} returns this
 */
proto.server.BrowserRestartServerReq.prototype.setIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.server.BrowserRestartServerReq} returns this
 */
proto.server.BrowserRestartServerReq.prototype.addId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.BrowserRestartServerReq} returns this
 */
proto.server.BrowserRestartServerReq.prototype.clearIdList = function() {
  return this.setIdList([]);
};


/**
 * optional bool wait_for_result = 2;
 * @return {boolean}
 */
proto.server.BrowserRestartServerReq.prototype.getWaitForResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.BrowserRestartServerReq} returns this
 */
proto.server.BrowserRestartServerReq.prototype.setWaitForResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.BrowserRestartServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.BrowserRestartServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.BrowserRestartServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.BrowserRestartServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    completed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    stateText: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.BrowserRestartServerResp}
 */
proto.server.BrowserRestartServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.BrowserRestartServerResp;
  return proto.server.BrowserRestartServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.BrowserRestartServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.BrowserRestartServerResp}
 */
proto.server.BrowserRestartServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.BrowserRestartServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.BrowserRestartServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.BrowserRestartServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.BrowserRestartServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getStateText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool completed = 1;
 * @return {boolean}
 */
proto.server.BrowserRestartServerResp.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.BrowserRestartServerResp} returns this
 */
proto.server.BrowserRestartServerResp.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string state_text = 2;
 * @return {string}
 */
proto.server.BrowserRestartServerResp.prototype.getStateText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.BrowserRestartServerResp} returns this
 */
proto.server.BrowserRestartServerResp.prototype.setStateText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.ServerRestartServerReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.ServerRestartServerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.ServerRestartServerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.ServerRestartServerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ServerRestartServerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    idList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    waitForResult: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.ServerRestartServerReq}
 */
proto.server.ServerRestartServerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.ServerRestartServerReq;
  return proto.server.ServerRestartServerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.ServerRestartServerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.ServerRestartServerReq}
 */
proto.server.ServerRestartServerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addId(values[i]);
      }
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWaitForResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.ServerRestartServerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.ServerRestartServerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.ServerRestartServerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ServerRestartServerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getWaitForResult();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated int64 id = 1;
 * @return {!Array<number>}
 */
proto.server.ServerRestartServerReq.prototype.getIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.server.ServerRestartServerReq} returns this
 */
proto.server.ServerRestartServerReq.prototype.setIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.server.ServerRestartServerReq} returns this
 */
proto.server.ServerRestartServerReq.prototype.addId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.ServerRestartServerReq} returns this
 */
proto.server.ServerRestartServerReq.prototype.clearIdList = function() {
  return this.setIdList([]);
};


/**
 * optional bool wait_for_result = 2;
 * @return {boolean}
 */
proto.server.ServerRestartServerReq.prototype.getWaitForResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.ServerRestartServerReq} returns this
 */
proto.server.ServerRestartServerReq.prototype.setWaitForResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.ServerRestartServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.ServerRestartServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.ServerRestartServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ServerRestartServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    completed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    stateText: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.ServerRestartServerResp}
 */
proto.server.ServerRestartServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.ServerRestartServerResp;
  return proto.server.ServerRestartServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.ServerRestartServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.ServerRestartServerResp}
 */
proto.server.ServerRestartServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.ServerRestartServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.ServerRestartServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.ServerRestartServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ServerRestartServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getStateText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool completed = 1;
 * @return {boolean}
 */
proto.server.ServerRestartServerResp.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.ServerRestartServerResp} returns this
 */
proto.server.ServerRestartServerResp.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string state_text = 2;
 * @return {string}
 */
proto.server.ServerRestartServerResp.prototype.getStateText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ServerRestartServerResp} returns this
 */
proto.server.ServerRestartServerResp.prototype.setStateText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.SoftwareUpdateVersionsServerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.SoftwareUpdateVersionsServerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.SoftwareUpdateVersionsServerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.SoftwareUpdateVersionsServerReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.SoftwareUpdateVersionsServerReq}
 */
proto.server.SoftwareUpdateVersionsServerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.SoftwareUpdateVersionsServerReq;
  return proto.server.SoftwareUpdateVersionsServerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.SoftwareUpdateVersionsServerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.SoftwareUpdateVersionsServerReq}
 */
proto.server.SoftwareUpdateVersionsServerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.SoftwareUpdateVersionsServerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.SoftwareUpdateVersionsServerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.SoftwareUpdateVersionsServerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.SoftwareUpdateVersionsServerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.SoftwareUpdateVersionsServerResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.SoftwareUpdateVersionsServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.SoftwareUpdateVersionsServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.SoftwareUpdateVersionsServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.SoftwareUpdateVersionsServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    softwareList: jspb.Message.toObjectList(msg.getSoftwareList(),
    proto.server.SoftwareUpdateVersionsSoftwareServerResp.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.SoftwareUpdateVersionsServerResp}
 */
proto.server.SoftwareUpdateVersionsServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.SoftwareUpdateVersionsServerResp;
  return proto.server.SoftwareUpdateVersionsServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.SoftwareUpdateVersionsServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.SoftwareUpdateVersionsServerResp}
 */
proto.server.SoftwareUpdateVersionsServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.server.SoftwareUpdateVersionsSoftwareServerResp;
      reader.readMessage(value,proto.server.SoftwareUpdateVersionsSoftwareServerResp.deserializeBinaryFromReader);
      msg.addSoftware(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.SoftwareUpdateVersionsServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.SoftwareUpdateVersionsServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.SoftwareUpdateVersionsServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.SoftwareUpdateVersionsServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSoftwareList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.server.SoftwareUpdateVersionsSoftwareServerResp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SoftwareUpdateVersionsSoftwareServerResp software = 1;
 * @return {!Array<!proto.server.SoftwareUpdateVersionsSoftwareServerResp>}
 */
proto.server.SoftwareUpdateVersionsServerResp.prototype.getSoftwareList = function() {
  return /** @type{!Array<!proto.server.SoftwareUpdateVersionsSoftwareServerResp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.server.SoftwareUpdateVersionsSoftwareServerResp, 1));
};


/**
 * @param {!Array<!proto.server.SoftwareUpdateVersionsSoftwareServerResp>} value
 * @return {!proto.server.SoftwareUpdateVersionsServerResp} returns this
*/
proto.server.SoftwareUpdateVersionsServerResp.prototype.setSoftwareList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.server.SoftwareUpdateVersionsSoftwareServerResp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.server.SoftwareUpdateVersionsSoftwareServerResp}
 */
proto.server.SoftwareUpdateVersionsServerResp.prototype.addSoftware = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.server.SoftwareUpdateVersionsSoftwareServerResp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.SoftwareUpdateVersionsServerResp} returns this
 */
proto.server.SoftwareUpdateVersionsServerResp.prototype.clearSoftwareList = function() {
  return this.setSoftwareList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.SoftwareUpdateVersionsSoftwareServerResp.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.SoftwareUpdateVersionsSoftwareServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.SoftwareUpdateVersionsSoftwareServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.SoftwareUpdateVersionsSoftwareServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.SoftwareUpdateVersionsSoftwareServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    versionsList: jspb.Message.toObjectList(msg.getVersionsList(),
    proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.SoftwareUpdateVersionsSoftwareServerResp}
 */
proto.server.SoftwareUpdateVersionsSoftwareServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.SoftwareUpdateVersionsSoftwareServerResp;
  return proto.server.SoftwareUpdateVersionsSoftwareServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.SoftwareUpdateVersionsSoftwareServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.SoftwareUpdateVersionsSoftwareServerResp}
 */
proto.server.SoftwareUpdateVersionsSoftwareServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = new proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp;
      reader.readMessage(value,proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.deserializeBinaryFromReader);
      msg.addVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.SoftwareUpdateVersionsSoftwareServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.SoftwareUpdateVersionsSoftwareServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.SoftwareUpdateVersionsSoftwareServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.SoftwareUpdateVersionsSoftwareServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.server.SoftwareUpdateVersionsSoftwareServerResp.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.SoftwareUpdateVersionsSoftwareServerResp} returns this
 */
proto.server.SoftwareUpdateVersionsSoftwareServerResp.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.server.SoftwareUpdateVersionsSoftwareServerResp.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.SoftwareUpdateVersionsSoftwareServerResp} returns this
 */
proto.server.SoftwareUpdateVersionsSoftwareServerResp.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SoftwareUpdateVersionsSoftwareVersionServerResp versions = 3;
 * @return {!Array<!proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp>}
 */
proto.server.SoftwareUpdateVersionsSoftwareServerResp.prototype.getVersionsList = function() {
  return /** @type{!Array<!proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp, 3));
};


/**
 * @param {!Array<!proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp>} value
 * @return {!proto.server.SoftwareUpdateVersionsSoftwareServerResp} returns this
*/
proto.server.SoftwareUpdateVersionsSoftwareServerResp.prototype.setVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp}
 */
proto.server.SoftwareUpdateVersionsSoftwareServerResp.prototype.addVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.SoftwareUpdateVersionsSoftwareServerResp} returns this
 */
proto.server.SoftwareUpdateVersionsSoftwareServerResp.prototype.clearVersionsList = function() {
  return this.setVersionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    size: jspb.Message.getFieldWithDefault(msg, 3, 0),
    changedAt: (f = msg.getChangedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp}
 */
proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp;
  return proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp}
 */
proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setChangedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getChangedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp} returns this
 */
proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp} returns this
 */
proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 size = 3;
 * @return {number}
 */
proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp} returns this
 */
proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp changed_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.prototype.getChangedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp} returns this
*/
proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.prototype.setChangedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp} returns this
 */
proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.prototype.clearChangedAt = function() {
  return this.setChangedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.SoftwareUpdateVersionsSoftwareVersionServerResp.prototype.hasChangedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.SoftwareUpdateServerReq.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.SoftwareUpdateServerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.SoftwareUpdateServerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.SoftwareUpdateServerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.SoftwareUpdateServerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    idList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    waitForResult: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    versionList: jspb.Message.toObjectList(msg.getVersionList(),
    proto.server.SoftwareUpdateVersionReq.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.SoftwareUpdateServerReq}
 */
proto.server.SoftwareUpdateServerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.SoftwareUpdateServerReq;
  return proto.server.SoftwareUpdateServerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.SoftwareUpdateServerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.SoftwareUpdateServerReq}
 */
proto.server.SoftwareUpdateServerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addId(values[i]);
      }
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWaitForResult(value);
      break;
    case 3:
      var value = new proto.server.SoftwareUpdateVersionReq;
      reader.readMessage(value,proto.server.SoftwareUpdateVersionReq.deserializeBinaryFromReader);
      msg.addVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.SoftwareUpdateServerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.SoftwareUpdateServerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.SoftwareUpdateServerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.SoftwareUpdateServerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getWaitForResult();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getVersionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.server.SoftwareUpdateVersionReq.serializeBinaryToWriter
    );
  }
};


/**
 * repeated int64 id = 1;
 * @return {!Array<number>}
 */
proto.server.SoftwareUpdateServerReq.prototype.getIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.server.SoftwareUpdateServerReq} returns this
 */
proto.server.SoftwareUpdateServerReq.prototype.setIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.server.SoftwareUpdateServerReq} returns this
 */
proto.server.SoftwareUpdateServerReq.prototype.addId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.SoftwareUpdateServerReq} returns this
 */
proto.server.SoftwareUpdateServerReq.prototype.clearIdList = function() {
  return this.setIdList([]);
};


/**
 * optional bool wait_for_result = 2;
 * @return {boolean}
 */
proto.server.SoftwareUpdateServerReq.prototype.getWaitForResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.SoftwareUpdateServerReq} returns this
 */
proto.server.SoftwareUpdateServerReq.prototype.setWaitForResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated SoftwareUpdateVersionReq version = 3;
 * @return {!Array<!proto.server.SoftwareUpdateVersionReq>}
 */
proto.server.SoftwareUpdateServerReq.prototype.getVersionList = function() {
  return /** @type{!Array<!proto.server.SoftwareUpdateVersionReq>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.server.SoftwareUpdateVersionReq, 3));
};


/**
 * @param {!Array<!proto.server.SoftwareUpdateVersionReq>} value
 * @return {!proto.server.SoftwareUpdateServerReq} returns this
*/
proto.server.SoftwareUpdateServerReq.prototype.setVersionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.server.SoftwareUpdateVersionReq=} opt_value
 * @param {number=} opt_index
 * @return {!proto.server.SoftwareUpdateVersionReq}
 */
proto.server.SoftwareUpdateServerReq.prototype.addVersion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.server.SoftwareUpdateVersionReq, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.SoftwareUpdateServerReq} returns this
 */
proto.server.SoftwareUpdateServerReq.prototype.clearVersionList = function() {
  return this.setVersionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.SoftwareUpdateVersionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.SoftwareUpdateVersionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.SoftwareUpdateVersionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.SoftwareUpdateVersionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    softwareId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    versionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.SoftwareUpdateVersionReq}
 */
proto.server.SoftwareUpdateVersionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.SoftwareUpdateVersionReq;
  return proto.server.SoftwareUpdateVersionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.SoftwareUpdateVersionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.SoftwareUpdateVersionReq}
 */
proto.server.SoftwareUpdateVersionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSoftwareId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.SoftwareUpdateVersionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.SoftwareUpdateVersionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.SoftwareUpdateVersionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.SoftwareUpdateVersionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSoftwareId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string software_id = 1;
 * @return {string}
 */
proto.server.SoftwareUpdateVersionReq.prototype.getSoftwareId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.SoftwareUpdateVersionReq} returns this
 */
proto.server.SoftwareUpdateVersionReq.prototype.setSoftwareId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string version_id = 2;
 * @return {string}
 */
proto.server.SoftwareUpdateVersionReq.prototype.getVersionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.SoftwareUpdateVersionReq} returns this
 */
proto.server.SoftwareUpdateVersionReq.prototype.setVersionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.SoftwareUpdateServerResp.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.SoftwareUpdateServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.SoftwareUpdateServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.SoftwareUpdateServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.SoftwareUpdateServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    completed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    stateText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    versionsList: jspb.Message.toObjectList(msg.getVersionsList(),
    proto.server.SoftwareUpdateVersionServerResp.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.SoftwareUpdateServerResp}
 */
proto.server.SoftwareUpdateServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.SoftwareUpdateServerResp;
  return proto.server.SoftwareUpdateServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.SoftwareUpdateServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.SoftwareUpdateServerResp}
 */
proto.server.SoftwareUpdateServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateText(value);
      break;
    case 3:
      var value = new proto.server.SoftwareUpdateVersionServerResp;
      reader.readMessage(value,proto.server.SoftwareUpdateVersionServerResp.deserializeBinaryFromReader);
      msg.addVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.SoftwareUpdateServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.SoftwareUpdateServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.SoftwareUpdateServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.SoftwareUpdateServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getStateText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.server.SoftwareUpdateVersionServerResp.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool completed = 1;
 * @return {boolean}
 */
proto.server.SoftwareUpdateServerResp.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.SoftwareUpdateServerResp} returns this
 */
proto.server.SoftwareUpdateServerResp.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string state_text = 2;
 * @return {string}
 */
proto.server.SoftwareUpdateServerResp.prototype.getStateText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.SoftwareUpdateServerResp} returns this
 */
proto.server.SoftwareUpdateServerResp.prototype.setStateText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SoftwareUpdateVersionServerResp versions = 3;
 * @return {!Array<!proto.server.SoftwareUpdateVersionServerResp>}
 */
proto.server.SoftwareUpdateServerResp.prototype.getVersionsList = function() {
  return /** @type{!Array<!proto.server.SoftwareUpdateVersionServerResp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.server.SoftwareUpdateVersionServerResp, 3));
};


/**
 * @param {!Array<!proto.server.SoftwareUpdateVersionServerResp>} value
 * @return {!proto.server.SoftwareUpdateServerResp} returns this
*/
proto.server.SoftwareUpdateServerResp.prototype.setVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.server.SoftwareUpdateVersionServerResp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.server.SoftwareUpdateVersionServerResp}
 */
proto.server.SoftwareUpdateServerResp.prototype.addVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.server.SoftwareUpdateVersionServerResp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.SoftwareUpdateServerResp} returns this
 */
proto.server.SoftwareUpdateServerResp.prototype.clearVersionsList = function() {
  return this.setVersionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.SoftwareUpdateVersionServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.SoftwareUpdateVersionServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.SoftwareUpdateVersionServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.SoftwareUpdateVersionServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    softwareId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    versionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.SoftwareUpdateVersionServerResp}
 */
proto.server.SoftwareUpdateVersionServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.SoftwareUpdateVersionServerResp;
  return proto.server.SoftwareUpdateVersionServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.SoftwareUpdateVersionServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.SoftwareUpdateVersionServerResp}
 */
proto.server.SoftwareUpdateVersionServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSoftwareId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.SoftwareUpdateVersionServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.SoftwareUpdateVersionServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.SoftwareUpdateVersionServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.SoftwareUpdateVersionServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSoftwareId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string software_id = 1;
 * @return {string}
 */
proto.server.SoftwareUpdateVersionServerResp.prototype.getSoftwareId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.SoftwareUpdateVersionServerResp} returns this
 */
proto.server.SoftwareUpdateVersionServerResp.prototype.setSoftwareId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string version_id = 2;
 * @return {string}
 */
proto.server.SoftwareUpdateVersionServerResp.prototype.getVersionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.SoftwareUpdateVersionServerResp} returns this
 */
proto.server.SoftwareUpdateVersionServerResp.prototype.setVersionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.ScreenshotsServerReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.ScreenshotsServerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.ScreenshotsServerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.ScreenshotsServerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ScreenshotsServerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    idList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    waitForResult: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    software: jspb.Message.getFieldWithDefault(msg, 3, ""),
    version: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.ScreenshotsServerReq}
 */
proto.server.ScreenshotsServerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.ScreenshotsServerReq;
  return proto.server.ScreenshotsServerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.ScreenshotsServerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.ScreenshotsServerReq}
 */
proto.server.ScreenshotsServerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addId(values[i]);
      }
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWaitForResult(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSoftware(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.ScreenshotsServerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.ScreenshotsServerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.ScreenshotsServerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ScreenshotsServerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getWaitForResult();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getSoftware();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * repeated int64 id = 1;
 * @return {!Array<number>}
 */
proto.server.ScreenshotsServerReq.prototype.getIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.server.ScreenshotsServerReq} returns this
 */
proto.server.ScreenshotsServerReq.prototype.setIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.server.ScreenshotsServerReq} returns this
 */
proto.server.ScreenshotsServerReq.prototype.addId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.ScreenshotsServerReq} returns this
 */
proto.server.ScreenshotsServerReq.prototype.clearIdList = function() {
  return this.setIdList([]);
};


/**
 * optional bool wait_for_result = 2;
 * @return {boolean}
 */
proto.server.ScreenshotsServerReq.prototype.getWaitForResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.ScreenshotsServerReq} returns this
 */
proto.server.ScreenshotsServerReq.prototype.setWaitForResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string software = 3;
 * @return {string}
 */
proto.server.ScreenshotsServerReq.prototype.getSoftware = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ScreenshotsServerReq} returns this
 */
proto.server.ScreenshotsServerReq.prototype.setSoftware = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string version = 4;
 * @return {string}
 */
proto.server.ScreenshotsServerReq.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ScreenshotsServerReq} returns this
 */
proto.server.ScreenshotsServerReq.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.ScreenshotsServerResp.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.ScreenshotsServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.ScreenshotsServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.ScreenshotsServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ScreenshotsServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    completed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    stateText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    screenshotsList: jspb.Message.toObjectList(msg.getScreenshotsList(),
    proto.server.ScreenshotServerResp.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.ScreenshotsServerResp}
 */
proto.server.ScreenshotsServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.ScreenshotsServerResp;
  return proto.server.ScreenshotsServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.ScreenshotsServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.ScreenshotsServerResp}
 */
proto.server.ScreenshotsServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateText(value);
      break;
    case 3:
      var value = new proto.server.ScreenshotServerResp;
      reader.readMessage(value,proto.server.ScreenshotServerResp.deserializeBinaryFromReader);
      msg.addScreenshots(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.ScreenshotsServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.ScreenshotsServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.ScreenshotsServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ScreenshotsServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getStateText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScreenshotsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.server.ScreenshotServerResp.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool completed = 1;
 * @return {boolean}
 */
proto.server.ScreenshotsServerResp.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.ScreenshotsServerResp} returns this
 */
proto.server.ScreenshotsServerResp.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string state_text = 2;
 * @return {string}
 */
proto.server.ScreenshotsServerResp.prototype.getStateText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ScreenshotsServerResp} returns this
 */
proto.server.ScreenshotsServerResp.prototype.setStateText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ScreenshotServerResp screenshots = 3;
 * @return {!Array<!proto.server.ScreenshotServerResp>}
 */
proto.server.ScreenshotsServerResp.prototype.getScreenshotsList = function() {
  return /** @type{!Array<!proto.server.ScreenshotServerResp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.server.ScreenshotServerResp, 3));
};


/**
 * @param {!Array<!proto.server.ScreenshotServerResp>} value
 * @return {!proto.server.ScreenshotsServerResp} returns this
*/
proto.server.ScreenshotsServerResp.prototype.setScreenshotsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.server.ScreenshotServerResp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.server.ScreenshotServerResp}
 */
proto.server.ScreenshotsServerResp.prototype.addScreenshots = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.server.ScreenshotServerResp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.ScreenshotsServerResp} returns this
 */
proto.server.ScreenshotsServerResp.prototype.clearScreenshotsList = function() {
  return this.setScreenshotsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.ScreenshotServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.ScreenshotServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.ScreenshotServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ScreenshotServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.ScreenshotServerResp}
 */
proto.server.ScreenshotServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.ScreenshotServerResp;
  return proto.server.ScreenshotServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.ScreenshotServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.ScreenshotServerResp}
 */
proto.server.ScreenshotServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.ScreenshotServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.ScreenshotServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.ScreenshotServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ScreenshotServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.server.ScreenshotServerResp.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ScreenshotServerResp} returns this
 */
proto.server.ScreenshotServerResp.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.ScreenshotsResultServerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.ScreenshotsResultServerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.ScreenshotsResultServerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ScreenshotsResultServerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.ScreenshotsResultServerReq}
 */
proto.server.ScreenshotsResultServerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.ScreenshotsResultServerReq;
  return proto.server.ScreenshotsResultServerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.ScreenshotsResultServerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.ScreenshotsResultServerReq}
 */
proto.server.ScreenshotsResultServerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.ScreenshotsResultServerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.ScreenshotsResultServerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.ScreenshotsResultServerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ScreenshotsResultServerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.server.ScreenshotsResultServerReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.ScreenshotsResultServerReq} returns this
 */
proto.server.ScreenshotsResultServerReq.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.ScreenshotsResultServerResp.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.ScreenshotsResultServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.ScreenshotsResultServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.ScreenshotsResultServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ScreenshotsResultServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    screenshotsList: jspb.Message.toObjectList(msg.getScreenshotsList(),
    proto.server.ScreenshotResultServerResp.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.ScreenshotsResultServerResp}
 */
proto.server.ScreenshotsResultServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.ScreenshotsResultServerResp;
  return proto.server.ScreenshotsResultServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.ScreenshotsResultServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.ScreenshotsResultServerResp}
 */
proto.server.ScreenshotsResultServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.server.ScreenshotResultServerResp;
      reader.readMessage(value,proto.server.ScreenshotResultServerResp.deserializeBinaryFromReader);
      msg.addScreenshots(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.ScreenshotsResultServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.ScreenshotsResultServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.ScreenshotsResultServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ScreenshotsResultServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getScreenshotsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.server.ScreenshotResultServerResp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.server.ScreenshotsResultServerResp.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.ScreenshotsResultServerResp} returns this
 */
proto.server.ScreenshotsResultServerResp.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated ScreenshotResultServerResp screenshots = 2;
 * @return {!Array<!proto.server.ScreenshotResultServerResp>}
 */
proto.server.ScreenshotsResultServerResp.prototype.getScreenshotsList = function() {
  return /** @type{!Array<!proto.server.ScreenshotResultServerResp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.server.ScreenshotResultServerResp, 2));
};


/**
 * @param {!Array<!proto.server.ScreenshotResultServerResp>} value
 * @return {!proto.server.ScreenshotsResultServerResp} returns this
*/
proto.server.ScreenshotsResultServerResp.prototype.setScreenshotsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.server.ScreenshotResultServerResp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.server.ScreenshotResultServerResp}
 */
proto.server.ScreenshotsResultServerResp.prototype.addScreenshots = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.server.ScreenshotResultServerResp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.ScreenshotsResultServerResp} returns this
 */
proto.server.ScreenshotsResultServerResp.prototype.clearScreenshotsList = function() {
  return this.setScreenshotsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.ScreenshotResultServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.ScreenshotResultServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.ScreenshotResultServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ScreenshotResultServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    screenId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expiresAt: (f = msg.getExpiresAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.ScreenshotResultServerResp}
 */
proto.server.ScreenshotResultServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.ScreenshotResultServerResp;
  return proto.server.ScreenshotResultServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.ScreenshotResultServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.ScreenshotResultServerResp}
 */
proto.server.ScreenshotResultServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScreenId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.ScreenshotResultServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.ScreenshotResultServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.ScreenshotResultServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.ScreenshotResultServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getScreenId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.server.ScreenshotResultServerResp.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.ScreenshotResultServerResp} returns this
 */
proto.server.ScreenshotResultServerResp.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 screen_id = 2;
 * @return {number}
 */
proto.server.ScreenshotResultServerResp.prototype.getScreenId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.ScreenshotResultServerResp} returns this
 */
proto.server.ScreenshotResultServerResp.prototype.setScreenId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.server.ScreenshotResultServerResp.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.ScreenshotResultServerResp} returns this
 */
proto.server.ScreenshotResultServerResp.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.ScreenshotResultServerResp.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.ScreenshotResultServerResp} returns this
*/
proto.server.ScreenshotResultServerResp.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ScreenshotResultServerResp} returns this
 */
proto.server.ScreenshotResultServerResp.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ScreenshotResultServerResp.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp expires_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.ScreenshotResultServerResp.prototype.getExpiresAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.ScreenshotResultServerResp} returns this
*/
proto.server.ScreenshotResultServerResp.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.ScreenshotResultServerResp} returns this
 */
proto.server.ScreenshotResultServerResp.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.ScreenshotResultServerResp.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.NetScanServerReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.NetScanServerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.NetScanServerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.NetScanServerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.NetScanServerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    idList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    waitForResult: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.NetScanServerReq}
 */
proto.server.NetScanServerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.NetScanServerReq;
  return proto.server.NetScanServerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.NetScanServerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.NetScanServerReq}
 */
proto.server.NetScanServerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addId(values[i]);
      }
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWaitForResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.NetScanServerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.NetScanServerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.NetScanServerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.NetScanServerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getWaitForResult();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated int64 id = 1;
 * @return {!Array<number>}
 */
proto.server.NetScanServerReq.prototype.getIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.server.NetScanServerReq} returns this
 */
proto.server.NetScanServerReq.prototype.setIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.server.NetScanServerReq} returns this
 */
proto.server.NetScanServerReq.prototype.addId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.NetScanServerReq} returns this
 */
proto.server.NetScanServerReq.prototype.clearIdList = function() {
  return this.setIdList([]);
};


/**
 * optional bool wait_for_result = 2;
 * @return {boolean}
 */
proto.server.NetScanServerReq.prototype.getWaitForResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.NetScanServerReq} returns this
 */
proto.server.NetScanServerReq.prototype.setWaitForResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.NetScanServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.NetScanServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.NetScanServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.NetScanServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    completed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    stateText: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.NetScanServerResp}
 */
proto.server.NetScanServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.NetScanServerResp;
  return proto.server.NetScanServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.NetScanServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.NetScanServerResp}
 */
proto.server.NetScanServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.NetScanServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.NetScanServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.NetScanServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.NetScanServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getStateText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool completed = 1;
 * @return {boolean}
 */
proto.server.NetScanServerResp.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.NetScanServerResp} returns this
 */
proto.server.NetScanServerResp.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string state_text = 2;
 * @return {string}
 */
proto.server.NetScanServerResp.prototype.getStateText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.NetScanServerResp} returns this
 */
proto.server.NetScanServerResp.prototype.setStateText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.NetScanResultServerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.NetScanResultServerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.NetScanResultServerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.NetScanResultServerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.NetScanResultServerReq}
 */
proto.server.NetScanResultServerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.NetScanResultServerReq;
  return proto.server.NetScanResultServerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.NetScanResultServerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.NetScanResultServerReq}
 */
proto.server.NetScanResultServerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.NetScanResultServerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.NetScanResultServerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.NetScanResultServerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.NetScanResultServerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.server.NetScanResultServerReq.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.NetScanResultServerReq} returns this
 */
proto.server.NetScanResultServerReq.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.NetScanResultServerResp.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.NetScanResultServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.NetScanResultServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.NetScanResultServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.NetScanResultServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hostsList: jspb.Message.toObjectList(msg.getHostsList(),
    proto.server.NetScanResultHostServerResp.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.NetScanResultServerResp}
 */
proto.server.NetScanResultServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.NetScanResultServerResp;
  return proto.server.NetScanResultServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.NetScanResultServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.NetScanResultServerResp}
 */
proto.server.NetScanResultServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.server.NetScanResultHostServerResp;
      reader.readMessage(value,proto.server.NetScanResultHostServerResp.deserializeBinaryFromReader);
      msg.addHosts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.NetScanResultServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.NetScanResultServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.NetScanResultServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.NetScanResultServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getHostsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.server.NetScanResultHostServerResp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.server.NetScanResultServerResp.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.NetScanResultServerResp} returns this
 */
proto.server.NetScanResultServerResp.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated NetScanResultHostServerResp hosts = 2;
 * @return {!Array<!proto.server.NetScanResultHostServerResp>}
 */
proto.server.NetScanResultServerResp.prototype.getHostsList = function() {
  return /** @type{!Array<!proto.server.NetScanResultHostServerResp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.server.NetScanResultHostServerResp, 2));
};


/**
 * @param {!Array<!proto.server.NetScanResultHostServerResp>} value
 * @return {!proto.server.NetScanResultServerResp} returns this
*/
proto.server.NetScanResultServerResp.prototype.setHostsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.server.NetScanResultHostServerResp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.server.NetScanResultHostServerResp}
 */
proto.server.NetScanResultServerResp.prototype.addHosts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.server.NetScanResultHostServerResp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.NetScanResultServerResp} returns this
 */
proto.server.NetScanResultServerResp.prototype.clearHostsList = function() {
  return this.setHostsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.NetScanResultHostServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.NetScanResultHostServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.NetScanResultHostServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.NetScanResultHostServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ip: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mac: jspb.Message.getFieldWithDefault(msg, 4, ""),
    macVendor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    logfileUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    logfileCreatedAt: (f = msg.getLogfileCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deviceId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    deviceInfoMap: (f = msg.getDeviceInfoMap()) ? f.toObject(includeInstance, undefined) : [],
    deviceVersion: jspb.Message.getFieldWithDefault(msg, 10, ""),
    deviceTypeName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    nodeId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    nodeGroupId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    nodeGroupName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    nodeGroupType: jspb.Message.getFieldWithDefault(msg, 15, ""),
    studioId: jspb.Message.getFieldWithDefault(msg, 16, 0),
    logBrowserUrl: jspb.Message.getFieldWithDefault(msg, 17, ""),
    milonCareUrl: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.NetScanResultHostServerResp}
 */
proto.server.NetScanResultHostServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.NetScanResultHostServerResp;
  return proto.server.NetScanResultHostServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.NetScanResultHostServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.NetScanResultHostServerResp}
 */
proto.server.NetScanResultHostServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMac(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMacVendor(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogfileUrl(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLogfileCreatedAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeviceId(value);
      break;
    case 9:
      var value = msg.getDeviceInfoMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceVersion(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceTypeName(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNodeId(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNodeGroupId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeGroupName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeGroupType(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStudioId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogBrowserUrl(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setMilonCareUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.NetScanResultHostServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.NetScanResultHostServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.NetScanResultHostServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.NetScanResultHostServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getIp();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMac();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMacVendor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLogfileUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLogfileCreatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeviceId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getDeviceInfoMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getDeviceVersion();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDeviceTypeName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getNodeId();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getNodeGroupId();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getNodeGroupName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getNodeGroupType();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getStudioId();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getLogBrowserUrl();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getMilonCareUrl();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.server.NetScanResultHostServerResp.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.NetScanResultHostServerResp} returns this
 */
proto.server.NetScanResultHostServerResp.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string ip = 3;
 * @return {string}
 */
proto.server.NetScanResultHostServerResp.prototype.getIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.NetScanResultHostServerResp} returns this
 */
proto.server.NetScanResultHostServerResp.prototype.setIp = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string mac = 4;
 * @return {string}
 */
proto.server.NetScanResultHostServerResp.prototype.getMac = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.NetScanResultHostServerResp} returns this
 */
proto.server.NetScanResultHostServerResp.prototype.setMac = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string mac_vendor = 5;
 * @return {string}
 */
proto.server.NetScanResultHostServerResp.prototype.getMacVendor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.NetScanResultHostServerResp} returns this
 */
proto.server.NetScanResultHostServerResp.prototype.setMacVendor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string logfile_url = 6;
 * @return {string}
 */
proto.server.NetScanResultHostServerResp.prototype.getLogfileUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.NetScanResultHostServerResp} returns this
 */
proto.server.NetScanResultHostServerResp.prototype.setLogfileUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp logfile_created_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.server.NetScanResultHostServerResp.prototype.getLogfileCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.server.NetScanResultHostServerResp} returns this
*/
proto.server.NetScanResultHostServerResp.prototype.setLogfileCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.server.NetScanResultHostServerResp} returns this
 */
proto.server.NetScanResultHostServerResp.prototype.clearLogfileCreatedAt = function() {
  return this.setLogfileCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.server.NetScanResultHostServerResp.prototype.hasLogfileCreatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 device_id = 8;
 * @return {number}
 */
proto.server.NetScanResultHostServerResp.prototype.getDeviceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.NetScanResultHostServerResp} returns this
 */
proto.server.NetScanResultHostServerResp.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * map<string, string> device_info = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.server.NetScanResultHostServerResp.prototype.getDeviceInfoMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 9, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.server.NetScanResultHostServerResp} returns this
 */
proto.server.NetScanResultHostServerResp.prototype.clearDeviceInfoMap = function() {
  this.getDeviceInfoMap().clear();
  return this;
};


/**
 * optional string device_version = 10;
 * @return {string}
 */
proto.server.NetScanResultHostServerResp.prototype.getDeviceVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.NetScanResultHostServerResp} returns this
 */
proto.server.NetScanResultHostServerResp.prototype.setDeviceVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string device_type_name = 11;
 * @return {string}
 */
proto.server.NetScanResultHostServerResp.prototype.getDeviceTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.NetScanResultHostServerResp} returns this
 */
proto.server.NetScanResultHostServerResp.prototype.setDeviceTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 node_id = 12;
 * @return {number}
 */
proto.server.NetScanResultHostServerResp.prototype.getNodeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.NetScanResultHostServerResp} returns this
 */
proto.server.NetScanResultHostServerResp.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 node_group_id = 13;
 * @return {number}
 */
proto.server.NetScanResultHostServerResp.prototype.getNodeGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.NetScanResultHostServerResp} returns this
 */
proto.server.NetScanResultHostServerResp.prototype.setNodeGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string node_group_name = 14;
 * @return {string}
 */
proto.server.NetScanResultHostServerResp.prototype.getNodeGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.NetScanResultHostServerResp} returns this
 */
proto.server.NetScanResultHostServerResp.prototype.setNodeGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string node_group_type = 15;
 * @return {string}
 */
proto.server.NetScanResultHostServerResp.prototype.getNodeGroupType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.NetScanResultHostServerResp} returns this
 */
proto.server.NetScanResultHostServerResp.prototype.setNodeGroupType = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int64 studio_id = 16;
 * @return {number}
 */
proto.server.NetScanResultHostServerResp.prototype.getStudioId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.server.NetScanResultHostServerResp} returns this
 */
proto.server.NetScanResultHostServerResp.prototype.setStudioId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string log_browser_url = 17;
 * @return {string}
 */
proto.server.NetScanResultHostServerResp.prototype.getLogBrowserUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.NetScanResultHostServerResp} returns this
 */
proto.server.NetScanResultHostServerResp.prototype.setLogBrowserUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string milon_care_url = 18;
 * @return {string}
 */
proto.server.NetScanResultHostServerResp.prototype.getMilonCareUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.NetScanResultHostServerResp} returns this
 */
proto.server.NetScanResultHostServerResp.prototype.setMilonCareUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.LogFileRequestServerReq.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.LogFileRequestServerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.LogFileRequestServerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.LogFileRequestServerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.LogFileRequestServerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    idList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    netScanIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    waitForResult: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.LogFileRequestServerReq}
 */
proto.server.LogFileRequestServerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.LogFileRequestServerReq;
  return proto.server.LogFileRequestServerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.LogFileRequestServerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.LogFileRequestServerReq}
 */
proto.server.LogFileRequestServerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addId(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNetScanId(values[i]);
      }
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWaitForResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.LogFileRequestServerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.LogFileRequestServerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.LogFileRequestServerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.LogFileRequestServerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getNetScanIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getWaitForResult();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * repeated int64 id = 1;
 * @return {!Array<number>}
 */
proto.server.LogFileRequestServerReq.prototype.getIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.server.LogFileRequestServerReq} returns this
 */
proto.server.LogFileRequestServerReq.prototype.setIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.server.LogFileRequestServerReq} returns this
 */
proto.server.LogFileRequestServerReq.prototype.addId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.LogFileRequestServerReq} returns this
 */
proto.server.LogFileRequestServerReq.prototype.clearIdList = function() {
  return this.setIdList([]);
};


/**
 * repeated int64 net_scan_id = 2;
 * @return {!Array<number>}
 */
proto.server.LogFileRequestServerReq.prototype.getNetScanIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.server.LogFileRequestServerReq} returns this
 */
proto.server.LogFileRequestServerReq.prototype.setNetScanIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.server.LogFileRequestServerReq} returns this
 */
proto.server.LogFileRequestServerReq.prototype.addNetScanId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.LogFileRequestServerReq} returns this
 */
proto.server.LogFileRequestServerReq.prototype.clearNetScanIdList = function() {
  return this.setNetScanIdList([]);
};


/**
 * optional bool wait_for_result = 3;
 * @return {boolean}
 */
proto.server.LogFileRequestServerReq.prototype.getWaitForResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.LogFileRequestServerReq} returns this
 */
proto.server.LogFileRequestServerReq.prototype.setWaitForResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.LogFileRequestServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.LogFileRequestServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.LogFileRequestServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.LogFileRequestServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    completed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    stateText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.LogFileRequestServerResp}
 */
proto.server.LogFileRequestServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.LogFileRequestServerResp;
  return proto.server.LogFileRequestServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.LogFileRequestServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.LogFileRequestServerResp}
 */
proto.server.LogFileRequestServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.LogFileRequestServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.LogFileRequestServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.LogFileRequestServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.LogFileRequestServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getStateText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool completed = 1;
 * @return {boolean}
 */
proto.server.LogFileRequestServerResp.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.LogFileRequestServerResp} returns this
 */
proto.server.LogFileRequestServerResp.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string state_text = 2;
 * @return {string}
 */
proto.server.LogFileRequestServerResp.prototype.getStateText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.LogFileRequestServerResp} returns this
 */
proto.server.LogFileRequestServerResp.prototype.setStateText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.server.LogFileRequestServerResp.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.LogFileRequestServerResp} returns this
 */
proto.server.LogFileRequestServerResp.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.server.CommandExecServerReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.CommandExecServerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.server.CommandExecServerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.CommandExecServerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.CommandExecServerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    idList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    waitForResult: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    command: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cwd: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.CommandExecServerReq}
 */
proto.server.CommandExecServerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.CommandExecServerReq;
  return proto.server.CommandExecServerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.CommandExecServerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.CommandExecServerReq}
 */
proto.server.CommandExecServerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addId(values[i]);
      }
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWaitForResult(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommand(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCwd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.CommandExecServerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.CommandExecServerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.CommandExecServerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.CommandExecServerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getWaitForResult();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getCommand();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCwd();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * repeated int64 id = 1;
 * @return {!Array<number>}
 */
proto.server.CommandExecServerReq.prototype.getIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.server.CommandExecServerReq} returns this
 */
proto.server.CommandExecServerReq.prototype.setIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.server.CommandExecServerReq} returns this
 */
proto.server.CommandExecServerReq.prototype.addId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.server.CommandExecServerReq} returns this
 */
proto.server.CommandExecServerReq.prototype.clearIdList = function() {
  return this.setIdList([]);
};


/**
 * optional bool wait_for_result = 2;
 * @return {boolean}
 */
proto.server.CommandExecServerReq.prototype.getWaitForResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.CommandExecServerReq} returns this
 */
proto.server.CommandExecServerReq.prototype.setWaitForResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string command = 3;
 * @return {string}
 */
proto.server.CommandExecServerReq.prototype.getCommand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.CommandExecServerReq} returns this
 */
proto.server.CommandExecServerReq.prototype.setCommand = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string cwd = 4;
 * @return {string}
 */
proto.server.CommandExecServerReq.prototype.getCwd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.CommandExecServerReq} returns this
 */
proto.server.CommandExecServerReq.prototype.setCwd = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.server.CommandExecServerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.server.CommandExecServerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.server.CommandExecServerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.CommandExecServerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    completed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    stateText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    command: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cwd: jspb.Message.getFieldWithDefault(msg, 4, ""),
    response: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.server.CommandExecServerResp}
 */
proto.server.CommandExecServerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.server.CommandExecServerResp;
  return proto.server.CommandExecServerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.server.CommandExecServerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.server.CommandExecServerResp}
 */
proto.server.CommandExecServerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommand(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCwd(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.server.CommandExecServerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.server.CommandExecServerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.server.CommandExecServerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.server.CommandExecServerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getStateText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCommand();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCwd();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getResponse();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional bool completed = 1;
 * @return {boolean}
 */
proto.server.CommandExecServerResp.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.server.CommandExecServerResp} returns this
 */
proto.server.CommandExecServerResp.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string state_text = 2;
 * @return {string}
 */
proto.server.CommandExecServerResp.prototype.getStateText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.CommandExecServerResp} returns this
 */
proto.server.CommandExecServerResp.prototype.setStateText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string command = 3;
 * @return {string}
 */
proto.server.CommandExecServerResp.prototype.getCommand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.CommandExecServerResp} returns this
 */
proto.server.CommandExecServerResp.prototype.setCommand = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string cwd = 4;
 * @return {string}
 */
proto.server.CommandExecServerResp.prototype.getCwd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.CommandExecServerResp} returns this
 */
proto.server.CommandExecServerResp.prototype.setCwd = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string response = 5;
 * @return {string}
 */
proto.server.CommandExecServerResp.prototype.getResponse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.server.CommandExecServerResp} returns this
 */
proto.server.CommandExecServerResp.prototype.setResponse = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * @enum {number}
 */
proto.server.VpnStatus = {
  VPN_STATUS_ALL: 0,
  VPN_STATUS_DISABLED: 1,
  VPN_STATUS_DISABLED_PENDING: 2,
  VPN_STATUS_ACTIVE_PENDING: 3,
  VPN_STATUS_ACTIVE: 4
};

/**
 * @enum {number}
 */
proto.server.ServerStatus = {
  SERVER_STATUS_ALL: 0,
  SERVER_STATUS_ACTIVE: 1,
  SERVER_STATUS_NEW: 2,
  SERVER_STATUS_DELETED: 3
};

/**
 * @enum {number}
 */
proto.server.ServerConnectedStatus = {
  SERVER_CONNECTED_STATUS_ALL: 0,
  SERVER_CONNECTED_STATUS_ONLINE: 1,
  SERVER_CONNECTED_STATUS_OFFLINE: 2
};

goog.object.extend(exports, proto.server);
