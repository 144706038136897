import {
    infoBoxShadow
} from "../../material-base";

const style = theme => ({
    card: {
        ...infoBoxShadow
    },
    cardHeaderTitle: {
        fontSize: '1.1rem',
        fontWeight: 300,
        cursor: 'pointer'
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        width: 38,
        height: 38,
        position: 'relative',
        top: theme.spacing.unit/2,
        right: theme.spacing.unit,
        '&.open': {
            transform: 'rotate(180deg)',
        }
    },
    withoutSpacingCardContent: {
        padding: 0
    },
});

export default style;