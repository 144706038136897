import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _ from 'lodash';
// actions
import {startDeleteServers, startUndeleteServers} from '../../actions/servers/servers'
import { stopServerVpn } from '../../actions/servers/stopVpn';
import { startServerVpn } from '../../actions/servers/startVpn';
import { restartServerBrowsers } from '../../actions/servers/browsers';
import { startRestartServer } from '../../actions/servers/serverRestart';
// Material UI
// own components
import ServerDataTable from './ServerDataTable';
import MassActionNotification from '../general/MassActionNotification';
import SearchCustomers from './SearchCustomers';
import ActionButtonComp from '../general/ActionButtonComp';
import LoadingProgressMessage from './LoadingProgressMessage';
// style
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/general/pageStyle';

const DEFAULT_SERVER_ACTION = 'empty';

const actionFnkOptions = [
    { key: DEFAULT_SERVER_ACTION,   value: 'Choose action',     },
    { key: 'startVpn',              value: 'Start VPN',         toBeConfirmed: false },
    { key: 'stopVpn',               value: 'End VPN',           toBeConfirmed: false },
    { key: 'restartBrowsers',       value: 'Restart browsers',  toBeConfirmed: true },
    { key: 'restartServer',         value: 'Restart server',    toBeConfirmed: true },
    { key: 'assignServers',         value: 'Assign to customer',toBeConfirmed: true },
    { key: 'delete',                value: 'Delete server',     toBeConfirmed: true, isAvailable: (server) => !server.deleted_at },
    { key: 'undelete',              value: 'Undelete server',   toBeConfirmed: false, isAvailable: (server) => !!server.deleted_at },
];

class ServerData extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            serverAction: DEFAULT_SERVER_ACTION,
            vpnStartError: '',
            vpnStopError: '',
            restartBrowsersError: '',
            restartServerError: ''

        }
    }

    handleServerAction = (name, value) => {
        if(value !== this.state[name]){
            this.setState({ [name]: value });
            let [ action ] = _.filter(actionFnkOptions, (el) => { return el.key === value });

            if(value !== DEFAULT_SERVER_ACTION && action && !action.toBeConfirmed){
                const server_id = this.props._server.item.id;

                if(value === 'startVpn'){
                    this.handleClearSelection();
                    this.props.startServerVpn(server_id)
                        .then(() => {})
                        .catch((err) => {   this.setState({ vpnStartError: err.message });  });

                }else if(value === 'stopVpn'){
                    this.handleClearSelection();
                    this.props.stopServerVpn(server_id)
                        .then(() => {})
                        .catch((err) => {   this.setState({ vpnStopError: err.message });   });
                }else if(value === 'undelete'){
                    this.handleClearSelection();
                    this.props.startUndeleteServer([server_id])
                        .then(() => {
                            window.location.reload();
                        })
                        .catch((err) => {   this.setState({ vpnStopError: err.message });   });
                }
            }
        }
    };

    cancelAction = (evt) => {
        this.setState({
            serverAction: DEFAULT_SERVER_ACTION,
            vpnStartError: '',
            vpnStopError: '',
            restartBrowsersError: '',
            restartServerError: ''
        });
    };

    handleAction = (evt) => {
        const action = this.state.serverAction;
        const server_id = this.props._server.item.id;

        if(action === 'delete'){
            this.props.startDeleteServers([server_id]).then(() => {
                this.handleClearSelection();
                this.props.history.push(`/servers`);
            });

        }else{
            // stream actions
            this.handleClearSelection();

            if(action === 'restartServer'){
                this.props.startRestartServer(server_id)
                    .then(() =>     {})
                    .catch((err) => {   this.setState({ restartServerError: err.message });  });

            }else if(action === 'restartBrowsers'){
                this.props.restartServerBrowsers(server_id)
                    .then(() =>     {})
                    .catch((err) => { this.setState({ restartBrowsersError: err.message });
                });
            }
        }
    };

    handleClearSelection = () => {
        this.setState({ serverAction: DEFAULT_SERVER_ACTION });
    };

    handleEditServer = () => {
        this.props.history.push(`/servers/edit/${this.props._server.item.id}`);
    };

    render(){
        const { serverAction, vpnStartError, vpnStopError, restartBrowsersError, restartServerError } = this.state;
        const { classes, _server, history, _browsers, _serverRestart, _startVpn, _stopVpn } = this.props;
        const { item } = _server;

        // server action variables
        let noIcon, topOriented, actionName, actionKey, message, errorNotification, reset = false;
        if(serverAction === 'delete'){
            actionName = "Delete";
            actionKey = "delete";
            message = "Do you really want to delete this server?";
        }else if(serverAction === 'restartServer'){
            actionName = "Restart";
            actionKey = "restart server";
            message = "Do you really want to restart this server?";
        }else if(serverAction === 'restartBrowsers'){
            actionName = "Restart";
            actionKey = "restart browsers";
            message = "Do you really want to restart browsers of this server?";
        }else if(serverAction === 'assignServers'){
            noIcon = true;
            topOriented = true;
            message = <SearchCustomers history={history} selectedServers={[item.id]} single={true} />;
        }else if(vpnStartError || vpnStopError  || restartServerError || restartBrowsersError){
            errorNotification = true;
            if(vpnStartError) message = `VPN connection process has failed: ${vpnStartError}`;
            if(vpnStopError) message = `VPN disconnection process has failed: ${vpnStopError}`;
            if(restartBrowsersError) message = `Restarting browsers process has failed: ${restartBrowsersError}`;
            if(restartServerError) message = `Restarting server process has failed: ${restartServerError}`;
        }else if((_startVpn.loading && item.id === _startVpn.serverId) ||
            (_stopVpn.loading && item.id === _stopVpn.serverId) ||
            (_browsers.loading && item.id === _browsers.serverId) ||
            (_serverRestart.loading && item.id === _serverRestart.serverId)
        ){
            topOriented = true;
            noIcon = true;
            if(_stopVpn.loading) message = <LoadingProgressMessage msg={`Stopping VPN is in progress. ${_stopVpn.stateText}`}/>;
            if(_startVpn.loading) message = <LoadingProgressMessage msg={`Starting VPN is in progress. ${_startVpn.stateText}`}/>;
            if(_browsers.loading) message = <LoadingProgressMessage msg={`Restarting browsers is in progress. ${_browsers.stateText}`}/>;
            if(_serverRestart.loading) message = <LoadingProgressMessage msg={`Restarting of server is in progress. ${_serverRestart.stateText}`}/>;
        }else{
            reset = true;
        }

        return <div>
            {item.deleted_at && <div className={classes.alert}>
                Attention: Server will be permanently deleted soon!
            </div>}

            <ServerDataTable
                actionFnkOptions={_.filter(actionFnkOptions, (action) => !action.isAvailable || action.isAvailable(item))}
                serverAction={serverAction}
                handleServerAction={this.handleServerAction}
                defaultServerAction={DEFAULT_SERVER_ACTION}
            />
            {!reset && <MassActionNotification
                cancelAction={this.cancelAction}
                handleAction={this.handleAction}
                multipleMsgs={actionKey = ("restart browsers" || "restart server")}
                noIcon={noIcon}
                topOriented={topOriented}
                actionName={actionName}
                actionKey={actionKey}
                message={message}
                error={errorNotification}
            />}
            {item && item.id && <div className={classes.btnContainer}>
                <ActionButtonComp
                    active={true}
                    action={this.handleEditServer}
                    title="Edit server"
                />

                {item.documentation_url && <ActionButtonComp
                    active={true}
                    action={() => { window.open(item.documentation_url, '_blank') }}
                    title="Documentation"
                />}
            </div>}
        </div>;
    };
}

const mapStateToProps = (state) => {
    return {
        _server: state.server,
        _browsers: state.browsers,
        _serverRestart: state.serverRestart,
        _startVpn: state.startVpn,
        _stopVpn: state.stopVpn
    };
};

const mapDispatchToProps = (dispatch) => ({
    startDeleteServers: (server_id) => dispatch(startDeleteServers(server_id)),
    startUndeleteServer: (server_id) => dispatch(startUndeleteServers(server_id)),
    startServerVpn: (server_id) => dispatch(startServerVpn(server_id)),
    stopServerVpn: (server_id) => dispatch(stopServerVpn(server_id)),
    startRestartServer: (server_id) => dispatch(startRestartServer(server_id)),
    restartServerBrowsers: (server_id) => dispatch(restartServerBrowsers(server_id)),
});

export default compose(
    withStyles(style),
    connect(mapStateToProps, mapDispatchToProps),
)(ServerData);
