import {
    successBoxShadow,
    dangerBoxShadow,
    grayColor
} from "../../material-base";

const style = theme => {
    return {
        textFieldRoot: {
            width: 200,
            '&.small': {
                width: 90,
            },
            '&.fullWidth': {
                display: 'grid',
                width: '100%'
            },
            '@media(max-width: 492px)': {
                width: '100%',
            }
        },
        searchDetailedInput: {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            border: '1px solid #ced4da',
            padding: `${theme.spacing.unit}px 10px`,
            transition: theme.transitions.create(['border-color']),
            '&:focus, &:hover, &.active': {
                cursor: 'pointer',
                borderColor: theme.palette.secondary.main,
                ...successBoxShadow,
            },
            '&.error': {
                borderColor: theme.palette.error.main,
                ...dangerBoxShadow
            },
        },
        searchDetailedLabel : {
            fontSize: 17,
            color: grayColor,
            '&.active, &.active.error': {
                color: theme.palette.common.black
            },
            '&.error': {
                color: grayColor,
            },
        },
    }
};

export default style;