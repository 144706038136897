import {
    SoftwareUpdateVersionsServerReq
} from '../../pb/server_pb';
import { serverPromiseService } from '../libs/request-clients';
import { getMetadata, handleResponseError, getDateTime } from '../libs/request-helpers';

export const DEFAULT_SOFTWARE_VERSION = 'empty';
export const DELETE_SOFTWARE = 'delete';

export const getVersionsOptions = (versions) => {
    const noSelectionOption     =   { key: DEFAULT_SOFTWARE_VERSION,    value: 'No selection'           };
    const deleteVersionOption   =   { key: DELETE_SOFTWARE,             value: 'Delete current version' };

    let versionsOptions = [];
    versionsOptions.push(noSelectionOption);
    versions.forEach((el) => {
        const version           =   { key: el.id,                       value: el.title                 };
        versionsOptions.push(version);
    });
    versionsOptions.push(deleteVersionOption);

    return versionsOptions;
};

export const setDevicesSoftwareLoading = () => ({
    type: 'SET_DEVICES_SOFTWARE_LOADING'
});

export const setDevicesSoftwareSuccess = (devicesSoftware) => ({
    type: 'SET_DEVICES_SOFTWARE_SUCCESS',
    payload: { devicesSoftware }
});

export const setDevicesSoftwareError = (error) => ({
    type: 'SET_DEVICES_SOFTWARE_FAILURE',
    payload: { error }
});

const getDeviceVersionsInfo = (_versions) => {
    let versions = [];
    _versions.forEach((_version) => {
        const version = {
            id:         _version.getId(),
            title:      _version.getTitle(),
            size:       _version.getSize(), // Größe des Updates (muss nicht zwingend ausgegeben werden)
            changed_at: getDateTime(_version.getChangedAt()) // Datum der letzten Änderung im S3 Bucket (muss auch nicht ausgegeben werden)
        };
        versions.push(version);
    });
    return versions;
};

// get available software updates
export const startSetDevicesSoftware = () => {
    return (dispatch, getState) => {

        dispatch(setDevicesSoftwareLoading());

        let softwareUpdateVersionsServerReq = new SoftwareUpdateVersionsServerReq();

        return serverPromiseService.softwareUpdateVersions(softwareUpdateVersionsServerReq, getMetadata())
            .then((response) => {
                const _devicesSoftware = response.getSoftwareList();
                let devicesSoftware = [];
                _devicesSoftware.forEach((item) => {
                    const deviceVersions = {
                        id:             item.getId(),
                        title:          item.getTitle(),
                        versions:       getDeviceVersionsInfo(item.getVersionsList())
                    };
                   devicesSoftware.push(deviceVersions);
                });
                dispatch(setDevicesSoftwareSuccess(devicesSoftware));
            })
            .catch((err) => {
                dispatch(setDevicesSoftwareError(err));
                handleResponseError(dispatch, err);
            });
    };
};