import React from "react";
// Material UI
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Button from "@material-ui/core/Button";
// own components
import InputSearchComp from '../general/InputSearchComp';
// styles
import withStyles from "@material-ui/core/styles/withStyles";
import style from '../../styles/jss/components/general/searchHeaderCompStyle';

const SearchHeaderComp = ({
    classes,
    searchId,
    searchAll,
    clearSearch,
    searchQuery,
    triggerAdvancedSearch,
    isAdvancedSearch,
    onHandleChange,
    searching
}) => {
    return (
        <div className={classes.searchHeader}>
            <span className={classes.searchTitle}>Search</span>
            <InputSearchComp
                className="dark"
                name={searchId}
                placeholder="Search in all fields..."
                value={searchAll}
                inProgress={searching}
                actionClear={clearSearch}
                actionChange={onHandleChange}
            />
            <Button
                className={`${classes.infoBtn} btnMore`}
                variant="contained"
                color={searchQuery.length > 0 ? "secondary" : "inherit"}
                onClick={triggerAdvancedSearch}
            >
                <ArrowDown className={`advSrcIcon ${isAdvancedSearch ? 'active': ''}`} />
                Advanced search
            </Button>
        </div>
    );
};

export default withStyles(style)(SearchHeaderComp);


