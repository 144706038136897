import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// Material UI
import UnfoldLessIcon from '@material-ui/icons/VisibilityOff';
import UnfoldMoreIcon from '@material-ui/icons/Visibility';
import Grid from '@material-ui/core/Grid';
// own components
import ServerCard from './ServerCard';
import ServerData from './ServerData';
import CustomerData from './CustomerData';
import LogsRecords from './LogsRecords';
import BandwidthTest from './BandwidthTest';
import DevicesInNetwork from './DevicesInNetwork';
import Screenshots from './Screenshots';
import DevicesUpdates from './DevicesUpdates';
import ActionFixedButtonComp from '../general/ActionFixedButtonComp';
// actions
import { startSetServer } from '../../actions/servers/server';
import { startSetCustomer } from '../../actions/customers/customer';
import { startSetDevices } from '../../actions/devices/devices';
import { startSetDevicesSoftware } from '../../actions/devices/software';
import { startSetScreenshots } from '../../actions/screenshots/screenshots';
import { startSetLogs } from '../../actions/logs/logs';
// style
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/general/pageStyle';

class ServerDetailsPage extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            expanded: {
                serverData: true,
                customerData: true,
                logsRecords: true,
                bandwidthTest: true,
                devicesInNetwork: true,
                screenshots: true,
                devicesUpdates: true
            }
        }
    }

    componentDidMount(){
        this.handleLoadServer();
        this.handleLoadLogs();
        this.handleLoadDevices();
        this.handleLoadScreenshots();
        this.props.startSetDevicesSoftware()
    }

    componentWillReceiveProps(newProps) {
        if(!newProps._server.loading){
            const customer_id = newProps._server.item.customer_id;
            if(customer_id) this.props.startSetCustomer(customer_id, true);
        }
    }

    handleLoadCustomer = () => {
        this.props.startSetCustomer(this.props._server.item.customer_id, true);
    };

    handleLoadServer = () => {
        const [ empty, path, id ] = this.props.history.location.pathname.split('/');
        this.props.startSetServer(id);
        /*
        if(!this.props._server.item.id){
            const [ empty, path, id ] = this.props.history.location.pathname.split('/');
            this.props.startSetServer(id);
        }
        */
    };

    handleLoadLogs = () => {
        const [ empty, path, id ] = this.props.history.location.pathname.split('/');
        const search = {
            search: ['', [{ serverId: id }]]
        };
        this.props.startSetLogs(search);
    };

    handleLoadDevices = () => {
        const [ empty, path, server_id ] = this.props.history.location.pathname.split('/');
        this.props.startSetDevices(server_id);
    };

    handleLoadScreenshots = () => {
        const [ empty, path, server_id ] = this.props.history.location.pathname.split('/');
        this.props.startSetScreenshots(server_id);
    };

    handleExpandClick = (title) => (evt) => {
        let _expanded = this.state.expanded;
        _expanded[title] = !_expanded[title];
        this.setState({ expanded: _expanded });
    };

    onHandleCloseAll = () => {
        const _expanded = {
            serverData: false,
            customerData: false,
            logsRecords: false,
            bandwidthTest: false,
            devicesInNetwork: false,
            screenshots: false,
            devicesUpdates: false
        };
        this.setState({ expanded: _expanded });
    };

    onHandleOpenAll = () => {
        this.setState({
            expanded: {
                serverData: true,
                customerData: true,
                logsRecords: true,
                bandwidthTest: true,
                devicesInNetwork: true,
                screenshots: true,
                devicesUpdates: true
            }
        });
    };

    render() {
        const { classes, history, _server } = this.props;
        const { expanded } = this.state;

        const getAction = (dataType) => {
            let action = this.handleLoadServer;
            if(dataType === 'logs') action = this.handleLoadLogs;
            else if(dataType === 'customer') action = this.handleLoadCustomer;
            else if(dataType === 'devices') action = this.handleLoadDevices;
            else if(dataType === 'screenshots') action = this.handleLoadScreenshots;
            else if(dataType === 'devicesSoftware') action = this.props.startSetDevicesSoftware;
            return action;
        };

        const card = (title, mainContent, compKey, dataType, hasParent) => {
            return <Grid className={`${classes.gridItem} ${hasParent ? 'withParent' : ''}`}>
                <ServerCard
                    handleReload={getAction(dataType)}
                    handleExpandClick={this.handleExpandClick}
                    expanded={expanded[compKey]}
                    mainContent={mainContent}
                    title={title}
                    compKey={compKey}
                    dataType={dataType}
                />
            </Grid>
        };

        const btn = (title, action, icon, className) => {
            return <ActionFixedButtonComp
                color="primary"
                title={title}
                className={className}
                action={action}
                icon={icon}
            />
        };

        return <div>
            <div className={classes.gridContainer}>
                {card("Server Data", <ServerData history={history}/>, "serverData", "server", true)}
                {card("Base Customer Data", <CustomerData history={history}/>, "customerData", "customer", true)}
            </div>
            {card("Devices in Network", <DevicesInNetwork history={history}/>, "devicesInNetwork", "devices", false)}
            {_server.item.type !== "on-premises" &&
            <div className={classes.gridContainer}>
                {card("Screenshots", <Screenshots history={history}/>, "screenshots", "screenshots", true)}
                {card("Devices Updates", <DevicesUpdates history={history}/>, "devicesUpdates", "devicesSoftware", true)}
            </div> }
            {/*<div className={classes.gridContainer}>
                {card("Studio Server Log Records", <LogsRecords />, "logsRecords", "logs", true)}
                {card("Bandwidth Evaluation Test", <BandwidthTest />, "bandwidthTest", "server", true)}
            </div>*/}
            {btn("Open all", this.onHandleOpenAll, <UnfoldMoreIcon />, "")}
            {btn("Close all", this.onHandleCloseAll, <UnfoldLessIcon />, "secondItem")}
        </div>
    }
}

const mapStateToProps = (state) => ({
    _server: state.server,
});

const mapDispatchToProps = (dispatch) => ({
    startSetServer: (id) => dispatch(startSetServer(id)),
    startSetCustomer: (id, isLight) => dispatch(startSetCustomer(id, isLight)),
    startSetDevices: (server_id) => dispatch(startSetDevices(server_id)),
    startSetScreenshots: (server_id) => dispatch(startSetScreenshots(server_id)),
    startSetLogs: (params) => dispatch(startSetLogs(params)),
    startSetDevicesSoftware: () => dispatch(startSetDevicesSoftware()),
});

export default compose(
    withStyles(style),
    connect(mapStateToProps, mapDispatchToProps),
)(ServerDetailsPage);


