import {
    successBoxShadow,
    dangerBoxShadow,
    primaryBoxShadow
} from "../../material-base";

const style = theme => {
    return {
        actionFixedBtn: {
            position: 'fixed',
            bottom: 18,
            right: 18,
            zIndex: 1,
            height: 48,
            width: 48,
            padding: 0,
            '&.secondary': {
                ...successBoxShadow,
            },
            '&.primary': {
                ...primaryBoxShadow
            },
            '&.danger': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.error.light,
                ...dangerBoxShadow,
                '&:hover': {
                    backgroundColor: theme.palette.error.main,
                }
            },
            '&.secondItem': {
                right: 76,
            }
        },
    }
};

export default style;