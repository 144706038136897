import React from 'react';
// Material UI components
import Chip from '@material-ui/core/Chip';
// style
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/general/chipCompStyle';

const ChipComp = ({ classes, action, label, clickable, className, title }) => {

    return <Chip
        onClick={action}
        label={label}
        title={title || ''}
        clickable={clickable}
        className={`${classes.chip} ${className}`}
    />
};

export default withStyles(style)(ChipComp);