import moment from 'moment';
import { startLogin } from '../auth/auth';
import { getAuthTokenGlobal, removeAuthTokenGlobal } from '../libs/cookie';

export const getMetadata = () => {
    let metadata = {
        authorization: `bearer ${getAuthTokenGlobal()}`
    };
    return metadata;
};

export const handleResponseError = (dispatch, err) => {
    if(err && err.code === 16){
        removeAuthTokenGlobal();
        dispatch(startLogin());
    }
};

export const getVpnStatus = (vpnStatus) => {
    let status = 'ALL';
    switch(vpnStatus){
        case 1:
            status = 'DISABLED'; break;
        case 2:
            status = 'DISABLED_PENDING'; break;
        case 3:
            status = 'ACTIVE_PENDING'; break;
        case 4:
            status = 'ACTIVE'; break;
    }
    return status;
};

export const getDateTime = (dateTime) => {
    dateTime = dateTime ? dateTime.toDate() : null;
    dateTime = dateTime ? moment(dateTime).format('YYYY-MM-DD HH:mm:ss') : null;
    return dateTime;
};

export const getDate = (dateTime) => {
    dateTime = dateTime ? dateTime.toDate() : null;
    dateTime = dateTime ? moment(dateTime).format('YYYY-MM-DD') : null;
    return dateTime;
};
