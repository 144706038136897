import {
} from "../../material-base";

const style = theme => {
    return {
        container: {
            width: '100%'
        },
        lastTextChild: {
            marginBottom: theme.spacing.unit
        }
    }
};

export default style;


