import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// actions
import {
    startSetDevicesSoftware,
    getVersionsOptions,
    DEFAULT_SOFTWARE_VERSION
} from '../../actions/devices/software';
import { startUpdateDeviceVersions } from '../../actions/servers/servers';
// own components
import SelectComp from '../general/SelectComp';
import ActionButtonComp from '../general/ActionButtonComp';
import Loader from '../general/Loader';
// styles
import { withStyles } from '@material-ui/core/styles';
import deviceVersionsSelectionsStyle from '../../styles/jss/components/servers/deviceVersionsSelectionsStyle';

class DeviceVersionsSelections extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            versionUpdateSuccess: false,
            versionUpdateError: '',
            updateVersions: {},
            noSelection: false
        }
    }

    componentDidMount(){
        this.props.startSetDevicesSoftware()
            .then(() => {
                const { loading, data, error } = this.props._devicesSoftware;
                if(!loading && !error && data.length > 0){
                    let updateVersions = {};
                    data.forEach((deviceVersion) => {
                        updateVersions[deviceVersion.id] = DEFAULT_SOFTWARE_VERSION;
                    });
                    this.setState({ updateVersions });
                }
            });
    };

    handleSelectAction = (name, value) => {
        let updateVersions = this.state.updateVersions;
        updateVersions[name] = value;
        this.setState({ updateVersions, noSelection: false });
    };

    handleUpdateDeviceVersions = () => {
        let updateDeviceVersions = [];
        const updateVersions = this.state.updateVersions;
        const keys = Object.keys(updateVersions);
        keys.forEach((key) => {
            if(updateVersions[key] !== DEFAULT_SOFTWARE_VERSION){
                updateDeviceVersions.push({ software_id: key, version_id: updateVersions[key] });
            }
        });

        if(updateDeviceVersions.length > 0){
            this.props.startUpdateDeviceVersions(this.props.selectedServers, updateDeviceVersions)
                .then(() => {
                    this.setState({ versionUpdateSuccess: true });
                })
                .catch((err) => {
                    this.setState({ versionUpdateError: err.message });
                });
        }else{
            this.setState({ noSelection: true });
        }
    };

    render() {
        const { updateVersions, noSelection, versionUpdateSuccess, versionUpdateError } = this.state;
        const { classes, _devicesSoftware } = this.props;
        const { loading, data, error } = _devicesSoftware;
        const successWithData = !loading && data.length > 0 && !versionUpdateSuccess && !versionUpdateError;
        const isLoadingError = !loading && error;

        return <div className={classes.wrapper}>
            <h3>Choose device versions</h3>
            {loading && <Loader size="small" />}
            {isLoadingError && <p className={classes.errorMsg}>
               Error has occurred while loading software versions: {error.message}
            </p>}
            {versionUpdateSuccess && <p>
                Software update has been triggered successfully. The process can take some time. Please wait...
            </p>}
            {versionUpdateError && <p className={classes.errorMsg}>
                Triggering of software update has failed: {versionUpdateError}
            </p>}
            {successWithData && <div className={classes.selectContainer}>
                {data.map(({ id, title, versions }) => {
                    const options = getVersionsOptions(versions);
                    if(!updateVersions[id]) return;
                    return  <SelectComp
                        key={id}
                        label={title}
                        name={id}
                        value={updateVersions[id]}
                        options={options}
                        inactive={updateVersions[id] === DEFAULT_SOFTWARE_VERSION}
                        action={this.handleSelectAction}
                        fixedWidth={true}
                    />;
                })}
            </div>}
            {noSelection && <p>You have not chosen any device version for update.</p>}
            {successWithData && <ActionButtonComp
                active={true}
                action={this.handleUpdateDeviceVersions}
                title="Update Device Versions"
                size="small"
            />}
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        _devicesSoftware:  state.devicesSoftware
    };
};

const mapDispatchToProps = (dispatch) => ({
    startSetDevicesSoftware: () => dispatch(startSetDevicesSoftware()),
    startUpdateDeviceVersions: (servers, deviceVersions) => dispatch(startUpdateDeviceVersions(servers, deviceVersions)),
});

export default compose(
    withStyles(deviceVersionsSelectionsStyle),
    connect(mapStateToProps, mapDispatchToProps),
)(DeviceVersionsSelections);