const initialState = {
    data: [],
    loading: true,
    stateText: '',
    error: null
};

const screenshotsReducer = (state = initialState, action) => {
    switch (action.type){
        case 'SET_SCREENSHOTS_LOADING':
            return {
                ...state,
                loading: true
            };
        case 'SET_SCREENSHOTS_LOADING_PROGRESS':
            return {
                ...state,
                stateText: action.payload.stateText
            };
        case 'SET_SCREENSHOTS_SUCCESS':
            return {
                ...state,
                stateText: '',
                loading: false,
                data: action.payload.screenshots,
                error: null
            };
        case 'SET_SCREENSHOTS_FAILURE':
            return {
                ...state,
                loading: false,
                stateText: '',
                error: action.payload.error
            };
        default:
            return state;
    }
};

export default screenshotsReducer;
