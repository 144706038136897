import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// Material UI components
import Grid from '@material-ui/core/Grid';
// own components
import CustomerCard from './CustomerCard';
import CustomerDetails from './CustomerDetails';
import NodeGroups from './NodeGroups';
import StudioServers from './StudioServers';
// actions
import { startSetCustomer } from '../../actions/customers/customer';
// styles
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/general/pageStyle';

class CustomerDetailsPage extends React.Component {

    componentDidMount(){
        this.handleLoadCustomer();
    }

    handleLoadCustomer = () => {
        const [ empty, path, id ] = this.props.history.location.pathname.split('/');
        this.props.startSetCustomer(id);
    };

    render() {
        const { classes, history } = this.props;

        const card = (mainContent, title, compKey, hasParent) => {
            return <Grid className={`${classes.gridItem} ${hasParent ? 'withParent' : ''}`}>
                <CustomerCard
                    handleReload={this.handleLoadCustomer}
                    mainContent={mainContent}
                    title={title}
                    compKey={compKey}
                />
            </Grid>
        };

        return <div>
            <div className={classes.gridContainer}>
                {card(<CustomerDetails/>, "Base Customer Data", "customerData", true)}
                {card(<StudioServers history={history} />, "Studio Servers", "serversData", true)}
            </div>
            {card(<NodeGroups />, "milon CARE Node Groups", "nodeGroupsData", false)}
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        _customer: state.customer
    };
};

const mapDispatchToProps = (dispatch) => ({
    startSetCustomer: (id) => dispatch(startSetCustomer(id)),
});

export default compose(
    withStyles(style),
    connect(mapStateToProps, mapDispatchToProps),
)(CustomerDetailsPage);

