import React from 'react';
// Material UI components
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// styles
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/general/simpleModalStyle';

const SimpleModal = ({ classes, isOpenedModal, handleClose, title, content, action }) => {

    return <Modal
        open={!!isOpenedModal}
        onClose={handleClose}
        className={classes.modal}
    >
        <Card className={classes.container}>
            {title && <CardHeader
                title={title}
                className={classes.cardHeader}
            />}
            <CardContent className={classes.content}>{content}</CardContent>
            {action && <CardActions className={classes.actions}>
                {action}
            </CardActions>}
        </Card>
    </Modal>;
};

export default withStyles(style)(SimpleModal);