import {
    root,
    drawerWidth,
    content
} from "../material-base";

const primaryLayoutStyle = theme => ({
    root,
    toolbar: theme.mixins.toolbar,
    content: {
        ...content,
        [theme.breakpoints.up("md")]: {
            marginLeft: drawerWidth
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: '10px',
            paddingRight: '10px',
            width: 'calc(100% - 2px)'
        },
    }
});


export default primaryLayoutStyle;

