import {} from '../../material-base';

const style = theme => ({
    modal: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        maxWidth: `calc(100% - ${theme.spacing.unit * 10}px)`,
        minWidth: '250px',
        borderRadius: '4px',
        maxHeight: `calc(100% - ${theme.spacing.unit * 10}px)`,
        padding: theme.spacing.unit * 3,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column'
    },
    content: {
        overflow: 'auto',
        paddingLeft: 0,
        paddingRight: 0
    },
    cardHeader: {
        padding: '0 0 15px 0',
        '& span': {
            fontWeight: 300,
            fontSize: '1.1rem',
        }
    },
    actions: {
        padding: '15px 0 0 0'
    }
});

export default style;
