import { GetCustomerReq } from '../../pb/customer_pb';
import { AssignToCustomerServerReq } from '../../pb/server_pb';
import { customerPromiseService, serverPromiseService } from '../libs/request-clients';
import { getMetadata, handleResponseError, getDateTime, getVpnStatus } from '../libs/request-helpers';

export const setCustomerLoading = () => ({
    type: 'SET_CUSTOMER_LOADING'
});

export const setCustomerSuccess = (customer) => ({
    type: 'SET_CUSTOMER_SUCCESS',
    payload: { customer }
});

export const setCustomerError = (error) => ({
    type: 'SET_CUSTOMER_FAILURE',
    payload: { error }
});

const getServers = (_servers) => {
    console.log(_servers[_servers.length -1]);
    let servers = [];
    _servers.forEach((el) => {
        const server = {
            id:         el.getId(),
            hostname:   el.getHostname(),
            tag:        el.getTag(),
            type:       el.getType(),
            os:         el.getOs(),
            isOnline:   el.getIsOnline(),
            deletedAt:  el.getDeletedAt(),
//            browser_status: [1, 0, 1], // TODO
            vpn_status: getVpnStatus(el.getVpnStatus()),
        };
        servers.push(server);
    });
    return servers;
};

const getNodeGroups = (_node_groups) => {
    let node_groups = [];
    _node_groups.forEach((el) => {

        const _nodes = el.getNodesList();
        let nodes = [];
        _nodes.forEach((_node) => {

            const _devices = _node.getDevicesList();
            let devices = [];
            _devices.forEach((_device) => {
                let device = {
                    id:                 _device.getId(),
                    address:            _device.getAddress(),
                    log_browser_url:    _device.getLogBrowserUrl(),
                    milon_care_url:     _device.getMilonCareUrl(),
                    info:               _device.getInfoMap().getEntryList()
                };
                devices.push(device);
            });

            let node = {
                id:             _node.getId(),
                device_type:    _node.getDeviceType().getName(),
                devices:        devices
            };
            nodes.push(node);
        });

        let node_group = {
            id:         el.getId(),
            name:       el.getName(),
            design:     el.getDesign(),
            type:       el.getType(),
            nodes:      nodes
        };
        node_groups.push(node_group);
    });
    return node_groups;
};

export const startSetCustomer = (id, isLight) => {
    return (dispatch, getState) => {

        // data not yet here
        dispatch(setCustomerLoading());

        let getCustomerReq = new GetCustomerReq();
        getCustomerReq.setId(id);
        if(!isLight){
            getCustomerReq.setGetMilonCare(true);
            getCustomerReq.setGetServers(true);
        }

        return customerPromiseService.get(getCustomerReq, getMetadata())
            .then((response) => {
                const data = {
                    id:             response.getId(),
                    number:         response.getNumber(),
                    milon_care_id:  response.getMilonCareId(),
                    name:           response.getName(),
                    contact_person: response.getContactPerson(),
                    street:         response.getStreet(),
                    zip:            response.getZip(),
                    city:           response.getCity(),
                    country:        response.getCountry(),
                    telephone:      response.getTelephone(),
                    homepage:       response.getHomepage(),
                    email:          response.getEmail(),
                    map_url:        'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(response.getStreet() + ', ' + response.getZip() + ' ' + response.getCity() + ', ' + response.getCountry()),
                    crm_url:        response.getCrmUrl(),
                    log_browser_url:response.getLogBrowserUrl(),
                    milon_care_url: response.getMilonCareUrl(),
                    created_at:     getDateTime(response.getCreatedAt()),
                    deleted_at:     getDateTime(response.getDeletedAt()),
                    servers:        getServers(response.getServersList()),
                    node_groups:    getNodeGroups(response.getNodeGroupsList()),
                };
                dispatch(setCustomerSuccess(data));
            })
            .catch((err) => {
                dispatch(setCustomerError(err));
                handleResponseError(dispatch, err);
            });
    };
};

export const startAssignServersToCustomer = (customer_id, server_ids) => {
    return (dispatch, getState) => {
        dispatch(setCustomerLoading());

        let assignToCustomerServerReq = new AssignToCustomerServerReq();
        assignToCustomerServerReq.setCustomerId(customer_id);
        assignToCustomerServerReq.setIdList(server_ids);
        return serverPromiseService.assignToCustomer(assignToCustomerServerReq, getMetadata())
            .then((response) => {
                const data = {};
                dispatch(setCustomerSuccess(data));
            })
            .catch((err) => {
                dispatch(setCustomerError(err));
                handleResponseError(dispatch, err);
            });
    };
};


