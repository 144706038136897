import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import createHistory from 'history/createBrowserHistory';
// Material UI
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ReplayIcon from '@material-ui/icons/Replay';
// own components
import LogsTableToolbar from './LogsToolbar';
import Loader from '../general/Loader';
import ErrorMsg from '../general/ErrorMsg';
import InfoMsg from '../general/InfoMsg';
import LogsTable from './LogsTable';
// action
import { setLogsFilters } from '../../actions/logs/filters';
import { startSetLogs } from '../../actions/logs/logs';
import { getFilters } from '../../actions/libs/get-query-params';
// style
import { withStyles } from '@material-ui/core/styles';
import pageStyle from '../../styles/jss/components/general/pageStyle';

const localHistory = createHistory();

class LogsPage extends React.Component {

    componentDidMount(){
        // check query and update
        const filters = getFilters('logs');
        if(filters) this.props.setLogsFilters(filters);
        this.props.startSetLogs(filters);
    }

    getLogs = () => {
        // get data
        this.props.startSetLogs()
            .then(() => {
                // generate / change url query
                let params = '/logs?';
                const filterKeys = Object.keys(this.props.logsFilters);
                filterKeys.forEach((key) => {
                    if(key !== 'search'){
                        params = `${params}${key}=${this.props.logsFilters[key]}&`;
                    }else{
                        const [ all, advanced ] = this.props.logsFilters.search;
                        if(all !== '') params = `${params}all=${all}&`;
                        advanced.forEach((el) => {
                            const [ searchKey ] = Object.keys(el);
                            params = `${params}${searchKey}=${el[searchKey]}&`;
                        });
                    }
                });
                params = params.substring(0, params.length - 1);
                localHistory.push(params);
            });
    };

    handleReload = () => {
        this.props.startSetLogs();
    };

    handleRequestSort = _sortBy => event => {
        if(_sortBy){
            const { sortBy, order, ...rest } = this.props.logsFilters;
            let _order = 'asc';
            if(sortBy === _sortBy && order === 'asc') _order = 'desc';

            let filters = {
                ...rest,
                order: _order,
                sortBy: _sortBy
            };
            this.props.setLogsFilters(filters);
            this.getLogs();
        }
    };

    render() {
        const { classes, logsObj, logsFilters } = this.props;
        const { sortBy, order } = logsFilters;
        const { data, loading, error } = logsObj;
        const isLoadingError = !loading && error;
        const isSuccessWithoutData = !loading && !error && data.length === 0;
        const isSuccessWithData = !error && data.length > 0;

        return <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                <LogsTableToolbar
                    getLogs={this.getLogs}
                />
                {loading && <Loader forTable={data.length > 0}/>}
                {isLoadingError && <ErrorMsg
                    size="small"
                    msg="Loading data error has occurred."
                    action={this.handleReload}
                    actionTitle="Retry loading"
                    actionIcon={<ReplayIcon/>}
                />}
                {isSuccessWithoutData && <InfoMsg
                    size="small"
                    msg="No data found."
                />}
                {isSuccessWithData && <LogsTable
                    data={data}
                    action={this.handleRequestSort}
                    sortBy={sortBy}
                    order={order}
                />}
            </CardContent>
        </Card>
    }
}

const mapStateToProps = (state) => {
    return {
        logsFilters: state.logsFilters,
        logsObj: state.logs
    };
};

const mapDispatchToProps = (dispatch) => ({
    startSetLogs: (params) => dispatch(startSetLogs(params)),
    setLogsFilters: (filters) => dispatch(setLogsFilters(filters)),
});

export default compose(
    withStyles(pageStyle),
    connect(mapStateToProps, mapDispatchToProps),
)(LogsPage);

