import React from 'react';
import moment from 'moment';
import _ from 'lodash';
// Material UI components
import ArrowIcon from '@material-ui/icons/ArrowUpward';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
// own components
import MultipleStatus from '../general/MultipleStatus';
// style
import { withStyles } from '@material-ui/core/styles';
import tableStyle from '../../styles/jss/components/general/tableStyle';

const columnData = [
    { id: 'hostname',       label: 'Hostname',      sort: true,     types: ['active', 'new', 'deleted'] },
    { id: 'os',             label: 'OS',            sort: false,    types: ['active', 'new', 'deleted'] },
    { id: 'lan_mac',        label: 'MAC address',   sort: true,     types: ['active', 'new', 'deleted'] },
    { id: 'hw_serial',      label: 'Serial number', sort: true,     types: ['active', 'new', 'deleted'] },
    { id: 'hw',             label: 'Hardware',      sort: true,    types: ['active', 'new', 'deleted'] },
    { id: 'name',           label: 'Customer',      sort: true,     types: ['active', 'deleted'] },
    { id: 'address',        label: 'Address',       sort: true,     types: ['active', 'deleted'] },
    { id: 'tag',            label: 'Tag',           sort: true,     types: ['active', 'deleted'] },
    { id: 'type',           label: 'Type',          sort: true,     types: ['active', 'deleted'] },
//    { id: 'browser_status', label: 'Browser status',sort: false,    types: ['active'] },
    { id: 'vpn_ip',         label: 'VPN IPV4/IPV6', sort: true,     types: ['active'] },
    { id: 'vpn_status',     label: 'VPN status',    sort: true,     types: ['active'] },
    { id: 'created_at',     label: 'Created',       sort: true,     types: ['new'] },
];

const getValue = (columnId, server) => {
    let value = server[columnId];

    switch(columnId){
        case 'hw':
            value = server.hw_vendor || server.hw_product ? (<span>
                <div>{server.hw_vendor}</div>
                <div>{server.hw_product}</div>
            </span>) : '';
            break;
        case 'browser_status':
            value = server[columnId].length > 0 ?
                <MultipleStatus data={server[columnId]} /> : '';
            break;
        case 'vpn_ip':
            value = server.vpn_ipv4 || server.vpn_ipv6 ?  (<span>
                <div>{server.vpn_ipv4}</div>
                <div>{server.vpn_ipv6}</div>
            </span>) : '';
            break;
        case 'name':
            value = server.customer && server.customer.name ? server.customer.name : '';
            break;
        case 'address':
            const { city, zip, country } = server.customer;
            const _city = city || zip ? `${zip} ${city}` : '';
            value = <span>{country} {_city ? <br/> : ''} {_city}</span>;
            break;
        case 'created':
            value = value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '';
            break;
    }

    return value;
};

const getSort = (columnId) => {
    let sort = columnId;
    switch(columnId){
        case 'hw':
            sort = 'hw_vendor'; break;
        case 'vpn_ip':
            sort = 'vpn_ipv4'; break;
        case 'type':
            sort = 'server_type'; break;
    }
    return sort;
};

const ServersTable = ({
    classes,
    data,
    actionSort,
    actionSelectAll,
    actionSelectServer,
    sortBy,
    order,
    serverType,
    selected,
    history
}) => {

    const isItemSelected = id => {
        return selected.indexOf(id) !== -1;
    };

    const activeServers = serverType === 'active' || serverType === "deleted";

    const onHandleServerDetails = id => evt => {
        evt.stopPropagation();
        if(activeServers){
            history.push(`/servers/${id}`);
        }
    };

    return <Table className={`${classes.mainTable} ${serverType} ServersTable`}>
        <TableHead>
            <TableRow className="withAction">
                <TableCell>
                    <Checkbox
                        className="checkBox"
                        indeterminate={selected.length > 0 && selected.length < data.length}
                        checked={selected.length === data.length && data.length !== 0}
                        onChange={actionSelectAll}
                    />
                </TableCell>
                {columnData.map(column => {
                    const { id, label, types } = column;
                    if(_.indexOf(types, serverType) === -1) return;
                    const sort = getSort(id);
                    return <TableCell
                        key={id}
                        onClick={actionSort(column.sort ? sort : '')}
                        className={!column.sort ? 'noSort' : ''}
                    >
                        <div className="headerCellContent">
                            <span className={sortBy === sort ? 'sortColumn': ''}>{label}</span>
                            {column.sort && <ArrowIcon
                                className={`sortIcon ${sortBy === sort ? 'active' : ''} ${sortBy === sort ? order : ''}`}
                            />}
                        </div>
                    </TableCell>;
                })}
            </TableRow>
        </TableHead>
        <TableBody>
            {data.map((server, index) => {
                const isSelected = isItemSelected(server.id);
                return <TableRow
                    className={`${isSelected ? 'selected' : ''}
                                ${!server.is_online ? 'inactive' : ''}
                                ${activeServers ? 'withAction' : ''}`}
                    key={index}
                    onClick={onHandleServerDetails(server.id)}
                >
                    <TableCell
                        className="withAction"
                        onClick={actionSelectServer(server.id)}
                    ><Checkbox className="checkBox" checked={isSelected}/></TableCell>
                    {columnData.map((column) => {
                        const { id, types } = column;
                        if(_.indexOf(types, serverType) === -1) return;
                        const value = getValue(id, server);
                        return <TableCell
                            key={id}
                        >{value || '---'}</TableCell>
                    })}
                </TableRow>
            })}
        </TableBody>
    </Table>;
};

export default withStyles(tableStyle)(ServersTable);
