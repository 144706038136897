import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// Material UI
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
// own components
import Loader from '../general/Loader';
import ErrorMsg from '../general/ErrorMsg';
import InfoMsg from '../general/InfoMsg';
import ReplayIcon from '@material-ui/icons/Replay';
// style
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/general/cardCompStyle';

class CustomerCard extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            expanded: {
                customerData: true,
                serversData: true,
                nodeGroupsData: true,
            },
        }
    }

    handleExpandClick = (title) => (evt) => {
        let _expanded = this.state.expanded;
        _expanded[title] = !_expanded[title];
        this.setState({ expanded: _expanded });
    };

    render() {
        const { expanded } = this.state;
        const { classes, handleReload, mainContent, title, compKey, _customer } = this.props;
        const { loading, error, item } =  _customer;

        let dataVolume, noDataMsg;
        if(compKey === 'customerData'){
            dataVolume = Object.keys(item).length;
            noDataMsg = "Customer data not found.";
        }else if(compKey === 'serversData'){
            dataVolume = Object.keys(item).length > 0 ? item.servers.length : 0;
            noDataMsg = "No servers found."
        }else if(compKey === 'nodeGroupsData'){
            dataVolume = Object.keys(item).length > 0 ? item.node_groups.length : 0;
            noDataMsg = "No circles found for this customer."
        }

        const isLoadingError = !loading && error;
        const isSuccessWithoutData = !loading && !error && dataVolume === 0;
        const isSuccessWithData = !loading && !error && dataVolume > 0;

        return <Card className={classes.card}>
            <CardHeader
                title={title}
                classes={{'title': classes.cardHeaderTitle}}
                onClick={this.handleExpandClick(compKey)}
                action={
                    <IconButton
                        className={`${classes.expand} ${expanded[compKey] ? 'open' : ''}`}
                        aria-expanded={this.state.expanded[compKey]}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                }
            />
            <Collapse in={this.state.expanded[compKey]} timeout="auto" unmountOnExit>
                <CardContent className={classes.withoutSpacingCardContent}>
                    {loading && <Loader size="small" />}
                    {isLoadingError && <ErrorMsg
                        size="small"
                        msg={`Loading data error has occurred: ${error.message}`}
                        action={handleReload}
                        actionTitle="Retry loading"
                        actionIcon={<ReplayIcon/>}
                    />}
                    {isSuccessWithoutData && <InfoMsg
                        size="small"
                        msg={noDataMsg}
                    />}
                    {isSuccessWithData && mainContent}
                </CardContent>
            </Collapse>
        </Card>
    }
}

const mapStateToProps = (state) => {
    return {
        _customer: state.customer,
    };
};

export default compose(
    withStyles(style),
    connect(mapStateToProps, null),
)(CustomerCard);
