const initialState = {
    data: [],
    pages: 1,
    loading: true,
    error: null
};

const serversReducer = (state = initialState, action) => {
    switch (action.type){
        case 'SET_SERVERS_LOADING':
            return {
                ...state,
                loading: true
            };
        case 'SET_SERVERS_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload.servers,
                pages: action.payload.pages,
                items: action.payload.items,
                error: null
            };
        case 'SET_SERVERS_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        default:
            return state;
    }
};

export default serversReducer;
