import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _ from 'lodash';
// material UI
import Collapse from '@material-ui/core/Collapse';
// own components
import DatePickerComp from '../general/DatePickerComp';
import AdvSrcBtnContainer from '../general/AdvSrcBtnContainer';
import SearchHeaderComp from '../general/SearchHeaderComp';
import PaginationComp from '../general/PaginationComp';
import InputTextComp from '../general/InputTextComp';
// action
import { setLogsFilters } from '../../actions/logs/filters';
import { startSetLogs } from '../../actions/logs/logs';
// style
import { withStyles } from '@material-ui/core/styles';
import toolbarStyle from '../../styles/jss/components/general/toolbarStyle';

const searchData = [
    { id: 'startDate',  label: 'Start date',    placeholder: 'DD-MM-YYYY hh:mm' },
    { id: 'endDate',    label: 'End date',      placeholder: 'DD-MM-YYYY hh:mm' },
    { id: 'action',     label: 'Action',        placeholder: 'e.g. update' },
    { id: 'user',       label: 'User',          placeholder: 'e.g. Max Mustermann'},
    { id: 'customer',   label: 'Customer',      placeholder: 'e.g. Body Fit' },
    { id: 'server',     label: 'Server',        placeholder: 'e.g. MSI normal' },
    { id: 'ipAddress',  label: 'IP address',    placeholder: 'e.g. 192.168.11.78' },
];
const WAIT_INTERVAL = 700;

class LogsTableToolbar extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isAdvancedSearch: this.props.logsFilters.search[1].length > 0
        };
    }

    componentWillMount() {
        this.timer = null;
    }

    clearSearch = () => {
        const { search } = this.props.logsFilters;
        if(search[0].length > 0){
            this.setSearch('');
        }
    };

    setSearch = (searchStr) => {
        clearTimeout(this.timer);
        const { ...rest } = this.props.logsFilters;
        const filters = {
            ...rest,
            search: [searchStr,[]]
        };
        this.props.setLogsFilters(filters);
        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
    };

    onHandleChange = (e) => {
        const searchStr = e.currentTarget.value;
        this.setSearch(searchStr);
    };

    triggerChange = () => {
        const { ...rest } = this.props.logsFilters;
        const filters = {
            ...rest,
            page: 1
        };
        this.props.setLogsFilters(filters);
        this.props.getLogs();
    };

    onHandleAdvancedSearch = () => {
        const { search } = this.props.logsFilters;
        if(search[1].length > 0){
            this.triggerChange();
        }
    };

    onHandleResetSearch = () => {
        const { search, ...rest } = this.props.logsFilters;
        if(search[1].length > 0) {
            const filters = {
                ...rest,
                page: 1,
                search: ['', []]
            };
            this.props.setLogsFilters(filters);
            this.props.getLogs();
        }
    };

    onAdvancedSearchChange = (name, value) => {
        this.changeSearchFilters(name, value);
    };

    triggerAdvancedSearch = () => {
        const isAdvancedSearch = this.state.isAdvancedSearch;
        this.setState({ isAdvancedSearch : !isAdvancedSearch });
    };

    handleNavigate = ( direction ) => ( evt ) => {
        const pages = this.props.logsObj.pages;
        const { page, ...rest } = this.props.logsFilters;
        const isDirectionNext = (direction === 'next') && (page <= pages && page !== pages && page > 0);
        const isDirectionPrev = (direction === 'prev') && (page <= pages && page !== 1 && page > 0);
        if(isDirectionNext || isDirectionPrev){
            const newPage = isDirectionNext ? page + 1 : page - 1;
            const filters = {
                ...rest,
                page: newPage
            };
            this.props.setLogsFilters(filters);
            this.props.getLogs();
        }
    };

    handleRowsChange = (name, value) => {
        const { rows, ...rest } = this.props.logsFilters;
        const _rows = parseInt(value, 10);
        if(_rows !== rows){
            const filters = {
                ...rest,
                rows: _rows,
                page: 1
            };
            this.props.setLogsFilters(filters);
            this.props.getLogs();
        }
    };

    handlePageChange = (name, value) => {
        const { ...rest } = this.props.logsFilters;
        const filters = {
            ...rest,
            page: value
        };
        this.props.setLogsFilters(filters);
        this.props.getLogs();
    };

    handlePageNumberChange = (name, value, allowUpdate) => {
        if(allowUpdate){
            const { ...rest } = this.props.logsFilters;
            const filters = {
                ...rest,
                page: value
            };
            this.props.setLogsFilters(filters);
        }
    };

    changeSearchFilters = (id, value) => {
        let params = this.props.logsFilters.search[1];
        let filteredParams = _.filter(params, (el) => { return el[id] });
        if(filteredParams.length > 0){
            params.forEach((el, index) => {
                const [key] = Object.keys(el);
                if(key === id) params[index][key] = value;
            });
            params = _.filter(params, (el) => {
                let [ v ] = _.values(el);
                return v !== '';
            });
        }else{
            if(value) params.push({ [id]: value });
        }

        const { ...rest } = this.props.logsFilters;
        const filters = {
            ...rest,
            search: ['', params]
        };
        this.props.setLogsFilters(filters);
    };

    handleDateChange = (name, date) => {
        const t = date ? date.valueOf(): '';
        this.changeSearchFilters(name, t);
    };

    render(){
        const { classes, logsFilters, logsObj } = this.props;
        const { pages, loading } = logsObj;
        const { isAdvancedSearch } = this.state;
        const { search, page, rows } = logsFilters;
        const [ searchAll, searchQuery ] = search;
        const resetAdvancedSearch = searchQuery.length > 0;

        return <div>
            <div className={`${classes.root} singleItem`}>
                <PaginationComp
                    handleNavigate={this.handleNavigate}
                    page={page}
                    pages={pages}
                    handlePageChange={this.handlePageChange}
                    handlePageNumberChange={this.handlePageNumberChange}
                    rows={rows}
                    handleRowsChange={this.handleRowsChange}
                />
            </div>
            <div className={classes.searchContainer}>
                <SearchHeaderComp
                    searchId="search-all"
                    searchAll={searchAll}
                    clearSearch={this.clearSearch}
                    searching={searchAll.length > 0 && loading}
                    searchQuery={searchQuery}
                    triggerAdvancedSearch={this.triggerAdvancedSearch}
                    isAdvancedSearch={isAdvancedSearch}
                    onHandleChange={this.onHandleChange}
                />
                <Collapse
                    className={classes.collapse}
                    in={isAdvancedSearch}
                    timeout={{enter: 270, exit:0}}
                    unmountOnExit
                >
                    <form className={classes.inputsContainer} >
                        {searchData.map((el) => {
                            let [ valueObj ] = _.filter(searchQuery, (query) => {
                                let [ key ] = Object.keys(query);
                                return key === el.id;
                            });
                            let value = valueObj ? valueObj[el.id] : '';

                            if(el.id === 'startDate' || el.id === 'endDate'){
                                let startDate, endDate, date, filterV;
                                date = value ? new Date(parseInt(value, 10)) : null;
                                filterV = el.id === 'startDate' ? 'endDate' : 'startDate';
                                let [ obj ] = _.filter(searchQuery, (query) => {
                                    let [ key ] = Object.keys(query);
                                    return key === filterV;
                                });
                                startDate = el.id === 'startDate' ? date : (obj ? new Date(parseInt(obj.startDate, 10).valueOf()) : null);
                                endDate = el.id === 'endDate' ? date : (obj ? new Date(parseInt(obj.endDate, 10).valueOf()) : null);

                                return  <DatePickerComp
                                    key={el.id}
                                    label={el.label}
                                    name={el.id}
                                    isClearable={true}
                                    placeholderText={el.placeholder}
                                    dateFormat="dd-MM-yyyy HH:mm"
                                    date={date}
                                    startDate={startDate}
                                    endDate={endDate}
                                    handleChangeDate={this.handleDateChange}
                                    selectsStart={el.id === 'startDate'}
                                    selectsEnd={el.id === 'endDate'}
                                    showTimeSelect={true}
                                    timeFormat="HH:mm"
                                    timeIntervals={5}
                                />;
                            }
                            return <InputTextComp
                                key={el.id}
                                name={el.id}
                                label={el.label}
                                value={value}
                                placeholder={el.placeholder}
                                action={this.onAdvancedSearchChange}
                            />;
                        })}
                    </form>
                    <AdvSrcBtnContainer
                        onHandleAdvancedSearch={this.onHandleAdvancedSearch}
                        onHandleResetSearch={this.onHandleResetSearch}
                        resetAdvancedSearch={resetAdvancedSearch}
                    />
                </Collapse>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        logsFilters: state.logsFilters,
        logsObj: state.logs
    };
};

const mapDispatchToProps = (dispatch) => ({
    startSetLogs: (params) => dispatch(startSetLogs(params)),
    setLogsFilters: (filters) => dispatch(setLogsFilters(filters)),
});

export default compose(
    withStyles(toolbarStyle),
    connect(mapStateToProps, mapDispatchToProps),
)(LogsTableToolbar);

