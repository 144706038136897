import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _ from 'lodash';
// actions
import { setCustomersFilters } from '../../actions/customers/filters';
// reducers
import { customersFiltersDefault } from '../../reducers/customers/filters';
// Material UI components
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse';
// own components
import AdvSrcBtnContainer from '../general/AdvSrcBtnContainer';
import SearchHeaderComp from '../general/SearchHeaderComp';
import PaginationComp from '../general/PaginationComp';
import InputTextComp from '../general/InputTextComp';
// styles
import { withStyles } from '@material-ui/core/styles';
import toolbarStyle from '../../styles/jss/components/general/toolbarStyle';

const searchData = [
    { id: 'number',         label: 'Customer number',   placeholder: 'e.g. 1254',           deletedCustomer: true },
    { id: 'milon_care_id',  label: 'Milon CARE ID',     placeholder: 'e.g. 54',             deletedCustomer: true },
    { id: 'name',           label: 'Name',              placeholder: 'e.g. Fit Body',       deletedCustomer: true },
    { id: 'contact_person', label: 'Contact person',    placeholder: 'e.g. Marten',         deletedCustomer: true },
    { id: 'street',         label: 'Street',            placeholder: 'e.g. Leopoldstraße',  deletedCustomer: true },
    { id: 'zip',            label: 'Zip',               placeholder: 'e.g. 80803',          deletedCustomer: true },
    { id: 'city',           label: 'City',              placeholder: 'e.g. Munich',         deletedCustomer: true },
    { id: 'country',        label: 'Country',           placeholder: 'e.g. Germany',        deletedCustomer: true },
//    { id: 'node_group',     label: 'Circle group',      placeholder: 'e.g. KAZ',            deletedCustomer: false },
//    { id: 'device_name',    label: 'Devices',           placeholder: 'e.g. Q LAT PULL V2',  deletedCustomer: false },
//    { id: 'device_version', label: 'Device version',    placeholder: 'e.g. 1.0.1',          deletedCustomer: false },
];

const WAIT_INTERVAL = 700;

class CustomersTableToolbar extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isAdvancedSearch: this.props.customersFilters.search[1].length > 0
        };
    }

    componentWillMount() {
        this.timer = null;
    }

    clearSearch = () => {
        const { search } = this.props.customersFilters;
        if(search[0].length > 0){
            this.setSearch('');
        }
    };

    setSearch = (searchStr) => {
        clearTimeout(this.timer);
        const { ...rest } = this.props.customersFilters;
        const filters = {
            ...rest,
            search: [searchStr,[]]
        };
        this.props.setCustomersFilters(filters);
        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
    };

    onHandleChange = (e) => {
        const searchStr = e.currentTarget.value;
        this.setSearch(searchStr);
    };

    triggerChange = () => {
        const { ...rest } = this.props.customersFilters;
        const filters = {
            ...rest,
            page: 1
        };
        this.props.setCustomersFilters(filters);
        this.props.getCustomers();
    };

    onHandleAdvancedSearch = () => {
        const { search } = this.props.customersFilters;
        // if(search[1].length > 0){
        this.triggerChange();
        // }
    };

    onHandleResetSearch = () => {
        const { search, ...rest } = this.props.customersFilters;
        if(search[1].length > 0) {
            const filters = {
                ...rest,
                search: ['', []]
            };
            this.props.setCustomersFilters(filters);
            this.props.getCustomers();
        }
    };

    onAdvancedSearchChange = (name, value) => {
        const { search, ...rest } = this.props.customersFilters;

        let params = search[1];
        let filteredParams = _.filter(params, (el) => { return el[name] });
        if(filteredParams.length > 0){
            params.forEach((el, index) => {
                const [key] = Object.keys(el);
                if(key === name) params[index][key] = value;
            });
            params = _.filter(params, (el) => {
                let [ v ] = _.values(el);
                return v !== '';
            });
        }else{
            if(value) params.push({ [name]: value });
        }

        const filters = {
            ...rest,
            search: ['', params]
        };
        this.props.setCustomersFilters(filters);
    };

    triggerAdvancedSearch = () => {
        const isAdvancedSearch = this.state.isAdvancedSearch;
        this.setState({ isAdvancedSearch : !isAdvancedSearch });
    };

    onCustomersTypeChange = (deleted) => (evt) => {
        const filters = {
            ...customersFiltersDefault,
            deleted: deleted
        };
        this.props.setCustomersFilters(filters);
        this.props.getCustomers();
    };

    handleNavigate = ( direction ) => ( evt ) => {
        const pages = this.props.customersObj.pages;
        const { page, ...rest } = this.props.customersFilters;
        const isDirectionNext = (direction === 'next') && (page <= pages && page !== pages && page > 0);
        const isDirectionPrev = (direction === 'prev') && (page <= pages && page !== 1 && page > 0);
        if(isDirectionNext || isDirectionPrev){
            const newPage = isDirectionNext ? page + 1 : page - 1;
            const filters = {
                ...rest,
                page: newPage
            };
            this.props.setCustomersFilters(filters);
            this.props.getCustomers();
        }
    };

    handleRowsChange = (name, value) => {
        const { rows, ...rest } = this.props.customersFilters;
        if(value !== rows){
            const filters = {
                ...rest,
                page: 1,
                rows: value
            };
            this.props.setCustomersFilters(filters);
            this.props.getCustomers();
        }
    };

    handlePageChange = (name, value) => {
        const { ...rest } = this.props.customersFilters;
        const filters = {
            ...rest,
            page: value
        };
        this.props.setCustomersFilters(filters);
        this.props.getCustomers();
    };

    handlePageNumberChange = (name, value, allowUpdate) => {
        if(allowUpdate){
            const { ...rest } = this.props.customersFilters;
            const filters = {
                ...rest,
                page: value
            };
            this.props.setCustomersFilters(filters);
        }
    };

    render(){
        const { isAdvancedSearch } = this.state;
        const { classes, customersFilters, customersObj, selected } = this.props;
        const { deleted, search, page, rows } = customersFilters;
        const { pages, items, loading } = customersObj;
        const [ searchAll, searchQuery ] = search;
        const resetAdvancedSearch = searchQuery.length > 0;

        const btn = (isDeleted, title) => {
            return <Button
                variant="contained"
                color={deleted === isDeleted ? "primary" : "inherit"}
                onClick={this.onCustomersTypeChange(isDeleted)}
            >{title}</Button>;
        };

        return <div>
            <div className={classes.root}>
                <div className={classes.toolbarBtnContainer}>
                    {btn(false, "Current")}
                    {btn(true, "Deleted")}
                </div>
                <PaginationComp
                    handleNavigate={this.handleNavigate}
                    page={page}
                    pages={pages}
                    items={items}
                    handlePageChange={this.handlePageChange}
                    handlePageNumberChange={this.handlePageNumberChange}
                    rows={rows}
                    handleRowsChange={this.handleRowsChange}
                    toolbarClass="pagesContainer"
                />
            </div>
            <div className={classes.searchContainer}>
                <SearchHeaderComp
                    searchId="search-all"
                    searchAll={searchAll}
                    clearSearch={this.clearSearch}
                    searchQuery={searchQuery}
                    triggerAdvancedSearch={this.triggerAdvancedSearch}
                    isAdvancedSearch={isAdvancedSearch}
                    onHandleChange={this.onHandleChange}
                    searching={searchAll.length > 0 && loading}
                />
                <Collapse
                    className={classes.collapse}
                    in={isAdvancedSearch}
                    timeout={{enter: 270, exit:0}}
                    unmountOnExit
                >
                    <form className={classes.inputsContainer}>
                        {searchData.map((el) => {
                            let [ valueObj ] = _.filter(searchQuery, (query) => {
                                let [ key ] = Object.keys(query);
                                return key === el.id;
                            });
                            let value = valueObj ? valueObj[el.id] : '';

                            if(deleted && !el.deletedCustomer) return;
                            return <InputTextComp
                                key={el.id}
                                name={el.id}
                                label={el.label}
                                value={value}
                                placeholder={el.placeholder}
                                action={this.onAdvancedSearchChange}
                            />;
                        })}
                    </form>
                    <AdvSrcBtnContainer
                        onHandleAdvancedSearch={this.onHandleAdvancedSearch}
                        onHandleResetSearch={this.onHandleResetSearch}
                        resetAdvancedSearch={resetAdvancedSearch}
                    />
                </Collapse>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        customersObj: state.customers,
        customersFilters: state.customersFilters,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setCustomersFilters: (filters) => dispatch(setCustomersFilters(filters))
});

export default compose(
    withStyles(toolbarStyle),
    connect(mapStateToProps, mapDispatchToProps),
)(CustomersTableToolbar);

