const initialState = {
    item: {},
    loading: true,
    error: null,
    errorEdit: null,
};

const serverReducer = (state = initialState, action) => {
    switch (action.type){
        case 'SET_SERVER_LOADING':
            return {
                ...state,
                loading: true
            };
        case 'SET_SERVER_SUCCESS':
            return {
                ...state,
                loading: false,
                item: action.payload.server,
                error: null,
                errorEdit: null
            };
        case 'SET_SERVER_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case 'SET_EDIT_SERVER_FAILURE':
            return {
                ...state,
                loading: false,
                errorEdit: action.payload.errorEdit
            };
        default:
            return state;
    }
};

export default serverReducer;

