import {
} from "../../material-base";

const style = theme => {
    return {
        forTable: {
            width: 'calc(100% - 308px)',
            height: '100%',
            backgroundColor: '#e0e0e01c',
            position: 'absolute',
            zIndex: '2',
            [theme.breakpoints.down("sm")]: {
                width: 'calc(100% - 20px)',
            },
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        small: {
            minWidth: '250px',
            minHeight: '200px'
        },
        main: {
            minHeight: '40vh',
        }
    }
};

export default style;