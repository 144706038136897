import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// Material UI
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
// own components
import SimpleModal from '../general/SimpleModal';
// style
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/servers/screenshotsStyle';

class ScreenshotsList extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            isOpenedModal: ''
        };
    }

    handleScaleImg = (img) => (evt) => {
        this.setState({ isOpenedModal: img });
    };

    handleClose = () => {
        this.setState({ isOpenedModal: '' });
    };

    render() {
        const { isOpenedModal } = this.state;
        const { classes, _screenshots } = this.props;
        const screenshots = _screenshots.data;

        return <div className={classes.container}>
           <div className={classes.imgWrapper}>
                <GridList cellHeight={180} className={classes.gridList}>
                    {screenshots.map((img) => {
                        return <GridListTile
                            className={classes.imgItem}
                            key={img.id}
                            onClick={this.handleScaleImg(img)}
                        >
                            <img src={img.url} alt={img.title} />
                            <GridListTileBar
                                title={`Screenshot #${img.screen_id}`}
                                subtitle={<span>{img.created_at}</span>}
                                classes={{
                                    root: classes.imgTitleBar,
                                    title: classes.imgTitle,
                                }}
                            />
                        </GridListTile>
                    })}
                </GridList>
            </div>
            {isOpenedModal && <SimpleModal
                title={`Screenshot #${isOpenedModal.screen_id} created at ${isOpenedModal.created_at}`}
                isOpenedModal={!!isOpenedModal}
                handleClose={this.handleClose}
                content={<img src={isOpenedModal.url} alt={isOpenedModal.title} />}
            />}
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        _screenshots: state.screenshots
    };
};

export default compose(
    withStyles(style),
    connect(mapStateToProps, undefined),
)(ScreenshotsList);
