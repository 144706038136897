import React from 'react';
import moment from 'moment';
// Material UI components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowIcon from '@material-ui/icons/ArrowUpward';
// style
import { withStyles } from '@material-ui/core/styles';
import tableStyle from '../../styles/jss/components/general/tableStyle';

const columnData = [
    { id: 't',          data: 't',              label: 'Date & Time',   sort: true },
    { id: 'user',       data: 'user',           label: 'User',          sort: true },
    { id: 'customer',   data: 'customer.name',  label: 'Customer',      sort: true },
    { id: 'server',     data: 'server',         label: 'Server',        sort: true },
    { id: 'action',     data: 'action',         label: 'Action',        sort: true },
    { id: 'ipAddress',  data: 'ipAddress',      label: 'IP Address',    sort: true },
];

const LogsTable = ({
    classes,
    data,
    action,
    sortBy,
    order
}) => {
    return <Table className={`${classes.mainTable} LogsTable`}>
        <TableHead>
            <TableRow className="withAction">
                {columnData.map(column => {
                    return <TableCell
                        key={column.id}
                        onClick={action(column.sort ? column.id : '')}
                        className={!column.sort ? 'noSort' : ''}
                    >
                        <div className="headerCellContent">
                            <span className={sortBy === column.id ? 'sortColumn': ''}>{column.label}</span>
                            {column.sort && <ArrowIcon
                                className={`sortIcon ${sortBy === column.id ? 'active' : ''} ${sortBy === column.id ? order : ''}`}
                            />}
                        </div>
                    </TableCell>;
                })}
            </TableRow>
        </TableHead>
        <TableBody>
            {data.map(n => {
                return <TableRow key={n.id}>
                    {columnData.map((dataItem, index) => {
                        let [ key1, key2 ] = dataItem.data.split('.');
                        let value = key1 && key2 ?  n[key1][key2] : n[key1];
                        if(key1 === 't'){
                            value = value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '---';
                        }else{
                            value = value ? value : '---';
                        }
                        return <TableCell
                            key={index}
                        >{value}</TableCell>
                    })}
                </TableRow>
            })}
        </TableBody>
    </Table>;
};

export default withStyles(tableStyle)(LogsTable);
