import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// Material UI
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
// own components
import ChipComp from '../general/ChipComp';
import NodeDetailsModal from './NodeDetailsModal';
// style
import { withStyles } from '@material-ui/core/styles';
import tableStyle from '../../styles/jss/components/general/tableStyle';

class NodeGroups extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            isOpenedModal: '',
        }
    }

    onHandleMacDetails = (device) => (evt) => {
        this.setState({ isOpenedModal: device });
    };

    handleCloseModal = () => {
        this.setState({ isOpenedModal: '' });
    };

    render() {
        const { classes, _customer } = this.props;
        const { node_groups } = _customer.item;
        const nodeColumns = ["device_type", "devices", "id"];
        return <div>
            {node_groups.map((ng) => {
                return <Table className={`${classes.mainTable} cardTable withHeader NodeGroups`} key={ng.id}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <ChipComp
                                    value={ng.type}
                                    label={ng.type}
                                    className={`tag ${ng.type}`}
                                />
                                {ng.name}
                            </TableCell>
                            <TableCell numeric>{ng.id}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ng.nodes.map((node) => {
                            return <TableRow key={node.id} className="nodeItem">
                                {nodeColumns.map((n, index) => {
                                    let value = node[n];
                                    if(n === "devices"){
                                        value = value.map((device) => {
                                            if(device.info.length < 1){
                                                return <span
                                                    key={device.id}
                                                    className="miniSpanContent"
                                                >{device.address}</span>
                                            }
                                            return <ChipComp
                                                key={device.id}
                                                value={device.address}
                                                action={this.onHandleMacDetails(device)}
                                                label={device.address}
                                                clickable={true}
                                                className="tblCellItem"
                                            />
                                        });
                                    }
                                    if(value.length === 0) value = '---';

                                    return <TableCell
                                        numeric={n === "id"}
                                        key={index}
                                    >{value}</TableCell>
                                })}
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            })}
            <NodeDetailsModal
                isOpenedModal={this.state.isOpenedModal}
                handleClose={this.handleCloseModal}
            />
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        _customer: state.customer
    };
};

export default compose(
    withStyles(tableStyle),
    connect(mapStateToProps, null),
)(NodeGroups);
