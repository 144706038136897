import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/auth/auth';
import browsersReducer from '../reducers/servers/browsers';
import customerReducer from '../reducers/customers/customer';
import customersReducer from '../reducers/customers/customers';
import customersFiltersReducer from '../reducers/customers/filters';
import devicesReducer from '../reducers/devices/devices';
import devicesUpdateReducer from '../reducers/devices/update';
import devicesSoftwareReducer from '../reducers/devices/software';
import logReducer from '../reducers/logs/log';
import logsReducer from '../reducers/logs/logs';
import logsFiltersReducer from '../reducers/logs/filters';
import screenshotsReducer from '../reducers/screenshots/screenshots';
import serverRestartReducer from '../reducers/servers/serverRestart';
import serverReducer from '../reducers/servers/server';
import serversReducer from '../reducers/servers/servers';
import serversFiltersReducer from '../reducers/servers/filters';
import startVpnReducer from '../reducers/servers/startVpn';
import stopVpnReducer from '../reducers/servers/stopVpn';
import userReducer from '../reducers/auth/user';

// for redux dev tool
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__COMPOSE__ || compose;

// Store creation
export default () => {
    const store = createStore(
        combineReducers({
            auth: authReducer,
            browsers: browsersReducer,
            customer: customerReducer,
            customers: customersReducer,
            customersFilters: customersFiltersReducer,
            devices: devicesReducer,
            devicesUpdate: devicesUpdateReducer,
            devicesSoftware: devicesSoftwareReducer,
            log: logReducer,
            logs: logsReducer,
            logsFilters: logsFiltersReducer,
            screenshots: screenshotsReducer,
            server: serverReducer,
            serverRestart: serverRestartReducer,
            servers: serversReducer,
            serversFilters: serversFiltersReducer,
            startVpn: startVpnReducer,
            stopVpn: stopVpnReducer,
            user: userReducer,
        }),
        composeEnhancers(applyMiddleware(thunk))
    );
    return store;
};


