import {
    infoBoxShadow,
    dangerBoxShadow
} from "../../material-base";

const style = theme => {
    return {
        actionBtn: {
            ...infoBoxShadow,
            '& svg': {
                marginRight: theme.spacing.unit/2,
                fontSize: 18
            },
            '&.tblItemComp': {
                margin: `0 ${theme.spacing.unit/2}px ${theme.spacing.unit/2}px 0`
            },
            '&.danger': {
                ...dangerBoxShadow,
                backgroundColor: theme.palette.error.main,
                color: 'white',
                marginRight: '10px',
                '&:hover': {
                    backgroundColor: theme.palette.error.dark
                },
            }
        },
    }
};

export default style;