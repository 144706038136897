import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// own components
import SelectComp from '../general/SelectComp';
import ActionButtonComp from '../general/ActionButtonComp';
// actions
// style
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/servers/bandwidthTestStyle';

const bandwidthTimeSlots = [
    { key: 'empty', value: 'No selection' },
    { key: 900,     value: '15 minutes' }, // 15 min * 60 sec,
    { key: 3600,    value: '1 hour' },
    { key: 21600,   value: '6 hours' },
    { key: 43200,   value: '12 hours' },
    { key: 86400,   value: '1 day' },
    { key: 259200,  value: '3 days' },
    { key: 604800,  value: '1 week' }
];

class BandwidthTest extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            bandwidthTime: 'empty'
        }
    }

    handleBandwidthTest = (evt) => {
        // TODO: GET and PUT bandwidth
        console.log('TODO: handle bandwidth test -> send request', this.state.bandwidthTime );
    };

    handleSelectTimeSlot = (name, value) => {
        this.setState({ [name]: value });
    };

    render() {
        const { bandwidthTime } = this.state;
        const { classes } = this.props;

        return <div className={classes.bwContainer}>
            <SelectComp
                label="Choose time"
                name="bandwidthTime"
                value={bandwidthTime}
                options={bandwidthTimeSlots}
                inactive={bandwidthTime === 'empty'}
                fixedWidth={true}
                action={this.handleSelectTimeSlot}
            />
            <div className={classes.miniBtnWrapper}>
                <ActionButtonComp
                    active={bandwidthTime !== 'empty'}
                    action={this.handleBandwidthTest}
                    title="Start test"
                />
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        //_bandwidth: state.bandwidth
    };
};

const mapDispatchToProps = (dispatch) => ({

});

export default compose(
    withStyles(style),
    connect(mapStateToProps, mapDispatchToProps),
)(BandwidthTest);
