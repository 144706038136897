import { BrowserRestartServerReq } from '../../pb/server_pb';
import { serverPromiseService } from '../libs/request-clients';
import { setServerSuccess } from '../servers/server';
import { getMetadata } from '../libs/request-helpers';

export const setBrowsersLoadingProgress = (serverId, stateText = '') => ({
    type: 'SET_BROWSERS_LOADING_PROGRESS',
    payload: { serverId, stateText }
});

export const setBrowsersSuccess = () => ({
    type: 'SET_BROWSERS_SUCCESS'
});

export const setBrowsersError = (error) => ({
    type: 'SET_BROWSERS_FAILURE',
    payload: { error }
});

export const restartServerBrowsers = (server_id) => {
    return (dispatch, getState) => {
        server_id = parseInt(server_id, 10);

        dispatch(setBrowsersLoadingProgress(server_id));

        let browserRestartServerReq = new BrowserRestartServerReq();
        browserRestartServerReq.setIdList([server_id]);
        browserRestartServerReq.setWaitForResult(true); // true for one server
        let stream = serverPromiseService.browserRestart(browserRestartServerReq, getMetadata());

        return new Promise((resolve, reject) => {
            let data = {
                completed: false,
                msg: ''
            };

            stream.on('error', (error) => {
                error.message = `Error occurred while restarting browsers: ${error.message}`;
                dispatch(setBrowsersError(error));
                reject(error);
            });

            stream.on('data', (response) => {
                data.completed  = response.getCompleted();
                data.msg        = response.getStateText();
                dispatch(setBrowsersLoadingProgress(server_id, data.msg));
            });

            stream.on('status', (status) => {
                if (status.code !== 0) {
                    const message = `Error occurred while restarting browsers: ${status.details}`;
                    const err = {code: status.code, message: message};
                    dispatch(setBrowsersError(err));
                    reject(err);
                    return;
                }

                // Success
                dispatch(setBrowsersSuccess());
                // TODO Update server with dispatch
                //dispatch(setServerSuccess(serverData));
                resolve(data);
            });
        });
    }
};
