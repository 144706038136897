import {
    GetServerReq,
    UpdateServerReq,
} from '../../pb/server_pb';
import { serverPromiseService } from '../libs/request-clients';
import { getMetadata, handleResponseError, getVpnStatus, getDateTime, getDate } from '../libs/request-helpers';

export const setServerLoading = () => ({
    type: 'SET_SERVER_LOADING'
});

export const setServerSuccess = (server) => ({
    type: 'SET_SERVER_SUCCESS',
    payload: { server }
});

export const setEditServerError = (errorEdit) => ({
    type: 'SET_EDIT_SERVER_FAILURE',
    payload: { errorEdit }
});

export const setServerError = (error) => ({
    type: 'SET_SERVER_FAILURE',
    payload: { error }
});

const getLoad = (value) => {
    value = value ? Math.round(value * 100)/100 : value;
    return value;
};

const getInGB = (value) => {
    value = value ? Math.round(value/1000000000) : value;
    return value;
};

const getInMB = (value) => {
    value = value ? Math.round(value/1000000) : value;
    return value;
};

const getAgentVersion = (valueCurrent, valueShould) => {
    if (!valueShould || valueShould == valueCurrent) {
        return valueCurrent;
    }

    return valueCurrent + ' (should: ' + valueShould + ')';
};

const getTimeDelta = (value) => {
    if (!value) {
        return undefined;
    }

    return value + ' ms';
};

function getMsToHMS(duration) {
    let sign = '';

    if (duration < 0) {
        duration = +-duration;
        sign = '-';
    }

    let milliseconds = parseInt((duration % 1000) / 100),
        seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)));

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return sign + hours + ":" + minutes + ":" + seconds ;
}


const getSoftwareUpdates = (data) => {
    let softwareUpdates = [];
    data.forEach((item) => {
        const version = {
            id:             item.getId(),
            software_id:    item.getSoftwareId(),
            version_id:     item.getVersionId(),
            created_at:     getDateTime(item.getCreatedAt()),
            updated_at:     getDateTime(item.getUpdatedAt()),
        };
        softwareUpdates.push(version);
    });
    return softwareUpdates;
};

export const startSetServer = (id) => {
    return (dispatch, getState) => {

        dispatch(setServerLoading());

        let getServerReq = new GetServerReq();
        getServerReq.setId(id);
        return serverPromiseService.get(getServerReq, getMetadata())
            .then((response) => {
                const data = {
                    id:             response.getId(),
                    hostname:       response.getHostname(),
                    customer_id:    response.getCustomerId(),
                    tag:            response.getTag(),
                    type:           response.getType(),
                    os:             response.getOs(),
                    kernel:         response.getKernel(),
                    is_online:      response.getIsOnline(),
                    lan_mac:        response.getLanMac(),
                    lan_ipv4:       response.getLanIpv4(),
                    lan_ipv6:       response.getLanIpv6(),
                    wan_ipv4:       response.getWanIpv4(),
                    wan_ipv4_ptr:   response.getWanIpv4Ptr(),
                    wan_ipv6:       response.getWanIpv6(),
                    wan_ipv6_ptr:       response.getWanIpv6Ptr(),
                    vpn_status:     getVpnStatus(response.getVpnStatus()),
                    vpn_ipv4:       response.getVpnIpv4(),
                    vpn_ipv6:       response.getVpnIpv6(),
                    hw_vendor:      response.getHwVendor(),
                    hw_product:     response.getHwProduct(),
                    hw_serial:      response.getHwSerial(),
                    browser_url1:   response.getBrowserUrl1(),
                    browser_url2:   response.getBrowserUrl2(),
                    browser_url3:   response.getBrowserUrl3(),
                    browser_url4:   response.getBrowserUrl4(),
                    load1:          getLoad(response.getLoad1()),
                    load5:          getLoad(response.getLoad5()),
                    load15:         getLoad(response.getLoad15()),
                    created_at:     getDateTime(response.getCreatedAt()),
                    updated_at:     getDateTime(response.getUpdatedAt()),
                    deleted_at:     getDateTime(response.getDeletedAt()),
                    up_since:       getDateTime(response.getUpSince()),
                    connected_since:getDateTime(response.getConnectedSince()),
                    last_seen_at:   getDateTime(response.getLastSeenAt()),
                    disk_root_total:getInGB(response.getDiskRootTotal()),
                    disk_root_used: getInGB(response.getDiskRootUsed()),
                    disk_boot_total:getInMB(response.getDiskBootTotal()),
                    disk_boot_used: getInMB(response.getDiskBootUsed()),
                    memory_total:   getInMB(response.getMemoryTotal()),
                    memory_used:    getInMB(response.getMemoryUsed()),
                    swap_total:     getInMB(response.getSwapTotal()),
                    swap_used:      getInMB(response.getSwapUsed()),
                    agent_version:     getAgentVersion(response.getAgentVersionCurrent(), response.getAgentVersionShould()),
                    time_delta:        getMsToHMS(response.getTimeDelta()),
                    software_updates:  getSoftwareUpdates(response.getSoftwareUpdatesList()),
                    notes:             response.getNotes(),
                    documentation_url: response.getDocumentationUrl(),
                    ntp_servers: response.getNtpServers(),

                    ups_status: response.getUpsStatus(),
                    ups_model: response.getUpsModel(),
                    ups_serial_number: response.getUpsSerialNumber(),
                    ups_firmware: response.getUpsFirmware(),
                    ups_minimum_time_left: response.getUpsMinimumTimeLeft(),
                    ups_minimum_battery_charge_percent: response.getUpsMinimumBatteryChargePercent(),
                    ups_load_percent: response.getUpsLoadPercent(),
                    ups_battery_charge_percent: response.getUpsBatteryChargePercent(),
                    ups_xon_battery: getDateTime(response.getUpsXonBattery()),
                    ups_xoff_battery: getDateTime(response.getUpsXoffBattery()),
                    ups_time_left: getMsToHMS(response.getUpsTimeLeft() / 1000000),
                    ups_time_on_battery: response.getUpsTimeOnBattery(),
                    ups_internal_temp: response.getUpsInternalTemp(),
                    ups_battery_date: getDate(response.getUpsBatteryDate()),
                    ups_number_transfers: response.getUpsNumberTransfers(),

                    sensors: response.getSensorsList(),

                    // TODO:
                    //swVersion: 'Client software version',
                    //sensorInfo: 'test',
                    //usvInfo: 'Informationen zu einer evtl. angeschlossen USV',
                    //selfTestInfo: '',
                };

                dispatch(setServerSuccess(data));
            })
            .catch((err) => {
                dispatch(setServerError(err));
                handleResponseError(dispatch, err);
            });
    };
};

const setServerUpdateData = (updateServerReq, updateData) => {
    const { hostname, type, tag, browser_url1, browser_url2, browser_url3, browser_url4, notes, documentation_url, ntp_servers } = updateData;

    const newData = {
        hostname:           hostname.trim(),
        type:               type.trim().toLowerCase(),
        tag:                tag.trim(),
        browser_url1:       browser_url1.trim(),
        browser_url2:       browser_url2.trim(),
        browser_url3:       browser_url3.trim(),
        browser_url4:       browser_url4.trim(),
        notes:              notes.trim(),
        documentation_url:  documentation_url.trim(),
        ntp_servers:        ntp_servers.trim(),
    };

    updateServerReq.setHostname(newData.hostname);
    updateServerReq.setType(newData.type);
    updateServerReq.setTag(newData.tag);
    updateServerReq.setBrowserUrl1(newData.browser_url1);
    updateServerReq.setBrowserUrl2(newData.browser_url2);
    updateServerReq.setBrowserUrl3(newData.browser_url3);
    updateServerReq.setBrowserUrl4(newData.browser_url4);
    updateServerReq.setNotes(newData.notes);
    updateServerReq.setDocumentationUrl(newData.documentation_url);
    updateServerReq.setNtpServers(newData.ntp_servers);

    return newData;
};

export const startUpdateServer = (id, updateData) => {
    return (dispatch, getState) => {

        let updateServerReq = new UpdateServerReq();
        updateServerReq.setId(id);
        const newData = setServerUpdateData(updateServerReq, updateData);
        return serverPromiseService.update(updateServerReq, getMetadata())
            .then((response) => {
                let data = { ...getState().server.item, ...newData };
                dispatch(setServerSuccess(data));
            })
            .catch((err) => {
                dispatch(setEditServerError(err));
                handleResponseError(dispatch, err);
            });
    };
};
