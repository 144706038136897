import React from 'react';
// routes
import SwitchComponent from '../routers/SwitchComponent';
// core components
import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';
// styles
import { withStyles } from '@material-ui/core/styles';
import primaryLayoutStyle from '../styles/jss/layouts/primaryLayoutStyle';

class PrimaryLayout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mobileOpen: false
        };
    }

    handleDrawerToggle = () => {
        this.setState(() => ({
            mobileOpen: !this.state.mobileOpen
        }));
    };

    render(){
        const { classes } = this.props;
        const { mobileOpen } = this.state;

        return <div className={classes.root}>
            <Header
                handleDrawerToggle={this.handleDrawerToggle}
                mobileOpen={mobileOpen}
                switcher={<SwitchComponent header={true}/>}
            />
            <Sidebar
                handleDrawerToggle={this.handleDrawerToggle}
                mobileOpen={mobileOpen}
                switcher={<SwitchComponent header={true}/>}
            />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <SwitchComponent />
            </main>
        </div>;
    }
}

export default withStyles(primaryLayoutStyle)(PrimaryLayout);

