import {
} from "../../material-base";

const style = theme => {
    return {
        bwContainer: {
            width: `calc(100% - ${theme.spacing.unit * 6}px)`,
            display: 'flex',
            alignItems: 'flex-end',
            margin: `0 ${theme.spacing.unit * 3}px`,
            '@media(max-width: 492px)': {
                flexDirection: 'column',
                alignItems: 'flex-start',
            },
        },
        miniBtnWrapper: {
            marginLeft: theme.spacing.unit * 2,
            '@media(max-width: 492px)': {
                margin: `${theme.spacing.unit * 2}px 0 0 0`,
            },
        }
    }
};

export default style;

