import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
// actions
import { startLogin } from '../../actions/auth/auth';
import { deleteCookie } from '../../actions/libs/cookie';
// Material UI components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
// own components
import ActionButtonComp from '../general/ActionButtonComp';
// styles
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/auth/signInErrorPageStyle';

export class SignInErrorPage extends React.Component {

    onSubmit = () => {
        if(this.props.errorLogin.code === 'stateToken'){
            deleteCookie('stateToken');
        }
        this.props.startLogin();
    };

    componentDidMount() {
        document.addEventListener('keypress', this.onPressKey);
    }

    componentWillUnmount() {
        // unsubscribe event
        document.removeEventListener('keypress', this.onPressKey);
    }

    onPressKey = (e) => {
        if(e.key === 'Enter'){
            this.onSubmit();
        }
    };

    render(){
        const { classes, errorLogin } = this.props;

        return <div className={classes.pageWrapper}>
            <div className={classes.headerWrapper}>
                <img src="/images/milon_logo.png" alt="milon logo" className={classes.logo} />
                <div>Maintenance Portal</div>
            </div>
            <Card className={classes.card}>
                <CardHeader
                    title="Sign-in Error"
                    className={classes.cardHeader}
                />
                <CardContent>
                    <div className={classes.errorMsg}>Login error occurred: {errorLogin.message}</div>
                    <ActionButtonComp
                        fullWidth={true}
                        color="primary"
                        action={this.onSubmit}
                        title="Retry sing-in"
                    />
                </CardContent>
            </Card>
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        errorLogin: state.auth.errorLogin,
    };
};

const mapDispatchToProps = (dispatch) => ({
    startLogin: () => dispatch(startLogin()),
});

export default compose(
    withStyles(style),
    connect(mapStateToProps, mapDispatchToProps),
)(SignInErrorPage);