import {

} from "../../material-base";

const style = theme => {
    return {
        devicesContainer: {
            margin: `0 ${theme.spacing.unit*3}px`,
        },
        deviceItem: {
            display: 'flex',
            alignItems: 'flex-end',
            marginBottom: theme.spacing.unit*2,
            '@media(max-width: 492px)': {
                flexDirection: 'column',
                alignItems: 'flex-start'
            },
        },
        deviceDetails: {
            marginLeft: theme.spacing.unit*2,
            fontSize: 15,
            '@media(max-width: 492px)': {
                margin: `${theme.spacing.unit}px 0 0 0`,
            },
        },
    }
};

export default style;
