import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
// actions
import { startSetCustomers } from '../../actions/customers/customers';
import { startAssignServersToCustomer } from '../../actions/customers/customer';
// Material UI
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// own components
import ActionButtonComp from '../general/ActionButtonComp';
import InputSearchComp from '../general/InputSearchComp';
import NotificationErrorMsg from '../general/NotificationErrorMsg';
// styles
import { withStyles } from '@material-ui/core/styles';
import searchCustomerStyle from '../../styles/jss/components/servers/searchCustomerStyle';

const WAIT_INTERVAL = 700;
const customerColumns = [
    { key: "number",        label: "Customer number" },
    { key: "name",          label: "" },
    { key: "contact_person",label: "" },
    { key: "street",        label: "" },
    { key: "zip_city",      label: "" },
    { key: "country",       label: "" }
];

class SearchCustomers extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            customers: [],
            customersSearch: '',
            selected: '',
            searching: false,
            assignServersErr: ''
        }
    }

    componentWillMount() {
        this.timer = null;
    }

    onHandleSearchCustomer = (e) => {
        const searchStr = e.currentTarget.value;
        this.setCustomersSearch(searchStr);
    };

    onHandleToggle = (id) => (evt) => {
        let selected = id;
        if(this.state.selected === id) selected = '';
        this.setState({ selected });
    };

    setCustomersSearch = (searchStr) => {
        clearTimeout(this.timer);
        this.setState({
            customersSearch: searchStr,
            searching: true
        });
        this.timer = setTimeout(this.triggerCustomersChange, WAIT_INTERVAL);
    };

    triggerCustomersChange = () => {
        if(this.state.customersSearch.length === 0){
            this.setState({
                customersSearch: '',
                customers: [],
                searching: false,
                selected: ''
            });
        }else{
            const params = {
                sortBy: 'name',
                order: 'asc',
                deleted: false,
                search: [this.state.customersSearch, []],
                page: 1,
                rows: 150,
            };
            this.props.startSetCustomers(params)
                .then(() => {
                    this.setState({
                        customers: this.props.customersObj.data,
                        searching: false,
                        selected: ''
                    });
                });
        }
    };

    clearCustomersSearch = () => {
        if(this.state.customersSearch.length > 0){
            this.setState({
                customersSearch: '',
                customers: [],
                selected: ''
            });
        }
    };

    onHandleServersToCustomer = () => {
        const id = this.state.selected;
        if(id){
            this.props.startAssignServersToCustomer(id, this.props.selectedServers)
                .then(() => {
                    const err = this.props._customer.error;
                    if(err){
                        this.setState({ assignServersErr: err.message });
                    }else{
                        this.setState({ assignServersErr: '' });
                        this.props.history.push(`/customers/${id}`);
                    }
                });
        }
    };

    render() {
        const { classes, customersObj, single } = this.props;
        const { error } = customersObj;
        const { customers, customersSearch, selected, searching, assignServersErr } = this.state;
        const foundCustomers = customers.length > 0;

        const getCustomerInfo = (customer) => {
            return <span className={classes.itemTextWrapper}>
                {customerColumns.map((column) => {
                    let value = customer[column.key];
                    switch(column.key) {
                        case 'zip_city':
                            if (!customer.zip && !customer.city) return;
                            return value = customer.zip ? `${customer.zip} ${customer.city}` : customer.city;
                    }
                    if(!value) return;
                    return <span
                        key={column.key}
                        className={column.label ? classes.itemText : ''}
                    >{column.label ? `${column.label}: ` : ''}{value}</span>
                })}
            </span>
        };

        return <div className={classes.notificationSearchWrapper}>
            <h3>{`Assign ${single ? 'server' : 'servers'} to a customer`}</h3>
            <InputSearchComp
                name="searchCustomers"
                placeholder="Search for customer..."
                value={customersSearch}
                inProgress={searching}
                actionClear={this.clearCustomersSearch}
                actionChange={this.onHandleSearchCustomer}
                focused={true}
            />
            {error && <NotificationErrorMsg
                errorMsg={`Loading data error has occurred: ${error.message}.`}
            />}
            {foundCustomers && !error && <div className={classes.contentWrapper}>
                <Card className={classes.foundCustomersCard}>
                    <List className={classes.list}>
                        {customers.map((el, index) => {
                            const customerInfo = getCustomerInfo(el);
                            return <ListItem
                                key={index}
                                role={undefined}
                                dense
                                button
                                onClick={this.onHandleToggle(el.id)}
                                className={`${classes.listItem} ${selected === el.id ? 'active' : ''}`}
                            >
                                <ListItemText primary={customerInfo} />
                            </ListItem>
                        })}
                    </List>
                </Card>
                <ActionButtonComp
                    size="small"
                    active={!!selected}
                    action={this.onHandleServersToCustomer}
                    title={`Assign ${single ? 'server' : 'servers'}`}
                />
                {assignServersErr && <NotificationErrorMsg
                    errorMsg={`Assigning ${single ? 'server' : 'servers'} to customer has failed: ${assignServersErr}.`}
                />}
            </div>}
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        customersObj: state.customers,
        _customer: state.customer
    };
};

const mapDispatchToProps = (dispatch) => ({
    startSetCustomers: (params) => dispatch(startSetCustomers(params)),
    startAssignServersToCustomer: (customer_id, server_ids) => dispatch(startAssignServersToCustomer(customer_id, server_ids))
});

export default compose(
    withStyles(searchCustomerStyle),
    connect(mapStateToProps, mapDispatchToProps),
)(SearchCustomers);