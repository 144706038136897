import _ from 'lodash';
import moment from 'moment';
import { SoftwareUpdateServerReq, SoftwareUpdateVersionReq } from '../../pb/server_pb';
import { serverPromiseService } from '../libs/request-clients';
import { setServerSuccess } from '../servers/server';
import { getMetadata } from '../libs/request-helpers';

export const setDevicesUpdateLoading = (serverId, stateText = '') => ({
    type: 'SET_DEVICES_UPDATE_LOADING',
    payload: { serverId, stateText }
});

export const setDevicesUpdateSuccess = () => ({
    type: 'SET_DEVICES_UPDATE_SUCCESS'
});

export const setDevicesUpdateError = (error) => ({
    type: 'SET_DEVICES_UPDATE_FAILURE',
    payload: { error }
});

const getUpdatedServerDataWithSoftware = (serverData, device_versions) => {
    let software_updates = serverData.software_updates;
    device_versions.forEach((item) => {
        const { software_id, version_id } = item;
        let [ currItem ] = _.filter(software_updates, (el) => { return el.software_id === software_id });
        const position = software_updates.map((el) => { return el.software_id; }).indexOf(software_id);
        if(currItem){
            currItem.updated_at = moment().format('YYYY-MM-DD HH:mm:ss');
            currItem.version_id = version_id;
        }
        if(position > -1 ){
            software_updates[position] = currItem;
        }else{
            software_updates.push({ software_id, version_id, updated_at: moment().format('YYYY-MM-DD HH:mm:ss') });
        }
    });
    serverData.software_updates = software_updates;
    return serverData;
};

export const startUpdateServerDeviceVersions = (server_id, device_versions) => {
    return (dispatch, getState) => {
        server_id = parseInt(server_id, 10);
        dispatch(setDevicesUpdateLoading(server_id));

        let softwareUpdateServerReq = new SoftwareUpdateServerReq();
        softwareUpdateServerReq.setIdList([server_id]);
        softwareUpdateServerReq.setWaitForResult(true); // true for one server

        let versionsList = [];
        device_versions.forEach((item) => {
            const { software_id, version_id } = item;
            if(software_id && version_id){
                let reqVersion = new SoftwareUpdateVersionReq();
                reqVersion.setSoftwareId(software_id);
                reqVersion.setVersionId(version_id);
                versionsList.push(reqVersion);
            }
        });
        softwareUpdateServerReq.setVersionList(versionsList);
        let stream = serverPromiseService.softwareUpdate(softwareUpdateServerReq, getMetadata());

        return new Promise((resolve, reject) => {
            let msg = '';

            stream.on('error', (error) => {
                error.message = `Error occurred while updating software version: ${error.message}`;
                dispatch(setDevicesUpdateError(error));
                reject(error);
            });

            stream.on('data', (response) => {
                msg = response.getStateText();
                dispatch(setDevicesUpdateLoading(server_id, msg));
            });

            stream.on('status', (status) => {
                if(status.code !== 0) {
                    const err = { code: status.code, message: `Error occurred while updating software version: ${status.details}` };
                    dispatch(setDevicesUpdateError(err));
                    reject(err);
                    return;
                }

                // Success -> prepare data for server details
                const serverData = getUpdatedServerDataWithSoftware(getState().server.item, device_versions);
                dispatch(setServerSuccess(serverData));
                dispatch(setDevicesUpdateSuccess());
                resolve({ completed: true });
            });
        });
    };
};
