import React from 'react';
// Material UI
import Snackbar from '@material-ui/core/Snackbar';
// own components
import ActionButtonComp from './ActionButtonComp';
import Notification from './Notification';

class MassActionNotification extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            open: true,
        }
    }

    handleClose = () => {
        this.setState({ open: false });
        if(this.props.cancelAction){
            this.props.cancelAction();
        }
    };

    handleClick = () => {
        if(!this.props.multipleMsgs){
            this.setState({ open: false });
        }
        this.props.handleAction();
    };

    render() {
        const { message, noIcon, topOriented, actionName, actionKey, error } = this.props;

        let action;
        if(actionName && actionKey){
            action = <ActionButtonComp
                key={actionKey}
                className={actionKey === "delete" ? 'danger' : ''}
                size="small"
                active={true}
                action={this.handleClick}
                title={actionName}
            />;
        }

        return <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={this.state.open}
        >
            <Notification
                action={action}
                onClose={this.handleClose}
                variant={error ? "error" : "warning"}
                noIcon={noIcon}
                message={message}
                topOriented={topOriented}
            />
        </Snackbar>
    }
}

export default MassActionNotification;