import React from 'react';
// Material UI components
import Button from '@material-ui/core/Button';
// style
import { withStyles } from '@material-ui/core/styles';
import style from '../../styles/jss/components/general/actionFixedButtonCompStyle';

const ActionFixedButtonComp = ({
    classes,
    color,
    action,
    title,
    icon,
    className
}) => {
    return <Button
        variant="extendedFab"
        color={color ? color : "inherit" }
        className={`${classes.actionFixedBtn} ${className} ${color}`}
        onClick={action}
        title={title}
    >{icon}</Button>
};

export default withStyles(style)(ActionFixedButtonComp);