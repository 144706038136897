import React from 'react';
import { NavLink } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import routes from '../../routers/routes';
// actions
import { startLogout } from '../../actions/auth/auth';
// Material UI components
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
// own components
import LogoutErrorNotification from './LogoutErrorNotification';
// styles
import { withStyles } from '@material-ui/core/styles';
import sidebarStyle from "./../../styles/jss/components/general/sidebarStyle";

const Sidebar = ({ classes, handleDrawerToggle, mobileOpen, startLogout, auth }) => {

    const handleLogout = () => {
        startLogout();
    };

    const retryLogout = () => {
        document.location.reload();
    };

    const drawer = <Drawer
        open
        anchor="left"
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
    >
        <div className={`${classes.toolbar} ${classes.toolbarContentWrapper}`}>
            <img src="/images/milon_logo.png" alt="milon logo" className={classes.logo} />
            <div className={classes.toolbarContent}>
                Maintenance Portal
            </div>
        </div>
        {routes.map((item, index) => {
            if(!item.isNav) return null;
            return <List
                className={classes.list}
                onClick={handleDrawerToggle}
                key={index}
            >
                <NavLink to={item.path} target={item.target || ""} className={classes.item}>
                    <ListItem button className={classes.itemLink}>
                        <ListItemIcon className={classes.itemIcon}>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText className={classes.itemText} disableTypography={true}>
                            {item.sidebarTitle}
                        </ListItemText>
                    </ListItem>
                </NavLink>
            </List>
        })}
        <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.logoutBtn}
            onClick={handleLogout}
        >
            <ListItemText className={classes.itemText} disableTypography={true}>
                 Logout
            </ListItemText>
        </Button>
    </Drawer>;

    return <div>
        <Hidden mdUp>
            <Drawer
                variant="temporary"
                anchor="left"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{ paper: classes.drawerPaper }}
                // Better open performance on mobile.
                ModalProps={{ keepMounted: true }}
            >{drawer}</Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
            <Drawer
                variant="permanent"
                open
                classes={{ paper: classes.drawerPaper }}
            >{drawer}</Drawer>
        </Hidden>
        {auth.errorLogout && <LogoutErrorNotification
            handleAction={retryLogout}
        />}
    </div>;
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => ({
    startLogout: () => dispatch(startLogout()),
});

export default compose(
    withStyles(sidebarStyle),
    connect(mapStateToProps, mapDispatchToProps)
)(Sidebar);
